import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { RequestFormDialogComponent } from '../request-form-dialog/request-form-dialog.component';
import { RequestFormData, SERVICE_ZH } from '../interfaces/request-form-data';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-open-hong-kong-bank',
  templateUrl: './open-hong-kong-bank.component.html',
  styleUrls: ['./open-hong-kong-bank.component.scss']
})
export class OpenHongKongBankComponent implements OnInit {

  lang: string;
  constructor(    public dialog: MatDialog,
    public translateService: TranslateService) {
    this.lang = this.translateService.currentLang;
    this.translateService.onLangChange.subscribe(
      (event) => (this.lang = event.lang)
    );
  }

  ngOnInit(): void {
  }
  openDialog(service: SERVICE_ZH): void {
    const formData: RequestFormData = {
      contact_person: '',
      contact_number: '',
      contact_email: '',
      service_zh: service,
    };

    const requestServiceDialogRef = this.dialog.open(
      RequestFormDialogComponent,
      { data: formData }
    );
    requestServiceDialogRef.afterClosed().subscribe((result) => {
      console.log('dialog closed');
      console.log('result', result);
    });
  }

}
