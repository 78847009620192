<!-- zh -->
<div *ngIf="lang === 'zh'" fxLayout="column" fxLayoutAlign="center stretch">
  <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
    <img src="assets/images/gov-fund.jpg" alt="" class="banner-image" />
  </div>
  <section
    fxLayout="row"
    fxLayoutAlign="center"
    style="background-color: white"
  >
    <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
      <p></p>
      <h1>熱門政府基金資助</h1>

      <!-- BUD -->
      <h2>BUD專項基金 資助高達$400萬</h2>
      <!-- <iframe src="https://www.youtube.com/embed/1rmk2eb0QYc"> </iframe> -->
      <br /><br />
      <img src="assets/images/bud-1.png" class="scheme-image" />
      <br />
      <p>
        BUD專項基金資助香港企業拓展中國內地及自貿協定經濟體（包括東盟十國、澳門、澳洲等）市場，涵蓋發展品牌（Branding）、升轉轉型（Upgrading）及拓展內銷（Domestic
        Sales）三大範疇的項目。
      </p>
      <app-whatsapp [lang]="'zh'"></app-whatsapp>

      <!-- EMF -->
      <h2>中小企市場推廣基金 資助高達$80萬</h2>
      <img src="assets/images/emf-1.png" class="scheme-image" />
      <br />
      <p>
        中小企市場推廣基金資助香港中小企進行出口推廣活動，擴展境外市場。主要資助項目包括參加展覽會、商貿考察團及於商會刊物刊登廣告等。
      </p>
      <app-whatsapp [lang]="'zh'"></app-whatsapp>

      <!-- RETAAS -->
      <!-- <h2>零售業人力需求管理科技應用支援計劃 資助高達$5萬</h2>
      <img src="assets/images/retaas-1.png" class="scheme-image" />
      <br />
      <p>
        零售業人力需求管理科技應用支援計劃（ReTAAS）專為香港零售業界而設，
        資助企業應用科技管理人力資源並提升生產力。常見項目包括POS系統、電子付款系統、建立網店等。
      </p>
      <app-whatsapp [lang]="'zh'"></app-whatsapp> -->

      <!-- TVP -->
      <h2>科技卷計劃 資助高達$60萬</h2>
      <img src="assets/images/tvp.jpg" alt="" class="scheme-image" />
      <br />
      <p>聘用顧問優勢，協助您將申請過程化繁為簡</p>
      <app-whatsapp [lang]="'zh'"></app-whatsapp>
    </div>
  </section>

  <section
    fxLayout="row"
    fxLayoutAlign="center"
    style="background-color: whitesmoke"
  >
    <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
      <h1>政府基金資助相關服務</h1>

      <h2>遙距營商計劃 D-Biz</h2>
      <p>獨立審計師編制經審計項目支出表</p>
      <app-whatsapp [lang]="'zh'"></app-whatsapp>

      <h2>科技券計劃 ("TVP")</h2>
      <p>獨立審計師編制經審計項目支出表</p>
      <app-whatsapp [lang]="'zh'"></app-whatsapp>

      <!-- <h2>零售業人力需求管理科技應用支援計劃 ("ReTAAS"）</h2>
      <p>獨立審計師編制經審計項目支出表</p>
      <app-whatsapp [lang]="'zh'"></app-whatsapp> -->

      <h2>BUD專項基金</h2>
      <p>獨立審計師編制經審計項目支出表</p>
      <app-whatsapp [lang]="'zh'"></app-whatsapp>
    </div>
  </section>
</div>
<!-- end of zh -->

<!-- en -->
<div *ngIf="lang === 'en'" fxLayout="column" fxLayoutAlign="center stretch">
  <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
    <img src="assets/images/gov-fund.jpg" alt="" class="banner-image" />
  </div>
  <section
    fxLayout="row"
    fxLayoutAlign="center"
    style="background-color: whitesmoke"
  >
    <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
      <p></p>
      <h1>Popular government funds</h1>

      <!-- BUD -->
      <h2>BUD Fund funding up to HK$4,000,000</h2>
      <!-- <iframe src="https://www.youtube.com/embed/1rmk2eb0QYc"> </iframe> -->
      <br /><br />
      <img src="assets/images/emf-1.png" class="scheme-image" />
      <br />
      <p>
        The SME Export Marketing Fund (EMF) provides funding support to SMEs for
        their participation in export promotion activities, expanding overseas
        markets. The main funded projects included trade fairs/exhibitions
        participation, business missions outside Hong Kong and placing
        advertisements on trade publications
      </p>
      <app-whatsapp [lang]="'en'"></app-whatsapp>

      <!-- EMF -->
      <h2>The SME Export Marketing Fund (EMF) funding up to $800,000</h2>
      <!-- <iframe src="https://www.youtube.com/embed/1rmk2eb0QYc"> </iframe> -->
      <br /><br />
      <img src="assets/images/bud-1.png" class="scheme-image" />
      <br />
      <p>
        To provide funding support for individual Hong Kong enterprises to
        undertake projects to develop brands, upgrade and restructure their
        business operations and promote sales in the Mainland China and all
        economies with which Hong Kong has signed Free Trade Agreements (FTAs)
        (included ten ASEAN countries, Macau, Australia) market.
      </p>
      <app-whatsapp [lang]="'en'"></app-whatsapp>

      <!-- RETAAS -->
      <!-- <h2>
        Retail Technology Adoption Assistance Scheme funding up to $50,000
      </h2>
      <img src="assets/images/retaas-1.png" class="scheme-image" />
      <br />
      <p>
        Retail Technology Adoption Assistance Scheme (ReTAAS) was designed for
        Hong Kong retail industry and provides funding support to apply
        technology to manage manpower and increase productivity. The popular
        funded projects include POS system, online payment system, establish
        online shop.
      </p>
      <app-whatsapp [lang]="'en'"></app-whatsapp> -->

      <!-- TVP -->
      <h2>Technology Voucher Programme funding up to $600,000</h2>
      <img src="assets/images/tvp.jpg" alt="" class="scheme-image" />
      <br />
      <!-- <p>聘用顧問優勢，協助您將申請過程化繁為簡</p> -->
      <app-whatsapp [lang]="'en'"></app-whatsapp>
    </div>
  </section>

  <section
    fxLayout="row"
    fxLayoutAlign="center"
    style="background-color: white"
  >
    <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
      <h1>Government fund related services</h1>

      <h2>Distance Business Programme (“D-Biz”)</h2>
      <p>Auditors’ Report and Statement of Expenditure</p>
      <app-whatsapp [lang]="'en'"></app-whatsapp>

      <h2>Technology Voucher Programme ("TVP")</h2>
      <p>Auditors’ Report and Statement of Expenditure</p>
      <app-whatsapp [lang]="'en'"></app-whatsapp>

      <!-- <h2>Retail Technology Adoption Assistance Scheme ( "ReTAAS"）</h2>
      <p>Auditors’ Report and Statement of Expenditure</p>
      <app-whatsapp [lang]="'en'"></app-whatsapp> -->

      <h2>BUD Fund</h2>
      <p>Auditors’ Report and Statement of Expenditure</p>
      <app-whatsapp [lang]="'en'"></app-whatsapp>
    </div>
  </section>
</div>
<!-- end of en -->
