<!-- zh -->
<div *ngIf="lang === 'zh'" fxLayout="column" fxLayoutAlign="center stretch">
  <div
    fxLayout="row"
    fxFlex="100"
    fxLayoutAlign="center center"
    style="background-color: white"
  >
    <img
      src="assets/images/accounting-service.jpg"
      alt="accounting service"
      class="banner-image"
      style="height: 60vh; object-fit: contain"
    />
  </div>

  <section
    fxLayout="column"
    fxLayoutAlign="center"
    style="background-color: white; padding: 10%"
  >
    <h1>Taihk雲端會計服務</h1>
    <p>
      稅務條例第51C條規定每名在香港經營某行業、專業或業務的人，須就其入息及開支以英文或中文備存足夠的紀錄，以便其應評稅利潤易於確定；該等紀錄須保留為期最少7年。我們的專業會計服務，均由資深的會計師主理，配合採用我們的Taihk雲端會計系統方案進行理賬，
      讓客戶體驗高質素的會計服務，包括:
    </p>
    <ul>
      <li>協助客戶有效降低會計成本</li>
      <li>資料得到妥善處理和絕對保密</li>
      <li>會計記錄可以長期保存</li>
    </ul>

    <div
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutAlign="space-between"
      fxFlex="100"
    >
      <mat-card fxFlex="20" fxLayout="column" fxLayoutAlign="center">
        <img src="assets/images/icons/002-justice.png" />
        <p>擬備管理賬目，包括資產負債表和損益表</p>
      </mat-card>
      <mat-card fxFlex="20" fxLayout="column" fxLayoutAlign="center">
        <img src="assets/images/icons/004-paper.png" />
        <p>進行銀行對賬</p>
      </mat-card>
      <mat-card fxFlex="20" fxLayout="column" fxLayoutAlign="center">
        <img src="assets/images/icons/005-analytics.png" />
        <p>準備分析及相關明細</p>
      </mat-card>
      <mat-card fxFlex="20" fxLayout="column" fxLayoutAlign="center">
        <img src="assets/images/icons/001-mobile-banking.png" />
        <p>建立、修改和維持內部會計和存檔系統</p>
      </mat-card>
      
    </div>
    <app-whatsapp [lang]="'zh'"></app-whatsapp>
  </section>

  <section
    fxLayout="column"
    fxLayoutAlign="center"
    style="background-color: whitesmoke; padding: 0 10%"
  >
    <h2>Taihk雲端會計服務</h2>
    <p>
      TAIHK
      雲端會計服務為大香港企業服務團隊共同研發的會計數據處理程式系統，通過有效的雲端會計數據處理程式能跟蹤和記錄交易及擬備管理賬目（包括資產負債表和損益表），協助客戶有效降低會計成本、會計記錄可以長期保存及資料得到妥善處理和絕對保密。
    </p>
    <p>
      TAIHK
      雲端會計系統能助中小型企業專注業務發展，省減資源投放於會計財務方面。TAIHK
      雲端會計系統有助於企業客戶與會計師於企業年結審計時建立可信賴的業務關係。現時我們會計系統輸出的管理賬目已獲多間會計師事務所及執業會計師認可。
    </p>
    <p style="text-align: center; font-size: 1.4rem">
      數據<br />
      <img
        src="assets/images/company-secretary-service/professional.png"
        alt="listed company"
        style="height: 2rem; margin: 1rem; vertical-align: middle"
      />專業認可* ：100%<br />
      <img
        src="assets/images/company-secretary-service/on_time.png"
        alt="listed company"
        style="height: 2rem; margin: 1rem; vertical-align: middle"
      />準時不拖延**：100%<br />
      <img
        src="assets/images/company-secretary-service/sme_co.png"
        alt="listed company"
        style="height: 2rem; margin: 1rem; vertical-align: middle"
      />準確度***：100%<br />
      <img
        src="assets/images/company-secretary-service/listed_co.png"
        alt="listed company"
        style="height: 2rem; margin: 1rem; vertical-align: middle"
      />完成個案 ：200＋
    </p>
    <p>
      * 獲會計師事務所或執業會計師認可<br />
      ** 準時輸出管理賬給會計師作法定審計程序<br />
      *** 會計師事務所或執業會計師對經TAIHK 雲端會計系統輸出的管理帳作出重大修改
    </p>
    <h2>我們的會計服務</h2>
    <p>
      <strong>會計師團隊：</strong
      >我們會計師團隊有超過二十年專業經驗，擁有不同類型行業財務經驗。
    </p>
    <p>
      <strong>彈性會計服務：</strong
      >客戶可選擇按月、按季度或按會計年度的會計服務
    </p>
    <p>
      <strong>性價比高：</strong
      >我們的數據處理程式能按客戶業務上的收支規則進行自動編碼和分類，另外我們也研發了三重檢測避免帳目掉入不適用的會計帳戶，從而節省財務準備的時間及成本，省略的成本會以優惠的服務費用回饋客戶。
    </p>
    <p>
      <strong>專業認可，直接輸出合乎審計要求：</strong>TAIHK
      雲端會計系統輸出的管理賬已獲多間會計師事務所及執業會計師認可，管理帳能直接無縫接軌，數據輸出合乎會計師審計要求。  
    </p>
    <p>
      <strong>跟你定死線、準時不拖延：</strong
      >委任我們做帳報稅後，我們會跟每一客戶相議時間流程表，
      死線前完成並準時輸出管理賬給會計師作法定審計程序。
    </p>
    <p>
      <strong>準確度：</strong>TAIHK
      雲端會計系統輸出的管理賬合乎國際會計準則，因此會計師事務所或執業會計師作年結審計時不會對管理帳作出重大修改的需要，準確度及精準度高，加快報稅及審計流程時間，避免稅表延遲申報的風險。
    </p>
    <p>
      <strong>遙距會計：</strong
      >客戶可以在不受地點和時間限制的情況下跟我們的會計師團隊即時通訊聯繫。
    </p>
    <p></p>
  </section>

  <section
    fxLayout="column"
    fxLayoutAlign="center"
    style="background-color: white; padding: 0 10%"
  >
    <p></p>
    <h1>香港有限公司核數、審計核數懶人包</h1>
    <img src="assets/images/audit.jpg" style="width: 15vw;"/>
    <p>
      <strong> 何為法定審計（公司核數）？ </strong>
    </p>
    <p>
      根據香港《公司條例》第622章，公司的董事須就每個財政年度擬備符合第380及383條規定的財務報表。該等財務報表須經香港執業會計師審核。
    </p>
    <p>
      <strong>
        有限公司呈交報稅表給香港稅務局時，必須要完成法定審計嗎？要呈交核數報告嗎？
      </strong>
    </p>
    <p>
      需要。香港稅務局要求公司每年在遞交利得稅申報表的同時，需完成經審核財務報表以作評稅之用。此外，經審計的財務報表也用於銀行融資安排和政府補助或津貼申請等。
    </p>
    <p>
      <strong> 有限公司核數要注意那些地方？ </strong>
    </p>
    <p>
      預備核數報告及報稅所需的文件甚多，可包括公司註冊文件副本、財務報表、銀行月結單、收入及成本發票、每月/每季規則性支出單據如工資、強積金、租金、水電費等。另外所有公司已簽署的合同也需要提供給會計師作審計之用。客戶最好在平日妥善理賬，並可在稅季來臨前聯絡會計師安排審計工作，以免收到報稅表時才手忙腳亂
    </p>
    <p>
      審計報告須經由香港註冊會計師（CPA）完成，經審計的核數報告內要包含資產負債表、損益表、現金流量表、權益變動表及稅收計算等。
    </p>
    <p>
      在香港有些並不是執業會計師事務所卻聲稱能提供審計服務，不但對持牌的執業會計師或會計師事務所構成威脅，更為選用了這些低質素服務的企業帶來種種問題。客戶選擇時要小心，一般來說公司名稱上使用「會計師事務所」或個人是「執業會計師」為受牌照監管合規地提供專業審計服務。公司名稱上使用「XX會計公司」
      或「XX會計事務所」為偽會計師不受牌照監管及不能提供專業審計服務。
      客戶敬請小心。
    </p>
    <p>
      我們的創辦人及會計師團隊均為持牌香港執業會計師，擁有二十多年行內經驗，如客戶於審計報稅方面有問題，歡迎聯繫我們。
    </p>
    <p></p>
  </section>
</div>
<!-- end of zh -->

<!-- en -->
<div *ngIf="lang === 'en'" fxLayout="column" fxLayoutAlign="center center">
  <div style="height: 100vh" fxLayout="column" fxLayoutAlign="center center">
    <p>Only Chinese Version Available</p>
  </div>
</div>
<!-- end of en -->
