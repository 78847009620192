<!-- en -->
<div *ngIf="lang === 'en'">
  <div class="center">
    <div class="w-800">
      <div class="banner-container center">
        <img
          src="assets/images/smartline-en.jpg"
          alt="singapore"
          class="banner-image"
        />
      </div>
      <div>
        <p>
          Unlimited number of calls to / from anywhere of the world (including
          China)
        </p>
        <p>
          Fixed company phone linked with your own smart phones, help you be
          more efficient working from home
        </p>
        <p>Save high IDD and roaming charges</p>
      </div>
      <br />

      <h1>Tai HK Smartline – International Office Line</h1>
      <app-whatsapp [lang]="'en'"></app-whatsapp>
      <br />

      <p>
        Tai HK Smartline service is the latest online Voice over Internet
        Protocol (VoIP) communication service. It utilizes the broadband network
        as a telephone network for voice communication. Voice quality of Tai HK
        Smartline is as clear as the normal voice calls. Tai HK Smartline
        service enables you to answer unlimited calls from anywhere of the world
        (including China and overseas) with the Hong Kong telephone number Tai
        HK provided to you and make unlimited number of calls to any Hong Kong
        phone numbers from anywhere of the world (including China and overseas)
        without incurring long distance call charges.
      </p>
      <p>
        Entrepreneurs who use Tai HK Smartline service can change the fixed
        company phone into a mobile phone, even if they work at home or on the
        move, they will feel like setting in their Hong Kong office. This helps
        them to establishment their corporate image and save office rental fees.
      </p>
      <p>
        Overseas companies which use HK Smartline service can have unlimited
        free access to any Hong Kong phone numbers (including calling and
        answering) from anywhere of the world (including China and overseas).
        Moreover, HK Smartline can be used as internal phone networks for their
        Hong Kong and overseas officers by multinational company, which assist
        them to save high IDD and roaming charges!
      </p>
      <p>
        HK Smartline service can also be used together with the call forwarding
        feature and voice mailbox functions, which ensure you will not miss any
        business chance.
      </p>
      <p>
        You can choose your own smart phones (currently supports PC, iPhone,
        Android, iPad, etc.) to use this service.
      </p>
    </div>
  </div>
</div>
<!-- end of en -->

<!-- zh -->
<div *ngIf="lang === 'zh'">
  <div class="center">
    <div class="w-800">
      <div class="banner-container center">
        <img
          src="assets/images/smartline-zh.jpg"
          alt="singapore"
          class="banner-image"
        />
      </div>
      <div>
        <p>
          用戶於海外（包括中國）無限接聴及撥打香港本地電話
        </p>
        <p>
          公司固網電話跟智慧手機整合，在家辦公無難度
        </p>
        <p>省卻高昂的IDD及漫遊費用</p>
      </div>
      <br />

      <h1>大香港Smartline – 國際隨身Office Line</h1>
      <app-whatsapp [lang]="'en'"></app-whatsapp>
      <br />

      <p>
        大香港Smartline – 國際隨身Office Line (HKD150/月 需預繳六個月服務費用)
      </p>
      <p>
        大香港Smartline服務是最新網上網絡電話通訊服務。透過現今的寬頻網絡作為網絡電話通訊工具，大香港Smartline的話音質素與一般語音通話一樣清晰。使用大香港Smartline
        透過我們提供的香港電話號碼，就可以在任何地方（包括中國及海外）透過程式軟件支援，無限以香港電話號碼接聽及撥打香港手提及固網電話而無須任何長途電話費。
      </p>
      <p>
        Entrepreneurs who use Tai HK Smartline service can change the fixed
        company phone into a mobile phone, even if they work at home or on the
        move, they will feel like setting in their Hong Kong office. This helps
        them to establishment their corporate image and save office rental fees.
      </p>
      <p>
        創業人士使用大香港Smartline網絡電話服務可將公司固網電話變成移動電話，即使在家中工作也儼如如置身在香港的辦公室，建立公司形象並節省辦公室租用費用。
      </p>
      <p>
        大香港Smartline網絡電話服務於海外裝設後於世界任何地方免費直駁香港任何手提及固網電話，通訊無間。
        並且大香港Smartline可以使設有香港及海外辦事處的公司建立內聯電話線路，無須任何長途電話費雙向互通，從此節省高昂的IDD及漫遊費用！
      </p>
      <p>
        大香港Smartline網絡電話服務更可以加上配合全新功能的來電轉駁功能及留言訊箱功能，保證不會錯失任何一個生意機會。
      </p>
      <p>
        客戶可選擇以自己的智慧手機（目前支持PC、iPhone、Android、iPad等）使用此服務。
      </p>
    </div>
  </div>
</div>
<!-- end of zh -->
