<!-- zh -->
<div *ngIf="lang === 'zh'" fxLayout="column" fxLayoutAlign="center stretch">
  <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
    <img src="assets/images/samoa.jpg" alt="samoa" class="banner-image" />
    <div class="banner-text" style="transform: translate(0,-50%)">
      <p>成立薩摩亞公司</p>
    </div>
  </div>
  <section
    fxLayout="row"
    fxLayoutAlign="center"
    style="background-color: white"
  >
    <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
      <p></p>
      <h1>成立薩摩亞公司</h1>
      <p>註冊費用: HK$6,800 (如有中文名稱, 需另加 HK$1,200)</p>
      <app-whatsapp [lang]="'zh'"></app-whatsapp>
      <br />

      <p>
        大香港提供完善的註冊薩摩亞公司服務，為客戶處理註冊薩摩亞公司的多重工序。
      </p>
      <p>
        薩摩亞位於國際換日線以東的南太平洋，由九個島嶼組成。
        薩摩亞於一九六二年獨立，英語是當地基本語言。薩摩亞現有服務架構完善，許多著名的會計師行均已在該國設立辦事處，而律師事務所也多不勝數。目前，薩摩亞有三間零售銀行。此外，駐薩摩亞的中國大使館可協助旁註和認證在中國營運時所需要的文件。
      </p>

      <h3>薩摩亞公司優勢</h3>
      <ul>
        <li>提供私隱保護，無需公開股東和董事身份</li>
        <li>無須申報稅務及繳納任何稅項</li>
        <li>可使用中文或英文公司名稱</li>
        <li>公司的文件能以任何語言保存</li>
        <li>允許以方便及有效的方式進行公司遷冊</li>
      </ul>

      <h3>薩摩亞公司註冊條件</h3>
      <ul>
        <li>至少一名股東、董事，股東和董事可同為一人</li>
        <li>當地個人或法團公司擔任的代理人（大香港可提供）</li>
      </ul>

      <h3>標準註冊資本</h3>
      <ul>
        <li>註冊資本一般為1,000,000美元及每股1美元 (最少分發1股)</li>
      </ul>

      <h3>所需資料及時間</h3>
      <ul>
        <li>公司中、英文名稱</li>
        <li>
          董事、公司發起人身份證或護照原件，或經執業會計師、律師、公證人核證的副本
        </li>
        <li>
          董事最近3個月的地址證明（例如：銀行月結單、水電、煤氣費單、固定電話繳費清單等）原件，或經執業會計師、律師、公證人核證的副本
        </li>
        <li>註冊全新公司約需15個工作日（資料收齊並且盡職審查通過後）</li>
        <li>購買現成空殼公司約需3個工作日（資料收齊並且盡職審查通過後）</li>
      </ul>
    </div>
  </section>
</div>
<!-- end of zh -->

<!-- en -->
<div *ngIf="lang === 'en'" fxLayout="column" fxLayoutAlign="center stretch">
  <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
    <img src="assets/images/samoa-en.jpg" alt="prc" class="banner-image" />
  </div>
  <section
    fxLayout="row"
    fxLayoutAlign="center"
    style="background-color: white"
  >
    <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
      <p></p>
      <h1>Samoa Company</h1>
      <p>
        Registration fee: HK$6,800 (an additional HK$1,200 for adding Chinese
        name)
      </p>
      <app-whatsapp [lang]="'en'"></app-whatsapp>
      <br />

      <p>
        Tai Hong Kong offers high quality registered services of Samoa Company,
        we help client to save their heavy workload in company registration.
      </p>
      <p>
        Samoa situated in the South Pacific Ocean east of the International Date
        Line, is composed of 9 islands. Samoa independent since 1962 whose basic
        local language is English. The existing service structure in Samoa is
        complete; therefore many famous accounting firms set up their offices in
        this country, and law offices are also numerous here. At present, there
        are 3 retail banks in Samoa. In addition, the China Embassy in Samoa
        will be more helpful to apostil and certify the documents needed to
        operate in China.
      </p>

      <h3>Advantages</h3>
      <ul>
        <li>
          Provide privacy protection, without disclosing shareholders and
          directors
        </li>
        <li>No tax shall be declarable and payable</li>
        <li>
          Company's name in Chinese (or other languages) can be shown on the
          certificate of incorporation
        </li>
        <li>Company can store its documents in any language</li>
        <li>
          It is permitted to relocate the company in a convenient and effective
          manner
        </li>
      </ul>

      <h3>General Requirements</h3>
      <ul>
        <li>
          There shall be at least one shareholder, director and company
          secretary
        </li>
        <li>
          It is required to compile the registers of directors, secretary and
          shareholders and store them in the registered office
        </li>
      </ul>

      <h3>Standard Registered Capital</h3>
      <ul>
        <li>1 million USD (at lease 1 issued shares)</li>
      </ul>

      <h3>Required Information and Time for Incorporation</h3>
      <ul>
        <li>Company name in Chinese and English</li>
        <li>
          Original copy of directors and company originators' ID card or
          passport, or certified copy by certified public accountants, lawyers
          and notaries
        </li>
        <li>
          Original copy of proof of address of the directors for the last 3
          months (such as bank statements, utilities bills, gas bills, landline
          phone bills, etc.) or certified copy by certified public accountants,
          lawyers, and notaries
        </li>
        <li>
          It takes about 15 working days to register a brand new company (after
          the particulars are fully collected and due diligence is passed)
        </li>
        <li>
          It takes approximately 3 working days to purchase a ready-to-use shell
          company (after the particulars are fully collected and due diligence
          is passed)
        </li>
      </ul>
    </div>
  </section>
</div>
<!-- end of en -->
