<!-- zh -->
<div *ngIf="lang === 'zh'" fxLayout="column" fxLayoutAlign="center stretch">
  <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
    <img
      src="assets/images/annual-maintenance.jpg"
      alt="banner"
      class="banner-image"
    />
    <div class="banner-text" style="transform: translate(60%, -20%)">
      <p
        style="
          color: black;
          font-weight: bold;
          font-size: 7vw;
          margin: 0;
          padding: 0;
        "
      >
        公司秘書<br />週年維護服務
      </p>
      <p style="color: black; font-size: 3.5vw">這是我們的職責</p>
    </div>
  </div>

  <section
    fxLayout="row"
    fxLayoutAlign="center"
    style="background-color: white"
  >
    <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
      <p></p>
      <h1>公司秘書 – 週年維護服務</h1>
      <app-whatsapp [lang]="'zh'"></app-whatsapp>
      <br />

      <ul>
        <li
          *ngFor="let service of services"
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutAlign.xs="space-between center"
        >
          <p>
            {{ serviceData[service].name_zh }}
            {{ serviceData[service].charge_desc_zh }}
            <span *ngIf="serviceData[service].name_2_zh !== ''">({{ serviceData[service].name_2_zh }})</span>
          </p>
          <app-add-to-cart-button
            [serviceId]="service"
          ></app-add-to-cart-button>
        </li>
        <!-- <li
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutAlign.xs="space-between center"
        >
          <p>
            {{ serviceData["transfer_shares"].name_zh }}
            {{ serviceData["transfer_shares"].charge_desc_zh }}
          </p>
          <app-request-manual-quotation
            [serviceId]="'transfer_shares'"
          ></app-request-manual-quotation>
        </li> -->
      </ul>
      <p>
        <i
          >*
          有關按金用作信件平郵或快遞往一般工商地址費用之用，客戶如沒有使用及沒有拖欠本公司任何費用下，取消註冊辦事處地址服務時會退回有關按金給客戶。</i
        >
      </p>
      <p><i> # 如股東及董事總人數超過6位，價錢另議 </i></p>
    </div>
  </section>

  <section
    fxLayout="row"
    fxLayoutAlign="center"
    style="background-color: whitesmoke"
  >
    <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
      <div class="grey-box">
        <h1>其他單項秘書服務，請聯絡我們</h1>
      </div>
      <h1>其他單項秘書服務收費表：</h1>

      <ul>
        <li
          *ngFor="let service of otherServices"
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutAlign.xs="space-between center"
        >
          <mat-icon>{{ serviceData[service].icon }}</mat-icon>
          <p>
            {{ serviceData[service].name_zh }}
            {{ serviceData[service].charge_desc_zh }}
          </p>
          <app-add-to-cart-button
            [serviceId]="service"
          ></app-add-to-cart-button>
        </li>
      </ul>

      <p><i>* 配發股份多於2位股東，價錢另議</i></p>
    </div>
  </section>

  <section
    fxLayout="row"
    fxLayoutAlign="center"
    style="background-color: white"
  >
    <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
      <p></p>
      <h1 style="text-align: center">公司秘書服務常見問題</h1>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>1. 何謂公司秘書？</mat-panel-title>
          </mat-expansion-panel-header>
          <p>答案：</p>
          <p>
            根據香港現行法例，每家有限公司必須委任最少一位公司秘書。公司秘書乃法定職位，其職責為安排會議、準備會議記錄、提交法定檔給政府部門、確保公司符合法定要求等。
          </p>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title
              >2. 香港的公司條例對公司秘書有什麼資格要求？</mat-panel-title
            >
          </mat-expansion-panel-header>
          <p>答案：</p>
          <p>
            公司秘書可以是個人，也可以是法人團體（有限公司）。如屬個人，必須為年滿18歲的香港居民；如屬公司法人，必須是在香港註冊的有限公司。
          </p>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title
              >3. 非香港居民可否被委任為有限公司的董事及秘書？</mat-panel-title
            >
          </mat-expansion-panel-header>
          <p>答案：</p>
          <p>
            非香港居民可被委任為公司董事。但是公司秘書如屬個人，須通常居於香港；如屬法人團體，則須在香港設有註冊辦事處或營業地點。
            每間私人有限公司最少須有一名董事及一名秘書。如公司只有一名董事，該名董事不得兼任該公司的秘書。
          </p>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>4. 董事可否兼任公司秘書？</mat-panel-title>
          </mat-expansion-panel-header>
          <p>答案：</p>
          <p>
            可以，只要該位董事符合以上第二題的要求便可。
            但是，如果一家有限公司只有一個董事，那麼該董事就不可以同時兼任公司秘書一職。
          </p>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>5. 股東可否兼任公司秘書？</mat-panel-title>
          </mat-expansion-panel-header>
          <p>答案：</p>
          <p>
            可以，只要該位股東符合以上第二題的要求便可。但是，如果該股東也是董事，而且是公司唯一的董事，那麼該股東就不可以同時兼任公司秘書一職。
          </p>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title
              >6. 如沒有適合人選出任公司秘書一職怎辦？</mat-panel-title
            >
          </mat-expansion-panel-header>
          <p>答案：</p>
          <p>
            你可以委任持牌秘書服務公司出任貴公司之公司秘書一職，本公司也可以提供此服務。
          </p>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>7. 公司秘書是否有管理公司的權力？</mat-panel-title>
          </mat-expansion-panel-header>
          <p>答案：</p>
          <p>
            公司秘書不具備管理公司的權力。例如，公司秘書不可以公司名義與公司的供應商簽訂貨物或勞務的購銷協議，也不具備代替公司簽訂各項投資協議的權力，除非得到董事會的正式授權。
          </p>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>8. 在什麼情況下可提名備任董事？</mat-panel-title>
          </mat-expansion-panel-header>
          <p>答案：</p>
          <p>
            公司如符合《公司條例》(第622章)第455條所列的準則，便可於任何時候提名備任董事。
          </p>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>9. 週年申報表逾期呈交罰款金額？</mat-panel-title>
          </mat-expansion-panel-header>
          <p></p>
          <table>
            <tr>
              <th>由成立為法團的周年日翌日起計超過42天但不超過3個月</th>
              <th>$870</th>
            </tr>
            <tr>
              <th>由成立為法團的周年日翌日起計超過3個月但不超過6個月</th>
              <th>$1,740</th>
            </tr>
            <tr>
              <th>由成立為法團的周年日翌日起計超過6個月但不超過9個月</th>
              <th>$2,610</th>
            </tr>
            <tr>
              <th>由成立為法團的周年日翌日起計超過9個月以上</th>
              <th>$3,480</th>
            </tr>
          </table>
          <p></p>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>10. 轉股印花稅如何計算？</mat-panel-title>
          </mat-expansion-panel-header>

          <p>
            股權轉讓由一方轉移到另一方被視為買賣，需要徵收印花稅。
            股權轉讓金額或所轉讓股份應占公司資產淨值之金額 （取兩者較高）x 0.1%
            x 2 + HKD 5
          </p>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </section>
</div>

<!-- en -->
<div *ngIf="lang === 'en'" fxLayout="column" fxLayoutAlign="center stretch">
  <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
    <img
      src="assets/images/annual-maintenance.jpg"
      alt="banner"
      class="banner-image"
    />
    <div class="banner-text" style="transform: translate(50%, -20%)">
      <p
        style="
          color: black;
          font-weight: bold;
          font-size: 5vw;
          margin: 0;
          padding: 0;
        "
      >
        Annual Maintenance<br />Services
      </p>
      <p style="color: black; font-size: 3.5vw">It's our responsibility</p>
    </div>
  </div>

  <section
    fxLayout="row"
    fxLayoutAlign="center"
    style="background-color: white"
  >
    <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
      <p></p>
      <h1>Company Secretary – Annual Maintenance Services</h1>
      <app-whatsapp [lang]="'en'"></app-whatsapp>
      <br />

      <ul>
        <li
          *ngFor="let service of services"
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutAlign.xs="space-between center"
        >
          {{ serviceData[service].name_en }}
          {{ serviceData[service].charge_desc_en }}
          <span *ngIf="serviceData[service].name_2_en !== ''">({{ serviceData[service].name_2_en }})</span>

          <app-add-to-cart-button
            [serviceId]="service"
          ></app-add-to-cart-button>
        </li>
      </ul>
    </div>
  </section>

  <section
    fxLayout="row"
    fxLayoutAlign="center"
    style="background-color: whitesmoke"
  >
    <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
      <div class="grey-box">
        <h1>Please contact us for more company secretary services</h1>
      </div>
      <h1>Fees of other company secretary services：</h1>

      <ul>
        <li
          *ngFor="let service of otherServices"
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutAlign.xs="space-between center"
        >
          <mat-icon>{{ serviceData[service].icon }}</mat-icon>
          <p>
            {{ serviceData[service].name_en }}
            {{ serviceData[service].charge_desc_en }}
          </p>
          <app-add-to-cart-button
            [serviceId]="service"
          ></app-add-to-cart-button>
        </li>
        <!-- <li
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutAlign.xs="space-between center"
        >
          <p>
            {{ serviceData["transfer_shares"].name_en }}
            {{ serviceData["transfer_shares"].charge_desc_en }}
          </p>
          <app-request-manual-quotation
            [serviceId]="'transfer_shares'"
          ></app-request-manual-quotation>
        </li> -->
      </ul>
      <p><i>* if allot for more than 2 shareholders, the fee will be determined separately.</i></p>

    </div>
  </section>

  <section
    fxLayout="row"
    fxLayoutAlign="center"
    style="background-color: white"
  >
    <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
      <p></p>
      <h1 style="text-align: center">Company Secretary Services – Q & A</h1>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>1. WHAT IS COMPANY SECRETARY?</mat-panel-title>
          </mat-expansion-panel-header>
          <p>Answer:</p>
          <p>
            A company secretary in Hong-Kong is the business’s named
            representative, who has the responsibility of ensuring the company
            and its operations are run in accordance with the law.
          </p>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title
              >2. WHAT ARE THE REQUIREMENTS OF COMPANY
              SECRETARY？</mat-panel-title
            >
          </mat-expansion-panel-header>
          <p>Answer:</p>
          <p>
            The company secretary could be a natural person or a a body
            corporate. If the company secretary is a natural person, he or she
            must over 18 years old and ordinarily reside in Hong Kong. If the
            company secretary is a body corporate, the address of its registered
            or principal office should be in Hong Kong.
          </p>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title
              >3. CAN NON-HONG KONG RESIDENTS BE APPOINTED AS COMPANY SECRETARY
              OR DIRECTOR OF A LOCAL LIMITED COMPANY？</mat-panel-title
            >
          </mat-expansion-panel-header>
          <p>Answer:</p>
          <p>
            There is no requirement under the Companies Ordinance that a
            director must be a Hong Kong resident. However, if the company
            secretary is a natural person, he or she must ordinarily reside in
            Hong Kong. If the company secretary is a body corporate, the address
            of its registered or principal office should be in Hong Kong.
          </p>
          <p>
            A private company must have a company secretary and at least one
            director who is a natural person (i.e. an individual). The sole
            director of a private company must not also be the company secretary
            of the company.
          </p>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title
              >4. CAN DIRECTOR OF THE COMPANY ACT AS THE COMPANY SECRETARY
              TOO？</mat-panel-title
            >
          </mat-expansion-panel-header>
          <p>Answer:</p>
          <p>
            Yes, having met the requirements stated in question 2. However, the
            sole director of a private company must not also be the company
            secretary of the company.
          </p>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title
              >5. CAN MEMBER OF THE COMPANY ACT AS THE COMPANY SECRETARY
              TOO？</mat-panel-title
            >
          </mat-expansion-panel-header>
          <p>Answer:</p>
          <p>
            Yes, having met the requirements stated in question 2. However, if
            the member is the sole director of a private company, he must not
            also be the company secretary of the company.
          </p>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title
              >6. WHAT SHALL BE DONE IF THERE IS NO ELIGIBLE PERSON TO ACT AS
              STATUTORY SECRETARY OF THE COMPANY？</mat-panel-title
            >
          </mat-expansion-panel-header>
          <p>Answer:</p>
          <p>
            Another company can be employed can also provide the service of
            statutory secretary. Tai Hong Kong can provide relevant service.
          </p>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title
              >7. DID THE COMPANY SECRETARY HAS EXECUTIVE OR MANAGEMENT
              RESPONSIBILITIES？</mat-panel-title
            >
          </mat-expansion-panel-header>
          <p>Answer:</p>
          <p>
            The company secretary is responsible for ensuring that the
            administrative responsibilities set out in company law and the
            articles of association are properly carried out. The post has no
            executive or management responsibilities unless these are explicitly
            delegated by the governing body. So a company secretary cannot, for
            example, sign contract on behalf of the company with supplier
            without the authority of the directors or company members.
          </p>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title
              >8. UNDER WHAT CIRCUMSTANCES CAN A RESERVE DIRECTOR BE
              NOMINATED？</mat-panel-title
            >
          </mat-expansion-panel-header>
          <p>Answer:</p>
          <p>
            According to section 455 of the Companies Ordinance (Cap. 622), if a
            private company which has only one member and that member is the
            sole director of the company, the company may nominate a person
            (other than a body corporate) who has attained the age of 18 years
            as a Reserve Director to act in the place of the sole director in
            the event of the sole director’s death.
          </p>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title
              >9. IS IT MANDATORY TO NOMINATE A RESERVE
              DIRECTOR？</mat-panel-title
            >
          </mat-expansion-panel-header>
          <p>Answer:</p>
          <p>
            No. It is an option available to the company. A company which
            satisfies the criteria set out in section 455 of the Companies
            Ordinance (Cap. 622) may choose to nominate a Reserve Director at
            any time.
          </p>
          <p></p>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title
              >10. LATE FILING FEE FOR REGISTRATION OF ANNUAL
              RETURNS？</mat-panel-title
            >
          </mat-expansion-panel-header>
          <table style="margin: 1rem">
            <tr>
              <th style="background-color: goldenrod; color: white">
                Registration of annual returns
              </th>
              <th style="background-color: goldenrod; color: white">HK$</th>
            </tr>
            <tr>
              <th colspan="2" style="color: goldenrod">If delivered</th>
            </tr>
            <tr>
              <th>within 42 days after the company’s return date@</th>
              <th>105</th>
            </tr>
            <tr>
              <th>
                more than 42 days after but within 3 months after the company’s
                return date@
              </th>
              <th>870</th>
            </tr>
            <tr>
              <th>
                more than 3 months after but within 6 months after the company’s
                return date@
              </th>
              <th>1,740</th>
            </tr>
            <tr>
              <th>
                more than 6 months after but within 9 months after the company’s
                return date@
              </th>
              <th>2,610</th>
            </tr>
            <tr>
              <th>more than 9 months after the company’s return date@</th>
              <th>3,480</th>
            </tr>
            <tr>
              <th>@ the anniversary of the company’s date of incorporation</th>
              <th></th>
            </tr>
          </table>
          <p></p>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title
              >11. STAMP DUTY COMPUTATION – SHARE TRANSFER</mat-panel-title
            >
          </mat-expansion-panel-header>

          <p>
            Transfer of shares from one party to another is regarded as a sale
            and purchase and chargeable to stamp duty. (Amount of Consideration
            (or net assets of the subject company x % of interest to be
            transferred) x 0.1%) x 2 + HKD5
          </p>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </section>
</div>
