<div class="plan-head-top">
  <h2>{{ priceChartData.title }}</h2>
</div>

<div class="plan-head-bottom">
  <div class="plan-price">
    <span class="plan-currency">{{ priceChartData.currency }}</span
    ><span class="plan-amount">{{ priceChartData.amount }}</span>
  </div>
  <p *ngIf="priceChartData" class="plan-desc">
    {{ priceChartData.highlight_sub_title }}
  </p>
  <p>{{ priceChartData.sub_title }}</p>
</div>

<div class="plan-body">
  <p *ngFor="let content of priceChartData.body">{{ content }}</p>
</div>

<div class="plan-foot">
  <!-- <a mat-button (click)="openDialog('成立NGO')"> -->
    <a mat-button href="https://firebasestorage.googleapis.com/v0/b/tai-hong-kong-platform.appspot.com/o/spreadsheets%2FsetupNGOForm.xlsx?alt=media&token=ff342e95-3387-4389-add2-4e2033fef4b1">
    <!-- <a mat-button href="/assets/forms/setupNGOForm.xlsx" download> -->
    <h2>{{ priceChartData.action_button_text }}</h2>
  </a>
</div>
