import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import {
  FormBuilder,
  Validators,
  FormGroup,
  FormControl,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss'],
})
export class InvoiceComponent implements OnInit {
  lang: string;

  requestInvoiceForm = this.fb.group({
    requestService: this.fb.group({
      serviceControl: ['', [Validators.required]],
      serviceFeeControl: [0, [Validators.required]],
    }),
    contactPerson: this.fb.group({
      companyNameControl: ['', [Validators.required]],
      nameControl: ['', [Validators.required]],
      titleControl: ['', [Validators.required]],
      emailControl: ['', [Validators.required, Validators.email]],
      mobileControl: [
        '',
        [Validators.required, Validators.pattern('^[23456789][0-9]{7}$')],
      ],
    }),
  });

  selectedService = {
    change_unlimited_com_name: false,
    apply_branch: false,
    increase_shares: false,
    transfer_shares: false,
    hk_com_sec: false,
    hk_com_reg_address: false,
    hk_trademark: false,
    sz_com_reg: false,
    gz_com_reg: false,
    bj_com_reg: false,
    sh_com_reg: false,
    china_com_tax: false,
    sgp_com_reg: false,
    office_address: false,
    sign_ctrl_reg: false,
    annual_return: false,
    com_sec: false,
    br: false,
    change_address: false,
    change_nature: false,
    change_limited_com_name: false,
    change_director: false,
    change_director_info: false,
    dereg_unlimited_com: false,
    dereg_limited_com: false,
    meeting_record: false,
    annual_meeting_record: false,
    change_article: false,
  };

  serviceFee = {
    change_unlimited_com_name: {
      name_zh: '更改無限公司名稱 / 業務名稱',
      name_desc_zh: '',
      name_en: 'Change of Company Name of a unlimited company / Business Name',
      name_desc_en: '',
      charge_desc_zh_zh: 'HK$ 500',
      charge_desc_zh_en: 'HK$ 500',
      disbursement: 0,
      var_disbursement: false,
      charge: 500,
      icon: 'business'
    },
    apply_branch: {
      name_zh: '申請分行商業登記',
      name_desc_zh: '代支 - 商業登記費',
      name_en: 'Application of Branch Business Registration',
      name_desc_en: 'Disbursement - BR fee',
      charge_desc_zh: 'HK$ 800',
      charge_desc_en: 'HK$ 800',
      disbursement: 250,
      var_disbursement: false,
      charge: 800,
      icon: 'store'
    },
    increase_shares: {
      name_zh: '增發股本',
      name_desc_zh: '',
      name_en: 'Issue and Allotment of Share(s)',
      name_desc_en: '',
      charge_desc_zh: 'HK$ 600',
      charge_desc_en: 'HK$ 600',
      disbursement: 0,
      var_disbursement: false,
      charge: 800,
      icon: 'present_to_all'
    },
    transfer_shares: {
      name_zh: '股份轉讓',
      name_desc_zh: '代支 - 政府印花稅',
      name_en: 'Share Transfer',
      name_desc_en: 'Disbursement - Stamp Duty',
      charge_desc_zh: 'HK$ 1,200',
      charge_desc_en: 'HK$ 1,200',
      disbursement: 0,
      var_disbursement: true,
      charge: 1200,
      icon: 'settings_ethernet'
    },
    hk_com_sec: {
      name_zh: '担任名義公司秘書 - 一年',
      name_desc_zh: '',
      name_en: 'Act as named company secretary - one year',
      name_desc_en: '',
      charge_desc_zh: 'HK$ 600',
      charge_desc_en: 'HK$ 600',
      disbursement: 0,
      var_disbursement: false,
      charge: 600,
      icon: ''
    },
    hk_com_reg_address: {
      name_zh: '香港公司註冊地址 - 一年',
      name_desc_zh: '已包HK$200 郵費按金',
      name_en: 'Provision of Registered Address',
      name_desc_en: '(HK$200 postal deposit included)',
      charge_desc_zh: 'HK$ 1,300',
      charge_desc_en: 'HK$ 1,300',
      disbursement: 0,
      var_disbursement: false,
      charge: 1300,
      icon: ''
    },
    hk_trademark: {
      name_zh: '註冊香港商標 - 首類別',
      name_desc_zh: '額外類別',
      name_en: 'Hong Kong Trademark Registration - First class',
      name_desc_en: 'Additional class(es)',
      charge_desc_zh: 'HK$ 2,500',
      charge_desc_en: 'HK$ 2,500',
      disbursement: 0,
      var_disbursement: true,
      charge: 2500,
      icon: ''
    },
    // sz_com_reg: {
    //   name_zh: '深圳公司註冊費用',
    //   name_desc_zh: '',
    //   name_en: '',
    //   name_desc_en: '',
    //   charge_desc_zh: 'HK$ 5,599',
    //   charge_desc_en: 'HK$ 5,599',
    //   disbursement: 0,
    //   var_disbursement: false,
    //   charge: 5599,
    // },
    // gz_com_reg: {
    //   name_zh: '廣州公司註冊費用',
    //   name_desc_zh: '',
    //   name_en: '',
    //   name_desc_en: '',
    //   charge_desc_zh: 'HK$ 12,999',
    //   charge_desc_en: 'HK$ 12,999',
    //   disbursement: 0,
    //   var_disbursement: false,
    //   charge: 12999,
    // },
    // bj_com_reg: {
    //   name_zh: '北京公司註冊費用',
    //   name_desc_zh: '',
    //   name_en: '',
    //   name_desc_en: '',
    //   charge_desc_zh: 'HK$ 15,999',
    //   charge_desc_en: 'HK$ 15,999',
    //   disbursement: 0,
    //   var_disbursement: false,
    //   charge: 15999,
    // },
    // sh_com_reg: {
    //   name_zh: '上海公司註冊費用',
    //   name_desc_zh: '',
    //   name_en: '',
    //   name_desc_en: '',
    //   charge_desc_zh: 'HK$ 15,999',
    //   charge_desc_en: 'HK$ 15,999',
    //   disbursement: 0,
    //   var_disbursement: false,
    //   charge: 15999,
    // },
    // china_com_tax: {
    //   name_zh: '國內公司報稅',
    //   name_desc_zh: '',
    //   name_en: '',
    //   name_desc_en: '',
    //   charge_desc_zh: 'HK$ 899／月',
    //   charge_desc_en: 'HK$ 899 per month',
    //   disbursement: 0,
    //   var_disbursement: false,
    //   charge: 899,
    // },
    // sgp_com_reg: {
    //   name_zh: '新加坡公司註冊費用',
    //   name_desc_zh: '',
    //   name_en: '',
    //   name_desc_en: '',
    //   charge_desc_zh: 'HK$ 7,000 起',
    //   charge_desc_en: '',
    //   disbursement: 0,
    //   var_disbursement: false,
    //   charge: 7000,
    // },
    // office_address: {
    //   name_zh: '提供註冊辦事處地址',
    //   name_desc_zh: '免地址變更手續費，已包括 *HK$200 按金及 HK$300 水牌費用',
    //   name_en: '',
    //   name_desc_en: '',
    //   charge_desc_zh: 'HK$ 1,300',
    //   charge_desc_en: 'HK$ 1,300',
    //   disbursement: 0,
    //   var_disbursement: false,
    //   charge: 1300,
    // },
    sign_ctrl_reg: {
      name_zh: '建立及或維護「重要控制人登記冊」',
      name_desc_zh: '',
      name_en: 'Prepare and/or Maintain Significant Controllers Register',
      name_desc_en: '',
      charge_desc_zh: 'HK$ 300',
      charge_desc_en: 'HK$ 300',
      disbursement: 0,
      var_disbursement: false,
      charge: 300,
      icon: ''
    },
    annual_return: {
      name_zh: '提交週年申報',
      name_desc_zh: '代支 - 政府登記費(本年度香港政府已括免）',
      name_en: 'Prepare and submit Annual Returns',
      name_desc_en: 'Disbursement - Annual filing fee ( HK Govt wave for this year)',
      charge_desc_zh: 'HK$ 550',
      charge_desc_en: 'HK$ 550',
      disbursement: 0,
      var_disbursement: false,
      charge: 550,
      icon: ''
    },
    // com_sec: {
    //   name_zh: '担任名義公司秘書',
    //   name_desc_zh: '免法定秘書變更手續費',
    //   name_en: '',
    //   name_desc_en: '',
    //   charge_desc_zh: 'HK$ 600',
    //   charge_desc_en: 'HK$ 600',
    //   disbursement: 0,
    //   var_disbursement: false,
    //   charge: 600,
    // },
    br: {
      name_zh: '商業登記續期服務',
      name_desc_zh: '代支 - 商業登記費',
      name_en: 'Renew Business Registration',
      name_desc_en: ' Disbursement - BR fee',
      charge_desc_zh: 'HK$ 500',
      charge_desc_en: 'HK$ 500',
      disbursement: 250,
      var_disbursement: false,
      charge: 500,
      icon: ''
    },
    change_address: {
      name_zh: '地址變更',
      name_desc_zh: '',
      name_en: 'Change of Registered Address',
      name_desc_en: '',
      charge_desc_zh: 'HK$ 500',
      charge_desc_en: 'HK$ 500',
      disbursement: 0,
      var_disbursement: false,
      charge: 500,
      icon: 'home'
    },
    change_nature: {
      name_zh: '變更業務性質',
      name_desc_zh: '',
      name_en: 'Change of Business Nature',
      name_desc_en: '',
      charge_desc_zh: 'HK$ 400',
      charge_desc_en: 'HK$ 400',
      disbursement: 0,
      var_disbursement: false,
      charge: 400,
      icon: 'receipt'
    },
    change_limited_com_name: {
      name_zh: '更改有限公司名稱',
      name_desc_zh: '已包括所有代支 - 政府登記費',
      name_en: 'Change of Company Name of a limited company',
      name_desc_en: 'Disbursement - government fee',
      charge_desc_zh: 'HK$ 800',
      charge_desc_en: 'HK$ 800',
      disbursement: 295,
      var_disbursement: false,
      charge: 800,
      icon: 'settings'
    },
    change_director: {
      name_zh: '董事及或備任董事／法定秘書之更替',
      name_desc_zh: '',
      name_en: 'Change of Director / Company Secretary',
      name_desc_en: '',
      charge_desc_zh: 'HK$ 300',
      charge_desc_en: 'HK$ 300',
      disbursement: 0,
      var_disbursement: false,
      charge: 300,
      icon: 'supervisor_account'
    },
    change_director_info: {
      name_zh: '董事及或備任董事／法定秘書之資料變更',
      name_desc_zh: '',
      name_en: 'Change in Particulars of Company Secretary / Director',
      name_desc_en: '',
      charge_desc_zh: 'HK$ 200',
      charge_desc_en: 'HK$ 200',
      disbursement: 0,
      var_disbursement: false,
      charge: 200,
      icon: 'supervised_user_circle'
    },
    dereg_unlimited_com: {
      name_zh: '撒銷無限公司註冊',
      name_desc_zh: '',
      name_en: 'Deregistration of a unlimited company',
      name_desc_en: '',
      charge_desc_zh: 'HK$ 300',
      charge_desc_en: 'HK$ 300',
      disbursement: 0,
      var_disbursement: false,
      charge: 300,
      icon: 'work_off'
    },
    dereg_limited_com: {
      name_zh: '撒銷有限公司註冊',
      name_desc_zh: '代支 - 政府登記費',
      name_en: 'Deregistration of a unlimited company',
      name_desc_en: 'Disbursement - government fee',
      charge_desc_zh: 'HK$ 2,000',
      charge_desc_en: 'HK$ 2,000',
      disbursement: 690,
      var_disbursement: false,
      charge: 2000,
      icon: 'cancel'
    },
    meeting_record: {
      name_zh: '撰寫公司狀況變更會議記錄',
      name_desc_zh: '',
      name_en: 'Board Minutes to Approve the Corporate Changes',
      name_desc_en: '',
      charge_desc_zh: 'HK$ 600',
      charge_desc_en: 'HK$ 600',
      disbursement: 0,
      var_disbursement: false,
      charge: 600,
      icon: 'description'
    },
    annual_meeting_record: {
      name_zh: '撰寫公司股東週年股東大會會議記錄',
      name_desc_zh: '',
      name_en: 'Minutes of Annual General Meeting',
      name_desc_en: '',
      charge_desc_zh: 'HK$ 600',
      charge_desc_en: 'HK$ 600',
      disbursement: 0,
      var_disbursement: false,
      charge: 600,
      icon: 'list'
    },
    change_article: {
      name_zh: '更改公司章程',
      name_desc_zh: '',
      name_en: 'Alteration of Company’s Articles',
      name_desc_en: '',
      charge_desc_zh: 'HK$ 1,000',
      charge_desc_en: 'HK$ 1,000',
      disbursement: 0,
      var_disbursement: false,
      charge: 1000,
      icon: 'subject'
    },
  };

  constructor(
    private fb: FormBuilder,
    public translateService: TranslateService,
    private afs: AngularFirestore
  ) {
    this.lang = this.translateService.currentLang;
    this.translateService.onLangChange.subscribe(
      (event) => (this.lang = event.lang)
    );
  }

  ngOnInit(): void {}

  async onSubmit() {
    try {
      const works = [];
      const works_price = [];
      // convert service array
      for (const key in this.selectedService) {
        if (this.selectedService[key]) {
          works.push(this.serviceFee[key].name);
          works_price.push(this.serviceFee[key].charge);
        }
      }

      console.log('works', works);
      console.log('works_price', works_price);

      if (works.length === 0) {
        console.log('no service selected');
        return;
      }

      // save to firestore
      const total = works_price.reduce((a, b) => a + b);
      await this.afs.collection('createPDFfromHTML').add({
        company_name: this.companyNameControl.value,
        contact_person: this.contactPersonNameControl.value,
        contact_number: this.contactPersonMobileControl.value,
        contact_email: this.contactPersonEmailControl.value,
        service_en: 'XXXX Service',
        service_zh: 'XXXX 服務',
        works: works,
        works_price: works_price,
        total: total,
      });

      console.log('success submit data');
    } catch (err) {
      console.log('fail submit data', err);
    }
  }

  getTotal() {}
  toggleService(service: string) {
    this.selectedService[service] = !this.selectedService[service];
  }
  get requestService() {
    return this.requestInvoiceForm.get('requestService') as FormGroup;
  }

  get contactPerson() {
    return this.requestInvoiceForm.get('contactPerson') as FormGroup;
  }

  get companyNameControl() {
    return this.contactPerson.get('companyNameControl') as FormControl;
  }

  get contactPersonNameControl() {
    return this.contactPerson.get('nameControl') as FormControl;
  }

  get contactPersonTitleControl() {
    return this.contactPerson.get('titleControl') as FormControl;
  }

  get contactPersonEmailControl() {
    return this.contactPerson.get('emailControl') as FormControl;
  }

  get contactPersonMobileControl() {
    return this.contactPerson.get('mobileControl') as FormControl;
  }

  get serviceControl() {
    return this.requestService.get('serviceControl') as FormControl;
  }

  get serviceFeeControl() {
    return this.requestService.get('serviceFeeControl') as FormControl;
  }
}
