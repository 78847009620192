import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { RequestFormDialogComponent } from '../../request-form-dialog/request-form-dialog.component';
import { RequestFormData, SERVICE_ZH } from '../../interfaces/request-form-data';

import { PriceChartData } from '../../interfaces/price-chart-data';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-unlimited-company-price-chart',
  templateUrl: './unlimited-company-price-chart.component.html',
  styleUrls: ['./unlimited-company-price-chart.component.scss'],
})
export class UnlimitedCompanyPriceChartComponent implements OnInit {

  @Input() priceChartData: PriceChartData;
  lang: string;
  
  constructor(
    public dialog: MatDialog,
    public translateService: TranslateService
  ) {
    this.lang = this.translateService.currentLang;
    this.translateService.onLangChange.subscribe(
      (event) => (this.lang = event.lang)
    );
  }

  ngOnInit(): void {}

  openDialog(service: SERVICE_ZH): void {
    const formData: RequestFormData = {
      contact_person: '',
      contact_number: '',
      contact_email: '',
      service_zh: service,
    };

    const requestServiceDialogRef = this.dialog.open(
      RequestFormDialogComponent,
      { data: formData }
    );
    requestServiceDialogRef.afterClosed().subscribe((result) => {
      console.log('dialog closed');
      console.log('result', result);
    });
  }

}
