<!-- zh -->
<div *ngIf="lang === 'zh'" fxLayout="column" fxLayoutAlign="center stretch">
  <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
    <img
      src="assets/images/singapore.jpg"
      alt="singapore"
      class="banner-image"
    />
    <div class="banner-text" style="transform: translate(-10%, -30%)">
      <p style="color: #3f3e38; font-weight: bold; font-size: 5vw">
        成立新加坡公司 / <br />解決成立新加坡公司常見問題
      </p>

      <p style="font-weight: bold; font-size: 4vw">大香港離岸企業服務部</p>
    </div>
  </div>
  <section
    fxLayout="row"
    fxLayoutAlign="center"
    style="background-color: white"
  >
    <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
      <p></p>
      <h1>註冊新加坡公司後，助你遙距開戶口，毋須親往新加坡</h1>
      <p>註冊費用: HK$7,000 起</p>
      <app-whatsapp [lang]="'zh'"></app-whatsapp>
      <p>
        <strong>責任有限公司(PTE Ltd)</strong>
      </p>
      <p class="sub-heading">成立新加坡責任有限公司（PTE Ltd）需要留意事項</p>
      <ul>
        <li>註冊新加坡公司後，助你遙距開戶母須親往新加坡</li>
        <li>根據新加坡ACRA商業局規定，董事必須是高於18歲的自然人</li>
        <li>公司的董事會可以由一名或多名董事組成</li>
        <li>
          公司董事必須至少包括一名本地董事（新加坡永久居民、新加坡公民、新加坡創業准證持有者、自雇就業准證持有者
        </li>
        <li>董事主要職責負責管理公司及確保公司營運符合新加坡當地法例</li>
        <li>
          任命公司秘書，而該秘書須是新加坡公民，永久居民或持有新加坡的工作簽證（入境證或就業證
        </li>
        <li>公司董事和/或股東並不能擔任公司秘書一職</li>
        <li>擁有至少1新加坡元的股本</li>
        <li>
          有關新加坡公司股本為認繳股本，即投資者願意認繳出資額，並不是實繳股本
        </li>
        <li>註冊地址必須具有實際的地址，而郵政信箱地址並不能用作註冊地址</li>
        <li>
          如選擇使用住所地址作為公司註冊地址，則須事先獲得新加坡建屋發展局（組屋單位）或市區重建局（私人財產）的批准
        </li>
        <li>新加坡業務性質是用分類代碼形式，不是用文字表述</li>
        <li>
          如果外國人想要註冊新加坡公司進行投資，我們的客戶可委託我們作新加坡本地代理董事及名義公司秘書，以便符合新加坡商業局規定。因我們只是名義代理形式，實際業務操作以及公司權益均由客戶所有。
        </li>
      </ul>

      <br />
      <p>
        隨著人才增加及外商投資的優勢，新加坡在亞洲的地位越來越重要，趁早在當地開公司座享無限機遇。
      </p>

      <p class="sub-heading">註冊新加坡公司的優點</p>
      <ul>
        <li>外商可投資100%股權</li>
        <li>最低繳付股本只需新加坡幣$1</li>
        <li>最少一位股東及一位董事</li>
        <li>
          首$300,000應課稅利潤的稅率為9%;
          其後應課稅利潤的稅率為17%。首3年的新公司首10萬稅款減免75%。第二個10萬稅款則減免50%，餘下的稅款，還會經常有20-45%減免，但要視乎當年政府對公司的稅務政策。
        </li>
        <li>投資增值收益及股息皆免稅</li>
        <li>入境護照可簽發給欲前往新加坡發展之公司東主</li>
        <li>沒外匯管制或派發利潤之限制</li>
      </ul>

      <p class="sub-heading">註冊新加坡公司服務包括</p>
      <ul>
        <li>查核公司名稱</li>
        <li>提供當地新加坡董事</li>
        <li>提供當地註冊地址</li>
        <li>提供當地秘書服務</li>
        <li>領取公司成立文件</li>
        <li>公司章程3本</li>
        <li>商業印章一個</li>
        <li>銀行開戶文件</li>
      </ul>

      <p class="sub-heading">所需文件</p>
      <p>
        我們將為閣下準備及遞交有關成立公司之文件，閣下無需為繁瑣的申請而操心。
        以下是我司為閣下提交申請時所需之文件:
      </p>
      <ul>
        <li>公司章程</li>
        <li>遵從公司註冊規定陳述書</li>
        <li>股東、董事及公司秘書的個人資料</li>
        <li>註冊辦事處座落地點及辦公時間通知書</li>
        <li>出任董事職位同意書</li>
        <li>出任公司秘書職位同意書</li>
      </ul>

      <p class="sub-heading">辦理所需時間</p>
      <ul>
        <li>當我們收到閣下簽妥的所有文件後，需約一星期完成所有註冊程序。</li>
      </ul>
      <p class="sub-heading">新加坡自雇移民</p>
      <ul>
        <li>
          新加坡自雇移民(Self-Employment
          Pass)是指申请人自己在新加坡開公司或投資入股現有公司后，以公司董事或高管的名義申请EP工作准証(Employment
          Pass)，持有EP工作准証六個月且保持公司正常運行，可携带全家（合法配偶以及年齡21以下未婚子女）申请新加坡永居PR永久居民身份的移民方式。持有永居PR满2年即可全家申请新加坡入籍。
        </li>
      </ul>
    </div>
  </section>
  <section
    fxLayout="row"
    fxLayoutAlign="center"
    style="background-color: whitesmoke"
  >
    <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
      <p></p>
      <h3>BUD 專項基金 - 香港政府資助高達600萬 助你發展新加坡巿場</h3>
      <p>
        新加坡屬BUD基金自貿計劃資助的巿場，資助香港的中小企向外發展，每項資助額為50%，每間公司最高可獲資助航空公司600萬，如有興趣，請<a
          routerLink="/bud"
          >按此了解詳情。</a
        ><app-whatsapp [lang]="'zh'"></app-whatsapp>
      </p>
    </div>
  </section>

  <section
    fxLayout="row"
    fxLayoutAlign="center"
    style="background-color: white"
  >
    <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
      <h3>新加坡銀行開戶</h3>
      <!--<p>
        香港及離岸公司或非新加坡居民及外籍人士亦可開戶口。如有興趣，請<a
          routerLink="/open-singapore-bank"
          >按此了解詳情</a
        ><app-whatsapp [lang]="'zh'"></app-whatsapp>
      </p> -->
      <p>註冊新加坡公司後，助你遙距開戶口毋須親往新加坡</p>
      <p>
        新加坡公司註冊後，我們能提供遙距的銀行開戶服務，毋須親臨新加坡亦可完成整個開戶安排。
      </p>
      <p>
        因為大部分提供遙距開戶服務的銀行，都不接受客戶的直接申請，因此，客戶需要先聯絡銀行指定的銀行開戶介紹人，經轉介至相關銀行以開始遙距開戶。
      </p>
      <p>
        大香港與新加坡的銀行有長久的聯繫，亦是部分銀行的指定開戶中間人。為您省時省力的方法達致開戶成功。
      </p>
      <app-whatsapp [lang]="'zh'"></app-whatsapp>
    </div>
  </section>

  <section
    fxLayout="row"
    fxLayoutAlign="center"
    style="background-color: whitesmoke"
  >
    <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
      <p></p>
      <p><strong>離岸戶口開戶小貼士：</strong></p>
      <ul>
        <li>
          <strong>最低結餘限制</strong>
          <p>
            大多數銀行都要求最低存款來開立賬戶。
            因此，您需要在合理範圍內尋找最適合您的銀行。
          </p>
        </li>
        <li>
          <strong>初存限制 </strong>
          <p>
            留意銀行有没有要求戶口開立後需存放一定金額的存款定存一段時間的要求。
            因此，您要預備充足儲備作資金流轉。
          </p>
        </li>
        <li>
          <strong>公司成立司法地限制 </strong>
          <p>
            一般來說企業註冊國家/地區與開戶銀行國家/地區相同是比企業註冊國家/地區與開戶銀行國家/地區不同容易開啓在岸銀行戶口。
          </p>
        </li>
        <li>
          <strong>高風險國家限制 </strong>
          <p>
            如銀行開立後戶口往來有涉及高風險國家或人士，戶口容易被凍結或戶口喪失某部份功能例如與其他銀行間轉帳的能力。
          </p>
        </li>
        <li>
          <strong>公司成立司法地資金管制限制 </strong>
          <p>留意部份國家實施外匯管制，資金流出的限制。</p>
        </li>
      </ul>
    </div>
  </section>
</div>
<!-- end of zh -->

<!-- en -->
<div *ngIf="lang === 'en'" fxLayout="column" fxLayoutAlign="center stretch">
  <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
    <img
      src="assets/images/singapore.jpg"
      alt="singapore"
      class="banner-image"
    />
    <div class="banner-text" style="transform: translate(-20%, -30%)">
      <p style="color: #3f3e38; font-weight: bold; font-size: 6vw">
        ESTABLISHMENT OF <br />SINGAPORE COMPANY
      </p>
      <p style="font-weight: bold; font-size: 4vw">大香港離岸企業服務部</p>
    </div>
  </div>
  <section
    fxLayout="row"
    fxLayoutAlign="center"
    style="background-color: white"
  >
    <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
      <p></p>
      <h1>Establishment of Singapore Company</h1>
      <p>Registration fee: HK$7,000 up</p>
      <app-whatsapp [lang]="'en'"></app-whatsapp>

      <p>
        <strong>
          Tai Hong Kong offers high quality registered services of Singapore
          Company, we help client to save their heavy workload in company
          registration.
        </strong>
      </p>
      <br />
      <p>
        Singapore plays as an attractive destination for investment and business
        with its growing importance in Asia with world-class infrastructure.
      </p>

      <p class="sub-heading">Advantages of Singapore Company</p>
      <ul>
        <li>100% foreign ownership allowed</li>
        <li>Minimum paid-up capital of S$1 only</li>
        <li>Minimum 1 shareholder and 1 director</li>
        <li>
          Only 9% tax rate on taxable income of up-to 300K annually; Low overall
          flat tax rate of 17% on taxable income above 300K. 75% is waived for
          the first 100K in the first three years; 50% waived for the following
          100K.
        </li>
        <li>Zero taxes on capital gains and dividends in general</li>
        <li>
          Immigration visa (entrepreneur pass) available for company owners
          wishing to relocate to Singapore to run their new Singapore company
        </li>
        <li>No exchange controls or restrictions on repatriation of profits</li>
      </ul>

      <p class="sub-heading">Basic Steps of Incorporation</p>
      <ul>
        <li>Get company name approval</li>
        <li>Prepare registration documents</li>
        <li>Client to sign company registration documents</li>
        <li>Register company</li>
        <li>Open bank account</li>
      </ul>

      <p class="sub-heading">Required Documents</p>
      <p>
        We will prepare and submit all the necessary documentation for
        registering your Singapore company. The following information is
        required to be submitted to the registrar of companies (ACRA):
      </p>
      <ul>
        <li>Memorandum and Articles of Association</li>
        <li>Statutory Declaration of Compliance</li>
        <li>Particulars of Shareholders, Directors, Secretaries, etc.</li>
        <li>Certificate of Identity</li>
        <li>Situation of Registered Office and Office Hours</li>
        <li>
          Consent to Act as Director and Statement of Non-disqualification to
          Act as Director
        </li>
        <li>A Consent to Act as Secretary</li>
      </ul>

      <p class="sub-heading">Time Required for the Application</p>
      <ul>
        <li>
          The registration process of Singapore company normally takes around 5
          weeks to complete upon receipt of all the signed documents.
        </li>
      </ul>
    </div>
  </section>
  <!-- <section
    fxLayout="row"
    fxLayoutAlign="center"
    style="background-color: whitesmoke"
  >
    <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
      <p></p>
      <h3>BUD 專項基金 - 香港政府資助高達400萬 助你發展中國巿場</h3>
      <p>
        中國屬BUD基金自貿計劃資助的巿場，資助香港的中小企向外發展，每項資助額為50%，每間公司最高可獲資助400萬，如有興趣，請<a
          routerLink="/gov-funds"
          >按此了解詳情。</a
        ><app-whatsapp [lang]="'zh'"></app-whatsapp>
      </p>
    </div>
  </section>

  <section
    fxLayout="row"
    fxLayoutAlign="center"
    style="background-color: white"
  >
    <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
      <h3>新加坡銀行開戶</h3>
      <p>
        香港及離岸公司或非新加坡居民及外籍人士亦可開戶口。如有興趣，請<a
          routerLink="/open-singapore-bank"
          >按此了解詳情</a
        ><app-whatsapp [lang]="'zh'"></app-whatsapp>
      </p>
    </div>
  </section> -->
</div>
