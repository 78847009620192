import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { RequestFormDialogComponent } from '../request-form-dialog/request-form-dialog.component';
import { RequestFormData, SERVICE_ZH } from '../interfaces/request-form-data';

@Component({
  selector: 'app-company-secretary-service',
  templateUrl: './company-secretary-service.component.html',
  styleUrls: ['./company-secretary-service.component.scss'],
})
export class CompanySecretaryServiceComponent implements OnInit {
  lang: string;

  constructor(
    public dialog: MatDialog,
    public translateService: TranslateService,
    private router: Router // @Inject(DOCUMENT) readonly document: Document
  ) {
    this.lang = this.translateService.currentLang;
    this.translateService.onLangChange.subscribe(
      (event) => (this.lang = event.lang)
    );
  }

  // get window(): Window { return this.document.defaultView; }
  ngOnInit(): void {}

  gotoRegisterCompanySecretaryService(): void {
    this.router.navigateByUrl('/register-company-secretary-service');
  }
  gotoRegisterHongKongUnlimitedCompany(): void {
    this.router.navigateByUrl('/register-hong-kong-unlimited-company');
  }
  gotoTransferCompanyShares(): void {
    this.router.navigateByUrl('/transfer-company-shares');
  }
  gotoVirtualOffice(): void {
    this.router.navigateByUrl('/virtual-office');
  }
  gotoTrademark(): void {
    this.router.navigateByUrl('/trademark');
  }
  gotoOpenHongKongBank(): void {
    this.router.navigateByUrl('open-hong-kong-bank');
  }
  gotoLiquidation(): void {
    this.router.navigateByUrl('/liquidation');
  }
  gotoUnlimitedCompanyToLimitedCompany(): void {
    this.router.navigateByUrl('/unlimited-company-to-limited-company');
  }

  openDialog(service: SERVICE_ZH): void {
    const formData: RequestFormData = {
      contact_person: '',
      contact_number: '',
      contact_email: '',
      service_zh: service,
    };

    const requestServiceDialogRef = this.dialog.open(
      RequestFormDialogComponent,
      { data: formData }
    );
    requestServiceDialogRef.afterClosed().subscribe((result) => {
      console.log('dialog closed');
      console.log('result', result);
    });
  }
}
