import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
// import * as AOS from 'aos';
import {
  trigger,
  animate,
  keyframes,
  style,
  transition,
} from '@angular/animations';
import { Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [
    trigger('bounce', [
      transition('* => *', [
        animate(
          '1s',
          keyframes([
            style({ transform: 'translateY(0) ' }),
            style({ transform: 'translateY(100px) ' }),
            style({ transform: 'translateY(0) ' }),
          ])
        ),
      ]),
    ]),
  ],
})
export class HomeComponent implements OnInit {
  lang: string;
  constructor(
    public translateService: TranslateService,
    private router: Router,
    private meta: Meta,
    private title: Title
  ) {
    this.lang = this.translateService.currentLang;
    this.translateService.onLangChange.subscribe(
      (event) => (this.lang = event.lang)
    );
    this.meta.addTags([
      { name: 'description', content: 'Tai Hong Kong Platform Home Page' },
      { name: 'keywords', content: 'Tai Hong Kong Platform Home Page' },
      { name: 'author', content: 'Tai Hong Kong Platform' },
    ]);
    this.title.setTitle('Tai Hong Kong Platform Home Page');
  }

  ngOnInit(): void {
    // AOS.init();
  }

  scrollDown($element) {
    $element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }

  goto(url) {
    this.router.navigate([url]);
  }
}
