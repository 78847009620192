import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fix-whatsapp-button',
  templateUrl: './fix-whatsapp-button.component.html',
  styleUrls: ['./fix-whatsapp-button.component.scss']
})
export class FixWhatsappButtonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
