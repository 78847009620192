<!-- zh -->
<app-fix-whatsapp-button></app-fix-whatsapp-button>
<div *ngIf="lang === 'zh'" fxLayout="column" fxLayoutAlign="center stretch">
  <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
    <img src="assets/images/loan8090.jpg" alt="banner" class="banner-image" />
    <div class="banner-text" style="transform: translate(-60%, -30%)"></div>
  </div>
  <section
    fxLayout="row"
    fxLayoutAlign="center"
    style="background-color: white"
  >
    <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
      <p></p>
      <h1>借唔到百分百擔保貸款？ 借完百分百擔保貸款想借完再借？</h1>
      <h1>八成（SFGS 80）/九成（SFGS 90）信貸擔保產品，安全、快遞、簡單</h1>

      <p>
        百分百擔保貸款申請程序雖然不煩複，何是營業額下跌三成及要提供銀行文件證明，令很多企業鎖死在這裡，這個難題就讓你卻步不前，影響企業融資計劃。
        由於近來發現有中介公司以企業諮詢名義，為企業營業額下跌三成做手腳或因申請人本身未符合審批條件，需偽造文件再作貸款申請，當中可能包括強積金、糧單，甚至公司戶口紀錄、月結單等等！
        事實上中小企老闆們需要支付高昂顧問費用予這些中介另外還會有涉詐騙的刑事責任。
      </p>
      <p>
        事實上除了百分百擔保貸款外，還有同樣低息、毋需抵押，貸款額度為甚至比百分百貸款高的八成/九成信貸擔保產品。最重要係申請條件簡單，流程連批核無需一個月！
      </p>
      <p>中小企融資擔保計劃下有幾多種擔保貸款？有甚麼分別？</p>
      <p>
        政府的「中小企融資擔保計劃」（SFGS）主要幫助中小企獲得銀行貸款，計劃下產品為八成信貸擔保產品（SFGS
        80），九成信貸擔保產品（SFGS 90）及百分百擔保特惠貸款（SFGS
        100），2020年初推出
      </p>
      <p>究竟這三款產品有什麼分別？企業該如何選擇？</p>

      <h3>貸款額</h3>
      <p>
        中小企融資擔保計劃下的三款產品最高貸款額各有不同，又以八成信貸擔保產品 （SFGS
        80）為最高：
      </p>
      <table>
        <thead>
          <tr>
            <th>信貸擔保產品</th>
            <th>最高貸款額</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>百分百擔保特惠貸款（SFGS 100）</th>
            <th>
              最高為900萬港元，或是27個月僱員薪金及租金的總和，以較低者為準
              （有期貸款）
            </th>
          </tr>
          <tr>
            <th>九成信貸擔保產品（SFGS 90）</th>
            <th>800萬港元 （有期貸款）</th>
          </tr>
          <tr>
            <th>八成信貸擔保產品（SFGS 80） </th>
            <th>1800萬港元 （有期貸款及循環貸款）</th>
          </tr>
        </tbody>
      </table>

      <h3>主要對象</h3>
      <p>
        除了貸款機構行業(Money
        Lender)，基本上所以行業的公司都可以申請中小企融資擔保計劃下的三款產品，不過從貸款額及申請條件可以見到各有主要服務的對象：
      </p>
      <table>
        <thead>
          <tr>
            <th>信貸擔保產品</th>
            <th>主要服務對象</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>百分百擔保特惠貸款（SFGS 100）</th>
            <th>
              適合疫情期間受經濟下行影響較大的行業，例如零售、旅遊、飲食、戲院、卡拉OK及運輸業等
            </th>
          </tr>
          <tr>
            <th>九成信貸擔保產品（SFGS 90）</th>
            <th>適合規模較小、經營經驗尚淺的企業，或有意獨立執業的專業人士</th>
          </tr>
          <tr>
            <th>八成信貸擔保產品（SFGS 80） </th>
            <th>適合有較大資金需求的中小企</th>
          </tr>
        </tbody>
      </table>

      <h3>合資格申請條件</h3>
      <p>
        申請企業需為香港註冊及營運的公司*，除了百分百擔保特惠貸款劃一申請資格外，八成及九成信貸擔保產品視乎個別銀行特定的申請條件：
      </p>
      <table>
        <thead>
          <tr>
            <th>信貸擔保產品</th>
            <th>申請條件</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>百分百擔保特惠貸款（SFGS 100）</th>
            <th>
              企業在2020年3月31日或之前營運，並自2020年2月份起，單月營業額較2019年1月至2020年6月期間任何一個季度的平均每月營業額下跌30%或以上
            </th>
          </tr>
          <tr>
            <th>九成信貸擔保產品（SFGS 90）</th>
            <th>
              按個別銀行特定的申請條件而定，由豁免企業營運年期至最少營運一年期。
            </th>
          </tr>
          <tr>
            <th>八成信貸擔保產品（SFGS 80） </th>
            <th>
              按個別銀行特定的申請條件而定，由最少營運一年期至最少營運三年期。
            </th>
          </tr>
          <tr>
            <th colspan="2">*貸款機構及貸款機構的聯繫公司均不符合申請資格</th>
          </tr>
        </tbody>
      </table>

      <h3>利息</h3>
      <p>
        政府中小企融資擔保計劃下的信貸擔保產品年利率會較市面一般貸款產品為低，企業只需支付擔保費用予香港按揭證券保險有限公司（按保），按保為企業作擔保，故銀行能夠提供較優惠的利率予借款人，三者又以百分百擔保特惠貸款
        （SFGS 100）利息最低：
      </p>
      <table>
        <thead>
          <tr>
            <th>信貸擔保產品</th>
            <th>利息</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>百分百擔保特惠貸款（SFGS 100）</th>
            <th>實際利率2.75%，手續費全免，不論哪間申請銀行，年利率均劃一</th>
          </tr>
          <tr>
            <th>九成信貸擔保產品（SFGS 90）#</th>
            <th>
              年利率<br />業務運作超過3年：4% - 8%<br />業務運作3年或以下：4%-10%
            </th>
          </tr>
          <tr>
            <th>八成信貸擔保產品（SFGS 80）#</th>
            <th>年利率：4%-10%</th>
          </tr>
          <tr>
            <th colspan="2">
              #由於SFGS 80與SFGS
              90產品由不同銀行設計，加上申請企業的業務情況及風險不一，利率會有分別，但所有銀行均需遵守按證保險公司設立的年利率上限
            </th>
          </tr>
        </tbody>
      </table>
      <p>
        另外，根據政府有關中小企融資擔保計劃於 2020年5 月 29
        日的最新更新，政府將為八成信貸擔保產品（SFGS
        80）和九成信貸擔保產品（SFGS
        90）的貸款提供一年內利息補貼，補貼貸款年利率上限為不超過 3%。
      </p>

      <h3>還款期</h3>
      <table>
        <thead>
          <tr>
            <th>信貸擔保產品</th>
            <th>利息</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>百分百擔保特惠貸款（SFGS 100）</th>
            <th>最長年期為10年</th>
          </tr>
          <tr>
            <th>九成信貸擔保產品（SFGS 90）#</th>
            <th>最長年期為5年</th>
          </tr>
          <tr>
            <th>八成信貸擔保產品（SFGS 80）#</th>
            <th>最長年期為7年</th>
          </tr>
        </tbody>
      </table>

      <h3>擔保費用</h3>
      <p>
        中小企融資擔保計劃下的三款產品均由政府旗下的香港按證保險有限公司（按保）作擔保，其中百分百擔保特惠貸款
        （SFGS 100）擔保費可獲全免：
      </p>
      <table>
        <thead>
          <tr>
            <th>信貸擔保產品</th>
            <th>擔保費用</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>百分百擔保特惠貸款（SFGS 100）</th>
            <th>全免</th>
          </tr>
          <tr>
            <th>九成信貸擔保產品（SFGS 90）</th>
            <th rowspan="2">
              需要支付擔保費用，一般為貸款額/信貸額的0.4%-1%。不過部分銀行提供保費支出資助
            </th>
          </tr>
          <tr>
            <th>八成信貸擔保產品（SFGS 80）</th>
          </tr>
        </tbody>
      </table>

      <h3>
        如果我的公司同時符合八成信貸擔保產品、九成信貸擔保產品及百分百擔保特惠貸款的申請條件，我可以同時向銀行提出申請多個產品嗎？這樣會否更快獲得審批？同時申請多個計劃所獲得的年利率會否不同？
      </h3>
      <p>
        百分百擔保特惠貸款（SFGS 100）的貸款產品較為特別，政府劃一規定年利率為實際利率2.75%，因此無論申請人向任何銀行提交申請，所得到的貸款利率都相同。
      </p>
      <p>
        如果企業符合中小企融資擔保計劃（SFGS）下的多款產品的申請條件，建議可先衡量自身的融資貸款需求，例如資金需求為900萬元以下，加上營業額急跌三成以上，可先申請百分百擔保特惠貸款（SFGS 100）；若企業沒有營業數據、或資金需求低於800萬港元，則可考慮申請九成信貸擔保產品（SFGS 90）。
      </p>
      <p>
        不過，無論申請哪款產品，銀行都會視之為貸款申請，所以會按照一貫的風險評估作出貸款決定，因此同時申請多過一款中小企融資擔保計劃（SFGS）下的產品，企業所獲得的年利率不會有太大差異。反而需要注意，企業同時申請多個信貸擔保產品，按保或會了解為何企業要提出多個申請，有可能影響批核進度。
      </p>
      <p>
        另外，政府於2020年推出的優化措施，表明會為八成及九成信貸擔保的貸款提供利息補貼，補貼以不超過
        3%為限。每宗貸款可獲最多 12
        個月利息補貼，利息補貼會自動存入相關賬戶，毋須另外申請。
      </p>
      <p>
        最後要注意，八成信貸擔保產品（SFGS 80）及九成信貸擔保產品（SFGS 90）提供的貸款產品類別有少許分別，SFGS 90只提供分期貸款產品，而SFGS 80則提供貿易融資及循環貸款產品，因此企業若有較高的資金需要，或希望量身定做貸款產品，可考慮SFGS 80。
      </p>

      <h3>
        我申請了其中一款中小企融資擔保計劃（SFGS）下的產品，但又想加大融資額度，可以怎樣做？
      </h3>
      <p>
        倘若企業已借取了百分百擔保特惠貸款（SFGS100），隨著業務需要而需更多資金，可考慮申請九成信貸擔保產品(SFGS
        90)或八成信貸擔保產品(SFGS 80)，以滿足新衍生的資金需求。
        例如企業已借入900萬港元的百分百擔保特惠貸款SFGS100
        (即已達到產品貸款額上限)，其後尚有500萬港元的資金需求，可向銀行額外申請SFGS
        90。
      </p>
      <p>
        另外，若企業已申請了九成信貸擔保產品(SFGS
        90)，隨著業務需要而需更多資金,企業可跟銀行商討並提出申請八成信貸擔保產品(SFGS
        80)。 例如企業已借入800萬港元的SFGS
        90(即已達到產品貸款額上限)，其後尚有500萬港元的資金需求，可向銀行額外申請SFGS
        80。 企業原本的SFGS
        90將按照原有條款還款，不會受新申請影響，而新舊申請的的保費支出亦不會因此而提高。 
      </p>
      <h3>提示：「借定唔借？還得到先好借！」 </h3>

      <p style="text-align: center">
        <img src="assets/images/icons/8090/documents.png" />
        <br />
        檢查所有提交文件格式與完整度<br />
        透視企業申請不足額或獲拒批的理由，幫手再申請<br />
        確保銀行在限期內儘快批出貸款
        <app-whatsapp [lang]="'zh'"></app-whatsapp>
      </p>

      <p style="text-align: center">
        <img src="assets/images/icons/8090/certificate.png" />
        <br />
        專業CPA註冊會計師<br />
        幫你準備齊全申請材料
        <app-whatsapp [lang]="'zh'"></app-whatsapp>
      </p>

      <p style="text-align: center">
        <img src="assets/images/icons/8090/bank.png" />
        <br />
        轉介友好銀行<br />
        話你知最快批核銀行
        <app-whatsapp [lang]="'zh'"></app-whatsapp>
      </p>

      <p style="text-align: center">
        <img src="assets/images/icons/8090/handshake.png" />
        <br />
        累計成功率高達98%<br />
        豐富經驗解決貸款疑難
        <app-whatsapp [lang]="'zh'"></app-whatsapp>
      </p>
    </div>
  </section>
</div>
