<!-- zh -->
<div *ngIf="lang === 'zh'" fxLayout="column" fxLayoutAlign="center stretch">
  <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
    <img src="assets/images/bvi.jpg" alt="bvi" class="banner-image" />
    <div class="banner-text">
      <p>成立英屬維爾京群島公司</p>
    </div>
  </div>

  <section
    fxLayout="row"
    fxLayoutAlign="center"
    style="background-color: white"
  >
    <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
      <p></p>
      <h1>成立英屬維爾京群島公司 (BVI)</h1>
      <p>註冊費用: HK$7,700 (如有中文名稱, 需另加 HK$1,200)</p>
      <app-whatsapp [lang]="'zh'"></app-whatsapp>
      <br />

      <h3>
        <strong
          >大香港提供完善的註冊英屬維爾京群島公司服務，為客戶處理註冊英屬維爾京群島公司的多重工序。</strong
        >
      </h3>
      <br />

      <h3>服務包括:</h3>
      <ul>
        <li>公司註冊證書</li>
        <li>BVI當地註冊地址</li>
        <li>代理費用</li>
        <li>政府首年牌費</li>
        <li>公司章程</li>
        <li>公司印章</li>
        <li>股票薄</li>
      </ul>

      <h3>英屬維爾京群島的優點</h3>
      <ul>
        <li>政府穩定性良好</li>
        <li>英式司法體系</li>
        <li>私隱受保護，無需公開股東和董事身份</li>
        <li>無需公司秘書</li>
        <li>無須申報稅務及繳納任何稅項</li>
        <li>可使用中文公司名稱</li>
        <li>無須遞交周年報表、審計報告和財務報表</li>
        <li>提升企業國際形象，有利於融資</li>
      </ul>

      <h3>成立英屬維爾京群島(BVI)公司要求</h3>
      <ul>
        <li>法定註冊股本 $50,000</li>
        <li>最少 1 位董事及股東 (可由同一人擔任)</li>
        <li>請填妥附件表格</li>
        <li>請提供董事/股東身份證明文件</li>
        <li>請提供董事/股東3個月有效地址證明</li>
      </ul>

      <h3>額外費用:</h3>
      <ul>
        <li>
          6個月內有效的成員身份證明書(Incumbency): (申請需另付: HK$1,500,
          辨理時間為7個工作天)
        </li>
        <li>全套註冊文件需做會計師鑒證 (由我司代辨需另付: HK$2,000)</li>
      </ul>

      <h3>辦理所需時間</h3>
      <ul>
        <li>客人提供文件及付款後, 20個工作天內可取公司綠盒。</li>
      </ul>
    </div>
  </section>
</div>
<!-- end of zh -->

<!-- en -->
<div *ngIf="lang === 'en'" fxLayout="column" fxLayoutAlign="center stretch">
  <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
    <img
      src="assets/images/bvi-en.jpg"
      alt="banner"
      class="banner-image"
    />
  </div>

  <section
    fxLayout="row"
    fxLayoutAlign="center"
    style="background-color: white"
  >
    <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
      <p></p>
      <h1>Establishment of British Virgin Islands (BVI) Company</h1>
      <p>
        Registration fee: HK$7,700 (an additional HK$1,200 for adding Chinese
        name)
      </p>
      <app-whatsapp [lang]="'en'"></app-whatsapp>
      <br />

      <h3>
        <strong>
          Tai Hong Kong offers high quality registered services of BVI Company,
          we help client to save their heavy workload in company registration.
        </strong>
      </h3>
      <br />

      <h3>Services include:</h3>
      <ul>
        <li>Certificate of Incorporation</li>
        <li>Local registered address BVI</li>
        <li>Agent fees</li>
        <li>Government first year license fee</li>
        <li>Company policy</li>
        <li>Company seal</li>
        <li>Stock book</li>
      </ul>

      <!-- <h3>英屬維爾京群島的優點</h3>
      <ul>
        <li>政府穩定性良好</li>
        <li>英式司法體系</li>
        <li>私隱受保護，無需公開股東和董事身份</li>
        <li>無需公司秘書</li>
        <li>無須申報稅務及繳納任何稅項</li>
        <li>可使用中文公司名稱</li>
        <li>無須遞交周年報表、審計報告和財務報表</li>
        <li>提升企業國際形象，有利於融資</li>
      </ul> -->

      <h3>
        The establishment of British Virgin Islands (BVI) company requires
      </h3>
      <ul>
        <li>Statutory registered capital of $50,000</li>
        <li>A minimum of one director and shareholder (be the same person)</li>
        <li>Please complete the form attached</li>
        <li>Please provide a director / shareholder identification document</li>
        <li>
          Please provide a director / shareholder of three months valid proof of
          address
        </li>
      </ul>

      <h3>Additional charges:</h3>
      <ul>
        <li>
          Within six months of the effective membership certificate
          (Incumbency): (application for an extra: HK$1,500, identified
          processing time is 7 working days)
        </li>
        <li>
          A full set of documents to be registered to do forensic accountant
          (identified by the Division I, need extra: HK$2,000)
        </li>
      </ul>

      <h3>The time required to handle</h3>
      <ul>
        <li>
          Offering documents and payments, the company desirable green box 20
          working days.
        </li>
      </ul>
    </div>
  </section>
</div>
<!-- end of en -->
