<mat-sidenav-container class="sidenav-container">
  <mat-sidenav
    #drawer
    class="sidenav"
    fixedInViewport
    [mode]="'over'"
    [opened]="false"
  >
    <mat-toolbar>
      <img src="assets/images/taihk-logo-without-r.png" class="sidenav-logo" />
    </mat-toolbar>

    <div fxLayout="column" fxLayoutAlign="start">
      <a
        class="sidenav-link"
        mat-button
        routerLink="/"
        (click)="drawer.close()"
      >
        {{ "MENU.HOME" | translate }}
      </a>
      <a
        class="sidenav-link"
        mat-button
        [matMenuTriggerFor]="companyIncorporation"
      >
        {{ "MENU.COMPANIES_INCORPORATION" | translate }}
      </a>
      <a
        class="sidenav-link"
        mat-button
        routerLink="accounting-service"
        (click)="drawer.close()"
      >
        {{ "MENU.ACCOUNTING_SERVICE" | translate }}
      </a>

      <a class="sidenav-link" mat-button [matMenuTriggerFor]="companyTax">
        {{ "MENU.COMPANY_TAX" | translate }}
      </a>
      <a class="sidenav-link" mat-button [matMenuTriggerFor]="companySecretary">
        {{ "MENU.COMPANY_SECRETARY" | translate }}
      </a>
      <a
        class="sidenav-link"
        mat-button
        routerLink="trademark"
        (click)="drawer.close()"
      >
        {{ "MENU.TRADEMARK_REG" | translate }}
      </a>
      <!-- <a
        class="sidenav-link"
        mat-button
        routerLink="corporate-finance"
        (click)="drawer.close()"
      >
        {{ "MENU.CORPORATE_FINANCE" | translate }}
      </a> -->
      <a class="sidenav-link" mat-button [matMenuTriggerFor]="smeLoans">
        {{ "MENU.SME_LOAN" | translate }}
      </a>
      <a class="sidenav-link" mat-button [matMenuTriggerFor]="openBankAccount">
        {{ "MENU.OPEN_BANK_ACCOUNT" | translate }}
      </a>
      <a class="sidenav-link" mat-button [matMenuTriggerFor]="govFunds">
        {{ "MENU.GOV_FUNDINGS" | translate }}
      </a>
      <a class="sidenav-link" mat-button routerLink="virtual-office">
        {{ "MENU.VIRTUAL_OFFICE" | translate }}
      </a>
      <a
        class="sidenav-link"
        mat-button
        routerLink="news"
        (click)="drawer.close()"
      >
        {{ "MENU.NEWS" | translate }}
      </a>
      <a
        class="sidenav-link"
        mat-button
        routerLink="csr"
        (click)="drawer.close()"
      >
        {{ "MENU.CSR" | translate }}
      </a>
      <!-- <a
        class="sidenav-link"
        mat-button
        routerLink="about-us"
        (click)="drawer.close()"
        >{{ "MENU.ABOUT_US" | translate }}</a
      > -->
    </div>

    <!-- sub menus -->
    <mat-menu #companyIncorporation>
      <a mat-menu-item [matMenuTriggerFor]="hongKongCompanies">
        {{ "MENU.EST_HK_COM" | translate }}
      </a>
      <a
        mat-menu-item
        routerLink="overseas"
        [matMenuTriggerFor]="overseasCompanies"
        (click)="drawer.close()"
      >
        {{ "MENU.EST_OVERSEAS_COM" | translate }}
      </a>
    </mat-menu>

    <mat-menu #hongKongCompanies>
      <a
        mat-menu-item
        routerLink="/register-hong-kong-limited-company"
        (click)="drawer.close()"
      >
        {{ "MENU.REG_HONG_KONG_LIMITED_COMPANY" | translate }}
      </a>
      <a
        mat-menu-item
        routerLink="/register-hong-kong-unlimited-company"
        (click)="drawer.close()"
      >
        {{ "MENU.REG_HONG_KONG_UNLIMITED_COMPANY" | translate }}
      </a>
      <a mat-menu-item routerLink="/ngo" (click)="drawer.close()">
        {{ "MENU.REG_NGO" | translate }}
      </a>
    </mat-menu>

    <mat-menu #overseasCompanies>
      <a mat-menu-item routerLink="/prc" (click)="drawer.close()">
        {{ "MENU.EST_CHINA_COM" | translate }}
      </a>
      <a mat-menu-item routerLink="/taiwan" (click)="drawer.close()">
        {{ "MENU.EST_TAIWAN_COM" | translate }}
      </a>
      <a mat-menu-item routerLink="/singapore" (click)="drawer.close()">
        {{ "MENU.EST_SINGAPORE_COM" | translate }}
      </a>
      <a mat-menu-item routerLink="/vietnam" (click)="drawer.close()">
        {{ "MENU.EST_VIETNAM_COM" | translate }}
      </a>
      <a mat-menu-item routerLink="/samoa" (click)="drawer.close()">
        {{ "MENU.EST_SAMOA_COM" | translate }}
      </a>
      <a mat-menu-item routerLink="/bvi" (click)="drawer.close()">
        {{ "MENU.EST_BVI_COM" | translate }}
      </a>
      <a mat-menu-item routerLink="/cayman" (click)="drawer.close()">
        {{ "MENU.EST_CAYMAN_COM" | translate }}
      </a>
    </mat-menu>

    <mat-menu #openBankAccount>
      <a
        mat-menu-item
        routerLink="/open-hong-kong-bank"
        (click)="drawer.close()"
      >
        {{ "MENU.OPEN_HONG_KONG_BANK_ACCOUNT" | translate }}
      </a>
      <a
        mat-menu-item
        routerLink="/open-singapore-bank"
        (click)="drawer.close()"
      >
        {{ "MENU.OPEN_SINGAPORE_BANK_ACCOUNT" | translate }}
      </a>
      <!-- <a mat-menu-item routerLink="/calculator" (click)="drawer.close()">{{
        "MENU.FEE_CAL" | translate
      }}</a> -->
    </mat-menu>

    <mat-menu #companyTax>
      <a
        mat-menu-item
        routerLink="company-tax"
        (click)="drawer.close()"
        [matMenuTriggerFor]="taxService"
      >
        {{ "MENU.TAX_SERVICE" | translate }}
      </a>
    </mat-menu>

    <mat-menu #taxService>
      <a
        mat-menu-item
        routerLink="/limited-company-tax"
        (click)="drawer.close()"
      >
        {{ "MENU.LIMITED_COMPANY_TAX" | translate }}
      </a>
      <a
        mat-menu-item
        routerLink="/unlimited-company-tax"
        (click)="drawer.close()"
      >
        {{ "MENU.UNLIMITED_COMPANY_TAX" | translate }}
      </a>
    </mat-menu>

    <mat-menu #accountingTaxAdvisory>
      <a mat-menu-item routerLink="/account-tax" (click)="drawer.close()">
        {{ "MENU.ACCOUNT_TAX" | translate }}
      </a>
      <!-- <a mat-menu-item routerLink="/calculator" (click)="drawer.close()">{{
        "MENU.FEE_CAL" | translate
      }}</a> -->
    </mat-menu>

    <mat-menu #accountingService>
      <a mat-menu-item routerLink="/account-tax" (click)="drawer.close()">
        {{ "MENU.ACCOUNT_TAX" | translate }}
      </a>
      <!-- <a mat-menu-item routerLink="/calculator" (click)="drawer.close()">{{
        "MENU.FEE_CAL" | translate
      }}</a> -->
    </mat-menu>

    <mat-menu #taxAdvisory>
      <a mat-menu-item routerLink="/account-tax" (click)="drawer.close()">
        {{ "MENU.ACCOUNT_TAX" | translate }}
      </a>
      <!-- <a mat-menu-item routerLink="/calculator" (click)="drawer.close()">{{
        "MENU.FEE_CAL" | translate
      }}</a> -->
    </mat-menu>

    <mat-menu #companySecretary>
      <a
        mat-menu-item
        routerLink="/company-secretary-service"
        (click)="drawer.close()"
      >
        {{ "MENU.COMPANY_SECRETARY_SERVICE" | translate }}
      </a>
      <a
        mat-menu-item
        routerLink="/thk-cloud-secretary"
        (click)="drawer.close()"
      >
        {{ "MENU.THK_CLOUD_SECRETARY" | translate }}
      </a>

      <!-- <a
        mat-menu-item
        routerLink="/annual-maintenance"
        (click)="drawer.close()"
        >{{ "MENU.ANNURAL_MAIN_SVC" | translate }}</a
      >
      <a mat-menu-item routerLink="/com-sec" (click)="drawer.close()">{{
        "MENU.CORP_CHANGE_SVC" | translate
      }}</a> -->
      <a mat-menu-item routerLink="/liquidation" (click)="drawer.close()">
        {{ "MENU.COMPANY_DISSOLUTION" | translate }}
      </a>
    </mat-menu>

    <mat-menu #govFunds>
      <a mat-menu-item routerLink="/tvp" (click)="drawer.close()">
        {{ "MENU.TVP" | translate }}
      </a>
      <a mat-menu-item routerLink="/bud" (click)="drawer.close()">
        {{ "MENU.BUD" | translate }}
      </a>
    </mat-menu>

    <mat-menu #professionalServices>
      <a mat-menu-item routerLink="/trademark" (click)="drawer.close()">
        {{ "MENU.TRADEMARK_REG" | translate }}
      </a>
      <a mat-menu-item routerLink="/corporate-finance" (click)="drawer.close()">
        {{ "MENU.CORPORATE_FINANCE" | translate }}
      </a>
    </mat-menu>

    <mat-menu #smeLoans>
      <a
        mat-menu-item
        routerLink="/100-loan-guarantee"
        (click)="drawer.close()"
      >
        {{ "MENU.100_LOAN" | translate }}
      </a>
      <a
        mat-menu-item
        routerLink="/80-90-guarantee-product"
        (click)="drawer.close()"
      >
        {{ "MENU.80_90_LOAN" | translate }}
      </a>
    </mat-menu>

    <mat-menu #langMenu>
      <button mat-menu-item (click)="useLang('zh')">
        {{ "MENU.LANG_ZH" | translate }}
      </button>
      <button mat-menu-item (click)="useLang('en')">
        {{ "MENU.LANG_EN" | translate }}
      </button>
    </mat-menu>
  </mat-sidenav>

  <mat-sidenav-content>
    <mat-toolbar
      class="sticky-toolbar"
      fxLayout="row"
      fxLayoutAlign="space-between center"
    >
      <div style="width: 10vw">
        <button
          type="button"
          aria-label="Toggle sidenav"
          mat-icon-button
          (click)="drawer.toggle()"
          style="margin: 0 auto"
        >
          <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
        </button>
      </div>

      <a mat-button disableRipple routerLink="home" fxHide.xs="true">
        <img
          src="assets/images/taihk-logo-without-r.png"
          alt="home logo"
          class="s-logo"
        />
      </a>

      <a
        mat-button
        disableRipple
        routerLink="home"
        fxHide.xs="false"
        fxHide.gt-xs="true"
      >
        <img
          src="assets/images/brown-s-logo.png"
          style="width: 50px; transform: translateX(10px)"
        />
      </a>

      <a style="padding: 0; margin: 0; width: 10vw" mat-button ></a>
      <!-- <a
        style="padding: 0; margin: 0; width: 10vw"
        class="toolbar-link"
        mat-button
        [matMenuTriggerFor]="langMenu"
      >
        <mat-icon style="transform: translateX(15px)">language</mat-icon>
      </a> -->
    </mat-toolbar>
    <div style="height: 80px"></div>

    <router-outlet></router-outlet>
    <app-footer></app-footer>
  </mat-sidenav-content>
</mat-sidenav-container>
