<!-- zh -->
<div *ngIf="lang === 'zh'" fxLayout="column" fxLayoutAlign="center stretch">
  <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
    <img src="assets/images/news.jpg" alt="news" class="banner-image" />
  </div>

  <section
    fxLayout="row"
    fxLayoutAlign="center"
    style="background-color: white"
  >
    <div fxLayout="row wrap" fxFlex="80" fxLayoutAlign="space-around center">
      <mat-card fxFlex="40" *ngFor="let news of news$| async; index as i;">
        <mat-card-header>
          <mat-card-title>{{ news.title }} </mat-card-title>
        </mat-card-header>

        <img mat-card-image [src]="coverImages[i]" class="cover-image"/>
        <mat-card-content>
          <p>
            {{ news.content }}
          </p>
        </mat-card-content>
      </mat-card>
    </div>
  </section>
</div>
<!-- end of zh -->

<!-- en -->
<div *ngIf="lang === 'en'" fxLayout="column" fxLayoutAlign="center stretch">
  <div
    class="banner-container"
    fxLayout="row"
    fxFlex="100"
    fxLayoutAlign="center center"
  >
    <img src="assets/images/news.jpg" alt="banner" class="banner-image" />
  </div>

  <section
    fxLayout="row"
    fxLayoutAlign="center"
    style="background-color: white"
  >
    <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
      <div style="height: 50vh" fxLayout="column" fxLayoutAlign="center center">
        <p>Only Chinese Version Available</p>
      </div>
    </div>
  </section>
</div>
<!-- end of en -->
