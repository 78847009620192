<div *ngIf="lang === 'zh'" fxLayout="column" fxLayoutAlign="center stretch">
  <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
    <img
      src="assets/images/corporate-change.jpg"
      alt="banner"
      class="banner-image"
    />
  </div>
  <section
    fxLayout="row"
    fxLayoutAlign="center"
    style="background-color: white"
  >
    <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
      <p></p>
      <p>
        無限公司註冊程序簡單，維護成本也比有限公司低，因此部分企業都會從無限公司開始。但當公司規模漸漸變大風險隨之而增，東主都會想把無限公司轉成有限公司，私人的財產能跟公司分開，不受公司的債務影響。
      </p>
      <h3>無限公司不能直接轉換成有限公司 </h3>
      <p>
        開始時，必須理解無限公司與有限公司在法律上是不同概念的存在。管理他們的政府部門與相關法例不同，擁有的權利與義務都不大相同。有限公司是以「法人」的身份存在，可視為獨立的個體，而無限公司只是東主個人的延伸，並不獨立於持有人本身。
      </p>
      <p>
        （有關兩者的分別，可參考：<a
          routerLink="/register-hong-kong-unlimited-company"
          >有限公司 VS 無限公司</a
        >）
      </p>

      <h3>方法1: 把無限公司結業，另開有限公司 </h3>
      <p>就客戶向大香港反映的成效，這是最好的做法。</p>
      <p>
        首先，政府把無限公司結業的手續並不複雜，而且能夠節省此無限公司營運的長遠開支。更重要的是不會做成行政上與帳目上的混亂，例如有客戶與你的有限公司簽約，卻把費用轉存至你無限公司的銀行帳戶內。有時公司忘記了交無限公司的商業登記費而被政府起訴，需要上庭及交罰款。
      </p>
      <p>
        當然，轉換時需要開設新的銀行戶口，或其他與別人合約/發票上改動都可能會花費不少時間，但基於「長痛不如短痛」的概念，在時間許可的情況下都會建議客人把無限公司結業，另開有限公司。
      </p>
      <p>
        成立公司詳情，可參考：<a
          routerLink="/register-hong-kong-limited-company"
          >成立香港有限公司</a
        >
      </p>
      <h3>使用有限公司持有無限公司</h3>
      <p>
        假如有部分業務的轉名手續是相當麻煩，例如一些政府牌照。
        可考慮開設一間新的有限公司，來持有原本的無限公司。
      </p>

      <p>
        如上文所說，有限公司在法例上屬於獨立的存在，是可以使用有限公司的身份來營運一間無限公司的。可是請注意該無限公司必須用合伙形式運作及其中一合伙人必須為自然人。
      </p>
      <p>這樣的做法可把無限公司在法律上的擁有權轉為有限公司名下。</p>
      <p><a href="https://wa.me/85295126339" target="_blank">立即申請</a></p>
    </div>
  </section>
</div>
