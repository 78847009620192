import { Component, OnInit, Inject } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/firestore';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { firestore } from 'firebase';
import { RequestFormData } from '../interfaces/request-form-data';

@Component({
  selector: 'app-request-form-dialog',
  templateUrl: './request-form-dialog.component.html',
  styleUrls: ['./request-form-dialog.component.scss'],
})
export class RequestFormDialogComponent implements OnInit {
  private requestCol: AngularFirestoreCollection;
  public uploading: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: RequestFormData,
    public dialogRef: MatDialogRef<RequestFormDialogComponent>,
    private afs: AngularFirestore,
    private snackBar: MatSnackBar
  ) {
    this.uploading = false;
    this.requestCol = this.afs.collection('request-form');
  }

  ngOnInit(): void {}

  cancel(): void {
    this.dialogRef.close();
  }

  async submit(): Promise<void> {
    this.uploading = true;
    try {
      const formRequestData = {
        ...this.data,
        created_at: firestore.FieldValue.serverTimestamp(),
      };
      const addServiceRequestResult = await this.requestCol.add(
        formRequestData
      );
      console.log(addServiceRequestResult.id);
      const message = `多謝您的查詢，我們將發送服務申請表格至您的電郵${this.data.contact_email}`;
      this.snackBar.open(message, 'x', { duration: 5000 });
      this.uploading = false;
      this.dialogRef.close();
      return;
    } catch (err) {
      const message = `我們暫未能處理您的查詢，請稍後再試。`;
      this.snackBar.open(message, 'x', { duration: 5000 });
      this.uploading = false;
      this.dialogRef.close();
      return;
    }
  }
}
