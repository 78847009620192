import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './home/home.component';
import { NewsComponent } from './news/news.component';
import { CalculatorComponent } from './calculator/calculator.component';
import { AboutUsComponent } from './about-us/about-us.component';

// angular fire
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { environment } from 'src/environments/environment';

// angular material
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';

// @angular-material-components
import { NgxMatFileInputModule } from '@angular-material-components/file-input';

// @ngx-translate/core
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

import { RegisterLimitedCompanyFormComponent } from './register-limited-company-form/register-limited-company-form.component';
import { ShareholderDirectorInfoFormComponent } from './shareholder-director-info-form/shareholder-director-info-form.component';
import { BviComponent } from './bvi/bvi.component';
import { CaymanComponent } from './cayman/cayman.component';
import { SamoaComponent } from './samoa/samoa.component';
import { PrcComponent } from './prc/prc.component';
import { SingaporeComponent } from './singapore/singapore.component';
import { TaiwanComponent } from './taiwan/taiwan.component';
import { ComSecComponent } from './com-sec/com-sec.component';
import { TrademarkComponent } from './trademark/trademark.component';
import { PatentComponent } from './patent/patent.component';
import { OpenHongKongBankComponent } from './open-hong-kong-bank/open-hong-kong-bank.component';
import { FooterComponent } from './footer/footer.component';
import { HongkongComponent } from './hongkong/hongkong.component';
import { LiquidationComponent } from './liquidation/liquidation.component';
import { GovFundsComponent } from './gov-funds/gov-funds.component';
import { OpenSingaporeBankComponent } from './open-singapore-bank/open-singapore-bank.component';
import { SmartLineComponent } from './smart-line/smart-line.component';
import { AccountTaxComponent } from './account-tax/account-tax.component';
import { WhatsappComponent } from './whatsapp/whatsapp.component';
import { NavShellComponent } from './nav-shell/nav-shell.component';
import { LayoutModule } from '@angular/cdk/layout';

import { FlexLayoutModule } from '@angular/flex-layout';
import { AnnualMaintenanceComponent } from './annual-maintenance/annual-maintenance.component';
import { CsrComponent } from './csr/csr.component';
import { CorporateFinanceComponent } from './corporate-finance/corporate-finance.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { AddToCartButtonComponent } from './add-to-cart-button/add-to-cart-button.component';
import { InvoiceTemplateComponent } from './invoice-template/invoice-template.component';
import { ContactFormDialogComponent } from './contact-form-dialog/contact-form-dialog.component';
import { RequestManualQuotationComponent } from './request-manual-quotation/request-manual-quotation.component';
import { RequestManualQuotationDialogComponent } from './request-manual-quotation-dialog/request-manual-quotation-dialog.component';
import { NgoComponent } from './ngo/ngo.component';
import { OverseasComponent } from './overseas/overseas.component';
import { VietnamComponent } from './vietnam/vietnam.component';
import { VirtualOfficeComponent } from './virtual-office/virtual-office.component';
import { RegisterHongKongLimitedCompanyComponent } from './register-hong-kong-limited-company/register-hong-kong-limited-company.component';
import { RegisterHongKongUnlimitedCompanyComponent } from './register-hong-kong-unlimited-company/register-hong-kong-unlimited-company.component';
import { ThkCloudSecretaryComponent } from './thk-cloud-secretary/thk-cloud-secretary.component';
import { TvpComponent } from './tvp/tvp.component';
import { BudComponent } from './bud/bud.component';
import { RequestServiceDialogComponent } from './request-service-dialog/request-service-dialog.component';
import { UnlimitedCompanyPriceChartComponent } from './components/unlimited-company-price-chart/unlimited-company-price-chart.component';
import { CompanySecretaryServiceComponent } from './company-secretary-service/company-secretary-service.component';
import { UnlimitedCompanyToLimitedCompanyComponent } from './unlimited-company-to-limited-company/unlimited-company-to-limited-company.component';
import { RegisterCompanySecretaryServiceComponent } from './register-company-secretary-service/register-company-secretary-service.component';
import { TransferCompanySharesComponent } from './transfer-company-shares/transfer-company-shares.component';
import { AccountingServiceComponent } from './accounting-service/accounting-service.component';
import { LimitedCompanyTaxComponent } from './limited-company-tax/limited-company-tax.component';
import { UnlimitedCompanyTaxComponent } from './unlimited-company-tax/unlimited-company-tax.component';
import { CompanyTaxComponent } from './company-tax/company-tax.component';
import { Loan100Component } from './loan100/loan100.component';
import { Loan8090Component } from './loan8090/loan8090.component';
import { AgmCoreModule } from '@agm/core';
import { TeamComponent } from './home/team/team.component';
import { FixWhatsappButtonComponent } from './fix-whatsapp-button/fix-whatsapp-button.component';
import { NgoPriceChartComponent } from './components/ngo-price-chart/ngo-price-chart.component';
import { RequestFormDialogComponent } from './request-form-dialog/request-form-dialog.component';
import { FourStepsComponent } from './components/four-steps/four-steps.component';
import { SetupCompanyFourStepsComponent } from './components/setup-company-four-steps/setup-company-four-steps.component';
import { VirtualOfficePriceChartComponent } from './components/virtual-office-price-chart/virtual-office-price-chart.component';
import { LimitedCompanyPriceChartComponent } from './components/limited-company-price-chart/limited-company-price-chart.component';
// import { TeamComponent } from './home/home/team/team.component';

@NgModule({
  declarations: [
    AppComponent,
    CalculatorComponent,
    HomeComponent,
    NewsComponent,
    AboutUsComponent,
    RegisterLimitedCompanyFormComponent,
    ShareholderDirectorInfoFormComponent,
    BviComponent,
    CaymanComponent,
    SamoaComponent,
    PrcComponent,
    SingaporeComponent,
    TaiwanComponent,
    ComSecComponent,
    TrademarkComponent,
    PatentComponent,
    OpenHongKongBankComponent,
    FooterComponent,
    HongkongComponent,
    LiquidationComponent,
    GovFundsComponent,
    OpenSingaporeBankComponent,
    SmartLineComponent,
    AccountTaxComponent,
    WhatsappComponent,
    NavShellComponent,
    AnnualMaintenanceComponent,
    CsrComponent,
    CorporateFinanceComponent,
    InvoiceComponent,
    AddToCartButtonComponent,
    InvoiceTemplateComponent,
    ContactFormDialogComponent,
    RequestManualQuotationComponent,
    RequestManualQuotationDialogComponent,
    NgoComponent,
    OverseasComponent,
    VietnamComponent,
    VirtualOfficeComponent,
    RegisterHongKongLimitedCompanyComponent,
    RegisterHongKongUnlimitedCompanyComponent,
    ThkCloudSecretaryComponent,
    TvpComponent,
    BudComponent,
    RequestServiceDialogComponent,
    UnlimitedCompanyPriceChartComponent,
    CompanySecretaryServiceComponent,
    UnlimitedCompanyToLimitedCompanyComponent,
    RegisterCompanySecretaryServiceComponent,
    TransferCompanySharesComponent,
    AccountingServiceComponent,
    LimitedCompanyTaxComponent,
    UnlimitedCompanyTaxComponent,
    CompanyTaxComponent,
    Loan100Component,
    Loan8090Component,
    TeamComponent,
    FixWhatsappButtonComponent,
    NgoPriceChartComponent,
    RequestFormDialogComponent,
    FourStepsComponent,
    SetupCompanyFourStepsComponent,
    VirtualOfficePriceChartComponent,
    LimitedCompanyPriceChartComponent,
    // TeamComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,

    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireFunctionsModule,

    NgxMatFileInputModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    FlexLayoutModule,
    LayoutModule,

    // material
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatRadioModule,
    MatCardModule,
    MatTabsModule,
    MatIconModule,
    MatMenuModule,
    MatSnackBarModule,
    MatExpansionModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatButtonToggleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCemVq9zNhWQs_P448L_LIXf-FUkBMKUpg', //f2m-demo
    }),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
