import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { RequestFormDialogComponent } from '../request-form-dialog/request-form-dialog.component';
import { RequestFormData, SERVICE_ZH } from '../interfaces/request-form-data';
import * as PLANS from '../../assets/data/virtual-office-price-data.json';
import { PriceChartData } from '../interfaces/price-chart-data';

@Component({
  selector: 'app-virtual-office',
  templateUrl: './virtual-office.component.html',
  styleUrls: ['./virtual-office.component.scss'],
})
export class VirtualOfficeComponent implements OnInit {

  lang: string;
  lat: number = 22.284675;
  lng: number = 114.153525;
  zoom: number = 20;
  public plans: { [key: string]: PriceChartData } = (PLANS as any).default;

  constructor(
    public dialog: MatDialog,
    public translateService: TranslateService
  ) {
    this.lang = this.translateService.currentLang;
    this.translateService.onLangChange.subscribe(
      (event) => (this.lang = event.lang)
    );
  }
  ngOnInit(): void {}


  openDialog(service: SERVICE_ZH): void {
    const formData: RequestFormData = {
      contact_person: '',
      contact_number: '',
      contact_email: '',
      service_zh: service,
    };

    const requestServiceDialogRef = this.dialog.open(
      RequestFormDialogComponent,
      { data: formData }
    );
    requestServiceDialogRef.afterClosed().subscribe((result) => {
      console.log('dialog closed');
      console.log('result', result);
    });
  }
}
