import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  _services = {
    change_unlimited_com_name: {
      name_zh: '更改無限公司名稱 / 業務名稱',
      name_2_zh: '',
      name_desc_zh: '',
      name_en: 'Change of Company Name of a unlimited company / Business Name',
      name_2_en: '',
      name_desc_en: '',
      charge_desc_zh: 'HK$ 500',
      charge_desc_en: 'HK$ 500',
      disbursement: 0,
      manual_quotation: false,
      var_disbursement: false,
      charge: 500,
      icon: 'business',
    },
    apply_branch: {
      name_zh: '申請分行商業登記',
      name_2_zh: '不包括商業登記費',
      name_desc_zh: '代支 - 商業登記費',
      name_en: 'Application of Branch Business Registration',
      name_2_en: 'Not included BR fee',
      name_desc_en: 'Disbursement - BR fee',
      charge_desc_zh: 'HK$ 800',
      charge_desc_en: 'HK$ 800',
      disbursement: 250,
      manual_quotation: false,
      var_disbursement: false,
      charge: 800,
      icon: 'store',
    },
    increase_shares: {
      name_zh: '增發股本 *',
      name_2_zh: '',
      name_desc_zh: '',
      name_en: 'Issue and Allotment of Share(s) *',
      name_2_en: '',
      name_desc_en: '',
      charge_desc_zh: 'HK$ 800',
      charge_desc_en: 'HK$ 800',
      disbursement: 0,
      manual_quotation: false,
      var_disbursement: false,
      charge: 800,
      icon: 'present_to_all',
    },
    transfer_shares: {
      name_zh: '股份轉讓',
      name_2_zh: '不包括政府印花稅',
      name_desc_zh: '代支 - 政府印花稅',
      name_en: 'Share Transfer',
      name_2_en: 'Not included Stamp Duty',
      name_desc_en: 'Disbursement - Stamp Duty',
      charge_desc_zh: 'HK$ 1,200',
      charge_desc_en: 'HK$ 1,200',
      disbursement: 0,
      manual_quotation: true,
      var_disbursement: true,
      charge: 1200,
      icon: 'settings_ethernet',
    },
    hk_com_sec: {
      name_zh: '担任名義公司秘書 - 一年',
      name_2_zh: '',
      name_desc_zh: '',
      name_en: 'Act as named company secretary - one year',
      name_2_en: '',
      name_desc_en: '',
      charge_desc_zh: 'HK$ 600',
      charge_desc_en: 'HK$ 600',
      disbursement: 0,
      manual_quotation: false,
      var_disbursement: false,
      charge: 600,
      icon: '',
    },
    hk_com_reg_address: {
      name_zh: '香港公司註冊地址 - 一年',
      name_2_zh: '',
      name_desc_zh: '已包HK$200 郵費按金',
      name_en: 'Provision of Registered Address',
      name_2_en: '',
      name_desc_en: '(HK$200 postal deposit included)',
      charge_desc_zh: 'HK$ 1,300',
      charge_desc_en: 'HK$ 1,300',
      disbursement: 0,
      manual_quotation: false,
      var_disbursement: false,
      charge: 1300,
      icon: '',
    },

    // trademark
    trademark_canada: {
      name_zh: '註冊加拿大商標 - 首類別',
      name_2_zh: '',
      name_desc_zh: '額外類別',
      name_en: 'Canada Trademark Registration - First class',
      name_2_en: '',
      name_desc_en: 'Additional classes(es)',
      charge_desc_zh: 'HK$ 9,000',
      charge_desc_en: 'HK$ 3,000',
      disbursement: 0,
      manual_quotation: true,
      var_disbursement: true,
      charge: 9000,
      icon: '',
    },
    trademark_hk: {
      name_zh: '註冊香港商標 - 首類別',
      name_2_zh: '',
      name_desc_zh: '額外類別',
      name_en: 'Hong Kong Trademark Registration - First class',
      name_2_en: '',
      name_desc_en: 'Additional classes(es)',
      charge_desc_zh: 'HK$ 2,500',
      charge_desc_en: 'HK$ 2,500',
      disbursement: 0,
      manual_quotation: true,
      var_disbursement: true,
      charge: 2500,
      icon: '',
    },
    trademark_china: {
      name_zh: '註冊中國商標 - 首類別',
      name_2_zh: '',
      name_desc_zh: '額外類別',
      name_en: 'China Trademark Registration - First class',
      name_2_en: '',
      name_desc_en: 'Additional classes(es)',
      charge_desc_zh: 'HK$ 3,000',
      charge_desc_en: 'HK$ 3,000',
      disbursement: 0,
      manual_quotation: true,
      var_disbursement: true,
      charge: 3000,
      icon: '',
    },
    trademark_macau: {
      name_zh: '註冊澳門商標 - 首類別',
      name_2_zh: '',
      name_desc_zh: '額外類別',
      name_en: 'Macau Trademark Registration - First class',
      name_2_en: '',
      name_desc_en: 'Additional classes(es)',
      charge_desc_zh: 'HK$ 3,800',
      charge_desc_en: 'HK$ 3,800',
      disbursement: 0,
      manual_quotation: true,
      var_disbursement: true,
      charge: 3800,
      icon: '',
    },
    trademark_taiwan: {
      name_zh: '註冊台灣商標 - 首類別',
      name_2_zh: '',
      name_desc_zh: '額外類別',
      name_en: 'Taiwan Trademark Registration - First class',
      name_2_en: '',
      name_desc_en: 'Additional classes(es)',
      charge_desc_zh: 'HK$ 6,000',
      charge_desc_en: 'HK$ 6,000',
      disbursement: 0,
      manual_quotation: true,
      var_disbursement: true,
      charge: 6000,
      icon: '',
    },
    trademark_us: {
      name_zh: '註冊美國商標 - 首類別',
      name_2_zh: '',
      name_desc_zh: '額外類別',
      name_en: 'United States Trademark Registration - First class',
      name_2_en: '',
      name_desc_en: 'Additional classes(es)',
      charge_desc_zh: 'HK$ 7,500',
      charge_desc_en: 'HK$ 7,500',
      disbursement: 0,
      manual_quotation: true,
      var_disbursement: true,
      charge: 7500,
      icon: '',
    },
    trademark_uk: {
      name_zh: '註冊英國商標 - 首類別',
      name_2_zh: '',
      name_desc_zh: '額外類別',
      name_en: 'Britain Trademark Registration - First class',
      name_2_en: '',
      name_desc_en: 'Additional classes(es)',
      charge_desc_zh: 'HK$ 6,500',
      charge_desc_en: 'HK$ 6,500',
      disbursement: 0,
      manual_quotation: true,
      var_disbursement: true,
      charge: 6500,
      icon: '',
    },
    trademark_germany: {
      name_zh: '註冊德國商標 - 首類別',
      name_2_zh: '',
      name_desc_zh: '額外類別',
      name_en: 'Germany Trademark Registration - First class',
      name_2_en: '',
      name_desc_en: 'Additional classes(es)',
      charge_desc_zh: 'HK$ 10,800',
      charge_desc_en: 'HK$ 10,800',
      disbursement: 0,
      manual_quotation: true,
      var_disbursement: true,
      charge: 10800,
      icon: '',
    },
    trademark_eu: {
      name_zh: '註冊歐盟商標 - 首類別',
      name_2_zh: '',
      name_desc_zh: '額外類別',
      name_en: 'Europe Union Trademark Registration - First class',
      name_2_en: '',
      name_desc_en: 'Additional classes(es)',
      charge_desc_zh: 'HK$ 12,500',
      charge_desc_en: 'HK$ 12,500',
      disbursement: 0,
      manual_quotation: true,
      var_disbursement: true,
      charge: 12500,
      icon: '',
    },
    trademark_jp: {
      name_zh: '註冊日本商標 - 首類別',
      name_2_zh: '',
      name_desc_zh: '',
      name_en: 'Japan Trademark Registration - First class',
      name_2_en: '',
      name_desc_en: 'Additional classes(es)',
      charge_desc_zh: 'HK$ 13,500',
      charge_desc_en: 'HK$ 13,500',
      disbursement: 0,
      manual_quotation: true,
      var_disbursement: true,
      charge: 13500,
      icon: '',
    },
    trademark_korea: {
      name_zh: '註冊南韓商標 - 首類別',
      name_2_zh: '',
      name_desc_zh: '額外類別',
      name_en: 'South Korea Trademark Registration - First class',
      name_2_en: '',
      name_desc_en: 'Additional classes(es)',
      charge_desc_zh: 'HK$ 9,500',
      charge_desc_en: 'HK$ 9,500',
      disbursement: 0,
      manual_quotation: true,
      var_disbursement: true,
      charge: 9500,
      icon: '',
    },
    trademark_singapore: {
      name_zh: '註冊新加坡商標 - 首類別',
      name_2_zh: '',
      name_desc_zh: '額外類別',
      name_en: 'Singapore Trademark Registration - First class',
      name_2_en: '',
      name_desc_en: 'Additional classes(es)',
      charge_desc_zh: 'HK$ 5,000',
      charge_desc_en: 'HK$ 5,000',
      disbursement: 0,
      manual_quotation: true,
      var_disbursement: true,
      charge: 5000,
      icon: '',
    },
    trademark_australia: {
      name_zh: '註冊澳洲商標 - 首類別',
      name_2_zh: '',
      name_desc_zh: '額外類別',
      name_en: 'Australia Trademark Registration - First class',
      name_2_en: '',
      name_desc_en: 'Additional classes(es)',
      charge_desc_zh: 'HK$ 5,000',
      charge_desc_en: 'HK$ 5,000',
      disbursement: 0,
      manual_quotation: true,
      var_disbursement: true,
      charge: 5000,
      icon: '',
    },
    // sz_com_reg: {
    //   name_zh: '深圳公司註冊費用',
    //   name_desc_zh: '',
    //   name_en: '',
    //   name_desc_en: '',
    //   charge_desc_zh: 'HK$ 5,599',
    //   charge_desc_en: 'HK$ 5,599',
    //   disbursement: 0,
    //   var_disbursement: false,
    //   charge: 5599,
    // },
    // gz_com_reg: {
    //   name_zh: '廣州公司註冊費用',
    //   name_desc_zh: '',
    //   name_en: '',
    //   name_desc_en: '',
    //   charge_desc_zh: 'HK$ 12,999',
    //   charge_desc_en: 'HK$ 12,999',
    //   disbursement: 0,
    //   var_disbursement: false,
    //   charge: 12999,
    // },
    // bj_com_reg: {
    //   name_zh: '北京公司註冊費用',
    //   name_desc_zh: '',
    //   name_en: '',
    //   name_desc_en: '',
    //   charge_desc_zh: 'HK$ 15,999',
    //   charge_desc_en: 'HK$ 15,999',
    //   disbursement: 0,
    //   var_disbursement: false,
    //   charge: 15999,
    // },
    // sh_com_reg: {
    //   name_zh: '上海公司註冊費用',
    //   name_desc_zh: '',
    //   name_en: '',
    //   name_desc_en: '',
    //   charge_desc_zh: 'HK$ 15,999',
    //   charge_desc_en: 'HK$ 15,999',
    //   disbursement: 0,
    //   var_disbursement: false,
    //   charge: 15999,
    // },
    // china_com_tax: {
    //   name_zh: '國內公司報稅',
    //   name_desc_zh: '',
    //   name_en: '',
    //   name_desc_en: '',
    //   charge_desc_zh: 'HK$ 899／月',
    //   charge_desc_en: 'HK$ 899 per month',
    //   disbursement: 0,
    //   var_disbursement: false,
    //   charge: 899,
    // },
    // sgp_com_reg: {
    //   name_zh: '新加坡公司註冊費用',
    //   name_desc_zh: '',
    //   name_en: '',
    //   name_desc_en: '',
    //   charge_desc_zh: 'HK$ 7,000 起',
    //   charge_desc_en: '',
    //   disbursement: 0,
    //   var_disbursement: false,
    //   charge: 7000,
    // },
    office_address: {
      name_zh: '提供註冊辦事處地址',
      name_2_zh: '免地址變更手續費及水牌費用，已包括 *HK$200 按金',
      name_desc_zh: '免地址變更手續費及水牌費用，已包括 *HK$200 按金',
      name_en: 'Provision of Registered Address',
      name_2_en: 'waive the fee for change of registered address',
      name_desc_en: 'waive the fee for change of registered address',
      charge_desc_zh: 'HK$ 1,300',
      charge_desc_en: 'HK$ 1,300',
      disbursement: 0,
      manual_quotation: false,
      var_disbursement: false,
      charge: 1300,
    },
    sign_ctrl_reg: {
      name_zh: '建立及或維護「重要控制人登記冊」',
      name_2_zh: '',
      name_desc_zh: '',
      name_en: 'Prepare and/or Maintain Significant Controllers Register',
      name_2_en: '',
      name_desc_en: '',
      charge_desc_zh: 'HK$ 300',
      charge_desc_en: 'HK$ 300',
      disbursement: 0,
      manual_quotation: false,
      var_disbursement: false,
      charge: 300,
      icon: '',
    },
    annual_return: {
      name_zh: '提交週年申報#',
      name_2_zh: '不包政府登記費及逾期罰款',
      name_desc_zh: '代支 - 政府登記費(本年度香港政府已括免）',
      name_en: 'Prepare and submit Annual Returns',
      name_2_en: 'Not included Annual filing fee and late penalty',
      name_desc_en:
        'Disbursement - Annual filing fee ( HK Govt wave for this year)',
      charge_desc_zh: 'HK$ 550',
      charge_desc_en: 'HK$ 550',
      disbursement: 0,
      manual_quotation: false,
      var_disbursement: false,
      charge: 550,
      icon: '',
    },
    com_sec: {
      name_zh: '担任名義公司秘書',
      name_2_zh: '',
      name_desc_zh: '免法定秘書變更手續費',
      name_en: 'Act as yearly named company secretary',
      name_2_en: '',
      name_desc_en: 'waive the fee for change of company secretary',
      charge_desc_zh: 'HK$ 600',
      charge_desc_en: 'HK$ 600',
      disbursement: 0,
      manual_quotation: false,
      var_disbursement: false,
      charge: 600,
    },
    br: {
      name_zh: '商業登記續期服務',
      name_2_zh: '不包政府登記費及逾期罰款',
      name_desc_zh: '代支 - 商業登記費',
      name_en: 'Renew Business Registration',
      name_2_en: 'Not included BR fee and late penalty',
      name_desc_en: 'Disbursement - BR fee',
      charge_desc_zh: 'HK$ 500',
      charge_desc_en: 'HK$ 500',
      disbursement: 250,
      manual_quotation: false,
      var_disbursement: false,
      charge: 500,
      icon: '',
    },
    change_address: {
      name_zh: '地址變更',
      name_2_zh: '',
      name_desc_zh: '',
      name_en: 'Change of Registered Address',
      name_2_en: '',
      name_desc_en: '',
      charge_desc_zh: 'HK$ 500',
      charge_desc_en: 'HK$ 500',
      disbursement: 0,
      manual_quotation: false,
      var_disbursement: false,
      charge: 500,
      icon: 'home',
    },
    change_nature: {
      name_zh: '變更業務性質',
      name_2_zh: '',
      name_desc_zh: '',
      name_en: 'Change of Business Nature',
      name_2_en: '',
      name_desc_en: '',
      charge_desc_zh: 'HK$ 400',
      charge_desc_en: 'HK$ 400',
      disbursement: 0,
      manual_quotation: false,
      var_disbursement: false,
      charge: 400,
      icon: 'receipt',
    },
    change_limited_com_name: {
      name_zh: '更改有限公司名稱',
      name_2_zh: '不包政府收費',
      name_desc_zh: '代支 - 政府登記費',
      name_en: 'Change of Company Name of a limited company',
      name_2_en: 'Not included government filing fee',
      name_desc_en: 'Disbursement - government fee',
      charge_desc_zh: 'HK$ 880',
      charge_desc_en: 'HK$ 880',
      disbursement: 295,
      manual_quotation: false,
      var_disbursement: false,
      charge: 880,
      icon: 'settings',
    },
    change_director: {
      name_zh: '董事及或備任董事／法定秘書之更替',
      name_2_zh: '',
      name_desc_zh: '',
      name_en: 'Change of Director / Company Secretary',
      name_2_en: '',
      name_desc_en: '',
      charge_desc_zh: 'HK$ 300',
      charge_desc_en: 'HK$ 300',
      disbursement: 0,
      manual_quotation: false,
      var_disbursement: false,
      charge: 300,
      icon: 'supervisor_account',
    },
    change_director_info: {
      name_zh: '董事及或備任董事／法定秘書之資料變更',
      name_2_zh: '',
      name_desc_zh: '',
      name_en: 'Change in Particulars of Company Secretary / Director',
      name_2_en: '',
      name_desc_en: '',
      charge_desc_zh: 'HK$ 200',
      charge_desc_en: 'HK$ 200',
      disbursement: 0,
      manual_quotation: false,
      var_disbursement: false,
      charge: 200,
      icon: 'supervised_user_circle',
    },
    dereg_unlimited_com: {
      name_zh: '撒銷無限公司註冊',
      name_2_zh: '',
      name_desc_zh: '',
      name_en: 'Deregistration of a unlimited company',
      name_2_en: '',
      name_desc_en: '',
      charge_desc_zh: 'HK$ 300',
      charge_desc_en: 'HK$ 300',
      disbursement: 0,
      manual_quotation: false,
      var_disbursement: false,
      charge: 300,
      icon: 'work_off',
    },
    dereg_limited_com: {
      name_zh: '撒銷有限公司註冊',
      name_2_zh: '不包政府收費',
      name_desc_zh: '代支 - 政府登記費',
      name_en: 'Deregistration of a limited company',
      name_2_en: 'Not included government filing fee',
      name_desc_en: 'Disbursement - government fee',
      charge_desc_zh: 'HK$ 2,000',
      charge_desc_en: 'HK$ 2,000',
      disbursement: 690,
      manual_quotation: false,
      var_disbursement: false,
      charge: 2000,
      icon: 'cancel',
    },
    meeting_record: {
      name_zh: '撰寫公司狀況變更會議記錄',
      name_2_zh: '',
      name_desc_zh: '',
      name_en: 'Board Minutes to Approve the Corporate Changes',
      name_2_en: '',
      name_desc_en: '',
      charge_desc_zh: 'HK$ 600',
      charge_desc_en: 'HK$ 600',
      disbursement: 0,
      manual_quotation: false,
      var_disbursement: false,
      charge: 600,
      icon: 'description',
    },
    annual_meeting_record: {
      name_zh: '撰寫公司股東週年股東大會會議記錄',
      name_2_zh: '',
      name_desc_zh: '',
      name_en: 'Minutes of Annual General Meeting',
      name_2_en: '',
      name_desc_en: '',
      charge_desc_zh: 'HK$ 600',
      charge_desc_en: 'HK$ 600',
      disbursement: 0,
      manual_quotation: false,
      var_disbursement: false,
      charge: 600,
      icon: 'list',
    },
    change_article: {
      name_zh: '更改公司章程',
      name_2_zh: '每事項',
      name_desc_zh: '',
      name_en: 'Alteration of Company’s Articles',
      name_2_en: 'Each Amendment',
      name_desc_en: '',
      charge_desc_zh: 'HK$ 1,000',
      charge_desc_en: 'HK$ 1,000',
      disbursement: 0,
      manual_quotation: false,
      var_disbursement: false,
      charge: 1000,
      icon: 'subject',
    },
  };

  constructor() {}

  get services() {
    return this._services;
  }
}
