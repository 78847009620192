import { Component, OnInit, OnDestroy } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { MatCard } from '@angular/material/card';
import { TranslateService } from '@ngx-translate/core';
import { Observable, pipe, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

interface News {
  title: string;
  cover_image: string;
  urls: string[];
  status: string;
  content: string;
}

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss'],
})
export class NewsComponent implements OnInit, OnDestroy {
  lang: string;
  news$: Observable<any[]>;
  newsSub: Subscription;
  // news$: Promise<unknown[]>;
  coverImages = {};
  constructor(
    public translateService: TranslateService,
    public afs: AngularFirestore,
    public storage: AngularFireStorage
  ) {
    this.lang = this.translateService.currentLang;
    this.translateService.onLangChange.subscribe(
      (event) => (this.lang = event.lang)
    );
    this.coverImages = [];

    this.news$ = this.afs.collection('posts').valueChanges().pipe(take(1));
  }

  ngOnInit(): void {
    this.newsSub = this.afs
      .collection<News>('posts')
      .valueChanges()
      .subscribe((posts) =>
        posts.map(async (post, idx) => {
          console.log(post, idx);
          // const id = post.cover_image.split('/').pop().split('.').reverse().pop();
          const url = await this.getImageURL(post.cover_image);
          this.coverImages[idx] = url;
        })
      );
  }

  ngOnDestroy(): void {
    this.newsSub.unsubscribe();
  }

  async getImageURL(path) {
    console.log('get image url', path);
    // const downloadURL = await
    const url = await this.storage.ref(path).getDownloadURL().toPromise();
    // console.log(url);
    return url;

    // .subscribe((url) => console.log(url));
    // .pipe(take(1))
    //   .toPromise();
    // console.log('url', downloadURL);
    // return downloadURL;
  }
}
