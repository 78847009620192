<!-- zh -->
<app-fix-whatsapp-button></app-fix-whatsapp-button>
<div *ngIf="lang === 'zh'" fxLayout="column" fxLayoutAlign="center stretch">
  <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
    <img src="assets/images/loan.jpg" alt="banner" class="banner-image"/>
    <div class="banner-text" style="transform: translate(-60%, -30%)"></div>
  </div>
  <section
    fxLayout="row"
    fxLayoutAlign="center"
    style="background-color: white"
  >
    <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
      <p></p>
      <h1>百分百擔保特惠貸款</h1>
      <h1>請善用政府給你的資源，好好經營你的生意</h1>
      <p>
        百分百擔保特惠貸款再度宣佈延遲，這對不少中小企業絕對是一個好消息，因為政府仍然願意施予援手幫忙企業渡過難關；但另一邊廂，銀行貸款機構卻不斷收緊申請程序，使申請門檻愈來愈高。加上今年，甚至下年的經濟情況不容樂觀，如你的公司仍希望取得史上最優惠的中小企無抵押貸款，那便需要加快腳步，以充實自身現金流。
      </p>
      <h1>金融界的百年難得一遇，為什麼不借？</h1>
      <ol>
        <li>
          實際年利率低，現時為2.75%
          （除稅後2.25%），還款期更延長至十年，更可選擇首二十四個月還息不還本。
        </li>
        <li>
          由於中小企融資擔保計劃採用劃一年利率，所以企業向任何一家銀行申請所得到的貸款配套基本上都一樣。不過正如其他貸款融資項目，銀行批核時會查閱企業的經營數據及貸款記錄而作出不同的年利率及借貸還款年期。
        </li>
        <li>
          借貸批核條件跟傳統中小企借貸不同，傳統中小企借貸銀行批核條件是企業的稅前利潤及企業銀行存款結餘，而百分百擔保特惠貸款則没有這些考慮因素。
        </li>
        <li>
          由於百分百擔保特惠貸款只基於企業營業額於2020年單月較2019年其中一季度下跌百分之三十作門檻，因此以往中小企為了釋放潛在貸款能力，改善其會計安排提升稅前利潤而引致稅務成本可省減。
        </li>
        <li>
          百分百擔保特惠貸款由香港按證保險有限公司推出，參與的財務機構及銀行角色只是審閱申請貸款公司的申請文件，並向香港按證保險公司提交擔保申請表及相關證明文件以作審閱及批核。
          因此請善用政府給你的資源， 抓緊這機會。
        </li>
        <li>
          百分百擔保特惠貸款由至2020年推出後已不繼優化，借貨額由剛開始的工資加租金的6倍及最長還款期為5年到現在增至工資加租金的27倍及最長還款期為10年。如客戶由2020年成功申請就像已鋪設了資金管道一樣，不繼可有新增營運資金但還款期可以不繼延長。
          一般而言，現已借款企業如有需要可向貸款機構申請增加貸款額及
          /或延長還款期，一般情況下無需遞交額外證明檔。 
        </li>
        <li>
          百分百擔保特惠貸款基本上不需要審查公司股東或擔保人自身TU ,
          另外百分百擔保特惠貸款借貸額也不會影響公司股東或擔保人個人樓宇按揭的借貸成數及還款壓力測試。
        </li>
        <li>
          百分百擔保特惠貸款能提早全數或部份還款，不需要任何罰息或銀行手續費。
        </li>
        <li>
          以下行業，由於受疫情影響被銀行於企業借貸方面視為高風險行業：
          <ul>
            <li>化妝品批發</li>
            <li>旅行社</li>
            <li>美容院</li>
            <li>餐廳</li>
          </ul>
        </li>
      </ol>
      <p>
        百分百擔保特惠貸款保貸款不會因應不同行業而影響申請難度、借貸成本、還款期。另外計劃重點是支援疫情期間受經濟下行影響較大的行業。
      </p>
      <h1>銀行為什麼對百分百擔保特惠貸款持保守態度</h1>
      <p>
        百分百貸款計劃已推行了超過一半時間，不少銀行都已由積極主動招攬客戶申請變成了非常被動的角色，部份更已「落雨收遮」。無他，銀行到數後會開始將注意力放於銀行賺錢的項目上。
      </p>
      <p>
        試想像，銀行在百分百擔保貸款這個產品當中能賺到什麼？貸款利息收入將歸政府所有，加上銀行不能向中小企業收取額外手續費，更不能銷售其他計劃。
        銀行參與計劃的收益成疑，在此非常時期，於缺乏商業誘因的情況下，銀行即使參與計劃，也顯然不會積極配合。
      </p>
      <p>
        此外，部分客人更會將所得之中小企百分百擔保貸款用於清還銀行較為貴息的產品，導致銀行所賺的利息收入大減。
        另外有一些不法份子涉假銀行文件詐騙「百分百擔保特惠貸款」，因此在回報有限，風險無限下，很多銀行都集中於投資保險產品，或八成中小企擔保貸款計劃及九成中小企擔保貸款計劃，並對百分百擔保貸款說不了！
      </p>
      <h1>百分百擔保特惠貸款（SFGS100）簡介</h1>
      <table>
        <thead>
          <tr>
            <th colspan="2">「百分百擔保特惠貸款」（SFGS100）詳情</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>營運要求</th>
            <th>
              <ol>
                <li>2020年3月31日或之前營運</li>
                <li>
                  自2020年2月份起單月營業額較2019年1月至2020年6月的任何一個季度的平均每月營業額下跌三成或以上
                </li>
                <li>業務在香港運作及擁有有效的商業登記證</li>
              </ol>
            </th>
          </tr>
          <tr>
            <th>每宗申請最高貸款額</th>
            <th>企業的27個月僱員薪金及租金的總和，或900萬港元（較低者為準）</th>
          </tr>
          <tr>
            <th>實際年利率</th>
            <th>P－2.5% （現時實際年利率為2.75%）</th>
          </tr>
          <tr>
            <th>擔保費（支付予按保的保費）</th>
            <th>全免</th>
          </tr>
          <tr>
            <th>還款期</th>
            <th>
              <ol>
                <li>有固定償還期限（每月還款）</li>
                <li>還款期最長10年</li>
                <li>最長24個月可以還息不還本（已延長）</li>
              </ol>
            </th>
          </tr>
        </tbody>
      </table>

      <h1>百分百擔保特惠貸款計劃可以為我提供多少資金？</h1>
      <h3 style="color: crimson">百分百擔保例子 公司A：</h3>
      <p>
        A公司是一間中小企業，主要業務為建築，有自己的寫字樓，經濟不景氣下申請了SFGS
        100；A公司的員工有10人，平均月薪為$12,904港元；而每月租金為$18,500港元，A公司成功申請SFGS100時，能獲取的金額為$3,983,580。
      </p>
      <p>$12,904*10*27 + $18,500 * 27 = $3,983,580</p>
      <h3 style="color: crimson">百分百擔保例子 公司B：</h3>
      <p>
        B公司也是一間中小企業，主要業務為零售，有自己的實體商店，經濟不景氣下申請了百分百擔保特惠貸款；B公司的員工有17人，平均月薪為$19,528港元；而每月租金為$75,000港元，B公司成功申請SFGS100時，能獲取的金額為$9,000,000*：
      </p>
      <p>$19,528*17*27 + $75,000 * 27 = $10,988,352 （上限$9,000,000）</p>

      <h1>營業額下跌三成計算方法參考例子</h1>
      <p>
        假設企業今年2月的營業額為150萬港元，而2019年的1-3月營業額分別為300萬港元、250萬港元及300萬港元，即2019年第一季平均營業額為283萬港元，較今年2月跌47%，符合申請資格。
      </p>
      <table>
        <thead>
          <tr>
            <th>申請資格</th>
            <th>金額（港元）</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>2020年2月營業額</th>
            <th>150萬</th>
          </tr>
          <tr>
            <th>
              <i>2019年首個季度平均營業額</i><br />
              2019年1月<br />2019年2月<br />2019年3月
            </th>
            <th><i>283萬</i><br />300萬<br />250萬<br />300萬</th>
          </tr>
          <tr>
            <th>變動</th>
            <th>下跌47%（符合資格）</th>
          </tr>
        </tbody>
      </table>

      <h1>
        由2020到現在，我們一直担任中小企業融資顧問，以下為申請百分百擔保特惠貸款過程中每一個的魔鬼細節位
      </h1>
      <ol>
        <li>只有租約，業主没有租金收據提供</li>
        <li>新入職僱員或新參與強積金計劃僱主強積金供款問題</li>
        <li>行業限制或員工要求，現金出糧的僱員及如何令銀行信納</li>
        <li>公司生意主要收入為現金，如何計算營業額及令銀行信納</li>
      </ol>

      <h1>百分百擔保特惠貸款對借貸人行業限制存在嗎？</h1>
      <p>
        由於百分百擔保特惠貸款（SFGS100）旨在紓緩中小企在經濟下行時支付薪金或租金的壓力，所以政府沒有為申請行業設限。不過一如其他信貸擔保產品，企業需要在香港註冊及營運，具有商業登記證。上市公司或財務機構（或其附屬公司）則不符合資格。
      </p>
      <p>
        值得注意的是，百分百擔保特惠貸款（SFGS100）要求企業在2020年3月31日或之前營運
        ，而且需要提供營業數據佐證，目前計劃只接受自去年2月份起單月營業額較2019年1月至2020年6月的任何一個季度的平均每月營業額下跌三成或以上的企業申請，以便將貸款發放給有逼切需要的企業。
      </p>
      <h1>怎樣才可更容易獲得申請？</h1>
      <ol>
        <li>
          交齊文件，檢查好「所有」要準備的文件是否齊全，例如商業登記證、最近3個月的強積金供款證明及銀行月結單等；
        </li>
        <li>展示令銀行信納銷售營業額下降至少30％的文件；</li>
        <li>
          公司老闆們溝通清晰及達成共識，有超過50%股份以上的股東願意作為擔保人；
        </li>
        <li>
          委任我們為企業融資顧問，讓你們能了解中小企百分百擔保貸款申請上各方要的魔鬼細節位及獲取我們專業判斷及考慮。
        </li>
      </ol>

      <h3>
        企業已借了九成信貸擔保產品(SFGS 90)或八成信貸擔保產品(SFGS
        80)，但又想加大融資額度，可以怎樣做？
      </h3>
      <p>
        倘若企業已申請了九成信貸擔保產品(SFGS 90)或八成信貸擔保產品(SFGS
        80)，隨著業務需要而需更多資金，申請百分百擔保特惠貸款(SFGS
        100)是不錯的選擇。
        由於百分百擔保特惠貸款跟傳統中小企借貸的審批基準不同，並不會計算借款公司的還款能力壓力測試，因此有關企業如已成功借取的九成信貸擔保產品或八成信貸擔保產品借貸額不會影響申請百分百擔保特惠貸款的成功率。
      </p>

      <h1>Q&A</h1>
      <p>
        從2020到現在，我們處理了多單「百分百擔保貸款」申請，這篇Q&A是綜合了客戶經常性對我們提出的問題。
      </p>
      <p>
        Q1: 剛剛成立的公司可以申請這筆貸款嗎？<br />
        A:
        不可以，根據政府2021至22年度的財政預算案，合資格企業必須在2020年6月30日前最少營運3個月。
      </p>
      <p>
        Q2:
        完成向銀行貸款後，僱主將公司破產，貸款是否不用歸還，由政府百分百擔保還給銀行？
        <br />
        A:
        銀行會先向該中小企百分百擔保貸款的擔保人作第一追討，如果有關擔保人不能為這一筆貸款準時還錢的話，銀行才會再向政府索取貸款金額。
      </p>
      <p>
        Q3: 如果僱主最近為公司的股權或董事作出調配，會否影響貸款的批核？<br />
        A:
        公司董事的委任或辭退並不會影響百份百擔保貸款的批核。反而，公司股權在申請貸款前12個月如有作出調配的話，銀行有機會會因此拒絕貸款的批核。
      </p>
      <p>
        Q4: 需要交齊什麼文件，銀行才可以最快批核百分百貸款？<br />A:
        商業登記證副本，公司註冊證書副本，銀行月結單，強積金供款紀錄，工資出糧紀錄，租約，租金支出紀錄，身份證副本(董事及股東)
      </p>
      <p>
        Q5: 無限公司和沒有已審計的財務報表可否申請百份百擔保貸款？<br />
        A:
        只要是香港成立的公司，無論是「有限公司」或「無限公司」都可以申請，以及「已審核財務報表」和「未經審核管理賬目」均可申請貸款。
      </p>
      <p>
        Q6: 無限公司没有公司戶口，能用東主個人銀行戶口流水作營運佐證嗎？<br />
        A: 可以， 但只適用於獨資經營的無限公司。 合伙經營的無限公司並不適用。
      </p>
      <p>
        百份百擔保貸款利率低，還款時間長，絕對有助香港現時的中小企渡過難關，甚至有助藉此發展業務。不過申請難度因人而異，不同的業務或僱主本身的貸款紀錄等等因素都會影響貸款批核的難度。
      </p>

      <p style="text-align: center">
        <img src="assets/images/icons/8090/documents.png" />
        <br />
        檢查所有提交文件格式與完整度<br />
        透視企業申請不足額或獲拒批的理由，幫手再申請<br />
        確保銀行在限期內儘快批出貸款
        <app-whatsapp [lang]="'zh'"></app-whatsapp>
      </p>

      <p style="text-align: center">
        <img src="assets/images/icons/8090/certificate.png" />
        <br />
        專業CPA註冊會計師<br />
        幫你準備齊全申請材料
        <app-whatsapp [lang]="'zh'"></app-whatsapp>
      </p>

      <p style="text-align: center">
        <img src="assets/images/icons/8090/bank.png" />
        <br />
        轉介友好銀行<br />
        話你知最快批核銀行
        <app-whatsapp [lang]="'zh'"></app-whatsapp>
      </p>

      <p style="text-align: center">
        <img src="assets/images/icons/8090/handshake.png" />
        <br />
        累計成功率高達98%<br />
        豐富經驗解決貸款疑難
        <app-whatsapp [lang]="'zh'"></app-whatsapp>
      </p>

      <h1>時間、額度無多，把握機會！</h1>
      <p>
        需求增加，供應減少，仍希望申請百分百貸款的朋友，請你加快步伐，不然計劃未到年尾就要提早完結了。我們的融資顧問會為客戶準備了不少申請貼士，令你申請更有把握。
      </p>
      <h3>忠告：借定唔借，還得到先好借。</h3>
    </div>
  </section>
</div>
