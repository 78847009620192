import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-four-steps',
  templateUrl: './four-steps.component.html',
  styleUrls: ['./four-steps.component.scss']
})
export class FourStepsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
