import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  FormArray,
  FormGroupDirective
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize, pairwise, take, map, startWith } from 'rxjs/operators';
import { combineLatest, Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-register-limited-company-form',
  templateUrl: './register-limited-company-form.component.html',
  styleUrls: ['./register-limited-company-form.component.scss'],
})
export class RegisterLimitedCompanyFormComponent implements OnInit, OnDestroy {
  
  // directive reference for reset validation
  @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;

  taiHK = {
    chiName: '',
    engName: 'In Formations & Secretaries Limited',
    address: '觀塘成業街18號新怡生工業大廈10樓1018A室',
    ci: '2703089',
  };

  readonly maxFileSize = 5 * 2 ** 20;
  registerLimitedCompanyForm = this.fb.group({
    contactPerson: this.fb.group({
      nameControl: ['', [Validators.required]],
      titleControl: ['', [Validators.required]],
      emailControl: ['', [Validators.required, Validators.email]],
      mobileControl: [
        '',
        [Validators.required, Validators.pattern('^[23456789][0-9]{7}$')],
      ], // mobile only '^[5679][0-9]{7}$'
      referSourceControl: ['', []],
    }),
    companyInfo: this.fb.group({
      firstEngNameControl: ['', [Validators.required]],
      secondEngNameControl: ['', [Validators.required]],
      firstChiNameControl: [''],
      secondChiNameControl: [''],

      useTaiHKAddressControl: ['no', [Validators.required]],
      addressControl: ['', [Validators.required]],

      issueSharesControl: [10000, [Validators.min(1), Validators.required]],
      totalFaceValueControl: [10000, [Validators.min(1), Validators.required]],
    }),
    companySec: this.fb.group({
      useTaiHKSecControl: ['no', [Validators.required]],
      typeControl: ['', [Validators.required]], // individual or company
      engNameControl: ['', [Validators.required]],
      chiNameControl: [''],
      addressControl: ['', [Validators.required]],
      idControl: ['', [Validators.required]],
      fileControl: ['', [Validators.required]],
    }),
    shareholderDirectorInfo: this.fb.array([
      this.createShareholderDirectorFormGroup(),
    ]),
  });

  lang: string;

  // subscription
  useTaiHKAdressSubscription: Subscription;
  useTaiHKSecSubscription: Subscription;
  shareholderDirectorInfoSubscription: Subscription;

  constructor(
    public translateService: TranslateService,
    private fb: FormBuilder,
    private afs: AngularFirestore,
    private storage: AngularFireStorage,
    public snackbar: MatSnackBar
  ) {
    this.lang = this.translateService.currentLang;
    this.translateService.onLangChange.subscribe(
      (event) => (this.lang = event.lang)
    );

    this.useTaiHKAdressSubscription = this.companyInfoUseTaiHKAdressControl.valueChanges.subscribe(
      (useTaiHKAddress) => {
        if (useTaiHKAddress == 'yes') {
          this.companyInfoAddressControl.setValue(this.taiHK.address);
          return;
        }
        if (useTaiHKAddress == 'no') {
          this.companyInfoAddressControl.reset();
          return;
        }
      }
    );

    this.useTaiHKSecSubscription = this.companySecUseTaiHKSecControl.valueChanges.subscribe(
      (useTaiHKSec) => {
        if (useTaiHKSec == 'yes') {
          this.companySecTypeControl.setValue('company');
          this.companySecEngNameControl.setValue(this.taiHK.engName);
          this.companySecChiNameControl.setValue(this.taiHK.chiName);
          this.companySecAddressControl.setValue(this.taiHK.address);
          this.companySecIdControl.setValue(this.taiHK.ci);
          this.companySecFileControl.disable();
          return;
        }

        if (useTaiHKSec == 'no') {
          this.companySecTypeControl.reset();
          this.companySecEngNameControl.reset();
          this.companySecChiNameControl.reset();
          this.companySecAddressControl.reset();
          this.companySecIdControl.reset();
          this.companySecFileControl.enable();
          return;
        }
      }
    );

    this.shareholderDirectorInfoSubscription = this.shareholderDirectorInfoControl.valueChanges
      .pipe(
        startWith({}),
        pairwise(),
        map(([oldState, newState]) => {
          // console.log('old state', oldState);
          // console.log('new state', newState);

          // if (oldState == {}) {
          if (oldState.length == undefined) {
            console.log('old state is null');
            return [0, newState];
          }

          if (oldState.length != newState.length) {
            const l = newState.length;
            return [l - 1, newState[l - 1]];
          }

          let changes = {};
          let idx = 0;
          for (let i = 0; i < newState.length; i++) {
            // console.log(oldState[i]);
            // console.log(newState[i]);
            if (JSON.stringify(oldState[i]) !== JSON.stringify(newState[i])) {
              idx = i;
              changes = newState[i];
              break;
            }
          }
          return [idx, changes];
        })
      )
      .subscribe(([index, shareholder]) => {
        // console.log(shareholder);
        const idx = index.toString();
        // const index = Object.keys(shareholder)[0];
        console.log('changed key:', idx);
        const sd = shareholder;
        // check role control
        const role = sd.roleControl;
        if (role == 'shareholder' || role == 'shareholderDirector') {
          // console.log('shareholder', index);
          const shareholderDirector = this.shareholderDirectorInfoArray.get(
            idx
          );
          shareholderDirector.get('sharesControl').enable({ emitEvent: false });
          shareholderDirector
            .get('fundSourceControl')
            .enable({ emitEvent: false });
          console.log('shares & fund source control enabled');
        }

        if (role == 'director') {
          // console.log('director', index);
          const shareholderDirector = this.shareholderDirectorInfoArray.get(
            idx
          );
          shareholderDirector
            .get('sharesControl')
            .disable({ emitEvent: false });
          shareholderDirector
            .get('fundSourceControl')
            .disable({ emitEvent: false });
          console.log('shares & fund source control disabled');
        }

        // check type control
        // const type = sd.typeControl;
        // if (type == 'Individual') {
        //   // console.log('individual', idx);
        // }

        // if (type == 'Corporate') {
        //   // console.log('company', idx);
        // }

        const ownerControls = [
          'ownerEngNameControl',
          'ownerChiNameControl',
          'ownerAddressControl',
          'ownerIdControl',
          'ownerIdIssueControl',
          'ownerFileControl',
        ];

        if (sd.ownerControl == 'yes') {
          this.ownerControlDisable(idx, ownerControls);
        }

        if (sd.ownerControl == 'no') {
          this.ownerControlEnable(idx, ownerControls);
        }
      });
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.unsubscribeAll();
  }

  unsubscribeAll() {
    this.useTaiHKAdressSubscription.unsubscribe();
    this.useTaiHKSecSubscription.unsubscribe();
    this.shareholderDirectorInfoSubscription.unsubscribe();
  }

  private createShareholderDirectorFormGroup(): FormGroup {
    return this.fb.group({
      roleControl: ['', [Validators.required]],
      typeControl: ['', [Validators.required]],
      sharesControl: [
        { disabled: true, value: 1 },
        [Validators.required, Validators.min(1)],
      ],
      engNameControl: ['', [Validators.required]],
      chiNameControl: [''],
      addressControl: ['', [Validators.required]],
      idControl: ['', [Validators.required]],
      idIssueControl: ['', [Validators.required]],
      fileControl: ['', [Validators.required]],
      fundSourceControl: [{ disabled: true, value: '' }, [Validators.required]],

      ownerControl: ['yes', [Validators.required]],
      ownerEngNameControl: [
        { disabled: true, value: '' },
        [Validators.required],
      ],
      ownerChiNameControl: [{ disabled: true, value: '' }],
      ownerAddressControl: [
        { disabled: true, value: '' },
        [Validators.required],
      ],
      ownerIdControl: [{ disabled: true, value: '' }, [Validators.required]],
      ownerIdIssueControl: [
        { disabled: true, value: '' },
        [Validators.required],
      ],
      ownerFileControl: [{ disabled: true, value: '' }, [Validators.required]],
    });
  }

  async onSubmit() {
    try {
      // save data to firestore
      await this.saveFormData();

      // save files to fire storage
      await this.uploadFiles();

      this.snackbar.open(
        `已遞交注冊有限公司資料，我們將會有同事聯絡您。`,
        'x',
        {
          duration: 10000,
        }
      );
      this.registerLimitedCompanyForm.reset();
      this.formGroupDirective.resetForm();
      // this.registerLimitedCompanyForm.
    } catch (err) {
      // console.log('on submit error', err);
      this.snackbar.open(
        err.message,
        // `遞交注冊有限公司資料出現問題，請檢查您的網路，或致電 3611 5772 / 3611 5773 聯絡我們。`,
        'x',
        { duration: 10000 }
      );
      return;
    }
  }

  addShareholderDirector() {
    this.shareholderDirectorInfoArray.push(
      this.createShareholderDirectorFormGroup()
    );
    window.scrollBy(0, -150);
  }

  removeShareholderDirector(idx: number) {
    this.shareholderDirectorInfoArray.removeAt(idx);
  }

  async saveFormData() {
    // this.unsubscribeAll();
    try {
      const applicationId = this.genApplicationId();
      const formData = this.registerLimitedCompanyForm.value;
      const sd = formData.shareholderDirectorInfo;

      // console.log(formData);
      // console.log(formData.companyInfo.addressControl);
      // console.log(formData.shareholderDirectorInfo);

      const directors = sd.filter(
        (x) =>
          x.roleControl == 'director' || x.roleControl == 'shareholderDirector'
      );
      console.log(`directors: ${JSON.stringify(directors)}`);

      // check 0) at least 1 director
      if (directors.length == 0) {
        throw new Error(`需要最少一位董事`);
      }

      // check 1) if only 1 director, he/she can't be the company secretary
      if (
        directors.length == 1 &&
        directors[0].idControl == formData.companySec.idControl
      ) {
        throw new Error(`單一董事的情況下，該董事不能同時擔任公司祕書`);
      }

      // check 2) combine shares = total shares
      const totalShares = formData.companyInfo.issueSharesControl;
      const combineShares = sd
        .map((x) => x.sharesControl)
        .reduce((acc, cur) => {
          if (cur) {
            return acc + cur;
          }
          return acc;
        }, 0);

      if (totalShares != combineShares) {
        throw new Error(`股東總持股數目必須等同發行股數`);
      }

      // check 3) must have at least one individual director
      let individualDirectorCount = 0;

      for (const d of directors) {
        if (d.typeControl == 'Individual') {
          individualDirectorCount++;
        }
      }

      console.log('individual director count', individualDirectorCount);

      if (individualDirectorCount < 1) {
        throw new Error(`必須有最少一名自然人出任董事`);
      }

      // construct data to be saved to firestore
      const newSd = sd.map((x) => ({
        role: x.roleControl,
        type: x.typeControl,
        shares: x.sharesControl,
        engName: x.engNameControl,
        chiName: x.chiNameControl || 'n/a',
        address: x.addressControl,
        id: x.idControl,
        idIssue: x.idIssueControl,
        fundSource: x.fundSourceControl,
        owner: x.ownerControl,
        ownerChiName: x.ownerChiNameControl || 'n/a',
        ownerEngName: x.ownerEngNameControl || 'n/a',
        ownerAddress: x.ownerAddressControl || 'n/a',
        ownerId: x.ownerIdControl || 'n/a',
        ownerIdIssue: x.ownerIdIssueControl || 'n/a',
        ownerFile: x.ownerFileControl || 'n/a',
      }));

      const data = {
        contactPersonName: formData.contactPerson.nameControl,
        contactPersonTitle: formData.contactPerson.titleControl,
        contactPersonEmail: formData.contactPerson.emailControl,
        contactPersonMobile: formData.contactPerson.mobileControl,

        referSource: formData.contactPerson.referSourceControl,

        companyInfoFirstEngName: formData.companyInfo.firstEngNameControl,
        companyInfoSecondEngName: formData.companyInfo.secondEngNameControl,
        companyInfoFirstChiName: formData.companyInfo.firstChiNameControl,
        companyInfoSecondChiName: formData.companyInfo.secondChiNameControl,
        companyInfoUseTaiHkAddress: formData.companyInfo.useTaiHKAddressControl,
        companyInfoAddress: formData.companyInfo.addressControl,
        companyInfoIssueShares: formData.companyInfo.issueSharesControl,
        companyInfoTotalFaceValue: formData.companyInfo.totalFaceValueControl,

        companySecUseTaiHkSec: formData.companySec.useTaiHKSecControl,
        companySecType: formData.companySec.typeControl,
        companySecEngName: formData.companySec.engNameControl,
        companySecChiName: formData.companySec.chiNameControl,
        companySecAddress: formData.companySec.addressControl,
        companySecId: formData.companySec.idControl,

        shareholders: newSd,
        applicationId: applicationId,
      };

      // console.log('saving to firestore', applicationId);
      // await this.afs.collection('register-company').add(data);
      // await this.afs.doc(`register-company/${applicationId}`).set(data);
      console.log(`data saved to firestore`);
    } catch (err) {
      // console.error(`error saving form data to firestore: ${err}`);
      throw new Error(err.message);
    }
  }

  onFileInputChange(evt) {
    console.log('file input change event target:', evt.target);

    // if (evt.target.files[0].size > this.maxFileSize) {
    //   this.snackbar.open(`文件不得超過5MB`, 'x', { duration: 5000 });
    //   evt.target.value = '';
    // }
  }

  private async uploadFiles() {
    const applicationId = this.genApplicationId();

    // upload com sec file
    const comSecFile = this.companySecFileControl.value;
    if (comSecFile) {
      await this.uploadFile(applicationId, comSecFile, 'company-sec-id');
    }

    // upload shareholder director file
    const shareholderDirectors = this.shareholderDirectorInfoArray.value;
    shareholderDirectors.forEach(async (sd, idx) => {
      const sdFile = sd.fileControl;
      if (sdFile) {
        await this.uploadFile(applicationId, sdFile, `sd-${idx}-id`);
      }

      const ownerFile = sd.ownerFileControl;
      if (ownerFile) {
        await this.uploadFile(applicationId, ownerFile, `sd-${idx}-owner-id`);
      }
    });
  }

  private uploadFile(applicationId: string, file: File, destFileName: string) {
    // const comSecFile = this.companySecFileControl.value;
    const fileExt = file['name'].split('.').pop();
    const filePath = `register-company/${applicationId}/${destFileName}.${fileExt}`;
    const ref = this.storage.ref(filePath); // change to unique file name
    const task = this.storage.upload(filePath, file);
    // console.log(`upload com sec id file to register-company/${applicationId}/company-sec-id.${comSecFileExt}`);

    let fileURL = '';

    task
      .snapshotChanges()
      .pipe(
        finalize(() => {
          ref.getDownloadURL().subscribe((url) => {
            fileURL = url;
            // console.log('url', fileURL);
          });
        })
      )
      .subscribe();
  }

  private genApplicationId(): string {
    const date = new Date().toISOString().split('T')[0]; // yyyy-mm-dd
    const companyName = this.companyInfoFirstEngNameControl.value || '';
    const applicationId = companyName.trim().split(' ').join('-') + '-' + date;
    return applicationId;
  }

  private ownerControlEnable(idx: string, ownerControls: string[]) {
    const shareholderDirector = this.shareholderDirectorInfoArray.get(idx);
    ownerControls.map((control) => {
      shareholderDirector.get(control).enable({ emitEvent: false });
    });
  }

  private ownerControlDisable(idx: string, ownerControls: string[]) {
    const shareholderDirector = this.shareholderDirectorInfoArray.get(idx);
    ownerControls.map((control) => {
      shareholderDirector.get(control).disable({ emitEvent: false });
    });
  }

  // getter method
  // returned control is of type AbstractControl,
  // so need to provide an explicit type to access the method syntax

  // aliases for group and array
  
  get contactPerson() {
    return this.registerLimitedCompanyForm.get('contactPerson') as FormGroup;
  }

  get companyInfo() {
    return this.registerLimitedCompanyForm.get('companyInfo') as FormGroup;
  }

  get companySec() {
    return this.registerLimitedCompanyForm.get('companySec') as FormGroup;
  }

  get shareholderDirectorInfoArray() {
    return this.registerLimitedCompanyForm.get(
      'shareholderDirectorInfo'
    ) as FormArray;
  }

  // aliases for contact person group

  get contactPersonNameControl() {
    return this.contactPerson.get('nameControl') as FormControl;
  }

  get contactPersonTitleControl() {
    return this.contactPerson.get('titleControl') as FormControl;
  }

  get contactPersonEmailControl() {
    return this.contactPerson.get('emailControl') as FormControl;
  }

  get contactPersonMobileControl() {
    return this.contactPerson.get('mobileControl') as FormControl;
  }

  get contactPersonReferSourceControl() {
    return this.contactPerson.get('referSourceControl') as FormControl;
  }

  // aliases for company info group

  get companyInfoFirstEngNameControl() {
    return this.companyInfo.get('firstEngNameControl') as FormControl;
  }
  get companyInfoSecondEngNameControl() {
    return this.companyInfo.get('secondEngNameControl') as FormControl;
  }
  get companyInfoFirstChiNameControl() {
    return this.companyInfo.get('firstChiNameControl') as FormControl;
  }
  get companyInfoSecondChiNameControl() {
    return this.companyInfo.get('secondChiNameControl') as FormControl;
  }

  get companyInfoUseTaiHKAdressControl() {
    return this.companyInfo.get('useTaiHKAddressControl') as FormControl;
  }

  get companyInfoAddressControl() {
    return this.companyInfo.get('addressControl') as FormControl;
  }

  get companyInfoIssueSharesControl() {
    return this.companyInfo.get('issueSharesControl') as FormControl;
  }

  get companyInfoTotalFaceValueControl() {
    return this.companyInfo.get('totalFaceValueControl') as FormControl;
  }

  // aliases for company sec group

  get companySecUseTaiHKSecControl() {
    return this.companySec.get('useTaiHKSecControl') as FormControl;
  }

  get companySecTypeControl() {
    return this.companySec.get('typeControl') as FormControl;
  }

  get companySecEngNameControl() {
    return this.companySec.get('engNameControl') as FormControl;
  }

  get companySecChiNameControl() {
    return this.companySec.get('chiNameControl') as FormControl;
  }

  get companySecAddressControl() {
    return this.companySec.get('addressControl') as FormControl;
  }

  get companySecIdControl() {
    return this.companySec.get('idControl') as FormControl;
  }

  get companySecFileControl() {
    return this.companySec.get('fileControl') as FormControl;
  }

  get shareholderDirectorInfoControl() {
    return this.registerLimitedCompanyForm.get(
      'shareholderDirectorInfo'
    ) as FormControl;
  }
}
