<!-- zh -->
<div *ngIf="lang === 'zh'" fxLayout="column" fxLayoutAlign="center stretch">
  <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
    <img
      src="assets/images/corporate-change.jpg"
      alt="banner"
      class="banner-image"
    />
    <div class="banner-text" style="transform: translate(-60%, -30%)">
      <!-- <p
        style="
          color: black;
          font-weight: bold;
          font-size: 5vw;
          margin: 0;
          padding: 0;
        "
      >
        公司秘書<br />公司狀況變更服務
      </p>
      <p style="color: black; font-size: 3vw">這是我們的職責</p> -->
    </div>
  </div>

  <section
    fxLayout="row"
    fxLayoutAlign="center"
    style="background-color: white"
  >
    <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
      <p></p>
      <h1>公司秘書服務</h1>
      <p>公司秘書 / 秘書公司</p>

      <p >
        在香港成立的有限公司必須委任一名公司秘書，公司秘書必須是一位通常居於香港的人士、香港持牌會計專業人士、香港持牌法律專業人士或持牌秘書公司。
        如公司為單一董事情況下，該董事不能同時擔任公司秘書，需委任第三者出任公司秘書，如没有適當人選出任公司秘書，可考慮委任持牌秘書公司出任以確保公司遵守公香港公司法。
      </p>
      <p>
        由於大部份創業者，以至中小企老闆都沒有對公司法有全面的認識，對公司合規程序不太了解，所以公司秘書服務是大受歡迎，讓你省卻不少煩惱和不必要罰款。
      </p>
      <p>
        根據現時法例，任何人在香港經營或擬在香港經營信託或公司服務業務，均須申請信託或公司服務提供者牌照。
      </p>
      <p>
        大香港創辦人鄭婕賢小姐（”鄭小姐”）是香港執業會計師，彼為信託及公司服務提供者的持牌人
        (牌照編號：TC001452)，在公司秘書服務範疇有超過15年經驗，曾連續三年於香港主板上市公司新城市（中國）建設有限公司（股份代號0456）出任名義公司秘書及已為超過20+香港主板上市公司完成公司秘書的法定合規職務。
      </p>
      <p style="text-align: center; font-size: 1.4rem; line-height: 2rem">
        <strong>數據</strong>
        <br />
        <img
          src="assets/images/company-secretary-service/listed_co.png"
          alt="listed company"
          style="height: 2rem; margin: 1rem; vertical-align: middle"
        />上市公司：20＋<br />
        <img
          src="assets/images/company-secretary-service/sme_co.png"
          alt="listed company"
          style="height: 2rem; margin: 1rem; vertical-align: middle"
        />中小企客戶：400＋<br />
        <img
          src="assets/images/company-secretary-service/on_time.png"
          alt="listed company"
          style="height: 2rem; margin: 1rem; vertical-align: middle"
        />周年申報表申報準時度：100%
      </p>
      <p>我們為客戶提供似下公司秘書服務</p>
      <ul>
        <li>公司註冊處周年報表</li>
        <li>撰寫公司股東週年股東大會會議記錄</li>
        <li>出任公司秘書法定職務</li>
        <li>建立及或維護「重要控制人登記冊」</li>
        <li>商業登記續期服務</li>
        <li>商業登記證申請</li>
        <li>申請分行商業登記</li>
        <li>有限公司地址變更/商業登記改地址</li>
        <li>變更業務性質</li>
        <li>更改有限公司名稱</li>
        <li>更改無限公司名稱 / 業務名稱</li>
        <li>增發股本</li>
        <li>董事及或備任董事／法定秘書之轉換 </li>
        <li>董事及或備任董事／法定秘書之資料變更 </li>
        <li>撰寫公司狀況變更會議記錄</li>
        <li>撒銷公司註冊（無限公司）</li>
        <li>撒銷公司註冊 （有限公司）</li>
        <li>公司清盤</li>
        <li>行政方式恢復註冊</li>
        <li>更改公司章程</li>
      </ul>
    </div>
  </section>

  <section
    fxLayout="row wrap"
    fxLayoutAlign="center stretch"
    style="background-color: whitesmoke; padding-top: 5rem"
  >
    <mat-card fxFlex="30" fxFlex.xs="80">
      <mat-card-header>
        <mat-card-title>
          <h3>公司註冊處周年申報表</h3>
        </mat-card-title>
      </mat-card-header>
      <img
        mat-card-image
        src="assets/images/company-secretary-service/anniversary.jpg"
        alt="anniversary"
      />
      <mat-card-content>
        <p>
          有限公司須於公司成立周年日42日內向公司註冊處遞交周年申報表。該報表載列截至公司周年成立日止，公司資料如股權狀況、董事股東等詳情資料。
          <br><br>
          $550
          
        </p>
      </mat-card-content>
      <mat-card-actions fxLayoutAlign="center">
        <button
          mat-stroked-button
          color="primary"
          (click)="openDialog('周年申報表')"
        >
          了解更多
        </button>
      </mat-card-actions>
    </mat-card>

    <mat-card fxFlex="30" fxFlex.xs="80">
      <mat-card-header>
        <mat-card-title>
          <h3>商業登記證申請</h3>
        </mat-card-title>
      </mat-card-header>
      <img
        mat-card-image
        src="assets/images/company-secretary-service/apply_br.jpg"
        alt="apply br"
      />
      <mat-card-content>
        <p >
          <!-- 如何開無限公司？ -->
          開無限公司後不知來年會計報稅公司東主要做什麼？不懂如何減低報稅成本？如何自行處理公司簡單的會計帳目不用交給專業會計人員處理？
          想找合適人選代收公司信件同郵包？
          <br />
          <!-- <br /><strong>商業登記證申請 $980</strong> -->
        </p>
      </mat-card-content>
      <mat-card-actions fxLayoutAlign="center">
        <button
          mat-stroked-button
          color="primary"
          (click)="openDialog('商業登記證')"
        >
          了解更多
        </button>
        <!-- <button
          mat-stroked-button
          color="primary"
          routerLink="/register-hong-kong-unlimited-company"
        >
          了解更多
        </button> -->
      </mat-card-actions>
    </mat-card>

    <mat-card fxFlex="30" fxFlex.xs="80">
      <mat-card-header>
        <mat-card-title>
          <h3>公司轉股</h3>
        </mat-card-title>
      </mat-card-header>
      <img
        mat-card-image
        src="assets/images/company-secretary-service/transfer_shares.jpg"
        alt="transfer shares"
      />
      <mat-card-content>
        <p>
          當公司股權出現變動或轉讓，公司轉讓文件須經稅務局 <br />
          <br />蓋上印花稅並繳付所需印花稅才生效。
        </p>
      </mat-card-content>
      <mat-card-actions fxLayoutAlign="center">
        <button
          mat-stroked-button
          color="primary"
          routerLink="/transfer-company-shares"
        >
          了解更多
        </button>
      </mat-card-actions>
    </mat-card>

    <mat-card fxFlex="30" fxFlex.xs="80">
      <mat-card-header>
        <mat-card-title>
          <h3>商業登記轉讓</h3>
        </mat-card-title>
      </mat-card-header>
      <img
        mat-card-image
        src="assets/images/company-secretary-service/transfer_br.jpg"
        alt="transfer br"
      />
      <mat-card-content>
        <p>
          無限公司轉讓、無限公司合夥人出現新增或退出情況，公司須於變更的1個月內到商業登記署遞交書面申請，通知更改商業登記證內資料。
        </p>
      </mat-card-content>
      <mat-card-actions fxLayoutAlign="center">
        <button mat-stroked-button color="primary">
          <a href="https://wa.me/85295126339">了解更多</a>
        </button>
      </mat-card-actions>
    </mat-card>

    <mat-card fxFlex="30" fxFlex.xs="80">
      <mat-card-header>
        <mat-card-title>
          <h3>商業登記改地址</h3>
        </mat-card-title>
      </mat-card-header>
      <img
        mat-card-image
        src="assets/images/company-secretary-service/br_update_address.jpg"
        alt="br update address"
      />
      <mat-card-content>
        <p>
          無限公司商業登記改地址，公司須於變更的1個月內到商業登記署遞交書面申請，通知更改商業登記證內資料。
        </p>
      </mat-card-content>
      <mat-card-actions fxLayoutAlign="center">
        <button mat-stroked-button color="primary">
          <a href="https://wa.me/85295126339">了解更多</a>
        </button>
      </mat-card-actions>
    </mat-card>

    <mat-card fxFlex="30" fxFlex.xs="80">
      <mat-card-header>
        <mat-card-title>
          <h3>虛擬辦公室</h3>
        </mat-card-title>
      </mat-card-header>
      <img
        mat-card-image
        src="assets/images/company-secretary-service/virtual_office.jpg"
        alt="virtual office"
      />
      <mat-card-content>
        <p>
          服務包括：公司註冊登記地址，無限代收郵件及包裹及每雙月份轉寄至指定地址。我們辦公室位於中環，交通便利，港鐵步行只需5分鐘。
        </p>
      </mat-card-content>
      <mat-card-actions fxLayoutAlign="center">
        <button mat-stroked-button color="primary" routerLink="/virtual-office">
          了解更多
        </button>
      </mat-card-actions>
    </mat-card>

    <mat-card fxFlex="30" fxFlex.xs="80">
      <mat-card-header>
        <mat-card-title>
          <h3>公司開戶</h3>
        </mat-card-title>
      </mat-card-header>
      <img
        mat-card-image
        src="assets/images/company-secretary-service/open_account.jpg"
        alt="open account"
      />
      <mat-card-content>
        <p>
          覺得開公司戶口很困難嗎？近年政府要求嚴格執行反洗黑錢條例
          (AML)，銀行對公司開戶較過往嚴緊很多。
        </p>
      </mat-card-content>
      <mat-card-actions fxLayoutAlign="center">
        <button
          mat-stroked-button
          color="primary"
          routerLink="/open-hong-kong-bank"
        >
          了解更多
        </button>
      </mat-card-actions>
    </mat-card>

    <mat-card fxFlex="30" fxFlex.xs="80">
      <mat-card-header>
        <mat-card-title>
          <h3>商標註冊</h3>
        </mat-card-title>
      </mat-card-header>
      <img
        mat-card-image
        src="assets/images/company-secretary-service/trademark.png"
        alt="trademark"
      />
      <mat-card-content>
        <p>
          註冊商標可令你就有關貨品或服務擁有該商標的專用使用權，令你對於該商標的使用獲得最大的保障，是經營品牌不可或缺的部份。
        </p>
      </mat-card-content>
      <mat-card-actions fxLayoutAlign="center">
        <button mat-stroked-button color="primary" routerLink="/trademark">
          了解更多
        </button>
      </mat-card-actions>
    </mat-card>

    <mat-card fxFlex="30" fxFlex.xs="80">
      <mat-card-header>
        <mat-card-title>
          <h3>公司清盤及撒銷公司註冊</h3>
        </mat-card-title>
      </mat-card-header>
      <img
        mat-card-image
        src="assets/images/company-secretary-service/liquidation.jpg"
        alt="liquidation"
      />
      <mat-card-content>
        <p>
          無論是有限公司或無限公司，當公司結業時，都需要按既定程序辦理註銷/結業手續。註銷前需完稅，有限公司註銷一般需時半年。
        </p>
      </mat-card-content>
      <mat-card-actions fxLayoutAlign="center">
        <button mat-stroked-button color="primary" routerLink="/liquidation">
          了解更多
        </button>
      </mat-card-actions>
    </mat-card>

    <mat-card fxFlex="30" fxFlex.xs="80">
      <mat-card-header>
        <mat-card-title>
          <h3>無限公司轉有限公司</h3>
        </mat-card-title>
      </mat-card-header>
      <img
        mat-card-image
        src="assets/images/company-secretary-service/unlimited-to-limited.jpg"
        alt="unlimited to limited"
      />
      <mat-card-content>
        <p>
          無限公司是不能直接轉換成有限公司的，如果想轉換，可以參考我們建議你的做法。
        </p>
      </mat-card-content>
      <mat-card-actions fxLayoutAlign="center">
        <button
          mat-stroked-button
          color="primary"
          routerLink="/unlimited-company-to-limited-company"
        >
          了解更多
        </button>
      </mat-card-actions>
    </mat-card>
  </section>
</div>

<!-- end of zh -->

<!-- en -->
<div *ngIf="lang === 'en'" fxLayout="column" fxLayoutAlign="center center">
  <div style="height: 100vh" fxLayout="column" fxLayoutAlign="center center">
    <p>Only Chinese Version Available</p>
  </div>
</div>
<!-- end of en -->
