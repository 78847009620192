<!-- zh -->
<div *ngIf="lang === 'zh'" fxLayout="column" fxLayoutAlign="center stretch">
  <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
    <img src="assets/images/csr.jpg" alt="banner" class="banner-image" />
  </div>
  <section
    fxLayout="row"
    fxLayoutAlign="center"
    style="background-color: white"
  >
    <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
      <p></p>
      <h1>企業社會責任</h1>

      <h1>免責聲明</h1>
      <p class="sub-heading">網站內容</p>
      <p>
        大香港企業服務 Tai HK Corporate Services 在本網頁所載的資料只供參考之用。所有本網頁內容，將會隨時更改，而不作另行通知。雖然本公司已盡力確保本網頁的資料準確，但本公司不會明示或隱含保證該等資料均準確無誤。本公司不會對任何錯誤或遺漏承擔責任。
      </p>
      <p>
        本網頁英文譯本只供參考，如英文譯本與中文版本有任何抵觸或不相符之處，一切以中文版為準。
      </p>
      <p class="sub-heading">服務收費</p>
      <p>
        本公司會努力確保網站內的服務內容標準、附件、收費等得以最快最精確的更新，不過仍然可能出現延誤而未能即時於網站內更正，本公司擁有對服務標準及其收費隨時更改的絕對權利，而且享有一切責任及義務的豁免權。
        網站內所有服務收費只供資料參考之用，不構成本公司與任何個人、團體、公司之間的正式法律合約。想瞭解更詳盡及最準確的服務收費及資訊，請您與我們聯絡。
      </p>

      <p class="sub-heading">資料轉載與連結</p>
      <p>
        本公司不會對使用或連結本網頁而引致任何損害(包括但不限於電腦病毒、系統固障、資料損失)承擔任何賠償。
        本網頁可能會連接至其他機構所提供的網頁，但這些網頁並不是由本公司所管理。本公司不會對這些網頁內容作出任何保證或承擔任何責任。使用者如瀏覽這些網頁，將要自己承擔後果。
      </p>

      <h1>私隱聲明</h1>
      <p>
        當您在本公司及本網站進行客戶登記、參加網上或公共論壇等各種活動時，在您的同意及確認下，本公司及本網站將通過表格等形式要求您提供一些個人資料。這些個人資料包括：
        如個人姓名、企業名稱、性別、年齡、出生日期、電話、通信地址、住址、電子郵件地址、等情況。
      </p>
      <p>
        請了解，在未經您同意及確認之前，本公司及本網站不會將您所提供的資料利用於其它目的。但是當政府機關依照法律程序要求本網站披露個人資料時，本公司及本網站將根據執法單位之要求或為公共安全之目的提供個人資料。在此情況下之任何披露，本公司及本網站均不承擔任何責任。
      </p>
    </div>
  </section>
</div>
<!-- end of zh -->

<!-- en -->
<div *ngIf="lang === 'en'" fxLayout="column" fxLayoutAlign="center stretch">
  <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
    <img src="assets/images/csr.jpg" alt="banner" class="banner-image" />
  </div>
  <section
    fxLayout="row"
    fxLayoutAlign="center"
    style="background-color: white"
  >
    <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
      <p></p>
      <h1>Disclaimer</h1>
      <p class="sub-heading">Website Content</p>
      <p>
        All information appearing on this website are for reference only and is
        subject to change at any time without prior notice and at the discretion
        of Tai Hong Kong Corporate Services Platform Limited (hereinafter 'Tai
        Hong Kong', 'the company', 'us', 'we' or 'our'). Whilst Tai Hong Kong
        endeavors to ensure the accuracy of the information on this website, no
        express or implied warranty is given by Tai Hong Kong to the accuracy of
        the information. Tai Hong Kong shall bear no responsibility for any
        incorrectness or leakage.
      </p>
      <p>
        The English version of this webpage is for reference only. If there is
        any inconsistency or ambiguity between these English and Chinese
        versions, the Chinese version shall prevail.
      </p>
      <p class="sub-heading">Services Charges</p>
      <p>
        Tai Hong Kong will work hard to ensure that the service content
        standards, attachments, charges, etc. on the website can be updated in
        the fastest and most accurate manner, but there may still be delays and
        cannot be corrected in the website immediately. Tai Hong Kong has the
        absolute right to change the service standards and charges at any time,
        and enjoy immunity from all responsibilities and obligations. All
        service charges on the website are for reference only, and do not
        constitute a formal legal contract between us and any individual, group,
        or company. For more detailed and accurate service charges and
        information, please contact us.
      </p>
      <p class="sub-heading">Data reprint and link</p>
      <p>
        Tai Hong Kong will not be liable for any damages (including but not
        limited to viruses, system failures, data loss) caused by the use or
        linking of this website. This webpage may be linked to webpages provided
        by other organizations, but these webpages are not managed by us. We
        will not make any guarantee or assume any responsibility for the content
        of these pages. Users who browse these pages will have to bear the
        consequences.
      </p>

      <h1>Privacy statement</h1>
      <p>
        When you register on this website, participate in various activities
        such as online or public forums, with your consent and confirmation,
        this website will ask you to provide some personal information through
        electronic forms. These personal information include: such as personal
        name, business name, gender, age, date of birth, telephone, mailing
        address, residential address, email address, etc.
      </p>
      <p>
        Please understand that Tai Hong Kong will not use the information you
        provide for other purposes without your consent and confirmation.
        However, when government agencies require us to disclose personal
        information in accordance with legal procedures, we will provide
        personal information in accordance with the requirements of law
        enforcement agencies or for public safety purposes. Under this
        circumstance, Tai Hong Kong shall not bear any responsibility for any
        disclosure
      </p>
    </div>
  </section>
</div>

<!-- end of en -->
