import { Component, Input, OnInit } from '@angular/core';

import { PriceChartData } from '../../interfaces/price-chart-data';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-virtual-office-price-chart',
  templateUrl: './virtual-office-price-chart.component.html',
  styleUrls: ['./virtual-office-price-chart.component.scss'],
})
export class VirtualOfficePriceChartComponent implements OnInit {

  @Input() priceChartData: PriceChartData;
  lang: string;

  constructor(public translateService: TranslateService) {
    this.lang = this.translateService.currentLang;
    this.translateService.onLangChange.subscribe(
      (event) => (this.lang = event.lang)
    );
  }

  ngOnInit(): void {}
}
