import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-tvp',
  templateUrl: './tvp.component.html',
  styleUrls: ['./tvp.component.scss']
})
export class TvpComponent implements OnInit {

  lang: string;
  constructor(public translateService: TranslateService) {
    this.lang = this.translateService.currentLang;
    this.translateService.onLangChange.subscribe(
      (event) => (this.lang = event.lang)
    );
  }
  ngOnInit(): void {}

}
