import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-open-singapore-bank',
  templateUrl: './open-singapore-bank.component.html',
  styleUrls: ['./open-singapore-bank.component.scss'],
})
export class OpenSingaporeBankComponent implements OnInit {
  lang: string;
  constructor(
    public translateService: TranslateService,
    private meta: Meta,
    private title: Title
  ) {
    this.lang = this.translateService.currentLang;
    this.translateService.onLangChange.subscribe(
      (event) => (this.lang = event.lang)
    );
    this.meta.addTags([
      {
        name: 'description',
        content: `無效退款。新加坡遙距開銀行戶口／香港公司／離岸公司。大部分提供遙距開戶服務的銀行，都不接受客戶的直接申請。大香港是部分銀行的指定開戶中間人。為您省時省力的方法達致開戶成功。最低結餘：SGD10,000`,
      },
      {
        name: 'keywords',
        content: '新加坡, 成立公司, 銀行開戶, 遙距開戶, 遙距開銀行戶口',
      },
    ]);
    this.title.setTitle('新加坡銀行開戶');
  }

  ngOnInit(): void {}
}
