import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from '../data.service';

@Component({
  selector: 'app-trademark',
  templateUrl: './trademark.component.html',
  styleUrls: ['./trademark.component.scss'],
})
export class TrademarkComponent implements OnInit {
  lang: string;
  services: string[];
  serviceData;
  constructor(
    public translateService: TranslateService,
    public data: DataService
  ) {
    this.lang = this.translateService.currentLang;
    this.translateService.onLangChange.subscribe(
      (event) => (this.lang = event.lang)
    );
    this.serviceData = data.services;
  }

  ngOnInit(): void {
    this.services = [
      // 'trademark_hk',
      'trademark_china',
      'trademark_macau',
      'trademark_taiwan',
      'trademark_us',
      'trademark_canada',
      'trademark_uk',
      'trademark_germany',
      'trademark_eu',
      'trademark_jp',
      'trademark_korea',
      'trademark_singapore',
      'trademark_australia',
    ];
  }
}
