<!-- zh -->
<div *ngIf="lang === 'zh'" fxLayout="column" fxLayoutAlign="center stretch">
  <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
    <img src="assets/images/account-tax.jpg" alt="" class="banner-image" />
  </div>
  <section
    fxLayout="row"
    fxLayoutAlign="center"
    style="background-color: white"
  >
    <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
      <p></p>
      <h1>會計報稅服務</h1>
      <app-whatsapp [lang]="'zh'"></app-whatsapp>
      <p>
        業務負責人每年需向稅務局提交報稅表,
        有限公司或合夥企業報稅表一般在每年的4月份寄出,
        而個人報稅表一般在每年的5月份寄出, 提交報稅表期限為發出日起計1個月。
      </p>
      <p>
        另外根據香港《公司條例》第622章第122條，香港有限公司需要委任執業會計師<strong>對貴司帳目</strong>進行年度審計及提交由執業會計師簽署的審計報告。香港稅務局每年會向納稅人發出報稅表並要求在指定時間填妥連同執業會計師簽署的審計報告交回稅務局。而根據香港《稅務條例》第51C條，公司需保存其會計紀錄不少於7年。
      </p>
      <!-- <h3>審計報稅計價機</h3>
      <p>
        <strong>
          我們憑著多年客戶回饋的數據及執業會計師的收費基準，建立了審計報稅計價機，<a
            routerLink="/calculator"
            >詳情請瀏覽此</a
          >。
          <img class="logo" src="assets/images/svg/calculator.svg" />
        </strong>
      </p> -->
      <!-- <div>Icons made by <a href="https://www.flaticon.com/authors/eucalyp" title="Eucalyp">Eucalyp</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div> -->

      <h3>專業會計服務</h3>
      <p>
        我們的專業會計服務，均由資深的會計師主理。我們採用國際認可的會計<strong>準則</strong>進行理賬,
        讓客戶體驗高質素的會計服務和確保客戶得到以下好處:
      </p>
      <ul>
        <li>協助客戶有效降低會計成本</li>

        <li>協助客戶有效避免不合規帳目而付出高昂代價</li>
        <li>會計記錄可以長期保存</li>
        <li>資料得到妥善處理和絕對保密</li>
      </ul>

      <h3>合規稅務服務</h3>
      <p>我們的服務包括：</p>
      <ul>
        <li>稅務諮詢及安排 (在合法途徑下把稅務開支減至最低)</li>
        <li>擔任稅務代表 (個人或公司)</li>
        <li>填寫稅表, 稅務計算及申報</li>
        <ul>
          <li>利得稅</li>
          <li>薪俸稅</li>
          <li>物業稅</li>
          <li>僱主及僱員薪酬報稅表</li>
        </ul>
        <li>稅務評估</li>
        <li>申報延期提交報稅表</li>
        <li>申請暫緩預繳稅</li>
        <li>提交反對評稅申請</li>
        <li>協助處理稅務局的查問</li>
        <li>協助申請海外收入稅務豁免</li>
        <li>稅務實地審核及調查</li>
      </ul>
    </div>
  </section>

  <section
    fxLayout="row"
    fxLayoutAlign="center"
    style="background-color: whitesmoke"
  >
    <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
      <p></p>
      <h1>政府資助基金審計服務</h1>
      <p>
        我們知道企業生存及發展是必須要有充足的資金維持，特別是新創公司及中小企業，對外融資困難。
        因此我們誠意為各企業家提供一系列政府資助計劃申請顧問服務及審計服務。
      </p>
      <p>我們的服務包括：</p>
      <ul>
        <li>創新科技署 - 科技券 ("TVP")</li>
        <li>創新科技署 - 企業資助計劃 ("ESS")</li>
        <li>創新科技基金項目 ("ITF")</li>
        <li>發展品牌升級轉型及拓展內銷市場的專項基金 ("BUD")</li>
        <li>回收基金 - 行業支援計劃</li>
        <li>回收基金 - 企業資助計劃</li>
        <li>環境保護署 (受管制電器) 規例 - 登記供應商提交審計報告 ("REE")</li>
      </ul>
    </div>
  </section>
</div>
<!-- end of zh -->

<!-- en -->
<div *ngIf="lang === 'en'" fxLayout="column" fxLayoutAlign="center stretch">
  <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
    <img src="assets/images/account-tax.jpg" alt="" class="banner-image" />
  </div>
  <section
    fxLayout="row"
    fxLayoutAlign="center"
    style="background-color: white"
  >
    <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
      <p></p>
      <h1>Accounting & Hong Kong Tax Advisory</h1>
      <app-whatsapp [lang]="'en'"></app-whatsapp>
      <p>
        The tax return of the limited company or partnership is generally posted
        in April of each year and the salaries tax return is usually posted in
        May of each year from Inland Revenue Department. The deadline for
        submission of tax returns is 1 month.
      </p>
      <p>
        On the other hand, under the section 122 of the Company ordinance
        (Cap.622) of the Laws of Hong Kong, the annual financial statements of
        Hong Kong limited company should be audited by Hong Kong Certified
        Public Accountants (Practising). Hong Kong Inland Revenue Department
        also requires Hong Kong limited companies to submit the profits tax
        return with the audited financial statements for the purpose of tax
        assessment within a specific period.
      </p>
      <p>
        Apart from that, in accordance with section 51C of the Hong Kong Inland
        Revenue Ordinance, every person carrying on a trade, profession or
        business in Hong Kong shall keep sufficient records of his income and
        expenditure to enable the assessable profits to be readily ascertained
        and shall retain such records for a period of not less than 7 years.
      </p>

      <!-- <h3>Audit & Tax Reporting Fee Calculator</h3>
      <p>
        
          The Audit & Tax Reporting Fee Calculator was based on extensive data
          from our client regard to the audit fee they paid and drawing from
          data provided by Hong Kong Certified Public Accountants (practicing)
          in Hong Kong.
          <a routerLink="/calculator">Click here for the calculator</a>
          <img class="logo" src="assets/images/svg/calculator.svg" />
        
      </p> -->
      <!-- <div>Icons made by <a href="https://www.flaticon.com/authors/eucalyp" title="Eucalyp">Eucalyp</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div> -->

      <h3>Professional accounting services</h3>
      <p>
        Our accounting services are handled by senior accountants. We based on
        international accounting standard to manage accounts, so that clients
        can enjoy high quality accounting services experience and receive the
        following benefits:
      </p>
      <ul>
        <li>Assist clients in reducing accounting costs effectively</li>
        <li>Assist clients in avoiding costs due to non-complaint accounts.</li>
        <li>Accounting records can be stored for a long</li>
        <li>Information is properly handled and kept confidential</li>
      </ul>

      <h3>Hong Kong Tax Advisory</h3>
      <p>Our services including:</p>
      <ul>
        <li>
          Providing tax advisory and tax planning (To minimize tax liabilities
          in a legitimate way)
        </li>
        <li>Acting as Tax Representative (Individual or Companies)</li>
        <li>Filling the tax return, tax computation and declare</li>
        <ul>
          <li>Profit tax</li>
          <li>Salaries tax</li>
          <li>Property tax</li>
          <li>Employer's Return of Remuneration and Pensions</li>
        </ul>
        <li>Applying for the extension of submission of tax return</li>
        <li>Applying for holdover of provisional tax</li>
        <li>Assisting for objection of tax assessment</li>
        <li>Assisting for handling the tax queries from IRD</li>
        <li>Assisting for applying the offshore income claims exemption</li>
        <li>Conducting tax field audit and investigation</li>
      </ul>
    </div>
  </section>

  <section
    fxLayout="row"
    fxLayoutAlign="center"
    style="background-color: whitesmoke"
  >
    <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
      <p></p>
      <h1>Accounting support for Government Fund</h1>
      <!-- <p>
        <ul>
          <li>Distance Business Programme (“D-Biz”)      <app-whatsapp [lang]="'en'"></app-whatsapp>
          </li>
          <li>Technology Voucher Programme ("TVP")  <app-whatsapp [lang]="'en'"></app-whatsapp></li>
          <li></li>
        </ul>
      </p> -->
      <p>
        <strong>Distance Business Programme (“D-Biz”)</strong>
      </p>
      <p>Auditors’ Report and Statement of Expenditure</p>
      <app-whatsapp [lang]="'en'"></app-whatsapp>

      <p>
        <strong>Technology Voucher Programme ("TVP")</strong>
      </p>
      <p>Auditors’ Report and Statement of Expenditure</p>
      <app-whatsapp [lang]="'en'"></app-whatsapp>

      <p>
        <strong
          >Retail Technology Adoption Assistance Scheme ( "ReTAAS"）</strong
        >
      </p>
      <p>Auditors’ Report and Statement of Expenditure</p>
      <app-whatsapp [lang]="'en'"></app-whatsapp>

      <p>
        <strong>BUD Fund</strong>
      </p>
      <p>Auditors’ Report and Statement of Expenditure</p>
      <app-whatsapp [lang]="'en'"></app-whatsapp>
    </div>
  </section>
</div>
<!-- end of en -->
