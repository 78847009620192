import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-shareholder-director-info-form',
  templateUrl: './shareholder-director-info-form.component.html',
  styleUrls: ['./shareholder-director-info-form.component.scss']
})
export class ShareholderDirectorInfoFormComponent implements OnInit {
  
  @Input() shareholderDirectorIndex: number;
  @Input() applicationId: string;

  shareholderDirectorInfoForm = new FormGroup({
    role: new FormControl('', [Validators.required]),
    type: new FormControl('', [Validators.required]),
    shareNumber: new FormControl('', [Validators.required]),
    nameEng: new FormControl('', [Validators.required]),
    nameChi: new FormControl('', [Validators.required]),
    address: new FormControl('', [Validators.required]),
    idDocNumber: new FormControl('', [Validators.required]),
    issueRegion: new FormControl('', [Validators.required])
  });

  fileControl = new FormControl('');

  idDocDownloadURL: Observable<string>;
  lang: string;
  constructor(private storage: AngularFireStorage, public translateService: TranslateService) { 
    this.lang = this.translateService.currentLang;
    this.translateService.onLangChange.subscribe(
      (event) => (this.lang = event.lang)
    );
  }

  ngOnInit(): void {
  }


  upload(event) {
    console.log('upload', event);
  }

  uploadFile(event) {
    const file = event.target.files[0];
    const filePath = `register-limited-company/${this.applicationId}/shareholder-diretor/${this.shareholderDirectorIndex.toString()}-image`;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);

    task.snapshotChanges().pipe(
      finalize(() => this.idDocDownloadURL = fileRef.getDownloadURL() )
    ).subscribe();
  }
}
