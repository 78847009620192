<!-- zh -->
<div *ngIf="lang === 'zh'" fxLayout="column" fxLayoutAlign="center stretch">
  <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
    <img
      src="assets/images/register-hong-kong-limited-company.jpg"
      alt="register hong kong limited company"
      class="banner-image"
    />
    <div class="banner-text">
      <p>香港有限公司註冊</p>
    </div>
  </div>

  <section
    fxLayout="row"
    fxLayoutAlign="center"
    style="background-color: white"
  >
    <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
      <p></p>

      <h2>【成立公司懶人包】註冊公司/開公司有什麼程序、收費和注意事項</h2>
      <p>
        無論你是剛投身社會的年輕人，懷著勇氣跳出舒適區的創業者
        ，還是已有多年經驗的企業家，嘉許你們打造一切生意可能性成立公司當老闆。
        從成立香港公司到開公司銀行戶口，過程手續繁複，大香港能幫各位衝破種種困難，輕鬆助你們完成成立公司程序及銀行開戶程序。
      </p>
      <p>成立公司費用及成本總表</p>
      <table>
        <thead>
          <tr>
            <th>收費項目</th>
            <th>有限公司註冊</th>
            <th>無限公司註冊</th>
          </tr>
        </thead>
        <tr>
          <th>商業登記證登記費（一年）</th>
          <th>＄2,000</th>
          <th>＄2,000</th>
        </tr>
        <tr>
          <th>商業登記證徵費（一年）</th>
          <th>＄250</th>
          <th>＄250</th>
        </tr>
        <tr>
          <th>公司註冊處收費</th>
          <th>＄1,720</th>
          <th>不適用</th>
        </tr>
        <tr>
          <th>其他費用</th>
          <th>圓印、簽名印、記錄名冊、股票、組織章程大綱及銀行開戶文件</th>
          <th>圓印、簽名印及銀行開戶文件</th>
        </tr>
        <tr>
          <th>公司註冊查詢電話</th>
          <th>3611 5771，3611 5772</th>
          <th>3611 5771，3611 5772</th>
        </tr>
      </table>

      <h3>成立公司程序</h3>
      <p>開有限公司前考慮和注意事項及成立公司流程如下：-</p>
      <ol>
        <li>
          <p>公司名稱</p>
          <ul>
            <li>公司名稱必須是獨一及不能令公眾被誤導</li>
            <li>公司名稱不能含有法例所列明禁止的字或詞</li>
            <li>
              公司名稱只能選用全中文或全英文，亦可以同時註冊一個英文及中文名稱
            </li>
            <li>公司名稱宜與其行業性質有關聯，易讀易記，高辨識度</li>
          </ul>
        </li>
        <li>
          <p>註冊辦事處</p>
          <ul>
            <li>香港成立的有限公司註冊地址必須要在香港</li>
            <li>
              註冊辦事地址, 是法例指定作為接收通知及程序的法定地址,
              可不同於營業地址
            </li>
            <li>
              法例規定公司須於註冊辦事處內放置能展示其公司名稱的水牌或顯示器
            </li>
            <li>
              如没有合適的地址作公司註冊辦事處，可考慮使用持牌秘書公司提供的地址作註冊辦事處及虛擬辦公室服務
            </li>
          </ul>
        </li>
        <li>
          <p>股東及董事</p>
          <ul>
            <li>股東人數最少為一人, 最多為五十人</li>
            <li>最少一個人（自然人）出任董事及須最年滿18歲</li>
            <li>公司（法人團體）可為公司股東和董事</li>
            <li>同一個人或同一間公司可同時成為公司的股東及董事</li>
            <li>股東和董事可以是任何國籍或居於任何地方的個人</li>
          </ul>
        </li>
        <li>
          <p>股本</p>
          <ul>
            <li>股東責任是有限，其責任及承擔是須繳付所投資股本金額</li>
            <li>股本繳付時間根據公司及股東雙方協定按公司要求而定</li>
            <li>可以多於一類型股本種類，各有不同的分紅權及話語權</li>
            <li>股本額没有規定，只有部份行業因受牌照規定才有最低股本要求</li>
          </ul>
        </li>
        <li>
          <p>公司秘書</p>
          <ul>
            <li>公司必須委任一名公司秘書</li>
            <li>
              公司秘書必須是一位通常居於香港的人士、香港持牌會計專業人士、香港持牌法律專業人士或持牌秘書公司
            </li>
            <li>
              公司為單一董事情況下，該董事不能同時擔任公司秘書，需委任第三者出任公司秘書
            </li>
            <li>如没有適當人選出任公司秘書，可考慮委任持牌秘書公司出任</li>
          </ul>
        </li>
        <li>
          <p>重要控制人登記冊</p>
          <p>自2018年3月1日起, 在香港註冊成立的公司必須:</p>
          <ul>
            <li>在公司的註冊辦事處或在香港其它地方備存重要控制人登記冊</li>
            <li>允許執法人員檢查重要控制人登記冊</li>
          </ul>
          <p>
            凡未有履行備存重要控制人登記冊責任,
            即屬刑事罪行。有關公司及其每名責任人可被刑罰。
            經大香港註冊平台註冊成立香港有限公司，免費助你處理重大控制人登記冊，助你遵守法規。
          </p>
        </li>
        <li>
          <p>重要控制人登記冊指定代表</p>
          <ul>
            <li>
              指定最少一名人士（該公司的股東、董事或僱員,
              而且必須是居於香港的自然人）為該公司的指定代表,
              以提供該公司的重要控制人登記冊的有關協助予執法人員
            </li>
            <li>
              如没有合適人選出任指定代表,
              可委任香港持牌會計專業人士、香港持牌法律專業人士或信託或公司服務持牌人出任重要控制人登記冊指定代表。
            </li>
          </ul>
        </li>
        <li>
          <p>公司註冊處註冊了公司名稱，是否代表同樣持有註冊商標？</p>
          <p>
            在香港，有限公司註冊和商標註冊的作用各異，它們受不同的法例規管，並由不同政府部門管理。公司註冊處負責註冊本地有限公司和香港設立營業地點的非香港公司，而由知識產權署管理的商標註冊處則負責辦理商標註冊。因此，即使公司名稱在公司註冊處註冊了本地有限公司或非香港公司，並不代表該公司名稱可以獲得商標註冊。商標註冊處會就每一宗商標註冊的申請進行審批，以決定該申請是否符合《商標條例》(第559章)及《商標規則》(第559A章)所訂明的註冊條件。
          </p>
        </li>
        <li>
          <p>持續遵守法規</p>
          <p>
            成立公司後當然要持續遵守公司法及稅務條例項下的各種法規，包括但不限於以下事項：-
          </p>
          <table>
            <tr>
              <th>每年度四月份期內</th>
              <th>申報僱主填報的薪酬及退休報稅表 ("BIR56B")</th>
              <th>《税務條例》<br />第52條</th>
            </tr>
            <tr>
              <th>公司成立週年日內42天</th>
              <th>呈交週年申報表</th>
              <th>《公司條例》<br />第662條</th>
            </tr>
            <tr>
              <th>公司成立週年日內9個月內及其後每年度會計年結日9個月內</th>
              <th>舉行週年股東大會</th>
              <th>《公司條例》<br />第610條</th>
            </tr>
            <tr>
              <th>公司成立每週年日內30天</th>
              <th>繳付商業登記證費用</th>
              <th>《商業登記條例》<br />第7條</th>
            </tr>
            <tr>
              <th>每年度公司會計年結日9個月內</th>
              <th>
                完成審計報告並呈交核數師報告及利得稅報稅表("BIR51")往梲務局
              </th>
              <th>
                《税務條例》<br />第51條<br />
                《公司條例》<br />第429條
              </th>
            </tr>
          </table>
        </li>
        <li>
          <p>開立商業銀行戶口</p>
          <p>
            公司成立後，香港商業戶口對於企業客戶是必須的。香港商業戶口能提供以下功能。
          </p>

          <ul>
            <li>環球匯款，接收海外款項</li>
            <li>外幣戶口，兌換外幣</li>
            <li>遙距操作，打破地域界限</li>
            <li>連接其他銀行，支援網上支付工具</li>
          </ul>
          <p>
            企業客戶可以在香港的國際性商業銀行開立他們的商業銀行戶口，我們會為閣下提供預先開戶評估，我們的團隊將了解客戶企業、及其董事和股東成員的背景，作出最專業的分析及最詳盡的說明。
            協助客戶妥善準備開戶所需文件和業務證明，以符合香港銀行的要求為原則。
          </p>
        </li>
      </ol>
    </div>
  </section>
  <app-setup-company-four-steps></app-setup-company-four-steps>
  <section
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutAlign="center stretch"
    style="background-color: white"
  >
    <!-- 香港公司成立註册服務 -->
    <div
      fxFlex="30"
      fxFlex.xs="80"
      fxLayoutAlign="center stretch"
      style="margin: 10px"
    >
      <app-limited-company-price-chart
        [priceChartData]="plans.PLAN_A_ZH"
      ></app-limited-company-price-chart>
    </div>

    <!-- 24小時特快香港公司成立註册服務 -->
    <div
      fxFlex="30"
      fxFlex.xs="80"
      fxLayoutAlign="center stretch"
      style="margin: 10px"
    >
      <app-limited-company-price-chart
        [priceChartData]="plans.PLAN_B_ZH"
      ></app-limited-company-price-chart>
    </div>

    <!-- 香港商業戶口連香港公司成立註册服務 -->
    <div
      fxFlex="30"
      fxFlex.xs="80"
      fxLayoutAlign="center stretch"
      style="margin: 10px"
    >
      <app-limited-company-price-chart
        [priceChartData]="plans.PLAN_C_ZH"
      ></app-limited-company-price-chart>
    </div>
  </section>
</div>
<!-- end of zh -->

<!-- en -->
<div *ngIf="lang === 'en'" fxLayout="column" fxLayoutAlign="center center">
  <div style="height: 100vh" fxLayout="column" fxLayoutAlign="center center">
    <p>Only Chinese Version Available</p>
  </div>
</div>
<!-- end of en -->
