<!-- zh -->
<div *ngIf="lang === 'zh'" fxLayout="column" fxLayoutAlign="center stretch">
  <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
    <img
      src="assets/images/register-hong-kong-unlimited-company.jpg"
      alt="register hong kong unlimited company"
      class="banner-image"
    />
    <div class="banner-text">
      <div class="banner-text">
        <p>香港無限公司註冊</p>
      </div>
    </div>
  </div>

  <section
    fxLayout="row"
    fxLayoutAlign="center"
    style="background-color: white"
  >
    <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
      <p></p>

      <h2>無限公司註冊</h2>
      <p>
        無限公司註冊程序簡單，維護成本也比有限公司低，因此部分企業都會從無限公司開始。但當公司規模漸漸變大風險隨之而增，東主都會想把無限公司轉成有限公司，私人的財產能跟公司分開，不受公司的債務影響。 
      </p>
      <p>有限公司和無限公司大不同</p>
      <ul>
        <li>
          有限公司擁有法人地位，有獨立的法律地位，公司本身可立約及負債。
          無限公司的東主或合伙人對公司的負債及合約需負上全部個人責任。
        </li>
        <li>
          有限公司每週年需遞交周年申報表及繳交商業登記費用，而無限公司只需要繳交商業登記費用
        </li>
        <li>
          無限公司須在開業後一個月內向商業登記署辦理商業登記證，不能未開業預先辦理商業登記證，有限公司則是公司成立後才辦理開業。
        </li>
        <li>
          無限公司的商業登記名稱不可以使人聯想到有關業務是以有限責任形式，例如Limited,
          INC, PTE 等字眼，而有限公司没有此限制。
        </li>

        <li>
          稅務條例規定有限公司須備妥經審計財務報表，作為報稅表的佐證文件；無限公司則没有此規定。
        </li>
      </ul>
      <p>無限公司註冊，東主最常遇到這些問題</p>
      <p>
        如何開無限公司？
        開無限公司後不知來年會計報稅公司東主要做什麼？不懂如何減低報稅成本？如何自行處理公司簡單的會計帳目不用交給專業會計人員處理？
        想找合適人選代收公司信件同郵包？
      </p>
      <p>大香港已為你們解決了以上問題</p>

      <ul>
        <li>
          免費提供記帳會計小程式給東主，直接輸出財務報表，一年線上支援助你完成會計報記帳，月月百分百省減會計成本，簡易完成報稅。
        </li>
        <li>
          大香港的虛擬辦公室服務提供中環商業區地址供選擇，延長服務時間，交通便利，中港、國際互遞信件包裹交收。
        </li>
        <li>
          會計師親自為公司作稅務合規計劃分析，專屬東主個人稅務策劃分折報告。
        </li>
      </ul>
    </div>
  </section>
  
  <app-setup-company-four-steps></app-setup-company-four-steps>

  <section
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutAlign="center"
    fxFlex="80"
    style="background-color: white"
  >
    <!-- 開無限公司 -->
    <div
      fxFlex="30"
      fxFlex.xs="80"
      fxLayoutAlign="center stretch"
      style="margin: 10px"
    >
      <app-unlimited-company-price-chart
        [priceChartData]="plans.PLAN_C_ZH"
      ></app-unlimited-company-price-chart>
    </div>

    <!-- 開無限公司 x 虛擬辦公室 x 稅務規劃 -->
    <div
      fxFlex="30"
      fxFlex.xs="80"
      fxLayoutAlign="center stretch"
      style="margin: 10px"
    >
      <app-unlimited-company-price-chart
        [priceChartData]="plans.PLAN_B_ZH"
      ></app-unlimited-company-price-chart>
    </div>

    <!-- 開無限公司 x 會計成本回贈 x 稅務規劃   -->
    <div
      fxFlex="30"
      fxFlex.xs="80"
      fxLayoutAlign="center stretch"
      style="margin: 10px"
    >
      <app-unlimited-company-price-chart
        [priceChartData]="plans.PLAN_A_ZH"
      ></app-unlimited-company-price-chart>
    </div>
  </section>
</div>
<!-- end of zh -->

<!-- en -->
<div *ngIf="lang === 'en'" fxLayout="column" fxLayoutAlign="center center">
  <div style="height: 100vh" fxLayout="column" fxLayoutAlign="center center">
    <p>Only Chinese Version Available</p>
  </div>
</div>
<!-- end of en -->
