import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-unlimited-company-to-limited-company',
  templateUrl: './unlimited-company-to-limited-company.component.html',
  styleUrls: ['./unlimited-company-to-limited-company.component.scss'],
})
export class UnlimitedCompanyToLimitedCompanyComponent implements OnInit {
  lang: string;

  constructor(public translateService: TranslateService) {
    this.lang = this.translateService.currentLang;
    this.translateService.onLangChange.subscribe(
      (event) => (this.lang = event.lang)
    );
  }

  ngOnInit(): void {}
}
