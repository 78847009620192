import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DataService } from '../data.service';
import { RequestManualQuotationDialogComponent } from '../request-manual-quotation-dialog/request-manual-quotation-dialog.component';

@Component({
  selector: 'app-request-manual-quotation',
  templateUrl: './request-manual-quotation.component.html',
  styleUrls: ['./request-manual-quotation.component.scss']
})
export class RequestManualQuotationComponent implements OnInit {
  @Input() serviceId: string;
  serviceData;
  constructor(public dialog: MatDialog, public data: DataService) {
    this.serviceData = data.services;
  }

  ngOnInit(): void {}

  openDialog() {
    const dialogRef = this.dialog.open(RequestManualQuotationDialogComponent, {
      width: '350px',
      data: {
        serviceId: this.serviceId
        // service: this.serviceData[this.serviceId].name_zh,
        // charge: this.serviceData[this.serviceId].charge_desc_zh,
      },
    });
  }

  gotoWhatsapp() {
    window.open('https://wa.me/85295126339', '_blank')
  }

}
