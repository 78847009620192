<!-- zh -->
<div *ngIf="lang === 'zh'">
  <div style="background-color: white">
    <!-- banner -->
    <section style="position: relative">
      <div fxLayout="column" fxLayoutAlign="center center">
        <img src="assets/images/home-banner.jpg" alt="" class="banner-image" />
        <div
          fxLayout="column"
          fxLayoutAlign="center center"
          class="logo-over-banner"
        >
          <img
            src="assets/images/logos/taihk-white-round-logo.png"
            style="width: 200px"
          />
          <div class="icon-over-banner">
            <button mat-button (click)="scrollDown(feature)">
              <mat-icon>keyboard_arrow_down</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </section>

    <!-- feature -->
    <section #feature class="feature">
      <div fxLayout="column" fxLayoutAlign="space-around center">
        <h1>用心幫你 助你起飛</h1>
        <div
          fxFlex="80"
          fxLayout="row"
          fxLayout.xs="column"
          fxLayoutAlign="center stretch"
        >
          <div fxFlex="18" fxFlex.xs="80" class="feature-box">
            <h3>
              費用合理
              <br />
              <br />
            </h3>
            <hr />
            <p>因應各客戶需求而收取費用，絕無隱藏收費。</p>
          </div>

          <!-- <div style="width: 2px; height: 100%; background-color: gray"></div> -->

          <div fxFlex="18" fxFlex.xs="80" class="feature-box">
            <h3>
              全方位支援
              <br />
              <br />
            </h3>
            <hr />
            <p>
              為客戶提供成立本地或離岸公司、公司秘書、開立銀行戶口及會計報稅等全方位的商務支援。
            </p>
          </div>

          <!-- <div style="width: 1px; height: 100%; background-color: gray"></div> -->

          <div fxFlex="18" fxFlex.xs="80" class="feature-box">
            <h3>
              緊貼法規、發放最新資訊
              <br />
              <br />
            </h3>
            <hr />
            <p>
              我們憑藉其專業領域中的知識及經驗，能助客戶緊貼法規及對營商環境變更發放最新資訊。
            </p>
          </div>

          <!-- <div style="width: 1px; height: 100%; background-color: gray"></div> -->

          <div fxFlex="18" fxFlex.xs="80" class="feature-box">
            <h3>
              商家信賴
              <br />
              <br />
            </h3>
            <hr />
            <p>憑藉至誠服務，深得各企業客戶及商家的信賴和稱讚。</p>
          </div>
        </div>
      </div>
    </section>

    <!-- history -->
    <section class="history-section">
      <div>
        <h3>品牌歷史</h3>
        <p>
          大香港企業服務Tai HK Corporate Services的源頭開始在2020年，與
          <a href="https://ztarincapital.com/" target="_blank"
            >In Formations & Secretaries Limited</a
          >（信託或公司服務持牌編號 TC007233）及Ztar In Ventures Capital Pte. Ltd 為兄弟公司。
          本公司創辦人因受普惠金融價值影響而萌生創辦本公司念頭，我們立足於提供給中小微企業平等機會及商業可持續原則基礎下，致力推動讓本地中小企能夠獲得「真正」及「正規」的專業服務。
        </p>
      </div>
    </section>

    <!-- team -->
    <!-- <app-team></app-team> -->

    <!-- founder -->
    <section style="padding: 5rem">
      <div fxLayout="column" fxLayoutAlign="space-around center">
        <h1>創辦人</h1>
        <!-- <div fxFlex="70" fxLayou="column"> -->
        <div style="width: 60vw" fxLayout="row" fxLayout.xs="column">
          <div
            fxFlex="30"
            fxLayout="column"
            fxLayoutAlign="space-around center"
            style="margin-right: 5vw"
          >
            <img
              src="assets/images/CZI.jpg"
              alt="founder"
              style="
                width: 200px;
                height: 200px;

                border-radius: 50%;
              "
            />
            <h3>鄭婕賢小姐</h3>
          </div>
          <div fxFlex="70">
            <ul>
              <li style="font-size: 1.4rem">香港執業會計師</li>
              <li style="font-size: 1.4rem">香港稅務學會資深會員</li>
              <li style="font-size: 1.4rem">香港華人會計師公會會員</li>
            </ul>
            <div fxLayout="row">
              <img
                src="assets/images/square-cpa-logo.png"
                alt=""
                class="prof-logo"
              />
              <img src="assets/images/tax-logo.png" alt="" class="prof-logo" />
              <img src="assets/images/ca-logo.png" alt="" class="prof-logo" />
            </div>
          </div>
        </div>
        <div style="width: 80vw" fxLayout="column">
          <p>
            創辦人鄭婕賢執業會計師(“鄭會計師”），生於基層家庭，鄭會計師從事會計行業20+年。鄭會計師深受普惠金融價值影響，有感中小微企缺乏平等機會獲得真正及合規的金融專業服務（俗稱：
            比人老點），毅然創辦「大香港企業服務 Tai HK Corporate
            Services」致力推動屬於中小企的專業服務平台。
          </p>
          <p>
            鄭會計師除了於會計界持有多年經驗外，彼亦為信託及公司服務提供者的持牌人
            (牌照編號 :
            TC001452)，在公司秘書服務範疇有15+年經驗，曾連續三年出任香港主板上市公司的名義公司秘書及已為超過20+香港主板上市公司完成法定合規職務。
          </p>
        </div>
        <div style="width: 80vw">
          <h2 style="text-align: center; margin-top: 2rem">我們的團隊</h2>
          <div
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutAlign="space-between center"
          >
            <div
              fxFlex="30"
              fxLayout="column"
              fxLayoutAlign="start center"
              style="height: 15rem"
            >
              <h3>LEE Y.W., Kenny</h3>
              <h4 style="margin: 0">會計師</h4>
              <ul>
                <li>香港執業會計師</li>
                <li>資深特許公認會計師</li>
              </ul>
            </div>
            <div
              fxFlex="30"
              fxLayout="column"
              fxLayoutAlign="start center"
              style="height: 15rem"
            >
              <h3>LAI C.W., Kirsty</h3>
              <h4 style="margin: 0">公司秘書</h4>
              <ul>
                <li>工商管理（榮譽）學士學位</li>
              </ul>
            </div>
            <div
              fxFlex="30"
              fxLayout="column"
              fxLayoutAlign="start center"
              style="height: 15rem"
            >
              <h3>SETO M.F.</h3>
              <h4 style="margin: 0">高級顧問</h4>
              <ul>
                <li>工商管理碩士</li>
                <li>美國公認會計師公會會員</li>
                <li>紐約州註冊會計師</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- service -->
    <section style="padding: 3rem 10rem; background-color: whitesmoke">
      <div fxLayout="column" fxLayoutAlign="center center">
        <h1>服務</h1>
        <div
          fxFlex="80"
          fxLayout="row wrap"
          fxLayout.xs="column"
          fxLayoutAlign="center stretch"
        >
          <div
            fxFlex="30"
            fxFlex.xs="80"
            fxLayout="column"
            class="service-box"
            (click)="goto('register-hong-kong-limited-company')"
          >
            <img
              src="assets/images/icons/home/formcom.png"
              alt="set up company"
              style="width: 10rem; margin: 0 auto"
            />
            <h1 style="text-align: center">成立公司</h1>
          </div>

          <div
            fxFlex="30"
            fxFlex.xs="80"
            fxLayout="column"
            class="service-box"
            (click)="goto('limited-company-tax')"
          >
            <img
              src="assets/images/icons/home/tax.png"
              alt="tax service"
              style="width: 10rem; margin: 0 auto"
            />
            <h1 style="text-align: center">會計報稅</h1>
          </div>

          <div
            fxFlex="30"
            fxFlex.xs="80"
            fxLayout="column"
            class="service-box"
            (click)="goto('company-secretary-service')"
          >
            <img
              src="assets/images/icons/home/comsec.png"
              alt="com sec maintenance"
              style="width: 10rem; margin: 0 auto"
            />
            <h1 style="text-align: center">公司秘書 – 週年維護服務</h1>
          </div>

          <div
            fxFlex="30"
            fxFlex.xs="80"
            fxLayout="column"
            class="service-box"
            (click)="goto('company-secretary-service')"
          >
            <img
              src="assets/images/icons/home/statusupdate.png"
              alt="company status update"
              style="width: 10rem; margin: 0 auto"
            />
            <h1 style="text-align: center">公司秘書 – 公司狀況變更服務</h1>
          </div>

          <div
            fxFlex="30"
            fxFlex.xs="80"
            fxLayout="column"
            class="service-box"
            (click)="goto('liquidation')"
          >
            <img
              src="assets/images/icons/home/liquid.png"
              alt="liquidation"
              style="width: 10rem; margin: 0 auto"
            />
            <h1 style="text-align: center">結業及恢復註冊</h1>
          </div>

          <div
            fxFlex="30"
            fxFlex.xs="80"
            fxLayout="column"
            class="service-box"
            (click)="goto('')"
          >
            <img
              src="assets/images/icons/home/professional.png"
              alt="professional"
              style="width: 10rem; margin: 0 auto"
            />
            <h1 style="text-align: center">專業服務</h1>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
<!-- end of zh -->

<!-- en -->
<div *ngIf="lang === 'en'">
  <div style="background-color: white">
    <!-- banner -->
    <section style="position: relative">
      <div fxLayout="column" fxLayoutAlign="center center">
        <img src="assets/images/home-banner.jpg" alt="" class="banner-image" />
        <div
          fxLayout="column"
          fxLayoutAlign="center center"
          class="logo-over-banner"
        >
          <img
            src="assets/images/logos/taihk-white-round-logo.png"
            style="width: 200px"
          />
          <div class="icon-over-banner">
            <button mat-button (click)="scrollDown(feature)">
              <mat-icon>keyboard_arrow_down</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </section>

    <!-- feature -->
    <section #feature class="feature">
      <div fxLayout="column" fxLayoutAlign="space-around center">
        <h1>Help you with your heart to help you succeed</h1>
        <div
          fxFlex="80"
          fxLayout="row"
          fxLayout.xs="column"
          fxLayoutAlign="center stretch"
        >
          <div fxFlex="18" fxFlex.xs="80" class="feature-box">
            <h3>
              Reasonable charges
              <br />
              <br />
            </h3>
            <hr />
            <p>
              We charge fees in response to customer needs, no hidden charges.
            </p>
          </div>

          <!-- <div style="width: 2px; height: 100%; background-color: gray"></div> -->

          <div fxFlex="18" fxFlex.xs="80" class="feature-box">
            <h3>
              Comprehensive support
              <br />
              <br />
            </h3>
            <hr />
            <p>
              We provide customers with a full range of business support such as
              establishing local or offshore companies, company secretaries,
              opening bank accounts, and Accounting & Hong Kong Tax Advisory
              services.
            </p>
          </div>

          <!-- <div style="width: 1px; height: 100%; background-color: gray"></div> -->

          <div fxFlex="18" fxFlex.xs="80" class="feature-box">
            <h3>
              Keep abreast of law and regulations, release the updated
              information
              <br />
              <br />
            </h3>
            <hr />
            <p>
              With knowledge and experience in our professional field, we help
              customers keep abreast of laws and regulations and release the
              latest information on business environment changes.
            </p>
          </div>

          <!-- <div style="width: 1px; height: 100%; background-color: gray"></div> -->

          <div fxFlex="18" fxFlex.xs="80" class="feature-box">
            <h3>
              Entrepreneur trust
              <br />
              <br />
            </h3>
            <hr />
            <p>
              With sincere service, we won the trust and praise of various
              corporate customers and entrepreneur.
            </p>
          </div>
        </div>
      </div>
    </section>

    <!-- history -->
    <section>
      <div
        fxLayout="row"
        fxLayout.xs="column"
        fxLayoutAlign="space-around stretch"
      >
        <div class="history-left" fxFlex fxShow.xs="false">
          <h3 style="transform: translateY(-10px)">History</h3>
          <p>
            Tai Hong Kong Corporate Services Platform start from 2020. It became
            a brother company with
            <a href="https://ztarincapital.com/" target="_blank"
              >In Formations & Secretaries Limited</a
            >
            (TCSP Licence No. TC007233). Our founder was influenced by the value
            of Financial Inclusion and initiation of an idea of starting our
            company.
          </p>
          <p>
            We commit to promoting local small, medium and micro enterprises
            having equal opportunity to obtain "real" and "formal" professional
            services and help corporates to hit their sustainability goals.
          </p>
          <img src="assets/images/brown-s-logo.png" />
        </div>
        <div class="history-right" fxFlex fxShow.xs="false">
          <img src="assets/images/home-history-2.jpg" alt="" />
        </div>
        <div
          fxShow.gt-xs="false"
          class="history-left"
          fxFlew="100"
          style="height: 80vh"
        >
          <h3 style="font-size: 2rem; transform: translateY(-10px)">History</h3>
          <p style="font-size: 1.2rem">
            Tai Hong Kong Corporate Services Platform start from 2020. It became
            a brother company with
            <a href="https://ztarincapital.com/" target="_blank"
              >In Formations & Secretaries Limited</a
            >. Our founder was influenced by the value of Financial Inclusion
            and initiation of an idea of starting our company.
          </p>

          <p style="font-size: 1.2rem">
            We commit to promoting local small, medium and micro enterprises
            having equal opportunity to obtain "real" and "formal" professional
            services and help corporates to hit their sustainability goals.
          </p>
          <img src="assets/images/brown-s-logo.png" />
        </div>
        <div fxShow.gt-xs="false" fxFlex.xs="100">
          <img
            src="assets/images/home-history-2.jpg"
            alt=""
            style="width: 100%"
          />
        </div>
      </div>
    </section>

    <!-- team -->
    <section style="padding-bottom: 6rem">
      <div fxLayout="column" fxLayoutAlign="space-around center">
        <h1>OUR TEAM</h1>

        <div
          fxFlex="80"
          fxLayout="row"
          fxLayout.xs="column"
          fxLayoutAlign="center stretch"
        >
          <!-- ceo -->
          <div fxFlex="25" class="team-box" fxHide.xs>
            <div class="front chairman">
              <p>Chief Executive Officer</p>
            </div>
            <div class="back">
              <h3>Chief Executive Officer</h3>
              <h3>
                <strong>Ms. Cheng Zit In</strong>
              </h3>
              <ul>
                <li>CPA (Practising)</li>
                <!-- <li>ACCA</li> -->
                <li>MSCA</li>
                <li>FTIHK</li>
              </ul>

              <div fxLayout="row">
                <img
                  src="assets/images/square-cpa-logo.png"
                  alt=""
                  class="prof-logo"
                />
                <!-- <img
                  src="assets/images/acca-logo.png"
                  alt=""
                  class="prof-logo"
                /> -->
                <img
                  src="assets/images/tax-logo.png"
                  alt=""
                  class="prof-logo"
                />
                <img src="assets/images/ca-logo.png" alt="" class="prof-logo" />
              </div>
            </div>
          </div>

          <div
            fxFlex="80"
            fxHide.gt-xs
            fxLayout="column"
            fxLayoutAlign="space-evenly center"
            style="padding: 0 2rem"
          >
            <h3>Chief Executive Officer</h3>
            <h3>
              <strong>Ms. Cheng Zit In</strong>
            </h3>
            <ul>
              <li>CPA (Practising)</li>
              <!-- <li>ACCA</li> -->
              <li>MSCA</li>
              <li>FTIHK</li>
            </ul>

            <div fxLayout="row" fxLayoutAlign="space-evenly center">
              <img
                src="assets/images/square-cpa-logo.png"
                alt=""
                style="margin: 1vw; width: 10vw; height: 10vw"
              />
              <!-- <img
                src="assets/images/acca-logo.png"
                alt=""
                style="margin: 1vw; width: 10vw; height: 10vw"
              /> -->
              <img
                src="assets/images/tax-logo.png"
                alt=""
                style="margin: 1vw; width: 10vw; height: 10vw"
              />
              <img
                src="assets/images/ca-logo.png"
                alt=""
                style="margin: 1vw; width: 10vw; height: 10vw"
              />
            </div>
          </div>

          <!-- consultant -->
          <div fxFlex="25" class="team-box" fxHide.xs>
            <div class="front consultant">
              <p>Honorary Consultant</p>
            </div>
            <div class="back">
              <h3>Honorary Consultant</h3>
              <h3>
                <strong>Mr. Paker Seto</strong>
              </h3>
              <ul>
                <li>MBA</li>
                <li>CPA（USA）</li>
                <li>New York State CPA</li>
              </ul>

              <p>
                Paker has over 20 years in the field of financial services. He
                has handled a number of annual audits of listed companies in
                Hong Kong, mergers and acquisitions cases between offshores
                structures and the Mainland.
              </p>
            </div>
          </div>

          <div
            fxFlex="80"
            fxHide.gt-xs
            fxLayout="column"
            fxLayoutAlign="space-evenly center"
            style="padding: 0 2rem"
          >
            <h3>Honorary Consultant</h3>
            <h3>
              <strong>Mr. Paker Seto</strong>
            </h3>
            <ul>
              <li>MBA</li>
              <li>CPA（USA）</li>
              <li>New York State CPA</li>
            </ul>

            <p>
              Paker has over 20 years in the field of financial services. He has
              handled a number of annual audits of listed companies in Hong
              Kong, mergers and acquisitions cases between offshores structures
              and the Mainland.
            </p>
          </div>

          <!-- offshore director -->
          <div fxFlex="25" class="team-box" fxHide.xs>
            <div class="front offshore-director">
              <p>Offshore Business Controller</p>
            </div>
            <div class="back">
              <h3>Offshore Business Controller</h3>
              <h3>
                <strong>Mr. Sezer Murat Uygar</strong>
              </h3>

              <p>
                Mr. Sezer holds a bachelor’s degree in Administration of Tourism
                and Hotel Management from Erciyes University in Turkey. After
                various experiences in customer service and law related
                industry, we make joint efforts to expand our offshore
                operations. He is handling cross border operations between
                Europe and Asia and in structuring our clients’ European
                expansion. He also focuses on advising European companies on
                their first move to Hong Kong.
              </p>
            </div>
          </div>

          <div
            fxFlex="80"
            fxHide.gt-xs
            fxLayout="column"
            fxLayoutAlign="space-evenly center"
            style="padding: 0 2rem"
          >
            <h3>Offshore Business Controller</h3>
            <h3>
              <strong>Mr. Sezer Murat Uygar</strong>
            </h3>

            <p>
              Mr. Sezer holds a bachelor’s degree in Administration of Tourism
              and Hotel Management from Erciyes University in Turkey. After
              various experiences in customer service and law related industry,
              we make joint efforts to expand our offshore operations. He is
              handling cross border operations between Europe and Asia and in
              structuring our clients’ European expansion. He also focuses on
              advising European companies on their first move to Hong Kong.
            </p>
          </div>
        </div>
      </div>
    </section>

    <!-- service -->
    <section style="padding-top: 3rem; background-color: whitesmoke">
      <div fxLayout="column" fxLayoutAlign="center center">
        <h1>Services</h1>
        <div
          fxFlex="80"
          fxLayout="row wrap"
          fxLayout.xs="column"
          fxLayoutAlign="center stretch"
        >
          <div fxFlex="35" fxFlex.xs="80" fxLayout="column" class="service-box">
            <div fxLayout="row" fxLayoutAlign="space-between center">
              <h1>Companies Incorporation</h1>
              <img src="assets/images/logos/home-box-logo-1.png" alt="" />
            </div>
            <div>
              <p>
                <strong
                  >Tai Hong Kong’s companies registration services allows you to
                  establish a business easily.</strong
                >
              </p>
            </div>
            <div>
              <div>
                <a routerLink="/hongkong">
                  Establishment of Hong Kong Company
                </a>
              </div>
              <div>
                <a routerLink="/prc"> Establishment of China Company </a>
              </div>
              <div>
                <a routerLink="/taiwan"> Establishment of Taiwan Company </a>
              </div>
              <div>
                <a routerLink="/singapore">
                  Establishment of Singapore Company
                </a>
              </div>
              <div>
                <a routerLink="/samoa"> Establishment of Samoa Company </a>
              </div>
              <div>
                <a routerLink="/bvi"> Establishment of BVI Company </a>
              </div>
              <div>
                <a routerLink="/cayman">Establishment of Cayman Islands Co. </a>
              </div>
            </div>
          </div>

          <div fxFlex="35" fxFlex.xs="80" fxLayout="column" class="service-box">
            <div fxLayout="row" fxLayoutAlign="space-between center">
              <h1>Accounting & Tax Advisory</h1>
              <img src="assets/images/logos/home-box-logo-2.png" alt="" />
            </div>
            <div>
              <p>
                <strong>
                  Tai Hong Kong provides one-stop service platform for you to
                  handle
                  <a routerLink="/account-tax">accounting and tax issues</a>
                  with confidence guarantee and help you pass every tax year in
                  compliance.
                  <!-- If you want to estimate the cost of your
                  accounting and tax compliance service, just enter simple
                  information and no need to submit any documents, you could get
                  immediate evaluation results. Please browse our
                  <a routerLink="/calculator"
                    >Audit & Tax Reporting Fee Calculator</a
                  > -->
                </strong>
              </p>
            </div>
            <div></div>
          </div>

          <div fxFlex="35" fxFlex.xs="80" fxLayout="column" class="service-box">
            <div fxLayout="row" fxLayoutAlign="space-between center">
              <h1>Company Secretary – Annual Maintenance Services</h1>
              <img src="assets/images/logos/home-box-logo-3.png" alt="" />
            </div>
            <div>
              <p>
                <strong
                  >Tai Hong Kong provides a variety of company secretarial
                  annual maintenance services
                </strong>
              </p>
            </div>
            <div>
              <div>
                <a routerLink="/annual-maintenance"
                  >Provision of Registered Address
                </a>
              </div>
              <div>
                <a routerLink="/annual-maintenance"
                  >Prepare and/or Maintain SCR</a
                >
              </div>
              <div>
                <a routerLink="/annual-maintenance"
                  >Prepare and submit Annual Returns</a
                >
              </div>
              <div>
                <a routerLink="/annual-maintenance"
                  >Act as yearly named company secretary
                </a>
              </div>
              <div>
                <a routerLink="/annual-maintenance"
                  >Renew Business Registration
                </a>
              </div>
            </div>
          </div>

          <div fxFlex="35" fxFlex.xs="80" fxLayout="column" class="service-box">
            <div fxLayout="row" fxLayoutAlign="space-between center">
              <h1>Company Secretary – Corporate Change Services</h1>
              <img src="assets/images/logos/home-box-logo-4.png" alt="" />
            </div>
            <div>
              <p>
                <strong
                  >Tai Hong Kong can help you with any corporates changes.
                </strong>
              </p>
            </div>
            <div>
              <div>
                <a routerLink="/com-sec">Share Transfer </a>
              </div>
              <div>
                <a routerLink="/com-sec"
                  >Change of Director / Company Secretary
                </a>
              </div>
              <div>
                <a routerLink="/com-sec">Change of Registered Address </a>
              </div>
              <div>
                <a routerLink="/com-sec">Issue and Allotment of Share(s) </a>
              </div>
              <div>
                <a routerLink="/com-sec">Change of Business Nature </a>
              </div>
              <div>
                <a routerLink="/com-sec">Change of Company Name </a>
              </div>
              <div>
                <a routerLink="/com-sec">Change in Particulars of Director </a>
              </div>
              <div>
                <a routerLink="/com-sec"
                  >Change in Particulars of Company Secretary
                </a>
              </div>
              <div>
                <a routerLink="/com-sec">Alteration of Company’s Articles </a>
              </div>
              <div>
                <a routerLink="/com-sec">Application of Branch BR</a>
              </div>
              <div>
                <a routerLink="/com-sec">Drafting of Board Minutes</a>
              </div>
            </div>
          </div>

          <div fxFlex="35" fxFlex.xs="80" fxLayout="column" class="service-box">
            <div fxLayout="row" fxLayoutAlign="space-between center">
              <h1>Company Secretary Dissolution / Restoration</h1>
              <img src="assets/images/logos/home-box-logo-5.png" alt="" />
            </div>
            <div>
              <p>
                <strong
                  >Tai Hong Kong provides a variety of company secretarial.
                </strong>
                <app-whatsapp [lang]="'en'"></app-whatsapp>
              </p>
            </div>
            <div>
              <div>
                <a routerLink="/liquidation">Liquidation</a>
              </div>
              <div>
                <a routerLink="/liquidation"
                  >Deregistration（limited and unlimited co.）</a
                >
              </div>
              <div>
                <a routerLink="/liquidation">Administrative restoration</a>
              </div>
              <div>
                <a routerLink="/liquidation">Registration a dormant company</a>
              </div>
              <div>
                <a routerLink="/liquidation"
                  >Dormant company ceases to be dormant</a
                >
              </div>
            </div>
          </div>

          <div fxFlex="35" fxFlex.xs="80" fxLayout="column" class="service-box">
            <div fxLayout="row" fxLayoutAlign="space-between center">
              <h1>Professional Services</h1>
              <img src="assets/images/logos/home-box-logo-6.png" alt="" />
            </div>
            <div>
              <p>
                <strong
                  >Tai Hong Kong provides one-stop business services to fully
                  support various corporate customers and entrepreneur.
                </strong>
                <app-whatsapp [lang]="'en'"></app-whatsapp>
              </p>
            </div>
            <div>
              <div>
                <a routerLink="/open-hong-kong-bank"
                  >Local Bank Account Opening Service</a
                >
              </div>
              <div>
                <a routerLink="/open-singapore-bank"
                  >Singapore Bank Account Opening Service</a
                >
              </div>
              <div>
                <a routerLink="/trademark"
                  >Trademark Registration（Hong Kong, China and oversea）</a
                >
              </div>
              <div>
                <a routerLink="/gov-funds"
                  >Government Funds application and relevant projects audit
                </a>
              </div>
              <div>
                <a routerLink="/corporate-finance">Corporate Finance Service</a>
              </div>
              <div>
                <a routerLink="/">Certified True Copies Service</a>
              </div>
              <div>
                <a routerLink="/">Apostille Services</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
<!-- end of en -->
