<!-- zh -->
<div *ngIf="lang === 'zh'" fxLayout="column" fxLayoutAlign="center stretch">
  <section fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
    <img src="assets/images/taiwan.jpg" alt="taiwan" class="banner-image" />
    <div class="banner-text" style="transform: translate(-60%, -80%)">
      <p
        style="
          color: black;
          font-weight: bold;
          font-size: 5vw;
          margin: 0;
          padding: 0;
        "
      >
        成立台灣有限公司
      </p>
      <p style="color: black; font-weight: bold; font-size: 3vw">
        大香港離岸企業服務部
      </p>
    </div>

    <!-- <p
      class="banner-text"
      fxShow.gt-xs="false"
      style="color: black; font-size: 1rem; line-height: 2rem;"
    >
    成立台灣有限公司<br />大香港離岸企業服務部
    </p> -->
  </section>

  <section fxLayout="row" fxLayoutAlign="center center">
    <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
      <h1>成立台灣公司</h1>
      <p>
        註冊費用: HK15,000
        <app-whatsapp [lang]="'zh'"></app-whatsapp>
        大香港提供完善的註冊台灣公司服務，為客戶處理註冊台灣公司的多重工序。
      </p>

      <br />

      <p>
        <strong
          ><i>
            一身在英國從事貿易行業的本港居民 Ricky Ho
            問：「香港人在台灣成立公司困難嗎？
            需要經過什麼步驟？分別有什麼需要注意的事情呢？」
          </i></strong
        >
      </p>

      <p>
        依據台灣投資法令規定，外國人及大陸人民或企業來台投資設立註冊台灣公司，必須事先取得經濟部投審會的審核後，才可進行公司或組織的設立註冊等程序，其中對於在台灣投資經營項目有一定的管制，當投資人身分為外國人時，可投資的營業項目採用負面表列方式管理，負面表列意思為除投審會所規定列示的項目不可為外國人投資外，其餘於投審會負面表列未列示類別均可投資。
      </p>
      <p>
        <strong
          ><i> 從事社褔界的麥小姐問：「台灣公司能請香港人嗎？」 </i></strong
        >
      </p>
      <p>
        可以。大香港能待台灣公司註冊成立後助你申請居留証，最久停留期限為自聘僱之日起三年。
      </p>
      <p class="sub-heading">(一) 外國自然人投資型態:</p>
      <p>
        外國人身份為自然人身分時，可選擇設立獨立或合夥的商業型態，其與公司組織有很大的差異，茲將公司與商業作一比較如下：
      </p>

      <table>
        <thead>
          <tr>
            <th>組織型態項目</th>
            <th>設立註冊台灣公司</th>
            <th>商業－設立註冊獨資或合夥</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>資本額</th>
            <th>
              無最低資本額要求，惟公司資本額應以經會計師查核簽證認定資本額足敷設立成本即可。
            </th>
            <th>無最低資本額要求</th>
          </tr>
          <tr>
            <th>責任風險</th>
            <th>由股東就出資額承擔有限責任</th>
            <th>
              商業負責人或合夥人需就商業經營上之所有風險及債務負擔清償責任
            </th>
          </tr>
          <tr>
            <th>業務拓展</th>
            <th>
              公司之登記名稱係由經濟部預審核准，其保護及使用較具全國性，有利業務拓展
            </th>
            <th>
              商業登記為所在地之縣(市)政府，故其名稱之保護及使用受到侷限。
            </th>
          </tr>
          <tr>
            <th>法人資格</th>
            <th>具有法人資格</th>
            <th>不具法人資格</th>
          </tr>
          <tr>
            <th>帳冊憑證</th>
            <th>需建置完善之帳冊及會計紀錄以反映公司之經營狀況</th>
            <th>較無強制要求，且經營盈虧直接反映在個人之所得額</th>
          </tr>
          <tr>
            <th>所得税</th>
            <th>適用營利事業所得税率20％及未分配盈餘税5％</th>
            <th>年度結算申報併入個人綜所税5％-40％</th>
          </tr>
        </tbody>
      </table>
      <p></p>

      <p class="sub-heading">(二) 外國法人投資型態</p>
      <p>
        依據台灣公司法規定，外國法人或自然人來台投資可設立註冊台灣公司、台灣分公司及台灣代表處，提供以下不同型態的權利及義務比較表：
      </p>

      <table>
        <thead>
          <tr>
            <th></th>
            <th>股份有限公司</th>
            <th>有限公司</th>
            <th>台灣分公司</th>
            <th>台灣辦事處</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>獨立法律責任</th>
            <th>有</th>
            <th>有</th>
            <th>無</th>
            <th>無</th>
          </tr>
          <tr>
            <th>公司名稱</th>
            <th>可自訂名稱</th>
            <th>可自訂名稱</th>
            <th>與母公司相同</th>
            <th>與母公司相同</th>
          </tr>
          <tr>
            <th>股東</th>
            <th>２名自然人或１名法人股東</th>
            <th>１名自然人或法人股東</th>
            <th>不適用</th>
            <th>不適用</th>
          </tr>
          <tr>
            <th>股本 / 營運資本</th>
            <th>無最低要求</th>
            <th>無最低要求</th>
            <th>無最低要求</th>
            <th>不適用</th>
          </tr>
          <tr>
            <th>董事 / 管理人員</th>
            <th>３名自然人</th>
            <th>１名自然人</th>
            <th>１名分行經理人</th>
            <th>不適用</th>
          </tr>
          <tr>
            <th>監察人</th>
            <th>１名自然人</th>
            <th>不適用</th>
            <th>不適用</th>
            <th>不適用</th>
          </tr>
        </tbody>
      </table>

      <p></p>
      <p class="sub-heading">(三) 台灣的稅務</p>
      <p>
        凡在台灣境內銷售貨物或勞務及進口貨物，應依營業稅法規定，課徵「營業稅」。課徵加值型營業稅為5%，而課徵非加值型營業稅為0.1%
        –
        25%。所有台灣公司應每２個月製備帳目作報稅，並繳納營業稅。由於台灣稅制複雜，建議聘請專業的會計人員／會計師來為你理帳，以免出現誤報或過期的罰金。
      </p>
      <p>
        除了營業稅外，所有台灣公司都應就營利事業所得課徵「營所稅」，稅率為20%，按年申報。此外，公司亦應為員工每年申報薪資及各類扣繳，並報繳勞健保。
      </p>
      <p>
        如台灣公司支付股息或費用給外國股東，需納扣繳稅。如需查詢其他稅務詳情，請立即遇我們的專家聯絡。<app-whatsapp
          [lang]="'zh'"
        ></app-whatsapp>
      </p>

      <p class="sub-heading">(四) 我們的服務</p>
      <ul>
        <li>我們協助客戶處理台灣股份有限公司成立前和後的每一細節</li>
        <li>取得經濟部投資審議委員會的投資許可</li>
        <li>取得經濟部投資審議委員會的資金審定</li>
        <li>取得台灣會計師發出的資金到位証明</li>
        <li>處理於經濟部商業司取得公司註冊</li>
        <li>籌備公司組織章程及大綱</li>
        <li>協助取得國稅局營業登記</li>
        <li>成立後之週年維護服務</li>
      </ul>

      <h3>註冊台灣公司後，助你遙距開戶口毋須親往台灣</h3>
      <p>
        台灣公司註冊後，我們能提供遙距的銀行開戶服務，毋須親臨台灣亦可完成整個開戶安排。
      </p>
      <p>
        因為大部分提供遙距開戶服務的銀行，都不接受客戶的直接申請，因此，客戶需要先聯絡銀行指定的銀行開戶介紹人，經轉介至相關銀行以開始遙距開戶。
      </p>
      <p>
        大香港與台灣的銀行有長久的聯繫，亦是部分銀行的指定開戶中間人。為您省時省力的方法達致開戶成功。
      </p>
      <app-whatsapp [lang]="'zh'"></app-whatsapp>
    </div>
  </section>
  
  <section
    fxLayout="row"
    fxLayoutAlign="center"
    style="background-color: whitesmoke"
  >
    <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
      <p><strong>離岸戶口開戶小貼士：</strong></p>
      <ul>
        <li>
          <strong>最低結餘限制</strong>
          <p>
            大多數銀行都要求最低存款來開立賬戶。
            因此，您需要在合理範圍內尋找最適合您的銀行。
          </p>
        </li>
        <li>
          <strong>初存限制 </strong>
          <p>
            留意銀行有没有要求戶口開立後需存放一定金額的存款定存一段時間的要求。
            因此，您要預備充足儲備作資金流轉。
          </p>
        </li>
        <li>
          <strong>公司成立司法地限制 </strong>
          <p>
            一般來說企業註冊國家/地區與開戶銀行國家/地區相同是比企業註冊國家/地區與開戶銀行國家/地區不同容易開啓在岸銀行戶口。
          </p>
        </li>
        <li>
          <strong>高風險國家限制 </strong>
          <p>
            如銀行開立後戶口往來有涉及高風險國家或人士，戶口容易被凍結或戶口喪失某部份功能例如與其他銀行間轉帳的能力。
          </p>
        </li>
        <li>
          <strong>公司成立司法地資金管制限制 </strong>
          <p>留意部份國家實施外匯管制，資金流出的限制。</p>
        </li>
      </ul>
    </div>
  </section>
</div>
<!-- end of zh -->

<!-- en -->
<div *ngIf="lang === 'en'" fxLayout="column" fxLayoutAlign="center stretch">
  <section fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
    <img src="assets/images/taiwan-en.jpg" alt="taiwan" class="banner-image" />
  </section>

  <section fxLayout="row" fxLayoutAlign="center center">
    <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
      <h1>Taiwan Company Incorporation</h1>
      <p>
        Registration fee: HK$7,000 up
        <app-whatsapp [lang]="'en'"></app-whatsapp>
      </p>
      <br />
      <br />
      <p>
        Tai Hong Kong offers high quality registered services of Taiwan Company,
        we help client to save their heavy workload during company registration.
      </p>

      <p>
        <strong
          ><i>
            Is it difficult to register a Taiwan Company? What is the procedure?
          </i></strong
        >
      </p>
      <p>
        A foreigner wishes to establish an enterprise in Taiwan or to
        participate in the management of a existing Taiwan corporation, the
        first thing is to get the approval from the Taiwan investment
        Commission, MOEA (Ministry of Economic Affairs). Once it is approved by
        MOEA, it would be possible to enter into the process of the company
        registration or formation.
      </p>
      <p>
        At present, most of industries in Taiwan are allowed to be invested by
        the foreigners, but there are few business or industries that are
        prohibited from holding or investing by the foreigners.
      </p>
      <p>
        More specifically speaking, except for the industries listed on
        "Negative List for Investment by Overseas Chinese and Foreign Nationals"
        which are issued by the investment Commission, the others industries are
        permitted to be invested by the Foreign Investor.
      </p>

      <p class="sub-heading">(1) Legal forms for foreign investors</p>
      <p>
        The foreign investors could incorporate a propritor business,
        partnership or company in Taiwan. The regulations and responsibilities
        between the One-man business or Partnership and the Company are
        different, we summarize the simple comparison for your reference as
        below:
      </p>

      <table>
        <thead>
          <tr>
            <th>Business Type Item</th>
            <th>Taiwan Company</th>
            <th>Sole Propritor or Partnerships</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>Capital Requirement</th>
            <th>Not Required</th>
            <th>Not Required</th>
          </tr>
          <tr>
            <th>Liability</th>
            <th>Liable to the extent of their contribution to the company.</th>
            <th>
              Liable for any liabilities arising from the business operation.
            </th>
          </tr>
          <tr>
            <th>Company Name</th>
            <th>Approved by the Ministry of Economic Affairs.</th>
            <th>Approved by the local city or county government.</th>
          </tr>
          <tr>
            <th>Being a juristic person</th>
            <th>Qualified</th>
            <th>Disqualified</th>
          </tr>
          <tr>
            <th>Bookkeeping</th>
            <th>Must</th>
            <th>Must</th>
          </tr>
          <tr>
            <th>Income Tax Rate</th>
            <th>20% Corporate Income Tax</th>
            <th>5％－40％ Personal Income Tax</th>
          </tr>
        </tbody>
      </table>
      <p></p>

      <p class="sub-heading">(2) Company types in Taiwan for the foreigners</p>
      <p>
        The comparison of the right and liability for the different business
        types are stated as following:
      </p>
      <p>Characteristics for different Taiwan company types</p>

      <table>
        <thead>
          <tr>
            <th></th>
            <th>Limited by Shares</th>
            <th>Limited Company</th>
            <th>Branch Office</th>
            <th>Representative Office</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>Separate Legal Liability</th>
            <th>Yes</th>
            <th>Yes</th>
            <th>Yes</th>
            <th>Yes</th>
          </tr>
          <tr>
            <th>Company Name</th>
            <th>Self-determined name</th>
            <th>Self-determined name</th>
            <th>Same as parent</th>
            <th>Same as parent</th>
          </tr>
          <tr>
            <th>Shareholder</th>
            <th>2 natural persons or 1 legal entity</th>
            <th>1 natural person or legal entity</th>
            <th>N/A</th>
            <th>N/A</th>
          </tr>
          <tr>
            <th>Share Capital</th>
            <th>No minimum requirement</th>
            <th>No minimum requirement</th>
            <th>No minimum requirement</th>
            <th>N/A</th>
          </tr>
          <tr>
            <th>Directorship / Management</th>
            <th>3 nature persons</th>
            <th>1 natural person</th>
            <th>1 branch manager</th>
            <th>N/A</th>
          </tr>
          <tr>
            <th>Supervisor</th>
            <th>1 natural person</th>
            <th>N/A</th>
            <th>N/A</th>
            <th>No</th>
          </tr>
        </tbody>
      </table>
      <p></p>

      <p class="sub-heading">(3) Tax System in Taiwan</p>
      <p>
        All sales of goods and provision of services in Taiwan, as well as all
        imports of goods into Taiwan, are subject to Business Tax (“BT”). BT is
        imposed under two systems: the VAT system (5%) and the Non-VAT system
        (0.1% – 25%). All companies are required to prepare accounts and file
        for BT every 2 months and make tax payment. Since taxation is quite
        complex in Taiwan, it is recommended to hire account professionals to
        complete the process to avoid late payment penalty.
      </p>
      <p>
        Apart from the BT, the companies are also subjected to 20% of corporate
        profits tax, filed annually. Also, employer must file salary income for
        the employees annually and pay labour insurance and national health
        insurance.
      </p>
      <p>
        If the company distributes profit or make payment to foreign
        shareholders, a withholding tax will be charged. For further tax details
        and requirement, contact our consultants now.<app-whatsapp
          [lang]="'en'"
        ></app-whatsapp>
      </p>

      <p class="sub-heading">(4) Our Services</p>
      <p>
        We assist clients with every aspect related to the formation of a Taiwan
        Company Limited by Shares, including:
      </p>
      <ul>
        <li>Get Investment Approval from the Investment Commission.</li>
        <li>
          Get the letter of capital verification from the Investment Commission.
        </li>
        <li>Get the auditor's report for capital verification.</li>
        <li>
          Process the company registration to the Ministry of Economic Affairs
          (“MOEA”).
        </li>
        <li>Prepared Memorandum and Articles of Incorporation.</li>
        <li>Process the tax registration to the Tax Authority.</li>
        <li>Annual Maintenance services after incorporated</li>
      </ul>
    </div>
  </section>
</div>
