<!-- zh -->
<div *ngIf="lang === 'zh'" fxLayout="column" fxLayoutAlign="center stretch">
  <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
    <img
      src="assets/images/cayman.jpg"
      alt="banner"
      class="banner-image"
    />
    <div class="banner-text">
      <p>成立開曼群島公司</p>
    </div>
  </div>

  <section
    fxLayout="row"
    fxLayoutAlign="center"
    style="background-color: white"
  >
    <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
      <p></p>
      <h1>成立開曼群島公司</h1>
      <p>註冊費用: HK$28,000 (如有中文名稱, 需另加 HK$2,000)</p>
      <app-whatsapp [lang]="'zh'"></app-whatsapp>
      <br />

      <h3>
        <strong
          >大香港提供完善的註冊開曼群島公司服務，為客戶處理註冊開曼群島公司的多重工序。
        </strong>
      </h3>
      <h3>
        <strong>
          開曼群島屬於英國領土。開曼群島是獲香港聯合交易所接納可在香港上市的兩個離岸司法管轄區之一。
        </strong>
      </h3>
      <br />

      <h3>註冊要求</h3>
      <ul>
        <li>公司名稱要求 : 英文名稱 (中文名稱 可自由選擇，需附加註冊費用)</li>
        <li>董事1位</li>
        <li>股東1位 (任何國籍人士均可 ; 董事與股東可同為1人擔任)</li>
        <li>標準法定股本50,000美元</li>
        <li>如增加註冊股數，需附加註冊費用</li>
      </ul>

      <h3>註冊優點</h3>
      <ul>
        <li>保障董事及股東個人資料，隱密性極高</li>
        <li>可使用中文公司名稱</li>
        <li>股份轉讓容易及免收釐印費，不受限制</li>
        <li>可於香港交易所上市</li>
        <li>無外匯管制，有利融資</li>
        <li>無須呈報經審計賬目</li>
        <li>無須申報稅務及繳納任何稅項</li>
      </ul>

      <h3>公司名稱限制</h3>
      <ul>
        <li>
          公司名稱不能出現 BANK(銀行) 、TRUST(信託)、MUTUAL
          FUND(基金)INSURANCE(保險)、
          Royal（皇家）、Imperial（皇帝）、Empire（帝國）、
          Bank（銀行）、Insurance（保險）、 Building Society（建房互助協會）
          、或是REINSURANCE(再保險)等字眼。稅務免除公司的名稱中不需要一定注明「有限」。名稱可以用拉丁字母表示，但註冊文件必須以英文書寫。
        </li>
      </ul>

      <h3>辦理所需時間</h3>
      <ul>
        <li>
          當我們收到閣下簽妥的所有文件後，若需30個工作天便可完成所有註冊程序。
        </li>
      </ul>
    </div>
  </section>
</div>
<!-- end of zh -->

<!-- en -->
<div *ngIf="lang === 'en'" fxLayout="column" fxLayoutAlign="center stretch">
  <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
    <img
      src="assets/images/cayman-en.jpg"
      alt="banner"
      class="banner-image"
    />
  </div>

  <section
    fxLayout="row"
    fxLayoutAlign="center"
    style="background-color: white"
  >
    <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
      <p></p>
      <h1>Establishment of Cayman Islands Company</h1>
      <p>
        Registration fee: HK$28,000 (an additional HK$2,000 for adding Chinese
        name)
      </p>
      <app-whatsapp [lang]="'en'"></app-whatsapp>
      <br />

      <p>
        <strong>
          Tai Hong Kong offers high quality registered services of Cayman
          Islands Company, we help client to save their heavy workload in
          company registration.</strong
        >
      </p>
      <br />
      <h3>Incorporation of Cayman Islands Company</h3>
      <p>
        Cayman Islands belongs to British Overseas Territory. Cayman is one of
        the two offshore jurisdictions accepted by Hong Kong Stock Exchange for
        listing in Hong Kong.
      </p>

      <h3>Requirements</h3>
      <ul>
        <li>
          Company Name Requirements: English name (Chinese name can be freely
          selected for additional registration fee)
        </li>
        <li>1 director</li>
        <li>
          1 shareholder (any nationality; directors and shareholders can be same
          person)
        </li>
        <li>Standard Registered Capital USD 50,000</li>
        <li>
          An additional registration fee increase with the number of registered
          shares.
        </li>
      </ul>

      <h3>Advantages</h3>
      <ul>
        <li>
          Provide privacy protection, without disclosing shareholders and
          directors
        </li>
        <li>Company is granted listing in Hong Kong Stock Exchange</li>
        <li>No tax shall be declarable and payable</li>
        <li>
          Chinese name can be shown in the Certificate of Incorporation of
          company
        </li>
        <li>Open the bank account in Hong Kong</li>
        <li>Cayman has no double taxation treaties with any other countries</li>
        <li>No exchange control, easy to raise capital</li>
      </ul>

      <h3>Company name restrictions</h3>
      <ul>
        <li>
          The Company name should not be included BANK、TRUST、MUTUAL FUND
          INSURANCE、 Royal、Imperial、Empire、 Bank、Insurance、 Building
          Society、OR REINSURANCE。
        </li>
      </ul>

      <h3>Time required</h3>
      <ul>
        <li>
          When we receive signed documents, it require 30 working days to
          complete all registration procedures.
        </li>
      </ul>
    </div>
  </section>
</div>
<!-- end of en -->
