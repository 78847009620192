import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import {
  FormBuilder,
  Validators,
  FormGroup,
  FormControl,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from '../data.service';

@Component({
  selector: 'app-contact-form-dialog',
  templateUrl: './contact-form-dialog.component.html',
  styleUrls: ['./contact-form-dialog.component.scss'],
})
export class ContactFormDialogComponent implements OnInit {
  lang: string;
  contactForm = this.fb.group({
    companyControl: ['', [Validators.required]],
    contactPersonControl: ['', [Validators.required]],
    emailControl: ['', [Validators.required, Validators.email]],
    mobileControl: [
      '',
      [Validators.required, Validators.pattern('^[23456789][0-9]{7}$')],
    ],
  });
  serviceData;
  serviceId: string;
  constructor(
    private fb: FormBuilder,
    public translateService: TranslateService,
    private afs: AngularFirestore,
    public dialogRef: MatDialogRef<ContactFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar,
    public dataService: DataService
  ) {
    this.lang = this.translateService.currentLang;
    this.serviceId = data.serviceId;
    this.serviceData = dataService.services;
  }

  ngOnInit(): void {}

  async onSubmit() {
    console.log('saving invoice data to firestore');
    try {
      const invoiceData = {
        company_name: this.companyControl.value,
        contact_person: this.contactPersonControl.value,
        contact_number: this.mobileControl.value,
        contact_email: this.emailControl.value,
        service_en: this.serviceData[this.data.serviceId].name_en,
        service_zh: this.serviceData[this.data.serviceId].name_zh,
        works:
          this.serviceData[this.data.serviceId].name_desc_zh === ''
            ? [this.serviceData[this.data.serviceId].name_zh]
            : [
                this.serviceData[this.data.serviceId].name_zh,
                this.serviceData[this.data.serviceId].name_desc_zh,
              ],
        works_price:
          this.serviceData[this.data.serviceId].name_desc_zh === ''
            ? [this.serviceData[this.data.serviceId].charge]
            : [
                this.serviceData[this.data.serviceId].charge,
                this.serviceData[this.data.serviceId].disbursement,
              ],
        total:
          this.serviceData[this.data.serviceId].name_desc_zh === ''
            ? this.serviceData[this.data.serviceId].charge
            : this.serviceData[this.data.serviceId].charge +
              this.serviceData[this.data.serviceId].disbursement
      };
      await this.afs.collection('invoices').add(invoiceData);
      console.log('invoice data saved to firestore');
      this.openSnackBar(this.emailControl.value);
      this.dialogRef.close();
    } catch (err) {
      console.error('error saving invoice data to firestore');
      this.dialogRef.close();
    }
  }

  openSnackBar(email: string) {
    const message = `發票已發送至${email}`;
    this.snackBar.open(message, 'x', {
      duration: 5000,
    });
  }

  get companyControl() {
    return this.contactForm.get('companyControl') as FormControl;
  }

  get contactPersonControl() {
    return this.contactForm.get('contactPersonControl') as FormControl;
  }

  get emailControl() {
    return this.contactForm.get('emailControl') as FormControl;
  }

  get mobileControl() {
    return this.contactForm.get('mobileControl') as FormControl;
  }
}
