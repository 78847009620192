<!-- <h1>{{ "REQUEST_SERVICE_DIALOG.TITLE" | translate }}</h1> -->
<h3>
  {{ "REQUEST_SERVICE_DIALOG.SELECTED_SERVICE" | translate }}:
  {{ data.service_name }}
</h3>
<p>{{ "REQUEST_SERVICE_DIALOG.SUBTITLE" | translate }}</p>
<div mat-dialog-content>
  <mat-form-field>
    <mat-label>{{ "REQUEST_SERVICE_DIALOG.NAME" | translate }}</mat-label>
    <input matInput [(ngModel)]="data.name" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>{{
      "REQUEST_SERVICE_DIALOG.CONTACT_NUMBER" | translate
    }}</mat-label>
    <input matInput [(ngModel)]="data.contact_number" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>{{ "REQUEST_SERVICE_DIALOG.EMAIL" | translate }}</mat-label>
    <input matInput [(ngModel)]="data.email" />
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="cancel()">
    {{ "REQUEST_SERVICE_DIALOG.CANCEL_BUTTON_TEXT" | translate }}
  </button>
  <button
    mat-button
    [disabled]="
      !data.name.length ||
      !data.contact_number.length ||
      !data.email.length ||
      uploading
    "
    (click)="submit()"
  >
    <!-- <button mat-button (click)="submit()" [mat-dialog-close]="data"> -->
    {{ "REQUEST_SERVICE_DIALOG.SUBMIT_BUTTON_TEXT" | translate }}
  </button>
</div>
