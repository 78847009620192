<section
  style="
    background-color: white;
    width: 100vw;
    display: flex;
    justify-content: center;
  "
>
  <img
    src="assets/images/setup-company-4-steps.jpg"
    alt="setup company 4 steps"
    style="width: 80%; margin: 3rem"
  />
</section>
