<!-- zh -->
<div *ngIf="lang === 'zh'" fxLayout="column" fxLayoutAlign="center stretch">
  <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
    <img src="assets/images/vietnam.jpg" alt="vietnam" class="banner-image" />
    <div class="banner-text">
      <p>成立越南公司</p>
    </div>
  </div>

  <section
    fxLayout="row"
    fxLayoutAlign="center"
    style="background-color: white"
  >
    <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
      <h2>成立越南公司</h2>
      <p>
        成立越南公司需要辦妥投資登記證書IRC及企業登記證書ERC，我們離岸公司成立團隊熟悉越南公司法規，助你最後註冊成功及獲取企業登記證書。申請註冊越南公司前你們需要以下文件作佐證：
      </p>
      <ol style="line-height: 2rem">
        <li>公司註冊證書</li>
        <li>章程</li>
        <li>
          近1年財務審計報告/投資者承諾融資新公司或證明投資者餘額的銀行對賬單(即銀行對賬單上的資金數量應
          當等於或多於投資者準備在越南投資的數目)以及證明投資者有能力出資設立新公司(分公司必須提供財務報告)
        </li>
        <li>投資人與出租人之間的越南辦公室租約</li>
        <li>
          辦公室法律文件(例如:土地使用權證書，房屋所有權證以及土地資產所有權證)
        </li>
        <li>投資方授權代表護照</li>
        <li>新公司法人代表護照</li>
        <li>投資方授權簽署人護照</li>
      </ol>

      <h3>越南公司成立後資本認購詳情</h3>
      <p>
        公司的註冊資本必須在企業註冊證書ERC發出後90天內繳入公司。
        90天的期限是不可更改的，若沒能在90天期限內完成將會產生嚴重後果並且很難改正。
        當註冊資本不足，而未按時在登記機關登記的企業，將被處以一千萬到兩千萬越南盾罰款。
      </p>

      <h3>越南公司成立後需留意各種附屬執照</h3>
      <p>
        根據2014年投資法附錄4，投資者投資特殊商業在越南開展業務前，需要申請經營資格證書、執業證書、職業責
        任保險、法律資本需求等執照。
      </p>

      <h3>越南公司成立後需注意的其他事項</h3>
      <ol style="line-height: 2rem">
        <li>
          公布業務註冊內容
          在被授予企業登記證後，投資方應於30天內在越南國家企業登記門戶網站上公開企業信息
        </li>
        <li>註冊商業印章</li>
        <li>
          印章使用注意
          在收到法人印章之後使用商業印章之前，企業必須向商業登記處發出企業印章通知，以便在企業註冊國家信息
          門戶網站上發佈
        </li>
        <li>
          銀行開戶
          投資方需要開兩類銀行賬戶，即投資資金賬戶用來接收投資金額和交易賬戶用於在越南處理日常的交易。
        </li>
        <li>
          稅務登記
          對於新成立公司，投資方應通知稅務機關公司的註冊地址並在稅務機關登記。
        </li>
        <li>增值稅 公司在收到營業執照後應立即登記VAT。沒有登記門檻。</li>
        <li>
          每年支付營業執照稅BLT<br />
          BLT是在越南做貿易每年需要繳納的一項直接稅。
          注:對於從事生產活動、商品貿易與服務的組織，若註冊資本或投入資本高於10億越南盾，征收的執照稅為3
          百萬越南盾每年;註冊資本或投入資本低於10億越南盾的公司為2百萬越南盾每年;分支機構、辦事處、營業
          場所以及其他經濟組織需繳納1百萬越南盾每年。<br />
          如果納稅人在1-6月份就職並獲得稅務登記證和稅碼，應當申報並繳納全年的BLT;如果納稅人在7-12月份就職
          並獲得稅務登記證和稅碼，只需申報並繳納全年BLT的50%。
          如果納稅人未成功註冊企業稅務登記證和稅碼而在經營的，無論何時被發現，都需要繳納全年的BLT。
        </li>

        <li>
          登記稅務報表電子簽名越南政府鼓勵越南公司在稅務申報及稅務報表中使用電子簽名<br />
          注:費用取決於提供的服務。一年期服務的費用在170萬越南盾左右，三年期服務的費用在350萬越南盾左右。
        </li>
        <li>
          公司名稱
          企業的名稱必須和其總公司一樣，正確的呈現出來。如果未能遵守，將被處以一千萬到一千五百萬越南盾的罰款。
        </li>
        <li>需要在越南居住的法人必須申請法人申請暫住證(TRC)。</li>
        <li>
          聘請員工後需要首次就業登記，需要準備工資單及註冊社會保險，失業保險和健康保險。
        </li>
      </ol>

      <h2>成立越南常見的問題</h2>

      <h3>
        1）註冊在越南的一般貿易公司，註冊後還需要再申請自營進出口權、批發或零售的許可嗎?
      </h3>
      <p>
        越南2019年的最新規定:在越南註冊的外資貿易公司註冊完成後即可自動獲得自營進出口權，也自動允許在越南當地開展批發業務，所以在當地從事批發業務的貿易公司，註冊後無需再單獨申請交易許可。
        但貿易公司若是要在越南當地從事零售業務，則需要再單獨申請交易許可證(trade-licensing
        ，即營業執照)。
      </p>

      <h3>2） 如何進行批發業務和零售業務的界定呢?</h3>
      <p>
        簡單來說，主要是看貿易公司在越南銷
        售的產品處在哪一個環節上。若越南公司銷
        售的產品進入了下一個銷售者手中，則是批發(如向超市供貨)。若越南公司銷售的產品直接進入了消費者手中，那便是零售(如在網店上售賣)。
      </p>

      <h3>3）是否可以代驗資?</h3>
      <p>不可以代驗資，驗資款必須從投資方名下賬戶打至驗資戶.</p>

      <h3>4）是否有稅務優惠</h3>
      <p>
        只有制造或兼營貿易公司可以進駐進出口免稅的工業區。只有加工區的制造公司才能享受稅率為0的進出口稅。單純的的貿易公司一般不能在加工區設立註冊，貿易區以
        外註冊的普通貿易公司或工廠沒有稅務優惠。
      </p>

      <h3>5） 開展業務是怎麼交稅，稅種和比例是多少?</h3>
      <p>
        增值稅稅率10%，可按季度或月度申報，到時可看代賬會計安排;
        所得稅稅率20%，一 般按年度申報。增值稅是有進項抵扣稅的，是差值的10%
        。關稅要看具體產品，到時 候貨代會告訴您詳細的稅率。
      </p>

      <h3>6） 越南公司的註冊資本有要求嗎?</h3>
      <p>
        越南對公司註冊資本沒有要求，3-4千美金
        都可以做，但是實際上去操作的時候，越南的計劃及投資部會注意到您公司註冊資本過低而不通過。所以註冊資本可以設為10000美金，我
        們一般建議最好註冊資本設為30000美金。
      </p>

      <h2>註冊越南公司後，助你遙距開戶口毋須親往越南</h2>
      <p>
        越南公司註冊後，我們能提供遙距的銀行開戶服務，毋須親臨越南亦可完成整個開戶安排。
      </p>
      <p>
        因為大部分提供遙距開戶服務的銀行，都不接受客戶的直接申請，因此，客戶需要先聯絡銀行指定的銀行開戶介紹人，經轉介至相關銀行以開始遙距開戶。
      </p>
      <p>
        大香港與越南的銀行有長久的聯繫，亦是部分銀行的指定開戶中間人。為您省時省力的方法達致開戶成功。
      </p>
      <app-whatsapp [lang]="'zh'"></app-whatsapp>
    </div>
  </section>

  <section
    fxLayout="row"
    fxLayoutAlign="center"
    style="background-color: whitesmoke"
  >
    <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
      <h3>離岸戶口開戶小貼士：</h3>
      <ul>
        <li>最低結餘限制</li>
        <p>
          大多數銀行都要求最低存款來開立賬戶。因此，您需要在合理範圍內尋找最適合您的銀行。
        </p>
        <li>初存限制</li>
        <p>
          留意銀行有没有要求戶口開立後需存放一定金額的存款定存一段時間的要求。因此，您要預備充足儲備作資金流轉。
        </p>
        <li>公司成立司法地限制</li>
        <p>
          一般來說企業註冊國家/地區與開戶銀行國家/地區相同是比企業註冊國家/地區與開戶銀行國家/地區不同容易開啓在岸銀行戶口。
        </p>
        <li>高風險國家限制</li>
        <p>
          如銀行開立後戶口往來有涉及高風險國家或人士，戶口容易被凍結或戶口喪失某部份功能例如與其他銀行間轉帳的能力。
        </p>
        <li>公司成立司法地資金管制限制</li>
        <p>留意部份國家實施外匯管制，資金流出的限制。</p>
      </ul>
      <h3>BUD 專項基金 - 香港政府資助高達600萬 助你發展越南巿場</h3>
      <p>
        越南屬BUD基金自貿計劃資助的巿場，資助香港的中小企向外發展，每項資助額為50%，每間公司最高可獲資助600萬，如有興趣，<a
          href="/bud"
          >請按此了解詳情。</a
        >
      </p>
      <app-whatsapp [lang]="'zh'"></app-whatsapp>
    </div>
  </section>


</div>
<!-- end of zh -->

<!-- en -->
<div *ngIf="lang === 'en'" fxLayout="column" fxLayoutAlign="center center">
  <div style="height: 100vh" fxLayout="column" fxLayoutAlign="center center">
    <p>Only Chinese Version Available</p>
  </div>
</div>
<!-- end of en -->
