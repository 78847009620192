import { Component } from '@angular/core';
import {
  FormBuilder,
  Validators,
  FormGroupDirective,
  FormGroup,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ClientFormData } from '../models/client-form-data';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as firebase from 'firebase/app';

@Component({
  selector: 'app-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.scss'],
})
export class CalculatorComponent {
  // propertyCount = 0;
  // vehicleCount = 0;
  // machineCount = 0;
  // stockCount = 0;
  // rentalPropertyCount = 0;
  // loanCount = 0;

  clientForm = this.fb.group({
    businessNature: this.fb.group({
      businessCategory: [null, Validators.required],
      // tradeBiz: [null, Validators.required],
      auditYearCount: [null],
    }),
    companyScale: this.fb.group({
      annualRevenue: [null, Validators.required],
      assetValue: [null, Validators.required],
      employeeCount: [null, Validators.required],
      outsourceCount: [null, Validators.required],
      outstandingClientCount: [null, Validators.required],
      outstandingSupplierCount: [null, Validators.required],
      directorCount: [null, Validators.required],
      bankAccountCount: [null, Validators.required],
      officeShopStorageCount: [null, Validators.required],
    }),
    companyCapital: this.fb.group({
      propertyCount: [null, [Validators.required, Validators.min(0)]],
      vehicleCount: [null, [Validators.required, Validators.min(0)]],
      machineCount: [null, [Validators.required, Validators.min(0)]],
      stockCount: [null, [Validators.required, Validators.min(0)]],
      rentalPropertyCount: [null, [Validators.required, Validators.min(0)]],
      loanCount: [null, [Validators.required, Validators.min(0)]],
    }),
    documentLog: this.fb.group({
      cashflowPct: [null, Validators.required],
      ledgerPct: [null, Validators.required],
      statementPct: [null, Validators.required],
      invoicePct: [null, Validators.required],
      lastTenInvoice: [null, Validators.required],
      firstFiveInvoice: [null, Validators.required],
      yearEndDate: [null], // required?
    }),
    clientInfo: this.fb.group({
      contactPerson: [null, Validators.required],
      contactEmail: [null, [Validators.required, Validators.email]],
      contactNumber: [
        null,
        [
          Validators.required,
          Validators.maxLength(8),
          Validators.pattern('^[235679][0-9]{7}$'),
        ],
      ],
      companyName: [null, Validators.required],
    }),
  });

  optionData = {
    businessCategoryOptionData: {
      propertyInvestment: '物業投資',
      equityInvestment: '股權投資',
      trading: '貿易（無存貨）',
      servicing: '服務',
      retail: '零售',
      catering: '餐飲',
      wholesale: '批發 / 貿易（有存貨）',
      manufacturing: '製造、生產或其他',
      building: '建築',
      logistics: '物流',
    },
    annualRevenueOptionData: [
      '多於 0 及少於 2 萬',
      '多於或等於 2 萬及少於 200 萬',
      '多於或等於 200 萬及少於 1000 萬',
      '多於或等於 1000 萬及少於 3000 萬',
      '多於或等於 3000 萬及少於 6000 萬',
      '多於或等於 6000 萬及少於 1 億',
      '多於或等於 1 億',
    ],
    assetValueOptionData: [
      '多於 0 及少於 2 萬',
      '多於或等於 2 萬及少於 200 萬',
      '多於或等於 200 萬及少於 1000 萬',
      '多於或等於 1000 萬及少於 3000 萬',
      '多於或等於 3000 萬及少於 6000 萬',
      '多於或等於 6000 萬及少於 1 億',
      '多於或等於 1 億',
    ],
    employeeCountOptionData: [
      '0人至10人',
      '11人至20人',
      '21人至30人',
      '31人至60人',
      '61人至100人',
      '多於100人',
    ],
    outsourceCountOptionData: [
      '0人至10人',
      '11人至20人',
      '21人至30人',
      '31人至60人',
      '61人至100人',
      '多於100人',
    ],
    outstandingClientCountOptionData: [
      '0 個至 5 個',
      '6 個至 10 個',
      '11 個至 30 個',
      '31 個至 60 個',
      '61 個至 100 個',
      '多於 100 個',
    ],
    outstandingSupplierCountOptionData: [
      '0 個至 5 個',
      '6 個至 10 個',
      '11 個至 30 個',
      '31 個至 60 個',
      '61 個至 100 個',
      '多於 100 個',
    ],
    directorCountOptionData: [
      '1 個至 4 個',
      '5 個至 6 個',
      '7 個至 8 個',
      '9 個至 11 個',
      '12 個至 15 個',
      '多於 15 個',
    ],
    bankAccountCountOptionData: [
      '0 個至 2 個',
      '3 個至 4 個',
      '5 個至 7 個',
      '8 個至 11 個',
      '12 個至 15 個',
      '多於 15 個',
    ],
    officeShopStorageCountOptionData: [
      '0 個至 2 個',
      '3 個至 4 個',
      '5 個至 7 個',
      '8 個至 11 個',
      '12 個至 15 個',
      '多於 15 個',
    ],
    cashflowPctOptionData: [
      '完全齊備或不適用(100%)',
      '大致齊全 (90%）',
      '基本齊全 (75%)',
      '一半齊全 (50%)',
      '少量齊全 (25%)',
      '極少量齊全 (10%)',
      '未有相關記錄',
    ],
    ledgerPctOptionData: [
      '完全齊備或不適用(100%)',
      '大致齊全 (90%）',
      '基本齊全 (75%)',
      '一半齊全 (50%)',
      '少量齊全 (25%)',
      '極少量齊全 (10%)',
      '未有相關記錄',
    ],
    statementPctOptionData: [
      '完全齊備或不適用(100%)',
      '大致齊全 (90%）',
      '基本齊全 (75%)',
      '一半齊全 (50%)',
      '少量齊全 (25%)',
      '極少量齊全 (10%)',
      '未有相關記錄',
    ],
    invoicePctOptionData: [
      '完全齊備或不適用(100%)',
      '大致齊全 (90%）',
      '基本齊全 (75%)',
      '一半齊全 (50%)',
      '少量齊全 (25%)',
      '極少量齊全 (10%)',
      '未有相關記錄',
    ],
    lastTenInvoiceOptionData: [
      '完全齊備或不適用(100%)',
      '大致齊全 (90%）',
      '基本齊全 (75%)',
      '一半齊全 (50%)',
      '少量齊全 (25%)',
      '極少量齊全 (10%)',
      '未有相關記錄',
    ],
    firstFiveInvoiceOptionData: [
      '完全齊備或不適用(100%)',
      '大致齊全 (90%）',
      '基本齊全 (75%)',
      '一半齊全 (50%)',
      '少量齊全 (25%)',
      '極少量齊全 (10%)',
      '未有相關記錄',
    ],
  };

  lang: string;
  submitting = false;

  constructor(
    private fb: FormBuilder,
    public translateService: TranslateService,
    private afs: AngularFirestore,
    private snackbar: MatSnackBar
  ) {
    this.lang = 'zh';
    this.translateService.onLangChange.subscribe(
      (event) => (this.lang = event.lang)
    );
  }

  async onSubmit(clientForm: FormGroup, formDirective: FormGroupDirective) {
    const formData = this.clientForm.value;
    const { companyName } = formData.clientInfo;
    const quotationId = this.genQuotationId(companyName);

    // const formData = clientForm.value
    this.submitting = true;
    try {
      // console.log(clientForm.value); // undefine
      // console.log(this.clientForm.value);
      const { companyName, contactNumber, contactEmail } = formData.clientInfo;
      console.log(
        'client info:',
        companyName,
        contactNumber,
        contactEmail,
        'quotation id:',
        quotationId
      );
      const DaysRepeatedRequestByCompanyName = 90;
      const AttemptsRepeatedRequestByContactNumber = 3;
      const TaiHongKongEmail = 'taihongkongplatform@gmail.com';

      /**
       *
       * update quotations collection
       * trigger createQuotationPDF()
       * createSendEmailTrigger()
       *
       **/

      if (contactEmail === TaiHongKongEmail) {
        // self made quotation

        await this.afs.doc(`quotations/${quotationId}`).set(formData);

        // update quotaton-request, used to keep track of repeat request
        await this.updateQuotationRequestRecord(companyName, contactNumber);
        // await this.afs.collection(`quotation-request`).add({
        //   createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        //   companyName,
        //   contactNumber,
        // });

        this.submitting = false;

        this.snackbar.open(
          this.lang == 'en'
            ? `your quotation will be sent to ${contactEmail}`
            : `報價單將會發到你的電郵箱${contactEmail}`,
          'x',
          {
            duration: 2000,
          }
        );
        // this.clientForm.reset();
        window.location.reload();

        return;
      }

      // check repeated request by company name
      if (
        await this.repeatedRequestByCompanyName(
          companyName,
          DaysRepeatedRequestByCompanyName
        )
      ) {
        this.snackbar.open(
          this.lang == 'en'
            ? `Your company ${companyName} have repeated request quotation in ${DaysRepeatedRequestByCompanyName} days, please try later`
            : `您的公司${companyName}已經於${DaysRepeatedRequestByCompanyName}天內取過報價單，請稍後再試`
        );
        this.submitting = false;

        return;
      }

      // check repeated request by contact number
      if (
        await this.repeatedRequestByContactNumber(
          contactNumber,
          AttemptsRepeatedRequestByContactNumber
        )
      ) {
        this.snackbar.open(
          this.lang == 'en'
            ? `Your number ${contactNumber} have repeated request quotation ${AttemptsRepeatedRequestByContactNumber} times, please try later`
            : `您的手機號碼${contactNumber}已經獲取報價單${AttemptsRepeatedRequestByContactNumber}次以上，請稍後再試`
        );
        this.submitting = false;

        return;
      }

      // eligible quotation
      console.log('form data to be saved to firestore', formData);
      await this.afs.doc(`quotations/${quotationId}`).set(formData);
      console.log('saved form data to firestore');
      // update quotaton-request, used to keep track of repeat request
      await this.updateQuotationRequestRecord(companyName, contactNumber);
      // await this.afs.collection(`quotation-request`).add({
      //   createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      //   companyName,
      //   contactNumber,
      // });
      console.log('updated quotation request record');

      this.submitting = false;

      this.snackbar.open(
        this.lang == 'en'
          ? `your quotation will be sent to ${contactEmail}`
          : `報價單將會發到你的電郵箱${contactEmail}`,
        'x',
        {
          duration: 2000,
        }
      );

      window.location.reload();
    } catch (err) {
      this.snackbar.open(
        this.lang == 'en'
          ? `error during submitting client data`
          : `遞交客戶資料出錯`,
        'x',
        {
          duration: 2000,
        }
      );
      this.submitting = false;
      console.error(`error submitting form: ${err}`);
    }
  }

  private async updateQuotationRequestRecord(
    companyName: string,
    contactNumber: string
  ) {
    if (!companyName) {
      throw new Error(
        `error during update quotation request record: no company name`
      );
    }

    if (!contactNumber) {
      throw new Error(
        `error during update quotation request record: no contact number`
      );
    }

    await this.afs.collection(`quotation-request`).add({
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      companyName,
      contactNumber,
    });
  }

  private genQuotationId(companyName: string): string {
    console.log('company name in gen quotation id', companyName);
    const date = new Date().toISOString().split('T')[0]; // yyyy-mm-dd
    // const companyName =
    //   this.clientForm.get('clientInfo').get('companyName').value || '';
    const applicationId = companyName.trim().split(' ').join('-') + '-' + date; // ABC-Company-Limited-2020-12-23
    return applicationId;
  }

  private async repeatedRequestByCompanyName(
    companyName: string,
    days: number
  ): Promise<boolean> {
    console.log('repeat request by company name', companyName, 'in', days, 'days');
    try {
      if (!companyName) {
        throw new Error('must provide company name 必須輸入公司名稱');
      }

      if (!Number.isInteger(days)) {
        throw new Error('must provide days 必須重覆輸入期限');
      }

      const snapshot = await this.afs
        .collection('quotation-request', (ref) =>
          ref.where('companyName', '==', companyName)
        )
        .get()
        .toPromise();
      // snapshot.docs.length
      console.log('snapshot', snapshot);
      // console.log(
      //   `repeated request by company name snapshot ${JSON.stringify(
      //     snapshot.docs[0].data().createdAt
      //   )}
      //   `
      // );

      if (snapshot.empty) {
        return Promise.resolve(false);
      }

      // snapshot not empty
      const created = snapshot.docs[0].data().createdAt.toDate();
      const now = new Date();
      const diffInDays = Math.round(
        (now.getTime() - created.getTime()) / (24 * 60 * 60 * 1000)
      );
      return diffInDays >= days
        ? Promise.resolve(false)
        : Promise.resolve(true);
    } catch (err) {
      throw new Error(err);
    }
  }

  private async repeatedRequestByContactNumber(
    contactNumber: string,
    attempt: number
  ): Promise<boolean> {
    console.log('repeat request by contact number', contactNumber, 'in', attempt, 'attempts');

    try {
      if (!contactNumber) {
        throw new Error('must provide contact number 必須輸入聯絡號碼');
      }

      if (!Number.isInteger(attempt)) {
        throw new Error('must provide attempt nubmer 必須輸入獲取報價單次數');
      }

      const snapshot = await this.afs
        .collection('quotation-request', (ref) =>
          ref.where('contactNumber', '==', contactNumber)
        )
        .get()
        .toPromise();

      // console.log(
      //   `repeated request by contact number snapshot ${snapshot.docs}`
      // );

      if (snapshot.empty) {
        return Promise.resolve(false);
      }

      return snapshot.docs.length >= attempt
        ? Promise.resolve(true)
        : Promise.resolve(false);
    } catch (err) {
      throw new Error(err);
    }
  }
}
