<!-- zh -->
<div *ngIf="lang === 'zh'" fxLayout="column" fxLayoutAlign="center stretch">
  <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
    <img
      src="assets/images/corporate-finance.jpg"
      alt=""
      class="banner-image"
    />
    <!-- <p
      class="banner-text"
      style="
        color: white;
        font-size: 4rem;
        text-shadow: 2px 8px 8px black;
        line-height: 2rem;
        left: 5vw；;
      "
    >
      企業融資
    </p> -->
  </div>
  <section
    fxLayout="row"
    fxLayoutAlign="center"
    style="background-color: white"
  >
    <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
      <p></p>
      <h1 style="color: rgba(89, 6, 89, 0.8)">
        <strong>
          大香港致力幫助中小企業獲得平等享受金融服務的權利，從而體現普惠金融價值。</strong
        >
      </h1>
      <p>
        現時在香港營運的中小企業面對急劇轉變的市場環境，本港銀行對於商業融資貸款越趨審慎，使中小企業的融資壓力日漸沉重。
      </p>
      <p>
        我們憑著豐富企業財務經驗，致力為面對融資困難的中小企提供提供商業融資策劃服務。
        有關企業融資因難的解決方法的相關操作可以分為︰
      </p>
      <ol>
        <li>改善企業的會計安排，以釋放企業的潛在貸款能力</li>
        <li>為企業配對合適的貸款融資產品，以組合企業能夠負擔的融資計劃</li>
        <li>
          幫助客戶編制商業計劃書，提升貸款申請的成功率、降低利息支出、加大貸款額度等的信貸便利
        </li>
      </ol>
      <p>
        當中，我們需要提供專業的會計審計、商業財務及法律合規的支援，同時比較市場上最新的貸款計劃及借貸環境。
        可見，我們的貸款融資合規顧問會為客戶提供度身訂製的計劃，為此，我們恆常與不同借貸機構保持緊密聯繫以掌握第一手融資訊息。
      </p>
      <p>
        要融資成功，必先了解現時本港的中小企融資渠道以及取得方法，並且盡早作出部署，方為積極面對融資壓力的解決方法。現時，中小企的的融資渠道有「資產抵押貸款」、「中小企無抵押貸款」、「貿易融資」、「應收帳融資」、「機器及設備貸款」，及由香港政府領頭的「中小企業信貸保證計劃
        (SGS)」及「中小企融資擔保計劃 (SFGS)」。
      </p>

      <p class="double-line">資產抵押貸款</p>
      <p>
        最受銀行及財務公司歡迎的貸款產品。資產抵押貸款能夠使企業輕鬆獲取低利息、大額度的流動資金，即時改善現金流，最高可以提供百份百抵押品市值的信貸金額。
      </p>
      <p>
        可接受為抵押品的資產種類眾多，包括香港物業土地、港幣/外幣定期存款、貴金屬、保險、股票、基金、債券、存款證、備用信用證等等的存款及投資性資產的合法契約。
      </p>
      <p>
        由於抵押品的流通性之差異，貸款機構可能需要律師提供抵押契約相關的法律服務以及評估抵押品價值，可能需要超過15個工作天才能後到初步審批結果。
      </p>
      <p>
        歡迎有意取應收賬融資及改善現有融資貸款的中小企，與我們的貸款融資合規顧問聯絡，取得免費諮詢。
      </p>
      <app-whatsapp [lang]="'zh'"></app-whatsapp>

      <p></p>
      <p class="double-line">中小企無抵押貸款</p>
      <p>
        一種主要由銀行提供，為急需現金周轉的中小企業而設特快週轉貸款，可以無須提供任何抵押品，貸款額可達港幣200萬，最快可於10個工作天內取得貸款。
      </p>
      <p>
        貸款機構會考慮申請企業的行業性質、公司成立年期、財務報表、戶口結餘、生意合約、發票收據等等有關於企業運作的文件作為審批貸款的條件。
      </p>
      <p>
        由於貸款銀行需要全面評估借貸企業的營利能力、現金流及負債水平，因此申請企業應該有恆常的會計安排，整理企業的財務表現，提升獲批的成功率。
      </p>
      <p>歡迎有意改善現有融資貸款的中小企，與我們的貸款融資合規顧問聯絡。</p>
      <app-whatsapp [lang]="'zh'"></app-whatsapp>

      <p></p>
      <p class="double-line">貿易融資</p>
      <p>
        進出口貿易是本港的四大產業之一，為使進出口貿易更有效率和靈活，本港的出口商家對各類中國內地、香港及全球的跨境貿易融資服務及信貸擔保服務有深切的需求。能夠為進口商及出口商提供有關進出口貿易的信貸手段，一般被統稱為「貿易融資」。
      </p>
      <p>
        現時貿易融資服務主要由銀行提供，仍可分類為進口、出口，及其他類型貿易融資。
      </p>

      <h3>進口類(為進口貿易提供信貸便利)︰</h3>
      <ul>
        <li>開出信用證</li>
        <li>背對背信用證</li>
        <li>進口代收</li>
        <li>進口代收墊款</li>
        <li>擔保提貨</li>
        <li>信託提貨</li>
        <li>進口發票融資</li>
      </ul>

      <h3>出口類(為出口貿易提供信貸便利)︰</h3>
      <ul>
        <li>信用證通知</li>
        <li>信用證保兌</li>
        <li>轉讓信用證</li>
        <li>出口託收</li>
        <li>出口託收買單</li>
        <li>出口信用證單據託收</li>
        <li>出口信用證單據議付 / 貼現</li>
        <li>打包放款</li>
        <li>備貨融資</li>
        <li>出口發票貼現</li>
      </ul>

      <h3>其他類︰</h3>
      <ul>
        <li>擔保函</li>
        <li>備用信用證</li>
        <li>福費廷</li>
        <li>保理</li>
        <li>外匯買賣</li>
      </ul>
      <p>
        貿易融資產品種類眾多，從事貿易相關的中小企，如希望估算以上貸款產品的效益，及找尋最合適的貸款機構，歡迎與我們聯絡，取得免費諮詢。
      </p>
      <p>
        歡迎有意取應收賬融資及改善現有融資貸款的中小企，與我們的貸款融資合規顧問聯絡，取得免費諮詢。
      </p>
      <app-whatsapp [lang]="'zh'"></app-whatsapp>

      <p></p>
      <p class="double-line">應收帳融資</p>
      <p>
        對於中小企，使用記帳放式向各戶提供貨品或服務，是非常普通的貿易方式。
      </p>
      <p>
        為協助中小企的貿易業務，應收帳融資機構(各大銀行及大型財務公司)協助中小企預先從應收帳款(Accounts
        Receivable)獲得現金貸款的信貸工具，亦能協助貿易企業管理應收帳款的收帳風險︰客戶有機會在收貨後不付款，或是拖延付款。
      </p>
      <p></p>
      <h3>應收帳融資的操作原理</h3>
      <ol>
        <li>
          中小企(seller)在向個別客戶(buyer)開出發票之前，先與應收帳款貸款機構訂立協議
        </li>
        <li>中小企(seller)完成向客戶(buyer)開出發票(invoice)，確認雙方貿易</li>
        <li>中小企將發票回到該貸款機構</li>
        <li>中小企憑發票即可貼現資金，貼現金額可達發票金額的某個協定比率</li>
        <li>
          當中小企交付貨物後, 應收帳款即產生，中小企把交易單據交予貸款機構
        </li>
        <li>當發票到期時，貸款機構或在它們的代表會從客戶處收取交易款項</li>
        <li>當發票兌現後，中小企便可收取餘款</li>
      </ol>

      <h3>優勢︰</h3>
      <ul>
        <li>無需任何資產抵押</li>
        <li>何時及如何使用這些資金完全由中小企決定</li>
        <li>
          即使客戶最終沒有支付貨款，你的中小企業仍能取得貨款(可能需要額外購買信用保險，最終所得貸款受保單賠償額上限及其他因素而定)。
        </li>
        <li>優化及縮短資產負債表</li>
        <li>增加你的中小企的商業彈性，為新的訂單籌措營運資金</li>
      </ul>
      <p>
        提供應收帳融資機構會直接向客戶收款，而且客戶身處海外居多，貸款機構會對申請的中小企的貿易客戶及交易貨品進行仔細分析，亦會對不同類型業務的企業有不同取向。
      </p>
      <p>要成功獲批應收賬融資，須要盡早與貸款機構建立長遠而隱定融資關係。</p>
      <p>
        歡迎有意取應收賬融資及改善現有融資貸款的中小企，與我們的貸款融資合規顧問聯絡，取得免費諮詢。
      </p>
      <app-whatsapp [lang]="'zh'"></app-whatsapp>

      <p></p>
      <p class="double-line">機器及設備融資</p>
      <p>
        對於從事生產事業的中小企業，購買或租用生產所需的機械及設備是營運及提升生產力的必然投資。
      </p>
      <p>銀行提供的機器及設備融資能夠為上述投資提供信貸服務。</p>

      <h3>服務對象︰</h3>
      <ul>
        <li>備有租賃及租購貸款以供選擇</li>
        <li>適用於放置在香港或中國內地的全新及二手機器和設備</li>
        <li>最高貸款額為設備購入價或市值的90</li>
        <li>靈活還款期可長達5年</li>
      </ul>
      <p>
        請即與我們聯絡，以取得免費諮詢，為你爭取貸款手續簡單、利率特優、敘做成數高的機器及設備融資貸款。
      </p>
      <p>
        歡迎有意取應收賬融資及改善現有融資貸款的中小企，與我們的貸款融資合規顧問聯絡，取得免費諮詢。
      </p>
      <app-whatsapp [lang]="'zh'"></app-whatsapp>

      <p></p>
      <p class="double-line">香港政府的中小企信貸擔保計劃</p>
      <p>
        香港政府為緩和本港中小企從金融市場中取得商業融資的困難，向合資格的香港中小企提供信貸擔保以便向相關的貸款機構取得融資(中小企並非直接向香港政府取得貸款)。現行的計劃有「中小企融資擔保計劃
        (SFGS)」及「中小企業信貸保證計劃
        (SGS)」，政府擔保的貸款額度可以達到最高幣1200萬元。
      </p>
      <p>
        中小企要成功取以上的政府擔保，必須理解不同貸款銀行對SGS及SFGS有各自的取向，我們會準確地為各行業的中小企配對適合的貸款機構，增加獲批成數，同時取得最大貸款額度及降低利息支出。
      </p>
      <p>
        歡迎有意取應收賬融資及改善現有融資貸款的中小企，與我們的貸款融資合規顧問聯絡，取得免費諮詢。
      </p>
      <app-whatsapp [lang]="'zh'"></app-whatsapp>

      <p></p>
      <p class="double-line">中小企融資擔保計劃 (SFGS)</p>
      <p>適用行業：所有行業</p>
      <p>
        目標：香港按揭證券有限公司（「按揭證券公司」）於2011年1月推出中小企融資擔保計劃（「本計劃」）。本計劃旨在協助中小企及非上市企業從參與計劃的銀行等機構取得貸款，應付業務需要，並在急速轉變的營商環境當中，提升生產力和競爭力。在本計劃下，按揭證券公司會為合資格企業的貸款額提供五成、六成或七成的信貸擔保。
      </p>
      <p>
        申請資格：企業須在香港有業務運作、根據《商業登記條例》（第310章）註冊，並且在申請擔保當日，必須最少已營運業務一年，以及具備良好的還款記錄。上市公司、貸款機構及貸款機構的聯繫公司則不可以申請本計劃
      </p>
      <p>
        支援/資助模式：甲)
        每家企業或所屬集團根據此計劃所取得的貸款不得超過1,200萬港元。最長還款年期為5年。一般來說，此計劃主要為年利率最高至10%的貸款提供擔保，但亦會個別考慮年利率超過10%的申請個案。貸款年利率的水平乃由貸款機構釐定，企業應向其貸款機構查詢；乙)
        在本計劃下，有期貸款及循環信貸均可申請擔保。企業可同時借取有期貸款及循環信貸，兩種貸款的比例不設限制。有關貸款必須用作提供營運資金予企業的業務運作、購置與企業業務有關的設備或資產，或原本在本計劃下獲得擔保的貸款或原本獲得由工業貿易署的特別信貸保證計劃擔保的貸款進行再融資
      </p>
      <p>
        詳情︰<a
          href="http://www.hkmc.com.hk/chi/our_business/sme_financing_guarantee_scheme.html"
        >
          香港按揭證券有限公司
        </a>
      </p>
      <p>
        歡迎有意取應收賬融資及改善現有融資貸款的中小企，與我們的貸款融資合規顧問聯絡，取得免費諮詢。
      </p>
      <app-whatsapp [lang]="'zh'"></app-whatsapp>

      <p></p>
      <p class="double-line">中小企業信貸保證計劃 (SGS)</p>
      <p>適用行業：所有行業</p>
      <p>
        目標：中小企業信貸保證計劃旨在協助中小企業向參與本計劃的貸款機構（下稱”參與貸款機構”）取得貸款，用作：購置營運設備及器材；以及一般業務用途的營運資金
      </p>
      <p>申請資格：</p>
      <p>符合下列條件的企業即有資格申請，但不包括貸款機構及其相關者：</p>
      <p>
        1)
        在本港根據《商業登記條例》（第310章）註冊及有實質業務運作*的企業，及；2)
        符合香港特別行政區（”香港特區”）政府的中小企業定義，即從事製造業而在本港僱用少於100人的企業；或從事非製造業而在本港僱用少於50人的
        企業**。就此而言，”企業”是指為了圖利而從事的任何形式的生意、商務、工藝、專業、職業或其他活動，但不包括任何會社，除非是《商業登記條例》內所訂明以圖利為目的而提供服務的會社。根據《商業登記條例》，”會社”指任何法團或一人以上的組織，其組成目的是為會員提供設施，以便進行社交或康樂活動，並且為其會員提供服務（不論是否為了圖利）；以及擁有其會員有權專用的會社處所
      </p>
      <p>
        *
        申請企業須在香港有實質業務運作。關於這方面，空殼公司或近乎所有主要業務運作在香港境外進行的公司，
        均不會被視為在本港有實質業務運作。詳情請參閱「特別信貸保證計劃及中小企業信貸保證計劃下有關「實質業務運作」規定的指引」
      </p>
      <p>
        **
        “僱用人數”包括經常參與企業業務的在職東主、合夥人及股東，以及企業的受薪員工，包括在遞交本計劃申請書時，由有關企業直接支付薪酬的全職或兼職受薪僱員，其中包括長期或臨時聘用的。
      </p>
      <p>支援/資助模式：</p>
      <p>
        每家中小企業可獲得的保證額為獲批貸款額的50%，最高為600萬元。信貸保證額可靈活運用於營運設備及器材貸款與營運資金貸款。保證期由首次提取貸款日期起計，最長為五年。若中小企業已全數清還在本計劃下獲批信貸保證的任何一筆營運設備及器材貸款或營運資金貸款，該中小企業可使用相應的信貸保證額多一次，以600萬元為上限。
      </p>
      <p>
        詳情︰
        <a href="https://www.smefund.tid.gov.hk/tc_chi/sgs/sgs_objective.html">
          工業貿易署
        </a>
      </p>
      <p>
        歡迎有意取應收賬融資及改善現有融資貸款的中小企，與我們的貸款融資合規顧問聯絡，取得免費諮詢。
      </p>
      <app-whatsapp [lang]="'zh'"></app-whatsapp>
    </div>
  </section>
</div>
<!-- end of zh -->

<!-- en -->
<div *ngIf="lang === 'en'" fxLayout="column" fxLayoutAlign="center stretch">
  <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
    <img
      src="assets/images/corporate-finance.jpg"
      alt=""
      class="banner-image"
    />
    <!-- <p
      class="banner-text"
      style="
        color: white;
        font-size: 3rem;
        text-shadow: 2px 8px 8px black;
        line-height: 2rem;
        left: 5vw；;
      "
    >
      Corporate Financing Service
    </p> -->
  </div>
  <section
    fxLayout="row"
    fxLayoutAlign="center"
    style="background-color: white"
  >
    <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
      <p></p>
      <h1 style="color: rgb(89, 6, 89)">
        <strong
          >We commit to helping SMEs obtain equal rights to enjoy financial
          services, to reflect the value of inclusive financing</strong
        >
      </h1>
      <p>
        At present, small and medium enterprises operating in Hong Kong are
        facing a drastically changing market environment. Banks in Hong Kong
        have become more cautious about commercial financing loans, which has
        put SMEs under increasing pressure on financing.
      </p>
      <p>
        With our experience in corporate finance, we are committed to providing
        commercial financing planning services for small and medium enterprises
        facing financing difficulties. The solutions related to corporate
        financing difficulties can be divided into:
      </p>
      <ol>
        <li>
          Improve the accounting arrangements of enterprises to release their
          potential loan capacity.
        </li>
        <li>
          Matching suitable loan financing products for enterprises for
          combining financing plans that enterprises can afford.
        </li>
        <li>
          Help customers prepare business plans, improve the success rate of
          loan applications, reduce interest expenses, increase loan lines and
          other credit facilities.
        </li>
      </ol>
      <p>Among them, we need to provide professional accounting auditing, business finance and legal compliance support, while comparing the latest loan plans and lending environment in the market. It can be seen that our financing compliance consultant will provide customers with tailor-made plans. To this end, we always maintain close contact with different lending institutions to grasp first-hand financing information.</p>
      <p>To be successful in financing, it is necessary to understand the current financing channels and methods for SMEs in Hong Kong, and finding out loan solution as soon as possible, so as to actively face the financing pressure.   At present, the financing channels of SMEs are Asset-Pledge Loan, Unsecured Loan, Trade Finance,  Receivables Finance, Machinery and Equipment Financing, SME Loan Guarantee Scheme and SME Financing Guarantee Scheme.</p>
      <app-whatsapp [lang]="'en'"></app-whatsapp>

    </div>
  </section>
</div>
<!-- end of en -->
