<div [formGroup]="">
  <h3>股東 / 董事資料</h3>

  <div>
    <mat-form-field>
      <mat-label>
        身份
      </mat-label>
      <mat-select required>
        <mat-option value="shareholder">股東</mat-option>
        <mat-option value="director">董事資料</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div>
    <mat-form-field>
      <mat-label>
        股東/董事類型
      </mat-label>
      <mat-select required>
        <mat-option value="individual">個人</mat-option>
        <mat-option value="company">公司</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div>
    <mat-form-field>
      <mat-label>股份數目 </mat-label>
      <input matInput type="number" />
    </mat-form-field>
  </div>

  <div>
    <mat-form-field>
      <mat-label>英文名稱</mat-label>
      <input matInput type="text" />
    </mat-form-field>
  </div>

  <div>
    <mat-form-field>
      <mat-label>中文名稱</mat-label>
      <input matInput type="text" />
    </mat-form-field>
  </div>
  <div>
    <mat-form-field>
      <mat-label>地址</mat-label>
      <input matInput type="text" />
    </mat-form-field>
  </div>

  <div>
    <mat-form-field>
      <mat-label>身份証/護照/公司註冊號</mat-label>
      <input matInput type="text" />
    </mat-form-field>
  </div>

  <div>
    <mat-form-field>
      <mat-label>簽發地區</mat-label>
      <input matInput type="text" />
    </mat-form-field>
  </div>

  <div>
    <!-- <mat-form-field> -->
    <mat-label>請上載証件的掃描本</mat-label>
    <button mat-raised-button type="button" >
      Choose File
    </button>

    <!-- </mat-form-field> -->
  </div>

  <div>
    <input type="file" (change)="uploadFile($event)" />
  </div>

  <div>
    <mat-form-field>
      <ngx-mat-file-input
        [formControl]="fileControl"
        [multiple]="false"
        accept="image/png,image/jpeg"
        (change)="upload($event)"
      >
        <!-- <mat-icon ngxMatFileInputIcon>folder</mat-icon> -->
        <mat-hint>Hint</mat-hint>
      </ngx-mat-file-input>
    </mat-form-field>
  </div>
</div>
