import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { ContactFormDialogComponent } from '../contact-form-dialog/contact-form-dialog.component';
import { AngularFirestore } from '@angular/fire/firestore';
import { Meta, Title } from '@angular/platform-browser';
@Component({
  selector: 'app-hongkong',
  templateUrl: './hongkong.component.html',
  styleUrls: ['./hongkong.component.scss'],
})
export class HongkongComponent implements OnInit {
  lang: string;
  package;
  package_en;
  relevantServices;
  relevantServices_en;
  constructor(
    public translateService: TranslateService,
    public dialog: MatDialog,
    public afs: AngularFirestore,
    private meta: Meta,
    private title: Title
  ) {
    this.lang = this.translateService.currentLang;
    this.translateService.onLangChange.subscribe(
      (event) => (this.lang = event.lang)
    );

    this.package = this.afs
      .doc('website-data/hong-kong-limited-company-registration')
      .valueChanges();

    this.package_en = this.afs
      .doc('website-data/hong-kong-limited-company-registration-en')
      .valueChanges();

    this.relevantServices = this.afs
      .doc('website-data/company-registration-relevant-services')
      .valueChanges();
    // .toPromise()
    // .then((data) => { console.log(data); this.package = data})
    // .catch((err) => console.log(err));

    this.meta.addTags([
      {
        name: 'description',
        content: `
        【成立公司懶人包】註冊公司/開公司程序、收費成本總表和注意事項

        開有限公司前考慮成立公司費用，公司成立後維護成本總表，大香港助你完成從成立公司到銀行開戶至公司成立後維護工作
        `,
      },
      { name: 'keywords', content: '香港公司, 有限公司' },
    ]);
    this.title.setTitle('成立香港有限公司');
  }

  ngOnInit(): void {}

  openContactFormDialog(service: string) {
    const dialogRef = this.dialog.open(ContactFormDialogComponent, {
      width: '350px',
      data: { service: service, charge: 700 },
    });
  }
}
