<!-- zh -->
<div *ngIf="lang === 'zh'" fxLayout="column" fxLayoutAlign="center center">
  <form
    [formGroup]="clientForm"
    (ngSubmit)="onSubmit(clientForm.value, formDirective)"
    fxLayout="column"
    fxLayoutAlign="center center"
    #formDirective="ngForm"
  >
    <div fxLayout="column" fxLayoutAlign="center center" fxFlex="80">
      <h1>會計報稅費用計算機</h1>
      <p>以下費用只為預覧，將根據貴司提供實質的財務資料及文件而調整。</p>

      <h2>估算服務費用</h2>
      <!-- business nature -->

      <h3>業務性質</h3>
      <div
        formGroupName="businessNature"
        fxLayout="row"
        fxLayoutAlign="center stretch"
        fxLayout.xs="column"
        fxLayoutAlign.xs="space-around stretch"
      >
        <div
          fxFlex="100"
          fxLayout="column"
          fxLayoutAlign="space-between stretch"
          class="selector-box"
        >
          <mat-form-field>
            <mat-label>業務類型</mat-label>
            <br />
            <mat-select formControlName="businessCategory" required>
              <mat-option
                *ngFor="
                  let option of optionData.businessCategoryOptionData | keyvalue
                "
                [value]="option.key"
                >{{ option.value }}</mat-option
              >
            </mat-select>
            <mat-error
              *ngIf="
                clientForm
                  .get('businessNature')
                  .get('businessCategory')
                  .hasError('required')
              "
              >必須填寫</mat-error
            >
          </mat-form-field>
        </div>
      </div>

      <!-- company scale -->
      <h3>公司規模</h3>
      <div
        formGroupName="companyScale"
        fxLayout="row wrap"
        fxLayoutAlign="center stretch"
        fxLayoutGap="15px grid"
        fxLayout.xs="column"
        fxLayoutAlign.xs="space-around stretch"
      >
        <div
          fxFlex="30"
          fxLayout="column"
          fxLayoutAlign="space-between stretch"
          class="selector-box"
        >
          <mat-form-field>
            <mat-label>年度營業額（港元）</mat-label><br />
            <mat-select formControlName="annualRevenue" required>
              <mat-option
                *ngFor="
                  let option of optionData.annualRevenueOptionData;
                  index as i
                "
                [value]="i"
                >{{ option }}</mat-option
              >
            </mat-select>
            <mat-error
              *ngIf="
                clientForm
                  .get('companyScale')
                  .get('annualRevenue')
                  .hasError('required')
              "
              >必須填寫</mat-error
            >
          </mat-form-field>
        </div>

        <div
          fxFlex="30"
          fxLayout="column"
          fxLayoutAlign="space-between stretch"
          class="selector-box"
        >
          <mat-form-field>
            <mat-label>總資產值（港元）</mat-label><br />
            <mat-select formControlName="assetValue" required>
              <mat-option
                *ngFor="
                  let option of optionData.assetValueOptionData;
                  index as i
                "
                [value]="i"
                >{{ option }}</mat-option
              >
            </mat-select>
            <mat-error
              *ngIf="
                clientForm
                  .get('companyScale')
                  .get('assetValue')
                  .hasError('required')
              "
              >必須填寫</mat-error
            >
          </mat-form-field>
        </div>

        <div
          fxFlex="30"
          fxLayout="column"
          fxLayoutAlign="space-between stretch"
          class="selector-box"
        >
          <mat-form-field>
            <mat-label>員工人數</mat-label><br />
            <mat-select formControlName="employeeCount" required>
              <mat-option
                *ngFor="
                  let option of optionData.employeeCountOptionData;
                  index as i
                "
                [value]="i"
                >{{ option }}</mat-option
              >
            </mat-select>
            <mat-error
              *ngIf="
                clientForm
                  .get('companyScale')
                  .get('employeeCount')
                  .hasError('required')
              "
              >必須填寫</mat-error
            >
          </mat-form-field>
        </div>

        <div
          fxFlex="30"
          fxLayout="column"
          fxLayoutAlign="space-between stretch"
          class="selector-box"
        >
          <mat-form-field>
            <mat-label>外判判頭、承辦商、分包商</mat-label><br />
            <mat-select formControlName="outsourceCount" required>
              <mat-option
                *ngFor="
                  let option of optionData.outsourceCountOptionData;
                  index as i
                "
                [value]="i"
                >{{ option }}</mat-option
              >
            </mat-select>
            <mat-error
              *ngIf="
                clientForm
                  .get('companyScale')
                  .get('outsourceCount')
                  .hasError('required')
              "
              >必須填寫</mat-error
            >
          </mat-form-field>
        </div>

        <div
          fxFlex="30"
          fxLayout="column"
          fxLayoutAlign="space-between stretch"
          class="selector-box"
        >
          <mat-form-field>
            <mat-label>尚未收取貿易應收帳數目<br />（公司年結當日）</mat-label
            ><br />
            <mat-select formControlName="outstandingClientCount" required>
              <mat-option
                *ngFor="
                  let option of optionData.outstandingClientCountOptionData;
                  index as i
                "
                [value]="i"
                >{{ option }}</mat-option
              >
            </mat-select>
            <mat-error
              *ngIf="
                clientForm
                  .get('companyScale')
                  .get('outstandingClientCount')
                  .hasError('required')
              "
              >必須填寫</mat-error
            >
          </mat-form-field>
        </div>

        <div
          fxFlex="30"
          fxLayout="column"
          fxLayoutAlign="space-between stretch"
          class="selector-box"
        >
          <mat-form-field>
            <mat-label>尚未支付貿易應付帳數目<br />（公司年結當日）</mat-label
            ><br />
            <mat-select formControlName="outstandingSupplierCount" required>
              <mat-option
                *ngFor="
                  let option of optionData.outstandingSupplierCountOptionData;
                  index as i
                "
                [value]="i"
                >{{ option }}</mat-option
              >
            </mat-select>
            <mat-error
              *ngIf="
                clientForm
                  .get('companyScale')
                  .get('outstandingSupplierCount')
                  .hasError('required')
              "
              >必須填寫</mat-error
            >
          </mat-form-field>
        </div>

        <div
          fxFlex="30"
          fxLayout="column"
          fxLayoutAlign="space-between stretch"
          class="selector-box"
        >
          <mat-form-field>
            <mat-label>董事及股東人數</mat-label><br />
            <mat-select formControlName="directorCount" required>
              <mat-option
                *ngFor="
                  let option of optionData.directorCountOptionData;
                  index as i
                "
                [value]="i"
                >{{ option }}</mat-option
              >
            </mat-select>
            <mat-error
              *ngIf="
                clientForm
                  .get('companyScale')
                  .get('directorCount')
                  .hasError('required')
              "
              >必須填寫</mat-error
            >
          </mat-form-field>
        </div>

        <div
          fxFlex="30"
          fxFlex.xs="80"
          fxLayout="column"
          fxLayoutAlign="space-between stretch"
          class="selector-box"
        >
          <mat-form-field>
            <mat-label
              >開戶銀行數目<br />
              （整年度總交易次數少於24次不計） </mat-label
            ><br />
            <mat-select formControlName="bankAccountCount" required>
              <mat-option
                *ngFor="
                  let option of optionData.bankAccountCountOptionData;
                  index as i
                "
                [value]="i"
                >{{ option }}</mat-option
              > </mat-select
            ><mat-error
              *ngIf="
                clientForm
                  .get('companyScale')
                  .get('bankAccountCount')
                  .hasError('required')
              "
              >必須填寫</mat-error
            >
          </mat-form-field>
        </div>

        <div
          fxFlex="30"
          fxLayout="column"
          fxLayoutAlign="space-between stretch"
          class="selector-box"
        >
          <mat-form-field>
            <mat-label>分店、辦公室、貨倉數目</mat-label><br />
            <mat-select formControlName="officeShopStorageCount" required>
              <mat-option
                *ngFor="
                  let option of optionData.officeShopStorageCountOptionData;
                  index as i
                "
                [value]="i"
                >{{ option }}</mat-option
              >
            </mat-select>
            <mat-error
              *ngIf="
                clientForm
                  .get('companyScale')
                  .get('officeShopStorageCount')
                  .hasError('required')
              "
              >必須填寫</mat-error
            >
          </mat-form-field>
        </div>
      </div>

      <!-- company capital -->
      <h3>公司物業及生財設施數量</h3>
      <div
        formGroupName="companyCapital"
        fxLayout="row wrap"
        fxLayoutAlign="center stretch"
        fxLayoutGap="15px grid"
        fxLayout.xs="column"
        fxLayoutAlign.xs="space-around stretch"
      >
        <div
          fxFlex="30"
          fxLayout="column"
          fxLayoutAlign="space-between stretch"
          class="selector-box"
        >
          <mat-form-field>
            <mat-label>物業數目</mat-label><br />
            <input
              matInput
              type="number"
              formControlName="propertyCount"
              min="0"
              required
            />
            <mat-error
              *ngIf="
                clientForm
                  .get('companyCapital')
                  .get('propertyCount')
                  .hasError('required')
              "
              >必須填寫</mat-error
            >
          </mat-form-field>
        </div>

        <div
          fxFlex="30"
          fxLayout="column"
          fxLayoutAlign="space-between stretch"
          class="selector-box"
        >
          <mat-form-field>
            <mat-label>汽車數目</mat-label><br />
            <input
              matInput
              type="number"
              formControlName="vehicleCount"
              min="0"
              required
            />
            <mat-error
              *ngIf="
                clientForm
                  .get('companyCapital')
                  .get('vehicleCount')
                  .hasError('required')
              "
              >必須填寫</mat-error
            >
          </mat-form-field>
        </div>

        <div
          fxFlex="30"
          fxLayout="column"
          fxLayoutAlign="space-between stretch"
          class="selector-box"
        >
          <mat-form-field>
            <mat-label>機器數目</mat-label><br />
            <input
              matInput
              type="number"
              formControlName="machineCount"
              min="0"
              required
            />
            <mat-error
              *ngIf="
                clientForm
                  .get('companyCapital')
                  .get('machineCount')
                  .hasError('required')
              "
              >必須填寫</mat-error
            >
          </mat-form-field>
        </div>
        <div
          fxFlex="30"
          fxLayout="column"
          fxLayoutAlign="space-between stretch"
          class="selector-box"
        >
          <mat-form-field>
            <mat-label>股票投資數目</mat-label><br />
            <input
              matInput
              type="number"
              formControlName="stockCount"
              min="0"
              required
            />
            <mat-error
              *ngIf="
                clientForm
                  .get('companyCapital')
                  .get('stockCount')
                  .hasError('required')
              "
              >必須填寫</mat-error
            >
          </mat-form-field>
        </div>

        <div
          fxFlex="30"
          fxLayout="column"
          fxLayoutAlign="space-between stretch"
          class="selector-box"
        >
          <mat-form-field>
            <mat-label>以租用形式使用之<br />物業單位或其他設施</mat-label
            ><br />
            <input
              matInput
              type="number"
              formControlName="rentalPropertyCount"
              min="0"
              required
            />
            <mat-error
              *ngIf="
                clientForm
                  .get('companyCapital')
                  .get('rentalPropertyCount')
                  .hasError('required')
              "
              >必須填寫</mat-error
            >
          </mat-form-field>
        </div>

        <div
          fxFlex="30"
          fxLayout="column"
          fxLayoutAlign="space-between stretch"
          class="selector-box"
        >
          <mat-form-field>
            <mat-label>待償還之貸款、債務及其他融資租貸</mat-label><br />
            <input
              matInput
              type="number"
              formControlName="loanCount"
              min="0"
              required
            />
            <mat-error
              *ngIf="
                clientForm
                  .get('companyCapital')
                  .get('loanCount')
                  .hasError('required')
              "
              >必須填寫</mat-error
            >
          </mat-form-field>
        </div>
      </div>

      <!-- document log -->
      <h3>財務文件記錄狀況</h3>
      <div
        formGroupName="documentLog"
        fxLayout="row wrap"
        fxLayoutAlign="center stretch"
        fxLayoutGap="15px grid"
        fxLayout.xs="column"
        fxLayoutAlign.xs="space-around stretch"
      >
        <div
          fxFlex="30"
          fxLayout="column"
          fxLayoutAlign="space-between stretch"
          class="selector-box"
        >
          <mat-form-field>
            <mat-label
              >銀行及現金流水帳，支票支出記錄，<br />
              收入及支出時序簿</mat-label
            ><br />
            <mat-select formControlName="cashflowPct" required>
              <mat-option
                *ngFor="
                  let option of optionData.cashflowPctOptionData;
                  index as i
                "
                [value]="i"
                >{{ option }}</mat-option
              > </mat-select
            ><mat-error
              *ngIf="
                clientForm
                  .get('documentLog')
                  .get('cashflowPct')
                  .hasError('required')
              "
              >必須填寫</mat-error
            >
          </mat-form-field>
        </div>

        <div
          fxFlex="30"
          fxLayout="column"
          fxLayoutAlign="space-between stretch"
          class="selector-box"
        >
          <mat-form-field>
            <mat-label>會計分類帳，總帳及明細帳<br /></mat-label><br />
            <mat-select formControlName="ledgerPct" required>
              <mat-option
                *ngFor="
                  let option of optionData.ledgerPctOptionData;
                  index as i
                "
                [value]="i"
                >{{ option }}</mat-option
              > </mat-select
            ><mat-error
              *ngIf="
                clientForm
                  .get('documentLog')
                  .get('ledgerPct')
                  .hasError('required')
              "
              >必須填寫</mat-error
            >
          </mat-form-field>
        </div>

        <div
          fxFlex="30"
          fxLayout="column"
          fxLayoutAlign="space-between stretch"
          class="selector-box"
        >
          <mat-form-field>
            <mat-label>銀行月結單（全年及年結後兩個月）<br /></mat-label><br />
            <mat-select formControlName="statementPct" required>
              <mat-option
                *ngFor="
                  let option of optionData.statementPctOptionData;
                  index as i
                "
                [value]="i"
                >{{ option }}</mat-option
              > </mat-select
            ><mat-error
              *ngIf="
                clientForm
                  .get('documentLog')
                  .get('statementPct')
                  .hasError('required')
              "
              >必須填寫</mat-error
            >
          </mat-form-field>
        </div>

        <div
          fxFlex="30"
          fxLayout="column"
          fxLayoutAlign="space-between stretch"
          class="selector-box"
        >
          <mat-form-field>
            <mat-label>收入成本發票及其銷貨購貨證明<br /></mat-label><br />
            <mat-select formControlName="invoicePct" required>
              <mat-option
                *ngFor="
                  let option of optionData.invoicePctOptionData;
                  index as i
                "
                [value]="i"
                >{{ option }}</mat-option
              > </mat-select
            ><mat-error
              *ngIf="
                clientForm
                  .get('documentLog')
                  .get('invoicePct')
                  .hasError('required')
              "
              >必須填寫</mat-error
            >
          </mat-form-field>
        </div>

        <div
          fxFlex="30"
          fxLayout="column"
          fxLayoutAlign="space-between stretch"
          class="selector-box"
        >
          <mat-form-field>
            <mat-label>年結日當天計最後十張<br />收入及成本發票</mat-label
            ><br />
            <mat-select formControlName="lastTenInvoice" required>
              <mat-option
                *ngFor="
                  let option of optionData.lastTenInvoiceOptionData;
                  index as i
                "
                [value]="i"
                >{{ option }}</mat-option
              > </mat-select
            ><mat-error
              *ngIf="
                clientForm
                  .get('documentLog')
                  .get('lastTenInvoice')
                  .hasError('required')
              "
              >必須填寫</mat-error
            >
          </mat-form-field>
        </div>

        <div
          fxFlex="30"
          fxLayout="column"
          fxLayoutAlign="space-between stretch"
          class="selector-box"
        >
          <mat-form-field>
            <mat-label>年結日後計首五張<br />收入及成本發票</mat-label><br />
            <mat-select formControlName="firstFiveInvoice" required>
              <mat-option
                *ngFor="
                  let option of optionData.firstFiveInvoiceOptionData;
                  index as i
                "
                [value]="i"
                >{{ option }}</mat-option
              > </mat-select
            ><mat-error
              *ngIf="
                clientForm
                  .get('documentLog')
                  .get('firstFiveInvoice')
                  .hasError('required')
              "
              >必須填寫</mat-error
            >
          </mat-form-field>
        </div>
      </div>

      <!-- client info -->
      <h3>客戶資料</h3>
      <div
        formGroupName="clientInfo"
        fxLayout="row wrap"
        fxLayoutAlign="center stretch"
        fxLayoutGap="15px grid"
        fxLayout.xs="column"
        fxLayoutAlign.xs="space-around stretch"
      >
        <p fxFlex="80">
          您的聯絡資料僅為本公司所存有，只作提供是次服務之用，且不作任何市場銷售之用。
          所有個案溝通，以至資料發放，均使用即時通訊軟件，電郵只作輔助，請確保您已提供正確的
          聯絡電話 及或電郵地址。
        </p>

        <div
          fxFlex="40"
          fxLayout="column"
          fxLayoutAlign="space-between stretch"
          class="selector-box"
        >
          <mat-form-field>
            <input
              matInput
              placeholder="聯絡人"
              formControlName="contactPerson"
              required
            /><mat-error
              *ngIf="
                clientForm
                  .get('clientInfo')
                  .get('contactPerson')
                  .hasError('required')
              "
              >必須填寫</mat-error
            >
          </mat-form-field>
        </div>

        <div
          fxFlex="40"
          fxLayout="column"
          fxLayoutAlign="space-between stretch"
          class="selector-box"
        >
          <mat-form-field>
            <input
              matInput
              placeholder="電郵"
              formControlName="contactEmail"
              required
            /><mat-error
              *ngIf="
                clientForm
                  .get('clientInfo')
                  .get('contactEmail')
                  .hasError('required')
              "
              >必須填寫</mat-error
            ><mat-error
              *ngIf="
                clientForm
                  .get('clientInfo')
                  .get('contactEmail')
                  .hasError('email')
              "
              >請填寫有效電郵格式</mat-error
            >
          </mat-form-field>
        </div>
        <div
          fxFlex="40"
          fxLayout="column"
          fxLayoutAlign="space-between stretch"
          class="selector-box"
        >
          <mat-form-field>
            <input
              matInput
              placeholder="聯絡電話"
              formControlName="contactNumber"
              maxlength="8"
              required
            /><mat-error
              *ngIf="
                clientForm
                  .get('clientInfo')
                  .get('contactNumber')
                  .hasError('required')
              "
              >必須填寫</mat-error
            ><mat-error
              *ngIf="
                clientForm
                  .get('clientInfo')
                  .get('contactNumber')
                  .hasError('pattern')
              "
              >請填寫有效電話號碼</mat-error
            >
          </mat-form-field>
        </div>

        <div
          fxFlex="40"
          fxLayout="column"
          fxLayoutAlign="space-between stretch"
          class="selector-box"
        >
          <mat-form-field>
            <input
              matInput
              placeholder="公司名稱"
              formControlName="companyName"
              required
            /><mat-error
              *ngIf="
                clientForm
                  .get('clientInfo')
                  .get('companyName')
                  .hasError('required')
              "
              >必須填寫</mat-error
            >
          </mat-form-field>
        </div>
      </div>

      <button
        mat-raised-button
        color="primary"
        type="submit"
        [disabled]="clientForm.invalid || submitting"
      >
        遞交
      </button>
      <br />
    </div>
  </form>
</div>
<!-- end of zh -->

<!-- en -->
<div *ngIf="lang === 'en'" fxLayout="column" fxLayoutAlign="center center">
  <div style="height: 100vh" fxLayout="column" fxLayoutAlign="center center">
    <p>Only Chinese Version Available</p>
  </div>
</div>
