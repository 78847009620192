import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { NewsComponent } from './news/news.component';
import { CalculatorComponent } from './calculator/calculator.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ComSecComponent } from './com-sec/com-sec.component';
import { OpenHongKongBankComponent } from './open-hong-kong-bank/open-hong-kong-bank.component';
import { PatentComponent } from './patent/patent.component';
import { RegisterLimitedCompanyFormComponent } from './register-limited-company-form/register-limited-company-form.component';
import { TrademarkComponent } from './trademark/trademark.component';
import { OpenSingaporeBankComponent } from './open-singapore-bank/open-singapore-bank.component';
import { GovFundsComponent } from './gov-funds/gov-funds.component';
import { SmartLineComponent } from './smart-line/smart-line.component';
import { AccountTaxComponent } from './account-tax/account-tax.component';
import { AnnualMaintenanceComponent } from './annual-maintenance/annual-maintenance.component';
import { CsrComponent } from './csr/csr.component';
import { CorporateFinanceComponent } from './corporate-finance/corporate-finance.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { InvoiceTemplateComponent } from './invoice-template/invoice-template.component';
import { NgoComponent } from './ngo/ngo.component';

// establish companies
import { HongkongComponent } from './hongkong/hongkong.component';
import { OverseasComponent } from './overseas/overseas.component';
import { PrcComponent } from './prc/prc.component';
import { TaiwanComponent } from './taiwan/taiwan.component';
import { SingaporeComponent } from './singapore/singapore.component';
import { VietnamComponent } from './vietnam/vietnam.component';
import { SamoaComponent } from './samoa/samoa.component';
import { BviComponent } from './bvi/bvi.component';
import { CaymanComponent } from './cayman/cayman.component';
import { VirtualOfficeComponent } from './virtual-office/virtual-office.component';

import { RegisterHongKongLimitedCompanyComponent } from './register-hong-kong-limited-company/register-hong-kong-limited-company.component';
import { RegisterHongKongUnlimitedCompanyComponent } from './register-hong-kong-unlimited-company/register-hong-kong-unlimited-company.component';

// company secretary
import { CompanySecretaryServiceComponent } from './company-secretary-service/company-secretary-service.component';
import { ThkCloudSecretaryComponent } from './thk-cloud-secretary/thk-cloud-secretary.component';
import { LiquidationComponent } from './liquidation/liquidation.component';

import { TvpComponent } from './tvp/tvp.component';
import { BudComponent } from './bud/bud.component';
import { TransferCompanySharesComponent } from './transfer-company-shares/transfer-company-shares.component';
import { AccountingServiceComponent } from './accounting-service/accounting-service.component';
import { LimitedCompanyTaxComponent } from './limited-company-tax/limited-company-tax.component';
import { UnlimitedCompanyTaxComponent } from './unlimited-company-tax/unlimited-company-tax.component';
import { CompanyTaxComponent } from './company-tax/company-tax.component';

import { Loan100Component } from './loan100/loan100.component';
import { Loan8090Component } from './loan8090/loan8090.component';
import { UnlimitedCompanyToLimitedCompanyComponent } from './unlimited-company-to-limited-company/unlimited-company-to-limited-company.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  // establish companies
  { path: 'hongkong', component: HongkongComponent },
  // establish companies / hong kong companies
  {
    path: 'register-hong-kong-limited-company',
    component: RegisterHongKongLimitedCompanyComponent,
  },
  {
    path: 'register-hong-kong-unlimited-company',
    component: RegisterHongKongUnlimitedCompanyComponent,
  },
  { path: 'ngo', component: NgoComponent },
  // establish companies / overseas companies
  { path: 'overseas', component: OverseasComponent },
  { path: 'prc', component: PrcComponent },
  { path: 'taiwan', component: TaiwanComponent },
  { path: 'singapore', component: SingaporeComponent },
  { path: 'vietnam', component: VietnamComponent },
  { path: 'samoa', component: SamoaComponent },
  { path: 'bvi', component: BviComponent },
  { path: 'cayman', component: CaymanComponent },
  // accounting service
  { path: 'accounting-service', component: AccountingServiceComponent },
  // company tax
  { path: 'company-tax', component: CompanyTaxComponent },
  { path: 'limited-company-tax', component: LimitedCompanyTaxComponent },
  { path: 'unlimited-company-tax', component: UnlimitedCompanyTaxComponent },
  // company secretary
  {
    path: 'company-secretary-service',
    component: CompanySecretaryServiceComponent,
  },
  { path: 'thk-cloud-secretary', component: ThkCloudSecretaryComponent },
  { path: 'liquidation', component: LiquidationComponent },
  // trademark
  { path: 'trademark', component: TrademarkComponent },
  //corporate finance
  { path: 'corporate-finance', component: CorporateFinanceComponent },
  // open bank account
  { path: 'open-hong-kong-bank', component: OpenHongKongBankComponent },
  { path: 'open-singapore-bank', component: OpenSingaporeBankComponent },
  // gov funds
  { path: 'tvp', component: TvpComponent },
  { path: 'bud', component: BudComponent },
  // virtual office
  { path: 'virtual-office', component: VirtualOfficeComponent },
  // news
  { path: 'news', component: NewsComponent },
  // csr
  { path: 'csr', component: CsrComponent },

  {
    path: 'transfer-company-shares',
    component: TransferCompanySharesComponent,
  },
  { path: '100-loan-guarantee', component: Loan100Component },
  { path: '80-90-guarantee-product', component: Loan8090Component },
  {
    path: 'unlimited-company-to-limited-company',
    component: UnlimitedCompanyToLimitedCompanyComponent,
  },
  // archive
  { path: 'calculator', component: CalculatorComponent },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'annual-maintenance', component: AnnualMaintenanceComponent },
  { path: 'com-sec', component: ComSecComponent },
  { path: 'patent', component: PatentComponent },
  {
    path: 'register-limited-company-form',
    component: RegisterLimitedCompanyFormComponent,
  },
  { path: 'gov-funds', component: GovFundsComponent },
  { path: 'smart-line', component: SmartLineComponent },
  { path: 'account-tax', component: AccountTaxComponent },
  { path: 'invoice', component: InvoiceComponent },
  { path: 'invoice-template', component: InvoiceTemplateComponent },
  // {path: 'whatsapp', resolve: { URL:}} s
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
