<div class="plan-head-top">
    <h2>{{ priceChartData.title }}</h2>
  </div>
  
  <div class="plan-head-bottom">
    <div class="plan-price">
      <span class="plan-currency">{{ priceChartData.currency }}</span
      ><span class="plan-amount">{{ priceChartData.amount }}</span>
    </div>
    <p *ngIf="priceChartData" class="plan-desc">
      {{ priceChartData.highlight_sub_title }}
    </p>
    <p>{{ priceChartData.sub_title }}</p>
  </div>
  
  <div class="plan-body">
    <p *ngFor="let content of priceChartData.body">{{ content }}</p>
  </div>
  
  <div class="plan-foot">
    <!-- <a mat-button (click)="openDialog(priceChartData.service)"> -->
      <a mat-button href="https://firebasestorage.googleapis.com/v0/b/tai-hong-kong-platform.appspot.com/o/spreadsheets%2FTHK_NNC1.xlsx?alt=media&token=2acebd2f-6429-41d6-b03c-81d612a0d69f">
        
      <!-- <a mat-button href="/assets/forms/THK_NCC1.xlsx" download> -->
      <h2>{{ priceChartData.action_button_text }}</h2>
    </a>
  </div>
  