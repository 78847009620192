<!-- zh -->
<div *ngIf="lang === 'zh'" fxLayout="column" fxLayoutAlign="center stretch">
  <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
    <img
      src="assets/images/limited-company-tax.jpg"
      alt="limited company tax"
      class="banner-image"
    />
  </div>
  <section
    fxLayout="column"
    fxLayoutAlign="center"
    style="background-color: white; padding: 0 10%"
  >
    <h2>有限公司報稅</h2>
    <h2>有限公司報稅期限</h2>
    <h3>公司利得稅報稅表</h3>
    <p>
      公司利得稅報稅表於每年四月初由稅務局分發給各合夥公司和法團，遞交公司利得稅報稅表的截止日期會因應不同公司的不同年結日而有所不同。標準的公司報稅時間及報稅期限如下：
    </p>

    <div fxLayout="row" fxLayoutAlign="space-between">
      <div fxFlex="40">
        <h3>新成立公司首次報稅<br/>時間計算機</h3>
        <mat-form-field appearance="fill">
          <mat-label>成立月份</mat-label>
          <mat-select
            [(ngModel)]="companyEstablishMonth"
            (selectionChange)="onMonthChange($event)"
          >
            <mat-option
              *ngFor="let m of monthStringArray; index as i"
              [value]="i"
            >
              {{ m }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>成立年份</mat-label>
          <mat-select
            [(ngModel)]="companyEstablishYear"
            (selectionChange)="onYearChange($event)"
          >
            <mat-option *ngFor="let y of yearStringArray" [value]="y">
              {{ y }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <p>
          計算收到首份公司報稅表：<strong *ngIf="monthYear1">{{
            monthYear1
          }}</strong>
        </p>
        <p>
          計算收到首份公司報稅表報稅期限：<strong *ngIf="monthYear2">{{
            monthYear2
          }}</strong>
        </p>
      </div>
      <div fxLayout="column" fxFlex="40">
        <h3>公司非首次報稅<br/>時間計算機</h3>
        <mat-form-field appearance="fill" fxFlex="100">
          <mat-label>選擇會計年度終結日</mat-label>
          <mat-select [(ngModel)]="selectedPeriodEndDate">
            <mat-option *ngFor="let p of periodEnds" [value]="p">
              {{ p }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <p>
          你的報稅期限：<strong>{{ periodData[selectedPeriodEndDate] }}</strong>
        </p>

        <!-- <h3>公司利得稅報稅表</h3>
        <p>
          公司利得稅報稅表於每年四月初由稅務局分發給各合夥公司和法團，遞交公司利得稅報稅表的截止日期會因應不同公司的不同年結日而有所不同。標準的公司報稅時間及報稅期限如下：
        </p> -->
      </div>
    </div>

    <p></p>
  </section>

  <section
    fxLayout="column"
    fxLayoutAlign="center"
    style="background-color: whitesmoke; padding: 0 10%"
  >
    <p></p>
    <h3>報稅延期稅務服務</h3>
    <p>
      大香港熟悉稅務條例及香港稅局的運作實務，明白稅務期限遲了的嚴重性，我們樂意助你免費申請報稅期限延期。
    </p>
    <div style="height: 3rem; display: flex">
      <img
        src="assets/images/free-tax-extend.jpg"
        style="height: 100%; align-self: center; margin-right: 2rem;"
        
      />
      <span style="align-self: center"><a href="https://wa.me/85295126339">按此獲得免費稅務延期</a></span>
    </div>
    <h3>過期遞交報稅表之刑罰</h3>
    <p>
      若有關人仕不能在指定日期遞交報稅表，稅務局將會發出該年度評估應課稅稅單及納稅人則將根據稅務條例第８０（２）條或第８２Ａ條遭受刑罰及訴訟，納稅人最高可被罰款二萬五千元及不超過有關稅項的三倍作額外稅款。
    </p>
   
    <p></p>
  </section>

  <section
    fxLayout="column"
    fxLayoutAlign="center"
    style="background-color: white; padding: 0 10%"
  >
    <p></p>
    <h2>有限公司報稅常見問題</h2>
    <p><strong>為什麼公司本年度没有收到公司報稅表？正常嗎？ </strong></p>
    <p>
      如公司業務未有賺取應評稅利潤，稅局一般會連續3個課稅年度停止發出公司報稅表，稅局
      也不會要求納稅人每年提交利得稅報稅表。
      若稅務局在覆查該等納稅人的潛在稅項責任時，會向其發出利得稅報稅表，納稅人則仍須遵照規定填交報稅表。
    </p>
    <p>
      <strong>如没有收到報稅表，是否表示該年度可以不做審計及會計帳？ </strong>
    </p>
    <p>
      不能。因為根據公司法例第430條規定公司必需於每年度的週年股東大會召開前21日將財務報表送達給股東傳閱。另外如財務報告反映公司當年度有應評稅利潤（抵銷任何承前虧損前），公司必須在公司會計基期結束後4個月內以書面通知稅務局，如未有遵守規定，可能會被罰款。
    </p>
    <p>
      <strong
        >有限公司無收入是否要報稅？如何減低没有營運公司維護成本？
      </strong>
    </p>
    <p>
      大香港關注市場變化, 現時商業登記費用上調至2150元,
      令很多没有營運公司維護成本上升。 我們創先驅創立有限公司零申報套餐,
      有限公司零申報全包價, 每年基本一個價錢$1680包晒每間香港有限公司面對稅局及公司法所需事項。
    </p>
    <p>
      <strong
        >公司只持有物業没有收入，還需要執業會計師作審計嗎？可以作零申報報稅嗎？
      </strong>
    </p>
    <p>
      關於零申報的定義, 必須同時滿足以下條件適用。無論如何,
      大香港還是建議每間公司根據《香港公司法》每年編製審計報告。
    </p>
    <ol>
      <li>沒有購買任何物業、資產</li>
      <li>除了作維護公司必須付的費用外，沒有任何收支出入記錄</li>
      <li>沒有經營任何業務,（包括香港本地業務和海外業務）</li>
    </ol>
    <p></p>
</section>
<section
fxLayout="column"
fxLayoutAlign="center"
style="background-color: whitesmoke; padding: 0 10%"
>
    <h3>免責聲明</h3>
    <p>
      本計算機只作參考用途，一切資料及報稅期限以香港稅務局按稅務條例規定及其最新公布為準。
      使用者建議應獨立向稅務局或其受監管機構確定其報稅期限及公司報稅時間。
    </p>
   <p></p>
  </section>
</div>
<!-- end of zh -->

<!-- en -->
<div *ngIf="lang === 'en'" fxLayout="column" fxLayoutAlign="center center">
  <div style="height: 100vh" fxLayout="column" fxLayoutAlign="center center">
    <p>Only Chinese Version Available</p>
  </div>
</div>
<!-- end of en -->
