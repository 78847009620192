<!-- zh -->
<div *ngIf="lang === 'zh'" fxLayout="column" fxLayoutAlign="center stretch">
  <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
    <img
      src="assets/images/company-tax.jpg"
      alt="company tax"
      class="banner-image"
    />
    <div class="banner-text">
      <p>公司報稅服務<br/>
        不想手尾長！！！<br/>
        應聘請專業合規稅務顧問</p>
    </div>
  </div>

  <section
    fxLayout="column"
    fxLayoutAlign="center"
    style="background-color: white; padding: 0 10%"
  >
    <p>公司報稅服務</p>
    <p>大香港企業服務是一所全方位支援本地中小企業的報稅中心。</p>
    <p style="text-align: center; font-size: 1.4rem; line-height: 2rem">
      <strong>數據</strong>        <br />
      <img
      src="assets/images/company-secretary-service/listed_co.png"
      alt="listed company"
      style="height: 2rem; margin: 1rem; vertical-align: middle"
    />企業客戶 456<br />
    <img
    src="assets/images/company-secretary-service/sme_co.png"
    alt="listed company"
    style="height: 2rem; margin: 1rem; vertical-align: middle"
  />完成個案 3328<br />
  <img
  src="assets/images/company-secretary-service/on_time.png"
  alt="listed company"
  style="height: 2rem; margin: 1rem; vertical-align: middle"
/>行業經驗 30年<br />
<img
src="assets/images/company-secretary-service/professional.png"
alt="listed company"
style="height: 2rem; margin: 1rem; vertical-align: middle"
/> 稅務專家 13位<br />
    </p>
    <p>我們提供</p>

    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center">
      <mat-card
        fxFlex="25"
        fxFlex.xs="80"
        fxLayout="column"
        fxLayoutAlign="center"
      >
        <mat-card-content>
          <img src="assets/images/tax-1.jpg" />
        </mat-card-content>
        <button
          mat-raised-button
          routerLink="/limited-company-tax"
          color="primary"
        >
          有限公司報稅
        </button>
      </mat-card>
      <mat-card
        fxFlex="25"
        fxFlex.xs="80"
        fxLayout="column"
        fxLayoutAlign="center"
      >
        <mat-card-content>
          <img src="assets/images/tax-2.jpg" />
        </mat-card-content>
        <button
          mat-raised-button
          routerLink="/unlimited-company-tax"
          color="primary"
        >
          獨資無限公司報稅
        </button>
      </mat-card>
      <mat-card
        fxFlex="25"
        fxFlex.xs="80"
        fxLayout="column"
        fxLayoutAlign="center"
      >
        <mat-card-content>
          <img src="assets/images/tax-3.jpg" />
        </mat-card-content>
        <button
          mat-raised-button
          routerLink="/unlimited-company-tax"
          color="primary"
        >
          合資無限公司報稅
        </button>
      </mat-card>
    </div>

    <!-- <img src="assets/images/tax-1.jpg" />
    <a routerLink="/limited-company-tax">有限公司報稅</a>
    <img src="assets/images/tax-2.jpg" />
    <a routerLink="/unlimited-company-tax">獨資無限公司報稅</a>
    <img src="assets/images/tax-3.jpg" />
    <a routerLink="/unlimited-company-tax">合資無限公司報稅</a>
     -->
    <p>除有限公司利得稅及無限公司利得稅外，我們提供其他全面專業稅務服務：</p>
    <ul>
      <li>申報”僱主填報的薪酬及退休金報稅表”</li>
      <li>申報”個人薪俸報稅表”</li>
      <li>申報”物業稅報稅表”</li>
      <li>稅務計劃顧問及建議</li>
      <li>豁免離岸收入諮詢</li>
      <li>反對估稅及稅務延期</li>
      <li>處理香港稅務局稅務調查</li>
      <li>稅務居民身份申請</li>
    </ul>
    <p>
      所有在香港成立的公司均必須保存清楚的業務紀錄，以及製作週年會計帳目和財務報表。香港公司帳目及原始票據需要保存七年，以備香港稅務局隨時檢查。
    </p>
    <p>
      我們針對香港稅務局要求及國際認可的會計準則，為客戶提供專業稅務服務、諮詢及稅務計劃服務。為防止中介註冊、非專業或假會計師事務所在做帳報稅時出現問題，導致公司蒙受經濟上的損失，在做帳報稅時應請真正專業人士(尤其香港會計師事務所或執業會計師)處理及提供合理合法稅務服務，才有權益保證。
    </p>

    <h2>專業合規稅務服務 vs 非專業稅務服務</h2>

    <h3>真實案例1</h3>
    <p>背景資料：</p>
    <ol>
      <li>公司某一課稅年度用了四十萬港元裝修開支</li>
      <li>公司營運狀況是虧損五十多萬港元</li>
    </ol>
    <p><strong>專業合規稅務顧問</strong></p>
    <p>
      按照國際會計準則把裝修支出認作公司資產，並每年度為客戶申索稅務折舊。因本身客戶營運狀況是虧損五十多萬港元，扣除稅務折舊後客戶依然是課稅虧損及持有三十多萬未抵扣的稅務折舊給公司往後扣除
    </p>

    <p><strong>非專業合規稅務顧問</strong></p>
    <p>
      因時間成本關係不按照國際會計準則把裝修支出認作公司資產，並當成是客戶當年度費用支出。於報稅時把該費用調整為非可扣稅費用，因客戶營運狀況已虧損五十多萬港元，不扣除裝修支出後也是不用繳稅，可是該四十萬港元公司真金百銀付出的支出便會消失，永遠不能抵扣。
    </p>
    <p>
      以上例子是由於很多時客戶只看繳稅款金額而忽略了課稅虧損的金額而未有察覺到非專業合規稅務顧問對客戶的傷害性。
    </p>

    <h3>真實案例2</h3>
    <p>背景資料：</p>
    <ol>
      <li>公司主要業務為國際貿易</li>
      <li>公司在香港没有聘請員工，辦公室地址為秘書公司提供的註冊地址</li>
    </ol>
    <p><strong>專業合規稅務顧問</strong></p>
    <p>
      審視貿易文件如貨件的供應商/
      輸出點及買家/接收點交易過程中的發票、運輸文件、報關單、運輸過程中貨件有沒有存放於香港、在香港有没有代理人、在那裡/是怎樣/跟誰產生業務生意。綜合以上各點後衡量客戶是否屬於離岸收入。
    </p>
    <p><strong>非專業合規稅務顧問</strong></p>
    <p>
      因時間成本關係没有審視客戶的貿易文件，只按客戶當年度的利潤決定是否離岸收入。如當年度客戶利潤金額大便會替其申報離岸收入，虧損年度便會申報為香港收入。
    </p>
    <p>
      以上例子是由於很多時客戶只看繳稅款金額及對稅務顧問的倚賴而忽略了稅務顧問的專業性及其錯誤的處理手法能對客戶的傷害性。
    </p>
    <p></p>
  </section>
</div>
<!-- end of zh -->

<!-- en -->
<div *ngIf="lang === 'en'" fxLayout="column" fxLayoutAlign="center center">
  <div style="height: 100vh" fxLayout="column" fxLayoutAlign="center center">
    <p>Only Chinese Version Available</p>
  </div>
</div>
<!-- end of en -->
