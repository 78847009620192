import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  
  lang: string;
  constructor(public translateService: TranslateService) {

    // this.translateService.onLangChange.subscribe(event => console.log(event));
    this.useLang('zh');
    
  }


  useLang(lang) {
    // console.log('use lang', lang);
    this.translateService.use(lang);
  }
}
