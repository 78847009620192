import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { RequestServiceDialogComponent } from '../request-service-dialog/request-service-dialog.component';
import * as PLANS from '../../assets/data/ngo-price-data.json';
import { PriceChartData } from '../interfaces/price-chart-data';

@Component({
  selector: 'app-ngo',
  templateUrl: './ngo.component.html',
  styleUrls: ['./ngo.component.scss'],
})
export class NgoComponent implements OnInit {
  
  lang: string;
  public plans: { [key: string]: PriceChartData } = (PLANS as any).default;

  constructor(
    public dialog: MatDialog,
    public translateService: TranslateService
  ) {
    this.lang = this.translateService.currentLang;
    this.translateService.onLangChange.subscribe(
      (event) => (this.lang = event.lang)
    );
  }

  ngOnInit(): void {}

  openDialog(service: string): void {
    const requestServiceDialogRef = this.dialog.open(
      RequestServiceDialogComponent,
      {
        data: {
          name: '',
          contact_number: '',
          email: '',
          service_requested: service,
          // service_name: service,
        },
      }
    );
    requestServiceDialogRef.afterClosed().subscribe((result) => {
      console.log('dialog closed');
      console.log('result', result);
    });
  }
}
