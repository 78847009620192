<div class="plan-head-top">
  <h2>{{ priceChartData.title }}</h2>
</div>

<div class="plan-body">
  <p *ngFor="let content of priceChartData.body">{{ content }}</p>
</div>

<div class="plan-foot">
  <!-- <a mat-button (click)="openDialog(priceChartData.service)"> -->
    <a mat-button href="https://firebasestorage.googleapis.com/v0/b/tai-hong-kong-platform.appspot.com/o/spreadsheets%2FVirtualOffice.xlsx?alt=media&token=af9ef956-b75d-4b9a-8e41-90c7999d0a02">
  <!-- <a mat-button href="/assets/forms/VirtualOffice.xlsx" download> -->
    <h2>{{ priceChartData.action_button_text }}</h2>
  </a>
</div>
