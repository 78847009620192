<!-- zh -->
<div *ngIf="lang === 'zh'" fxLayout="column" fxLayoutAlign="center stretch">
  <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
    <img src="assets/images/tvp-1.jpg" alt="tvp fund" class="banner-image" />
    <div class="banner-text">
      <!-- <p style="color: #3f3e38; font-weight: bold; font-size: 8vw">
          成立海外公司
        </p>-->
      <p>科技券顧問 科技券審計</p>
    </div>
  </div>

  <section
    fxLayout="column"
    fxLayoutAlign="center"
    style="background-color: white; padding: 0 10%"
  >
    <p></p>

    <h2>科技券計劃 (TVP) – 助企業善用科技，把握商機</h2>
    <p>
      創新科技署推出「科技券計劃」後，已准核2000+項目，資助金額超過3億。自2020年4月1日起資助上限已由四十萬元增加至六十萬元，申請資格亦已放寬。
      大香港致力與客戶由科技劵申請到為客戶提供優質科技券服務供應商及完成科技系統項目每個階層及過程都與客戶同行。
      不少客戶已透過科技券計劃，利用全面的軟件方案提高生產力及競爭優勢，加快升級轉型。有關科技券資訊及報價詳情，歡迎聯絡我們查詢。
    </p>
  </section>

  <section
    fxLayout="column"
    fxLayoutAlign="center"
    style="background-color: whitesmoke; padding: 0 10%"
  >
    <h1 style="text-align: center">為何我們是客戶理想的科技券顧問選擇？</h1>
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center">
      <div
        fxFlex="25"
        fxLayout="column"
        fxLayoutAlign="start center"
        fxFlex.xs="100"
        style="margin: 10px"
      >
        <img src="assets/images/icons/tvp-trust.png" alt="icon" />
        <h1>信譽良好</h1>
        <p>豐富系統開發及實施經驗，眾多成功案例、 涵蓋行業廣泛</p>
      </div>
      <div
        fxFlex="25"
        fxLayout="column"
        fxLayoutAlign="start center"
        fxFlex.xs="100"
        style="margin: 10px"
      >
        <img src="assets/images/icons/tvp-professional.png" alt="icon" />
        <h1>專業顧問</h1>
        <p>強大研發及支援團隊，熟悉科技券申請程序、 優質售後服務</p>
      </div>
      <div
        fxFlex="25"
        fxLayout="column"
        fxLayoutAlign="start center"
        fxFlex.xs="100"
        style="margin: 10px"
      >
        <img src="assets/images/icons/tvp-total.png" alt="icon" />
        <h1>全面方案</h1>
        <p>多元化科技產品及方案， 可按客戶需求度身設計、迎合本地經營環境</p>
      </div>
    </div>
  </section>

  <section
    fxLayout="column"
    fxLayoutAlign="center"
    style="background-color: white; padding: 0 10%"
  >
    <h1 style="text-align: center">科技券申請程序</h1>
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center">
      <div
        fxFlex="20"
        fxLayout="column"
        fxLayoutAlign="start center"
        fxFlex.xs="100"
        style="margin: 10px"
      >
        <img src="assets/images/icons/tvp-apply-doc.png" alt="icon" />
        <h1>提交文件</h1>
        <p>提交申請並附上所需文件</p>
      </div>
      <div
        fxFlex="20"
        fxLayout="column"
        fxLayoutAlign="start center"
        fxFlex.xs="100"
        style="margin: 10px"
      >
        <img src="assets/images/icons/tvp-apply-sign.png" alt="icon" />
        <h1>簽署協議</h1>
        <p>成功申請者須在展開項目前 簽署資助協議</p>
      </div>
      <div
        fxFlex="20"
        fxLayout="column"
        fxLayoutAlign="start center"
        fxFlex.xs="100"
        style="margin: 10px"
      >
        <img src="assets/images/icons/tvp-apply-report.png" alt="icon" />
        <h1>提交報告</h1>
        <p>項目在12個月內完成後， 提交項目最終報告</p>
      </div>
      <div
        fxFlex="20"
        fxLayout="column"
        fxLayoutAlign="start center"
        fxFlex.xs="100"
        style="margin: 10px"
      >
        <img src="assets/images/icons/tvp-apply-fund.png" alt="icon" />
        <h1>發放資助</h1>
        <p><br /></p>
      </div>
    </div>
  </section>

  <section
    fxLayout="column"
    fxLayoutAlign="center"
    style="background-color: whitesmoke; padding: 0 10%"
  >
    <h1 style="text-align: center">企業申請科技券資格</h1>

    <p style="text-align: center">
      中小企運用科技服務方案提高生產力與競爭力，加快升級轉型
    </p>
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center">
      <div
        fxFlex="40"
        fxLayout="column"
        fxLayoutAlign="start center"
        fxFlex.xs="100"
        style="margin: 10px"
      >
        <img src="assets/images/icons/tvp-operation.png" alt="icon" />
        <h1>業務運作</h1>
        <p>提交申請時須在本港有實質業務運作，而該業務須與申請項目相關</p>
      </div>
      <div
        fxFlex="40"
        fxLayout="column"
        fxLayoutAlign="start center"
        fxFlex.xs="100"
        style="margin: 10px"
      >
        <img src="assets/images/icons/tvp-br.png" alt="icon" />
        <h1>商業登記</h1>
        <p>根據《商業登記條例》(第310章)在香港登記</p>
      </div>
    </div>
  </section>

  <!-- <section
    fxLayout="row"
    fxLayoutAlign="center"
    style="background-color: whitesmoke"
  >
    <h1 style="text-align: center">中小企業解決方案</h1>

    <div
      fxFlex="40"
      fxFlex.xs="100"
      fxLayoutAlign.xs="center stretch"
      fxLayout.xs="column"
      style="margin: 10px"
    ></div>
    <div
      fxFlex="40"
      fxFlex.xs="100"
      fxLayoutAlign.xs="center stretch"
      fxLayout.xs="column"
      style="margin: 10px"
    ></div>
  </section> -->

  <section
    fxLayout="column"
    fxLayoutAlign="center"
    style="background-color: white; padding: 0 10%"
  >
    <h1>TVP科技券審計服務 – TVP Audit</h1>
    <p>
      科技券審計 (Technology Voucher Programme) – TVP Audit
      根據創新科技處規定，所有參與科技券計劃的項目，如核准資助額超過五萬港元，須向創新科技署提交由獨立審計師編製的經審計項目支出表，大香港於提供此項服務具有相當經驗。
    </p>
    <ul>
      <li>
        科技券每家合資格企業／機構提供最多 60 萬港元資助，是以 3:1 的配對模式
      </li>
      <li>每個項目一般應在12個月內完成</li>
      <li>每名申請者最多可獲批六個項目</li>
      <li>
        創新科技署會在核實有關文件後將不超過項目核准資助額
        25%的首期撥款存入同一銀行帳戶。終期撥款將於項目完成及項目最終報告獲創新科技署接納後發放予申請者
      </li>
      <li>
        如希望獲發首期撥款，須在以企業／機構的名義開設及維持獨立的無風險計息港元帳戶，此帳戶只可處理與項目相關的所有收入及開支
      </li>
      <li>科技券計畫資助款項可用以支付相關的審計費用</li>
    </ul>

    <h1>審計費用統一為 HK$3000</h1>
    <h2>
      **** 所有經審核TVP項目支出表均由香港會計師公會註冊執業會計師(TVP Auditor
      獨立審計師)所發出***
    </h2>
    <p>我們的服務計劃:</p>
    <ul>
      <li>審計師發出之項目支出表</li>
      <li>協助 TVP 相關申報及科技券審計項目查詢</li>
      <li>審計費用統一為 HK$3000</li>
    </ul>
    <p>
      *我們已處理很多相關審計項目, 為順利安排完成相關審核及資助申報,
      我們將先收取一半作為行政費用，行政費將從審計及編製審計報告之費用中扣除。
    </p>
    <p>
      如果您想索取我們官方審計報價和誠信及不合謀報價，請電郵/上傳以下文件給我們:
    </p>
    <p>電郵 /上傳 以下文件給我們</p>
    <ul>
      <li>有效的商業登記證(BR)</li>
      <li>申請人身分證</li>
      <li>科技券項目名稱</li>
    </ul>
    <!-- <p>
      電郵到 chengzitin@taihk.com.hk並註名科技券審計 , 或直接WhatsApp<img
        style="width: 1rem"
        src="assets/images/svg/whatsapp.svg"
        alt="whatsapp-logo"
      />上傳文件。
    </p> -->
    <app-whatsapp [lang]="'zh'"></app-whatsapp>
  </section>
</div>
<!-- end of zh -->

<!-- en -->
<div *ngIf="lang === 'en'" fxLayout="column" fxLayoutAlign="center center">
  <div style="height: 100vh" fxLayout="column" fxLayoutAlign="center center">
    <p>Only Chinese Version Available</p>
  </div>
</div>
<!-- end of en -->
