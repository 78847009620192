<!-- zh -->
<section
  fxLayout="row"
  fxLayout.xs="column"
  fxLayoutAlign="center center"
  style="background-color: white; width: 100vw; margin: 2rem auto"
>
  <div fxFlex="30" style="aspect-ratio: 1/1; margin: 2rem">
    <a href="https://wa.me/85295126339" target="_blank">
      <img
        src="assets/images/free-consult.jpg"
        alt="free consultation"
        style="width: 100%"
      />
    </a>
  </div>
  <div
    fxLayout="column"
    fxLayoutAlign="center center"
    fxFlex="30"
    style="
      margin: 2rem;
    "
  >
    <p style="font-size: 1.6rem">立即免費查詢</p>
    <button
      style="
        background-color: #1bd741;
        border: none;
        width: 80%;
        border-radius: 2rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      "
      (click)="gotoWhatsapp()"
    >
      <span style="display: inline; margin-right: 1rem"
        ><img
          style="height: 4rem"
          src="assets/images/whatsapp.png"
          alt="whatsapp"
      /></span>
      <p style="font-size: 1.4rem; font-weight: 100">whatsapp 聯絡</p>
    </button>
  </div>
</section>
<div
  *ngIf="lang === 'zh'"
  fxLayout="row"
  fxLayout.xs="column"
  fxLayoutAlign="center stretch"
  class="footer"
>
  <div
    fxFlex="30"
    fxFlex.xs="80"
    fxLayout="column"
    fxLayoutAlign="start center"
  >
    <p>聯絡我們</p>
    <hr />

    <p><mat-icon>phone</mat-icon></p>
    <p>+852 3611 5771</p>
    <p>+852 3611 5772</p>

    <p><mat-icon>smartphone</mat-icon></p>
    <p>+852 9512 6339</p>

    <p><mat-icon>email</mat-icon></p>
    <p>info@taihk.com.hk</p>
  </div>

  <div
    fxFlex="30"
    fxFlex.xs="80"
    fxLayout="column"
    fxLayoutAlign="start center"
  >
    <p>辦事處</p>
    <hr />
    <p><i>至專</i>香港企業服務平台</p>
    <p>辦事處</p>
    <p>香港中環皇后大道中168號6樓1室</p>
  </div>
  <div
    fxFlex="30"
    fxFlex.xs="80"
    fxLayout="column"
    fxLayoutAlign="start center"
  >
    <p>追隨我們</p>
    <hr />
    <div>
      <span>
        <a href="https://www.instagram.com/tai.hongkong/">
          <img
            src="assets/images/svg/instagram-brands.svg"
            class="social-media-icon"
        /></a>
      </span>
      <span>
        <a
          href="https://www.facebook.com/%E5%A4%A7%E9%A6%99%E6%B8%AF%E4%BC%81%E6%A5%AD%E6%9C%8D%E5%8B%99%E5%B9%B3%E5%8F%B0-108400744779149/"
        >
          <img
            src="assets/images/svg/facebook-brands.svg"
            class="social-media-icon"
          />
        </a>
      </span>
      <span>
        <a
          href="https://www.youtube.com/channel/UCZGYC3C-0Mz9gO3UQJn0wnw/"
        >
          <img
            src="assets/images/svg/youtube-brands.svg"
            class="social-media-icon"
          />
        </a>
      </span>
    </div>
  </div>
</div>
<!-- end of zh -->

<!-- en -->
<div
  *ngIf="lang === 'en'"
  fxLayout="row"
  fxLayout.xs="column"
  fxLayoutAlign="center stretch"
  class="footer"
>
  <div
    fxFlex="30"
    fxFlex.xs="80"
    fxLayout="column"
    fxLayoutAlign="start center"
  >
    <p>CONTACT US</p>
    <hr />

    <p><mat-icon>phone</mat-icon></p>
    <p>+852 3611 5771</p>
    <p>+852 3611 5772</p>

    <p><mat-icon>smartphone</mat-icon></p>
    <p>+852 9512 6339</p>

    <p><mat-icon>email</mat-icon></p>
    <p>info@taihk.com.hk</p>
  </div>

  <div
    fxFlex="30"
    fxFlex.xs="80"
    fxLayout="column"
    fxLayoutAlign="start center"
  >
    <p>OUR OFFICES</p>
    <hr />
    <p>Main Office</p>
    <p>Unit no. 1, 6/F, No. 168 Queen's Road Central, Central, Hong Kong</p>
  </div>
  <div
    fxFlex="30"
    fxFlex.xs="80"
    fxLayout="column"
    fxLayoutAlign="start center"
  >
    <p>FOLLOW US</p>
    <hr />
    <div>
      <span>
        <a href="https://www.instagram.com/tai.hongkong/">
          <img
            src="assets/images/svg/instagram-brands.svg"
            class="social-media-icon"
        /></a>
      </span>
      <span>
        <a
          href="https://www.facebook.com/%E5%A4%A7%E9%A6%99%E6%B8%AF%E4%BC%81%E6%A5%AD%E6%9C%8D%E5%8B%99%E5%B9%B3%E5%8F%B0-108400744779149/"
        >
          <img
            src="assets/images/svg/facebook-brands.svg"
            class="social-media-icon"
          />
        </a>
      </span>
    </div>
  </div>
</div>
<!-- end of en -->
