<!-- zh -->
<div *ngIf="lang === 'zh'" fxLayout="column" fxLayoutAlign="center stretch">
  <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
    <img src="assets/images/cloud-sec.jpg" alt="cloud secretary" class="banner-image" />
    <div class="banner-text" style="transform: translate(-30%, -30%)">
      <!-- <p style="color: #3f3e38; font-weight: bold; font-size: 8vw">
          成立海外公司
        </p>
        <p style="font-weight: bold; font-size: 4vw">大香港離岸企業服務部</p> -->
    </div>
  </div>

  <section
    fxLayout="row"
    fxLayoutAlign="center"
    style="background-color: white"
  >
    <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
      <p></p>

      <h2>THK雲端小秘書</h2>
      <h3>
        隨時隨地手機查閱客戶企業法定記錄、公司註冊處文件、股份轉讓文件！滿足企業【移動商務】需求！
      </h3>
      <p>
        THK雲端小秘書商務解決方案，無論身處何處，只要運用行動上網裝置，不論是客戶企業法定記錄、商業登記證、公司註冊證書、公司成立文件、
        公司註冊處周年申報表，公司註冊處董事變更表格、董事除名聲明、股份轉讓等，都能透過THK雲端小秘書透過手機或行動裝置，隨時隨地可直接查閱及下載
        。
      </p>

      <h3>不在辦公室，亦可辦公事</h3>
      <p>
        企業移動辦公需求日增，常在外進行業務拜訪或常在外出差開會人員，都可即時下載，便利移動辦公、銀行手續、牌照申請、拜訪客戶，隨時隨地連上網路進行文件備份，文件下載，輕鬆解決企業日常運作大小事。
        締造移動辦公及無紙化工作環境，形成可自由移動的行動辦公室。
      </p>
      <app-whatsapp [lang]="'zh'"></app-whatsapp>
      <p></p>

      <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="center center">
        <div
          fxLayout="row"
          fxFlex="40"
          fxFlex.xs="100"
          fxLayoutAlign="center center"
        >
          <div style="position: relative; width: 100%; height: 500px">
            <img
              src="assets/images/iphone.png"
              style="
                width: 100%;
                position: absolute;
                height: 100%;
                object-fit: contain;
              "
            />

            <img
              src="assets/images/brown-s-logo.png"
              style="
                width: 60%;
                max-width: 250px;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
              "
            />
            <h1
              style="
                width: 100%;
                position: absolute;
                left: 50%;
                top: 70%;
                transform: translate(-50%, -50%);
                text-align: center;
              "
            >
              THK雲端小秘書
            </h1>
          </div>
        </div>
        <div
          fxLayout="row"
          fxFlex="60"
          fxFlex.xs="100"
          fxLayoutAlign="center center"
        >
          <div style="width: 100%; background-color: #eeeae8">
            <div fxLayout="column" fxFlex="100" fxLayoutAlign="start center">
              <!-- <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center"> -->
              <h1>THK雲端小秘書</h1>
              <!-- </div> -->
              <div
                fxLayout="row wrap"
                fxFlex="100"
                fxLayoutAlign="space-evenly center"
              >
                <mat-card fxFlex="40" fxFlex.xs="80">
                  <p fxLayoutAlign="center center">商業登記證</p>
                </mat-card>
                <mat-card fxFlex="40" fxFlex.xs="80">
                  <p fxLayoutAlign="center center">公司成立文件</p>
                </mat-card>
                <mat-card fxFlex="40" fxFlex.xs="80">
                  <p fxLayoutAlign="center center">週年申報</p>
                </mat-card>
                <mat-card fxFlex="40" fxFlex.xs="80">
                  <p fxLayoutAlign="center center">其他法定記錄</p>
                </mat-card>
                <mat-card fxFlex="40" fxFlex.xs="80">
                  <p fxLayoutAlign="center center">公司資料</p>
                </mat-card>
                <mat-card fxFlex="40" fxFlex.xs="80">
                  <p fxLayoutAlign="center center">與我們聯絡</p>
                </mat-card>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p></p>

      <p>
        THK雲端小秘書為一免費版軟體,主要提供 B2B
        企業客戶進行文件備份管理、法定紀錄及客戶管理。
        透過THK雲端小秘書，客戶可以在日常工作下無需增加太多負擔就可以輕鬆完成原本需要額外手動的文件備份、文件整合等工作，
        同時也可以輕鬆完成企業資料維護與管理，隨時掌握企業內部狀態。
      </p>

      <h2>企業資料維護/法定記錄好幫手</h2>
      <ul>
        <li>適用對象：已委任我們出任公司秘書的客戶</li>
        <li>
          產品目的：免額外手動的文件備份、文件整合等工作，助客戶輕鬆完成資料維護與管理，隨時掌握企業內部狀態。
        </li>
      </ul>
      <hr />
      <p>產品特色：</p>
      <ul>
        <li>全年無休好秘書</li>
        <li>你睡覺她工作</li>
        <li>超時工作不須加班費</li>
        <li>工作細心有效率</li>
      </ul>

      <p>產品功能：</p>
      <ul>
        <li>企業法定記錄查閱及下載</li>
        <li>商業登記證查閱及下載</li>
        <li>公司註冊證書查閱及下載</li>
        <li>公司成立文件查閱及下載</li>
        <li>周年申報表查閱及下載</li>
        <li>董事變更表格查閱及下載</li>
        <li>股份轉讓文件查閱及下載</li>
      </ul>
      <p>
        免責聲明：客戶使用我們雲端小秘書商務解決方案，同意接納我們是真誠及盡職地已採取所有切實可能步驟確保資料使用者持有的個人資料受保障而不受未獲准許的或意外的查閱、處理或使用所影響。大香港及其關連公司/人物不會作出任何資料外洩承擔之保證。
        另外如客戶知悉或懷疑其個人資料外洩及資料外洩是由我們雲端小秘書造成，應立即通知我們停止有關系統操作。
      </p>
    </div>
  </section>
</div>
<!-- end of zh -->

<!-- en -->
<div *ngIf="lang === 'en'" fxLayout="column" fxLayoutAlign="center center">
  <div style="height: 100vh" fxLayout="column" fxLayoutAlign="center center">
    <p>Only Chinese Version Available</p>
  </div>
</div>
<!-- end of en -->
