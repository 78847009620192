import { Component, OnInit, Inject } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/firestore';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { firestore } from 'firebase';

export interface RequestServiceData {
  name: string;
  contact_number: string;
  email: string;
  service_requested: string;
  service_name: string;
}

@Component({
  selector: 'app-request-service-dialog',
  templateUrl: './request-service-dialog.component.html',
  styleUrls: ['./request-service-dialog.component.scss'],
})
export class RequestServiceDialogComponent implements OnInit {

  private requestCol: AngularFirestoreCollection;
  public uploading: boolean;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: RequestServiceData,
    public dialogRef: MatDialogRef<RequestServiceDialogComponent>,
    private afs: AngularFirestore,
    private snackBar: MatSnackBar
  ) {
    this.uploading = false;
    this.requestCol = this.afs.collection('service_request');
  }

  ngOnInit(): void {}

  cancel(): void {
    this.dialogRef.close();
  }

  async submit(): Promise<void> {
    this.uploading = true;
    try {
      const serviceRequestData = {
        ...this.data,
        created_at: firestore.FieldValue.serverTimestamp(),
      };
      const addServiceRequestResult = await this.requestCol.add(
        serviceRequestData
      );
      console.log(addServiceRequestResult.id);
      const message = `多謝您的查詢，我們將發送服務申請表格至您的電郵${this.data.email}`;
      this.snackBar.open(message, 'x', { duration: 5000 });
      this.uploading = false;
      this.dialogRef.close();
      return;
    } catch (err) {
      const message = `我們暫未能處理您的查詢，請稍後再試。`;
      this.snackBar.open(message, 'x', { duration: 5000 });
      this.uploading = false;
      this.dialogRef.close();
      return;
    }
  }
}
