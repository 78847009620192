<!-- zh -->
<div *ngIf="lang === 'zh'" fxLayout="column" fxLayoutAlign="center stretch">
  <section
    fxLayout="row"
    fxLayoutAlign="center"
    style="background-color: white"
  >
    <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
      <h1>
        擁有®的註冊商標令人感覺是珍愛自已創辦的企業，用心做事業，信任度提升
      </h1>
      <img
        src="assets/images/taihk-new-logo.png"
        alt="logo"
        class="banner-image"
        style="border: 2px solid saddlebrown; padding: 2rem;"
      />
      <p>
        註冊商標成功後，申請公司便擁有該商標的專用權，受到地域法律的保障。當有不良商人未經你的同意下，在商業用途中使用該商標或近似的商標是屬於侵權行為，擁有者可從司法程序介入並停止他使用，以及追討金錢上的賠償。 
      </p>
      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center stretch">
        <mat-card fxFlex="40" fxLayout="column" fxLayoutAlign="space-evenly">
          <mat-card-header>
            <mat-card-title>
              <h2 style="color: red">香港商標註冊</h2>
            </mat-card-title></mat-card-header
          >
          <mat-card-content>
            <ul>
              <li>申請時間約6-8個月</li>
              <li>商標有效期為10年</li>
              <li>包括所有官方費用</li>
              <li>每個額外類別需附加$1,500</li>
              <li>
                包括查冊，處理文件，遞交及之後跟進，收到證書安排郵寄
                (不包括申請上訴費用)
              </li>
            </ul>
            <div>
              <h3 style="color: red">HKD $2,500.00</h3>
            </div>
          </mat-card-content>
        </mat-card>

        <mat-card fxFlex="40" fxLayout="column">
          <mat-card-header>
            <mat-card-title>
              <h2 style="color: red">申請「初步意見服務」</h2>
            </mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <p>申請知識產權署官方初步意見服務</p>
            <ul>
              <li>申請時間約2個月</li>
              <li>包括所有官方費用</li>
              <li>包括處理文件</li>
            </ul>
            <div>
              <h3 style="color: red">HKD $980.00</h3>
            </div>
          </mat-card-content>
        </mat-card>
      </div>

      </div>
      </section>
      <section
      fxLayout="row"
      fxLayoutAlign="center"
      style="background-color: whitesmoke"
    >
      <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
       
      <h2 style="color: red">免費商標註冊查詢</h2>
      <ul>
        <li>商標檢索，你申請註冊的商標會否跟已登記的商標相似</li>
        <li>提供商標註冊的詳細說明及有關分類的諮詢服務</li>
        <li>註冊商標流程及申請和審批時間所需</li>
        <li>提供申請建議，提高商標獲得註冊的成功率及更全面保護你的商標</li>
      </ul>

      <a
        mat-raised-button
        color="primary"
        target="_blank"
        href="https://wa.me/85295126339"
      >
        按此獲取免費諮詢
      </a>
      <p>

      </p>
      </div>
      </section>
      <section
      fxLayout="row"
      fxLayoutAlign="center"
      style="background-color: white"
    >
      <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
       
      <h2 style="color: red">註冊商標好處</h2>
      <p>
        商標是用來推廣及識別持有者的商品及服務，協助公眾將他們的商品及服務與其他競爭者的商品及服務分辨出來。所以，當商人成功推廣商品及服務後，最好立即將商品及服務註冊商標，以防止其他商人推出冒牌商品或/及服務欺騙顧客,
        直接影響公司的信譽及利潤。一般來說，註冊商標有以下幾項用途： 
      </p>
      <ul>
        <li>保障公司的商品及服務免受侵權；</li>
        <li>成功的商標能有效地提升企業形象；</li>
        <li>香港註冊商標得到國際認可，日後於大部份國家註冊可享有優先權。</li>
      </ul>

      <h2 style="color: red">註冊商標流程</h2>
      <p>以下列出註冊商標流程及可能出現的問題 </p>
      <h3>1 商標註冊文件處理及遞交申請 </h3>
      <p>
        所需時間: 5天<br />
        所需資料/文件:
        商標圖案，申請人姓名/地址，商品/服務說明，申請人委託書，申請人身份證明文件 
      </p>
      <h3>2 官方審查或駁回 </h3>
      <p>
        所需時間: 60-120天<br />
        香港知識產權署有機會對申請作出異議並提出駁回，需要公司進一步補充有關資料及答辯。 
      </p>

      <h3>3 公開諮詢 </h3>
      <p>
        所需時間: 90天<br />
        第三方(例如擁有近似商標的商戶)有機會對申請作出異議，需要公司進一步補充有關資料及答辯。 
      </p>
      <h3>4 完成註冊 </h3>
      <p>
        完成商標註冊，為期 10 年有效，其後每 10
        年需要續期，其間商標受到相關法律保障，避免競爭對手/不良商人使用你的商標做生意。 
      </p>

      <p>
        註冊商標要留意的地方<br />
        1）註冊商標屬於屬地主義（在哪註冊，在哪受保護）<br />
        2）註冊商標能轉讓，商標持有人可轉讓給等三方持有<br />
        3）商標註冊提出申請後就不得更改，如有任何商標構圖上或文字上的更改，只能再提出申請。
      </p>
      <app-whatsapp [lang]="'zh'"></app-whatsapp>
      <br />

      <h2 style="color: red">註冊商標 (其他國家) </h2>
    </div>
  </section>

  <section
    fxLayout="row wrap"
    fxLayoutAlign="start stretch"
    style="background-color: white"
  >
    <div
      fxFlex.xs="100"
      fxFlex="25"
      class="flag-box c1"
      fxLayout="column"
      fxLayoutAlign="center center"
    >
      <h1>中國</h1>
      <span class="flag">🇨🇳</span>
      <span>有效期：10年</span>
      <span>註冊時間：12-24個月</span>
      <span>申請費(HK$)</span>
      <span>首類別3,000</span>
      <span>次類別3,000</span>
      <span><br /></span>
      <app-request-manual-quotation
        [serviceId]="'trademark_china'"
      ></app-request-manual-quotation>
      <span><br /></span>
    </div>

    <div
      fxFlex.xs="100"
      fxFlex="25"
      class="flag-box c2"
      fxLayout="column"
      fxLayoutAlign="center center"
    >
      <h1>澳門</h1>
      <span class="flag">🇲🇴</span>
      <span>有效期：7年</span>
      <span>註冊時間：6-9個月</span>
      <span>申請費(HK$)</span>
      <span>首類別3,800</span>
      <span>次類別3,800</span>
      <span>公証授權費：1,500</span>
      <app-request-manual-quotation
        [serviceId]="'trademark_macau'"
      ></app-request-manual-quotation>
    </div>

    <div
      fxFlex.xs="100"
      fxFlex="25"
      class="flag-box c3"
      fxLayout="column"
      fxLayoutAlign="center center"
    >
      <h1>台灣</h1>
      <span class="flag">🇹🇼</span>
      <span>有效期：10年</span>
      <span>註冊時間：9-15個月</span>
      <span>申請費(HK$)</span>
      <span>首類別6,000</span>
      <span>次類別4,500</span>
      <app-request-manual-quotation
        [serviceId]="'trademark_taiwan'"
      ></app-request-manual-quotation>
    </div>

    <div
      fxFlex.xs="100"
      fxFlex="25"
      class="flag-box c1"
      fxLayout="column"
      fxLayoutAlign="center center"
    >
      <h1>美國</h1>
      <span class="flag">🇺🇸</span>
      <span>有效期：10年</span>
      <span>註冊時間：10-18個月</span>
      <span>申請費(HK$)</span>
      <span>首類別7,500</span>
      <span>次類別5,500</span>
      <app-request-manual-quotation
        [serviceId]="'trademark_us'"
      ></app-request-manual-quotation>
    </div>

    <div
      fxFlex.xs="100"
      fxFlex="25"
      class="flag-box c2"
      fxLayout="column"
      fxLayoutAlign="center center"
    >
      <h1>加拿大</h1>
      <span class="flag">🇨🇦</span>
      <span>有效期：10年</span>
      <span>註冊時間：22-24個月</span>
      <span>申請費(HK$)</span>
      <span>首類別9,000</span>
      <span>次類別3,000</span>
      <app-request-manual-quotation
        [serviceId]="'trademark_canada'"
      ></app-request-manual-quotation>
    </div>

    <div
      fxFlex.xs="100"
      fxFlex="25"
      class="flag-box c3"
      fxLayout="column"
      fxLayoutAlign="center center"
    >
      <h1>英國</h1>
      <span class="flag">🇬🇧</span>
      <span>有效期：10年</span>
      <span>註冊時間：7-9個月</span>
      <span>申請費(HK$)</span>
      <span>首類別6,500</span>
      <span>次類別5,500</span>
      <app-request-manual-quotation
        [serviceId]="'trademark_uk'"
      ></app-request-manual-quotation>
    </div>

    <div
      fxFlex.xs="100"
      fxFlex="25"
      class="flag-box c1"
      fxLayout="column"
      fxLayoutAlign="center center"
    >
      <h1>德國</h1>
      <span class="flag">🇩🇪</span>
      <span>有效期：10年</span>
      <span>註冊時間：8-12個月</span>
      <span>申請費(HK$)</span>
      <span>首類別10,800</span>
      <span>次類別4,000</span>
      <app-request-manual-quotation
        [serviceId]="'trademark_germany'"
      ></app-request-manual-quotation>
    </div>

    <div
      fxFlex.xs="100"
      fxFlex="25"
      class="flag-box c2"
      fxLayout="column"
      fxLayoutAlign="center center"
    >
      <h1>歐盟</h1>
      <span class="flag">🇪🇺</span>
      <span>有效期：10年</span>
      <span>註冊時間：6-12個月</span>
      <span>申請費(HK$)</span>
      <span>首類別12,500</span>
      <span>次類別2,500</span>
      <app-request-manual-quotation
        [serviceId]="'trademark_eu'"
      ></app-request-manual-quotation>
    </div>

    <div
      fxFlex.xs="100"
      fxFlex="25"
      class="flag-box c3"
      fxLayout="column"
      fxLayoutAlign="center center"
    >
      <h1>日本</h1>
      <span class="flag">🇯🇵</span>
      <span>有效期：10年</span>
      <span>註冊時間：8-15個月</span>
      <span>申請費(HK$)</span>
      <span>首類別13,500</span>
      <span>次類別8,500</span>
      <app-request-manual-quotation
        [serviceId]="'trademark_jp'"
      ></app-request-manual-quotation>
    </div>

    <div
      fxFlex.xs="100"
      fxFlex="25"
      class="flag-box c1"
      fxLayout="column"
      fxLayoutAlign="center center"
    >
      <h1>南韓</h1>
      <span class="flag">🇰🇷</span>
      <span>有效期：10年</span>
      <span>註冊時間：12-16個月</span>
      <span>申請費(HK$)</span>
      <span>首類別9,500</span>
      <span>次類別7,000</span>
      <app-request-manual-quotation
        [serviceId]="'trademark_korea'"
      ></app-request-manual-quotation>
    </div>

    <div
      fxFlex.xs="100"
      fxFlex="25"
      class="flag-box c2"
      fxLayout="column"
      fxLayoutAlign="center center"
    >
      <h1>新加坡</h1>
      <span class="flag">🇸🇬</span>
      <span>有效期：10年</span>
      <span>註冊時間：8-12個月</span>
      <span>申請費(HK$)</span>
      <span>首類別5,000</span>
      <span>次類別5,000</span>
      <app-request-manual-quotation
        [serviceId]="'trademark_singapore'"
      ></app-request-manual-quotation>
    </div>

    <div
      fxFlex.xs="100"
      fxFlex="25"
      class="flag-box c3"
      fxLayout="column"
      fxLayoutAlign="center center"
    >
      <h1>澳洲</h1>
      <span class="flag">🇦🇺</span>
      <span>有效期：10年</span>
      <span>註冊時間：10-12個月</span>
      <span>申請費(HK$)</span>
      <span>首類別5,000</span>
      <span>次類別5,000</span>
      <app-request-manual-quotation
        [serviceId]="'trademark_australia'"
      ></app-request-manual-quotation>
    </div>
  </section>

  <section
    fxLayout="row"
    fxLayoutAlign="center"
    style="background-color: white"
  >
    <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
      <p>
        # 歐盟 (共27個國家)，包括：法國, 德國, 希臘, 匈牙利, 愛爾蘭, 意大利,
        拉脫維亞, 立陶宛, 盧森堡, 馬爾他, 荷蘭, 波蘭, 葡萄牙, 羅馬尼亞,
        斯洛伐克, 斯洛文尼亞, 西班牙, 瑞典,奧地利, 比利時, 克羅地亞,保加利亞,
        塞浦路斯, 捷克共和國, 丹麥, 愛沙尼亞, 芬蘭
      </p>

      <p><strong>尼斯分類</strong></p>
      <p>
        尼斯分類一共包含45個類別。其中1-34為商品類別，35-45為服務類別。如想了解更多有關熱門行業需要註冊的商標類別，可聯絡本公司商標註冊部。
        <app-whatsapp [lang]="'zh'"></app-whatsapp>
      </p>
    </div>
  </section>
</div>
<!-- end of zh -->

<!-- en -->
<div *ngIf="lang === 'en'" fxLayout="column" fxLayoutAlign="center stretch">
  <section
    fxLayout="row"
    fxLayoutAlign="center"
    style="background-color: white"
  >
    <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
      <h1>Trademark Registration</h1>
      <iframe
        width="100%"
        height="400"
        src="https://www.youtube.com/embed/eki0DGPPvXI"
      >
      </iframe>

      <h3>Benefits of Trademark Registration</h3>
      <p>
        Trademark is used for representing your goods and services and make
        public easily to distinguish your goods & services from those of other
        traders.
      </p>
      <p>
        Therefore, when your goods or services have been launched to the market
        with great success, you are suggested to register the trademark for
        preventing your competitors to use your trademark on their goods or
        services to imperil your company’s reputation and income; normally,
        there are several purposes for trademark registration:
      </p>
      <ul>
        <li>Protect your goods & services</li>
        <li>Successful trademark & brand name can enhance company’s image</li>
        <li>
          Hong Kong trademark obtain international recognition which can enjoy
          priority of registration in most of the countries.
        </li>
      </ul>
      <app-whatsapp [lang]="'en'"></app-whatsapp>
      <br />

      <h3>Trademark Registration</h3>
    </div>
  </section>

  <section
    fxLayout="row wrap"
    fxLayoutAlign="start stretch"
    style="background-color: white"
  >
    <div
      fxFlex.xs="100"
      fxFlex="25"
      class="flag-box c1"
      fxLayout="column"
      fxLayoutAlign="center center"
    >
      <h1>Hong Kong</h1>
      <span class="flag">🇭🇰</span>
      <span>Validity: 10 Years</span>
      <span>Application duration: 9-15 months</span>
      <span>Application Fee (HK$)</span>
      <span>First class fee: $2,500</span>
      <span>Extra class fee: $1,500</span>
      <app-request-manual-quotation
        [serviceId]="'trademark_hk'"
      ></app-request-manual-quotation>
    </div>

    <div
      fxFlex.xs="100"
      fxFlex="25"
      class="flag-box c2"
      fxLayout="column"
      fxLayoutAlign="center center"
    >
      <h1>China</h1>
      <span class="flag">🇨🇳</span>
      <span>Validity: 10 Years</span>
      <span>Application duration: 12-24 months</span>
      <span>Application Fee (HK$)</span>
      <span>First class fee: $3,000</span>
      <span>Extra class fee: $3,000</span>
      <app-request-manual-quotation
        [serviceId]="'trademark_china'"
      ></app-request-manual-quotation>
    </div>

    <div
      fxFlex.xs="100"
      fxFlex="25"
      class="flag-box c3"
      fxLayout="column"
      fxLayoutAlign="center center"
    >
      <h1>Macau</h1>
      <span class="flag">🇲🇴</span>
      <span>Validity: 7 Years</span>
      <span>Application duration: 6-9 months</span>
      <span>Application Fee (HK$)</span>
      <span>First class fee: $3,800</span>
      <span>Extra class fee: $3,800</span>
      <span>Notarization fee: $1,500</span>
      <app-request-manual-quotation
        [serviceId]="'trademark_macau'"
      ></app-request-manual-quotation>
    </div>

    <div
      fxFlex.xs="100"
      fxFlex="25"
      class="flag-box c1"
      fxLayout="column"
      fxLayoutAlign="center center"
    >
      <h1>Taiwan</h1>
      <span class="flag">🇹🇼</span>
      <span>Validity: 10 Years</span>
      <span>Application duration: 9-15 months</span>
      <span>Application Fee (HK$)</span>
      <span>First class fee: $6,000</span>
      <span>Extra class fee: $4,500</span>
      <app-request-manual-quotation
        [serviceId]="'trademark_taiwan'"
      ></app-request-manual-quotation>
    </div>

    <div
      fxFlex.xs="100"
      fxFlex="25"
      class="flag-box c2"
      fxLayout="column"
      fxLayoutAlign="center center"
    >
      <h1>U.S.A</h1>
      <span class="flag">🇺🇸</span>
      <span>Validity: 10 Years</span>
      <span>Application duration: 10-18 months</span>
      <span>Application Fee (HK$)</span>
      <span>First class fee: $7,500</span>
      <span>Extra class fee: $5,500</span>
      <app-request-manual-quotation
        [serviceId]="'trademark_us'"
      ></app-request-manual-quotation>
    </div>

    <div
      fxFlex.xs="100"
      fxFlex="25"
      class="flag-box c3"
      fxLayout="column"
      fxLayoutAlign="center center"
    >
      <h1>England</h1>
      <span class="flag">🇬🇧</span>
      <span>Validity: 10 Years</span>
      <span>Application duration: 7-9 months</span>
      <span>Application Fee (HK$)</span>
      <span>First class fee: $6,500</span>
      <span>Extra class fee: $5,500</span>
      <app-request-manual-quotation
        [serviceId]="'trademark_uk'"
      ></app-request-manual-quotation>
    </div>

    <div
      fxFlex.xs="100"
      fxFlex="25"
      class="flag-box c1"
      fxLayout="column"
      fxLayoutAlign="center center"
    >
      <h1>Germany</h1>
      <span class="flag">🇩🇪</span>
      <span>Validity: 10 Years</span>
      <span>Application duration: 8-12 months</span>
      <span>Application Fee (HK$)</span>
      <span>First class fee: $10,800</span>
      <span>Extra class fee: $4,000</span>
      <app-request-manual-quotation
        [serviceId]="'trademark_germany'"
      ></app-request-manual-quotation>
    </div>

    <div
      fxFlex.xs="100"
      fxFlex="25"
      class="flag-box c2"
      fxLayout="column"
      fxLayoutAlign="center center"
    >
      <h1>European Community</h1>
      <span class="flag">🇪🇺</span>
      <span>Validity: 10 Years</span>
      <span>Application duration: 6-12 months</span>
      <span>Application Fee (HK$)</span>
      <span>First class fee: $12,500</span>
      <span>Extra class fee: $2,500</span>
      <app-request-manual-quotation
        [serviceId]="'trademark_eu'"
      ></app-request-manual-quotation>
    </div>

    <div
      fxFlex.xs="100"
      fxFlex="25"
      class="flag-box c3"
      fxLayout="column"
      fxLayoutAlign="center center"
    >
      <h1>Japan</h1>
      <span class="flag">🇯🇵</span>
      <span>Validity: 10 Years</span>
      <span>Application duration: 8-15 months</span>
      <span>Application Fee (HK$)</span>
      <span>First class fee: $13,500</span>
      <span>Extra class fee: $8,500</span>
      <app-request-manual-quotation
        [serviceId]="'trademark_jp'"
      ></app-request-manual-quotation>
    </div>

    <div
      fxFlex.xs="100"
      fxFlex="25"
      class="flag-box c1"
      fxLayout="column"
      fxLayoutAlign="center center"
    >
      <h1>South Korea</h1>
      <span class="flag">🇰🇷</span>
      <span>Validity: 10 Years</span>
      <span>Application duration: 12-16 months</span>
      <span>Application Fee (HK$)</span>
      <span>First class fee: $9,500</span>
      <span>Extra class fee: $7,000</span>
      <app-request-manual-quotation
        [serviceId]="'trademark_korea'"
      ></app-request-manual-quotation>
    </div>

    <div
      fxFlex.xs="100"
      fxFlex="25"
      class="flag-box c2"
      fxLayout="column"
      fxLayoutAlign="center center"
    >
      <h1>Singapore</h1>
      <span class="flag">🇸🇬</span>
      <span>Validity: 10 Years</span>
      <span>Application duration: 8-12 months</span>
      <span>Application Fee (HK$)</span>
      <span>First class fee: $5,000</span>
      <span>Extra class fee: $5,000</span>
      <app-request-manual-quotation
        [serviceId]="'trademark_singapore'"
      ></app-request-manual-quotation>
    </div>

    <div
      fxFlex.xs="100"
      fxFlex="25"
      class="flag-box c3"
      fxLayout="column"
      fxLayoutAlign="center center"
    >
      <h1>Australia</h1>
      <span class="flag">🇦🇺</span>
      <span>Validity: 10 Years</span>
      <span>Application duration: 10-12 months</span>
      <span>Application Fee (HK$)</span>
      <span>First class fee: $5,000</span>
      <span>Extra class fee: $5,000</span>
      <app-request-manual-quotation
        [serviceId]="'trademark_australia'"
      ></app-request-manual-quotation>
    </div>
  </section>

  <section
    fxLayout="row"
    fxLayoutAlign="center"
    style="background-color: white"
  >
    <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
      <p>* European Community is including 27 country members as below:-</p>
      <p>
        Austria, Belgium, Bulgaria, Croatia, Cyprus, the Czech Republic,
        Denmark, Estonia, Finland, France, Germany, Greece, Hungary, Ireland,
        Italy, Latvia, Lithuania, Luxembourg, Malta, Holland, Poland, Portugal,
        Romania, Slovakia, Slovenia, Spain, Sweden.
      </p>

      <p><strong>Nice Classification</strong></p>
      <p>
        There are 45 trademark classes in Nice Classification that cover all
        goods and services, in which classes 1 – 34 are for the goods , whereas
        classes 35 – 45 are for the services. Contact for more details.
        <app-whatsapp [lang]="'en'"></app-whatsapp>
      </p>
    </div>
  </section>
</div>
