import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-taiwan',
  templateUrl: './taiwan.component.html',
  styleUrls: ['./taiwan.component.scss']
})
export class TaiwanComponent implements OnInit {

  lang: string;
  constructor(public translateService: TranslateService) {
    this.lang = this.translateService.currentLang;
    this.translateService.onLangChange.subscribe(
      (event) => { this.lang = event.lang; console.log(this.lang) }
    );
  }

  ngOnInit(): void {
  }

}
