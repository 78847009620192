<!-- zh -->
<form
  [formGroup]="contactForm"
  (ngSubmit)="onSubmit()"
  fxLayout="column"
  fxLayoutAlign="center center"
  *ngIf="lang == 'zh'"
>
  <p>
    獲取{{ serviceData[serviceId].name_zh }}報價單
  </p>

  <div fxFlex="80">
    <mat-form-field>
      <mat-label>公司名稱〈需填寫〉</mat-label>
      <input matInput type="text" [formControl]="companyControl" required />
      <mat-error *ngIf="companyControl.hasError('required')"
        >必須填寫</mat-error
      >
    </mat-form-field>
  </div>

  <div fxFlex="80">
    <mat-form-field>
      <mat-label>您的姓名〈需填寫〉</mat-label>
      <input
        matInput
        type="text"
        [formControl]="contactPersonControl"
        required
      />
      <mat-error *ngIf="contactPersonControl.hasError('required')"
        >必須填寫</mat-error
      >
    </mat-form-field>
  </div>

  <div fxFlex="80">
    <mat-form-field>
      <mat-label>您的電子郵件信箱〈需填寫〉</mat-label>
      <input matInput type="email" [formControl]="emailControl" required />
      <mat-error *ngIf="emailControl.hasError('required')">必須填寫</mat-error>
      <mat-error *ngIf="emailControl.hasError('email')"
        >請填寫有效的電子郵件格式</mat-error
      >
    </mat-form-field>
  </div>

  <div fxFlex="80">
    <mat-form-field>
      <mat-label>聯絡電話〈需填寫〉</mat-label>
      <input
        matInput
        type="text"
        [formControl]="mobileControl"
        maxlength="8"
        required
      />
      <mat-error *ngIf="mobileControl.hasError('required')">必須填寫</mat-error>
      <mat-error *ngIf="mobileControl.hasError('pattern')"
        >請填寫有效的聯絡電話</mat-error
      >
    </mat-form-field>
  </div>

  <button color="primary" type="submit" mat-raised-button [disabled]="contactForm.invalid">
    獲取報價單
  </button>
</form>
<!-- end of zh -->

<!-- en -->
<form
  [formGroup]="contactForm"
  (ngSubmit)="onSubmit()"
  fxLayout="column"
  fxLayoutAlign="center center"
  *ngIf="lang == 'en'"
>
  <p>
    Get {{ serviceData[serviceId].name_en }} Quotation
  </p>
  
  <div fxFlex="80">
    <mat-form-field>
      <mat-label>Company Name(Required)</mat-label>
      <input matInput type="text" [formControl]="companyControl" required />
      <mat-error *ngIf="companyControl.hasError('required')"
        >Company name is required</mat-error
      >
    </mat-form-field>
  </div>

  <div fxFlex="80">
    <mat-form-field>
      <mat-label>Name of Contact Person (Required)</mat-label>
      <input
        matInput
        type="text"
        [formControl]="contactPersonControl"
        required
      />
      <mat-error *ngIf="contactPersonControl.hasError('required')"
        >Name of contact person is required</mat-error
      >
    </mat-form-field>
  </div>

  <div fxFlex="80">
    <mat-form-field>
      <mat-label>Email of Contact Person (Required)</mat-label>
      <input matInput type="email" [formControl]="emailControl" required />
      <mat-error *ngIf="emailControl.hasError('required')"
        >Email of contact person is required</mat-error
      >
      <mat-error *ngIf="emailControl.hasError('email')"
        >Please input valid email</mat-error
      >
    </mat-form-field>
  </div>

  <div fxFlex="80">
    <mat-form-field>
      <mat-label>Phone of contact person (Required)</mat-label>
      <input
        matInput
        type="text"
        [formControl]="mobileControl"
        maxlength="8"
        required
      />
      <mat-error *ngIf="mobileControl.hasError('required')"
        >Phone of contact person is required</mat-error
      >
      <mat-error *ngIf="mobileControl.hasError('pattern')"
        >Please input valid phone number</mat-error
      >
    </mat-form-field>
  </div>

  <button color="primary" type="submit" mat-raised-button [disabled]="contactForm.invalid">
    Get Quotation
  </button>
</form>
<!-- end of en -->
