import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as PeriodData from '../../assets/data/tax-period-calculator.json';
import * as moment from 'moment';

@Component({
  selector: 'app-limited-company-tax',
  templateUrl: './limited-company-tax.component.html',
  styleUrls: ['./limited-company-tax.component.scss'],
})
export class LimitedCompanyTaxComponent implements OnInit {
  lang: string;
  public periodData = (PeriodData as any).default;
  // public companyEstablishDate =
  periodEnds: string[];
  startDate = new Date(2020, 0, 1);
  monthStringArray = [
    'JAN',
    'FEB',
    'MAR',
    'APR',
    'MAY',
    'JUN',
    'JUL',
    'AUG',
    'SEP',
    'OCT',
    'NOV',
    'DEC',
  ];
  yearStringArray = [2019, 2020, 2021, 2022, 2023, 2024, 2025];
  companyEstablishYear: number;
  companyEstablishMonth: number;
  monthYear1:string;
  monthYear2: string;
  constructor(public translateService: TranslateService) {
    this.lang = this.translateService.currentLang;
    this.translateService.onLangChange.subscribe(
      (event) => (this.lang = event.lang)
    );
    this.periodEnds = Object.keys(this.periodData);
  }
  ngOnInit(): void {
    console.log(this.periodEnds);
  }

  getEstablishMonthYear() {
    console.log('get mont year')
    if (this.companyEstablishMonth !== undefined && this.companyEstablishYear !== undefined) {
      const monthYear = moment([
        this.companyEstablishYear,
        this.companyEstablishMonth,
      ]);
      // console.log(monthYear.format('MMM YYYY'));

      const monthYear1 = moment(monthYear).add(18, 'months');
      const monthYear2 = moment(monthYear).add(21, 'months');
      // console.log(
      //   monthYear.format('MMM YYYY'),
      //   monthYear1.format('MMM YYYY'),
      //   monthYear2.format('MMM YYYY')
      // );
      this.monthYear1 = monthYear1.format('MMM YYYY');
      this.monthYear2 = monthYear2.format('MMM YYYY');

    }
  }

  onMonthChange($event) {
    // console.log($event.value);
    console.log(this.companyEstablishMonth);
    this.getEstablishMonthYear();
  }

  onYearChange($event) {
    // console.log($event.value);
    console.log(this.companyEstablishYear);
    this.getEstablishMonthYear();
  }
}
