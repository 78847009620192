<div
  style="
    position: fixed;
    margin: 3rem;
    bottom: 0;
    right: 0;
    width: 60px;
    height: 60px;
  "
>
  <a href="https://wa.me/85295126339">
    <img src="assets/images/svg/whatsapp.svg" alt="whatsapp-logo" />
  </a>
</div>
