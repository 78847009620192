<div class="plan-head-top">
  <h2>{{ priceChartData.title }}</h2>
</div>

<div class="plan-head-bottom">
  <div class="plan-price">
    <span class="plan-currency">{{ priceChartData.currency }}</span
    ><span class="plan-amount">{{ priceChartData.amount }}</span>
  </div>
  <p *ngIf="priceChartData" class="plan-desc">
    {{ priceChartData.highlight_sub_title }}
  </p>
  <p>{{ priceChartData.sub_title }}</p>
</div>

<div class="plan-body">
  <p *ngFor="let content of priceChartData.body">{{ content }}</p>
</div>

<div class="plan-foot">
  <!-- <a mat-button (click)="openDialog(priceChartData.service)"> -->
    <a mat-button href="https://firebasestorage.googleapis.com/v0/b/tai-hong-kong-platform.appspot.com/o/spreadsheets%2FsetupUnlimitedCompanyForm.xlsx?alt=media&token=f0abf4d0-b4e1-4a78-b80d-33eca6eb1108">
    <!-- <a mat-button href="/assets/forms/setupUnlimitedCompanyForm.xlsx" download> -->
    <h2>{{ priceChartData.action_button_text }}</h2>
  </a>
</div>
