<!-- zh -->
<div *ngIf="lang === 'zh'" fxLayout="column" fxLayoutAlign="center stretch">
  <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
    <img
      src="assets/images/liquidation.jpg"
      alt="banner"
      class="banner-image"
    />
    <div class="banner-text" style="transform: translate(-40%, -70%)">
      <p
        style="
          color: white;
          font-weight: bold;
          font-size: 4.5vw;
          margin: 0;
          padding: 0;
        "
      >
        公司清盤／撤銷公司註冊<br />行政方式恢復註冊
      </p>
      <p class="banner-text" style="color: white; font-size: 2vw">
        這是我們的職責
      </p>
    </div>
  </div>

  <section
    fxLayout="row"
    fxLayoutAlign="center"
    style="background-color: white"
  >
    <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
      <p></p>
      <h1 style="font-weight: bold">公司清盤／撤銷公司註冊（註銷）／行政方式恢復註冊</h1>
      <app-whatsapp [lang]="'zh'"></app-whatsapp>
      <br />

      <p>
        股東在計劃公司結業時，通常不太清楚有關終止手續，只是採取不理會態度，因此，可能在不知情下違反相關法例，情况嚴重的，甚至可能被逮捕或對以後的出入境造成影響。
      </p>
      <p>
        大香港涵蓋公司清盤／撤銷公司註冊（註銷）的所有環節，且積累了豐富的項目經驗，可協助順利公司結業。
      </p>

      <p class="double-line">公司清盤</p>

      <p>
        香港有限公司清盤可分為股東自願清盤、債權人自願清盤及由法庭強制清盤。
      </p>
      <h1>
        有限公司結業程序及條件：
      </h1>

      <h3>股東自願清盤</h3>
      <ul>
        <li>公司賬簿完整</li>
        <li>通過股東會特別決議，得到大部分股東同意</li>
        <li>該公司有能力償還債務</li>
        <li>必須委任一名清盤人，負責監察及處理整個清盤過程</li>
      </ul>

      <h3>債權人自願清盤</h3>
      <ul>
        <li>公司賬簿完整</li>
        <li>公司無能力清還所有債務且不能繼續營運</li>
        <li>公司由債權人委任一名清盤人，負責監察及處理整個清盤過程</li>
        <li>該清盤人必須是執業會計師或律師</li>
      </ul>

      <h3>由法庭強制清盤</h3>
      <p>
        由法庭提出的清盤，屬強制清盤。不論公司本身、公司債權人、公司註冊處處長或破產管理處處長均可以提出清盤要求。當清盤令發出後，法庭會委派一名清盤人，破產管理處處長亦可以擔任臨時清盤人，整個程序涉及多次董事、股東及債權人會議。
      </p>

      <p class="double-line">撤銷公司註冊</p>
      <p>
        根據香港公司法例第32章291條規定，向公司註冊處申請撤銷公司註冊程序及條件如下：
      </p>
      <ul>
        <li>全體股東同意</li>
        <li>
          公司從未開始營業或運作，或在申請撤銷註冊前已有至少3個月停止營業或運作
        </li>
        <li>沒有未償還的債項，包括政府牌照費及稅款</li>
        <li>公司不是任何法律程序的一方</li>
        <li>公司資產不包含位於香港的不動產</li>
        <li>如公司是控股公司，該公司附屬公司的資產均不包括位於香港的不動產</li>
        <li>必須取得稅務局局長發出的《不反對書面通知》</li>
      </ul>
      <p>
        此外，倘若有人對該項撤銷公司註冊持有反對意見，可以在註銷生效後的20年內向法庭提出行政方式恢復註冊申請，法庭可下令公司註冊處將已註銷的公司復業。
      </p>

      <p class="double-line">行政方式恢復註冊</p>
      <p>
        「行政方式恢復註冊」是一項新程序，公司註冊處處長可藉此以行政方式把公司恢復列入公司登記冊。
        只有公司的名稱必須已被公司註冊處處長從公司登記冊剔除及公司必須已告解散才符合資格申請「以行政方式恢復註冊」。因撤銷註冊或清盤而解散的公司不可以行政方式恢復公司的註冊。
      </p>
    </div>
  </section>

  <section
    fxLayout="row"
    fxLayoutAlign="center"
    style="background-color: whitesmoke"
  >
    <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
      <p></p>
      <p>* 政府行政收費列表（實報實銷）</p>
      <table>
        <tr>
          <th>撤銷公司註冊</th>
          <th>
            <p>向稅務局申請不反對通知書 HK$270</p>
            <p>向公司註冊處申請撤銷公司註冊及刊登憲報 HK$420</p>
          </th>
        </tr>
        <tr>
          <th>行政方式恢復註冊</th>
          <th>公司註冊處行政方式恢復註冊費用 HK$2,700</th>
        </tr>
      </table>
      <br />
      <br />
    </div>
  </section>
</div>
<!-- end of zh -->

<!-- en -->
<div *ngIf="lang === 'en'" fxLayout="column" fxLayoutAlign="center stretch">
  <div
    class="banner-container"
    fxLayout="row"
    fxFlex="100"
    fxLayoutAlign="center center"
  >
    <img
      src="assets/images/liquidation.jpg"
      alt="banner"
      class="banner-image"
    />
    <div fxShow.xs="false">
      <p
        class="banner-text"
        style="
          color: white;
          font-weight: bold;
          font-size: 3rem;
          top: 20vh;
          left: 15vw;
        "
      >
        Hong Kong Company<br />Liquidation / Deregistration<br />Administrative
        Restoration
      </p>
      <p
        class="banner-text"
        style="color: white; font-size: 2rem; top: 45vh; left: 15vw"
      >
        It's our responsibility
      </p>
    </div>

    <p
      class="banner-text"
      fxShow.gt-xs="false"
      style="
        color: white;
        font-size: 1rem;
        text-shadow: 2px 8px 8px black;
        line-height: 2rem;
        left: 5vw；;
      "
    >
      Hong Kong Company<br />Liquidation / Deregistration<br />Administrative
      Restoration <br />It's our responsibility
    </p>
  </div>

  <section
    fxLayout="row"
    fxLayoutAlign="center"
    style="background-color: white"
  >
    <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
      <p></p>
      <h1 style="font-weight: bold">
        Hong Kong Company Liquidation / Deregistration / Administrative
        Restoration
      </h1>
      <ul>
        <li>Deregistration: HK$2,000 *</li>
        <li>Administrative Restoration: HK$2,500*</li>
        <li>Liquidation: HK$12,000 up</li>
      </ul>
      <app-whatsapp [lang]="'en'"></app-whatsapp>
      <br />

      <p>
        Shareholders usually are not familiar with the related formalities when
        planning to terminate the company, so this may possibly result in the
        breach of relevant laws due to ignorance, and if the infractions are
        severe, they could lead to arrest or impact future exit and entry of
        Hong Kong.
      </p>
      <p>
        Tai Hong Kong's services cover all sectors in company
        liquidation/deregistration, and with extensive knowledge and experience,
        we can help a shareholders to terminate their company successfully
        through our professional service.
      </p>

      <p class="double-line">Hong Kong Company Liquidation</p>
      <p>
        Hong Kong Company Liquidation can be divided into Voluntary Liquidation
        by Shareholders or Creditors, and Statutory Liquidation by the Court.
      </p>

      <h3>Voluntary Liquidation by Shareholders</h3>
      <p>
        Voluntary Liquidation by Shareholders shall be conditional in the
        following:
      </p>
      <ul>
        <li>Account book of company is complete</li>
        <li>
          Consent is obtained from a majority of shareholders through
          extraordinary resolution passed at meetings of shareholders
        </li>
        <li>The company is solvent</li>
        <li>
          A liquidator must be appointed to monitor and handle the whole process
          of liquidation
        </li>
      </ul>

      <h3>Voluntary Liquidation by Creditors</h3>
      <p>
        Voluntary Liquidation by Creditors shall be conditional in the
        following:
      </p>
      <ul>
        <li>Account book of company is complete</li>
        <li>The company is insolvent and cannot continue operation</li>
        <li>
          A liquidator of the company is appointed by creditors to monitor and
          handle the whole process of liquidation
        </li>
        <li>
          This liquidator must be a certified public accountant or solicitor
        </li>
      </ul>

      <h3>Statutory Liquidation by Court</h3>
      <p>
        Liquidation petitioned by the court is a statutory liquidation. The
        company itself, its creditors, the Company Registrar or the Official
        Receiver can be petitioned for liquidation. After the order of
        liquidation is issued, the court will delegate a liquidator, and the
        Official Receiver can also act as temporary liquidator; the whole
        process will involve more than many meetings of directors, shareholders
        and creditors.
      </p>

      <p class="double-line">Deregistration</p>
      <p>
        Under Section 291 of the Companies Ordinance (Cap. 32) of the Laws of
        Hong Kong, a company applying for deregistration with Companies Registry
        must meet the following conditions:
      </p>
      <ul>
        <li>Consent by all shareholders;</li>
        <li>
          The company has not commenced operation or business, or has ceased
          operation or business at least 3 months before the application of
          deregistration;
        </li>
        <li>
          No outstanding indebtedness, including governmental license fee and
          tax;
        </li>
        <li>The company is not a party to any legal proceedings;</li>
        <li>The company has no immovable property situate in Hong Kong;</li>
        <li>
          If the company is a holding company, none of its subsidiary’s assets
          consist of any immovable property situate in Hong Kong; and
        </li>
        <li>
          Obtain the Written Notice of No Objection issued by the Commissioner
          of Inland Revenue.
        </li>
      </ul>
      <p>
        In addition, if someone has an objection to this deregistration, he/she
        can file application for re-registration with the court within 20 years
        from the effective date of deregistration, when the court shall order
        the Companies Registry to resume business of the deregistered company.
      </p>

      <p class="double-line">Administrative restoration</p>
      <p>
        Administrative restoration is a new process to administratively restore
        a company to the Companies Register by the Registrar of Companies. To be
        eligible for administrative restoration, the company's name must have
        been struck off the Companies Register by the Registrar of Companies and
        the company must have been dissolved consequently. A company which was
        dissolved by way of deregistration or winding up cannot apply for
        administrative restoration.
      </p>
    </div>
  </section>
  <section
    fxLayout="row"
    fxLayoutAlign="center"
    style="background-color: whitesmoke"
  >
    <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
      <p></p>
      <p>
        * Government Registration Fees lists (to be reimbursed for actual
        expenses)
      </p>
      <table>
        <tr>
          <th>Deregistration</th>
          <th>
            <p>
              1) Apply for a Notice of No Objection to a Company Being
              Deregistered (HK$270)
            </p>
            <p>
              2) Apply for Company Deregistration and Publish in the Gazette
              Notice (HK$420)
            </p>
          </th>
        </tr>
        <tr>
          <th>Administrative Restoration</th>
          <th>Government fee for administrative restoration (HK$2,700)</th>
        </tr>
      </table>
      <br />
      <br />
    </div>
  </section>
</div>
<!-- end of en -->
