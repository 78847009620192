import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-bud',
  templateUrl: './bud.component.html',
  styleUrls: ['./bud.component.scss'],
})
export class BudComponent implements OnInit {
  lang: string;
  constructor(public translateService: TranslateService) {
    this.lang = this.translateService.currentLang;
    this.translateService.onLangChange.subscribe(
      (event) => (this.lang = event.lang)
    );
  }

  ngOnInit(): void {}
}
