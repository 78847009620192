import { Component, OnInit, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { RequestServiceDialogComponent } from '../request-service-dialog/request-service-dialog.component';
// import { UnlimitedCompanyPriceChartComponent } from '../components/unlimited-company-price-chart/unlimited-company-price-chart.component';
import * as PLANS from '../../assets/data/unlimited-company-price-data.json';
import { PriceChartData } from '../interfaces/price-chart-data';

@Component({
  selector: 'app-register-hong-kong-unlimited-company',
  templateUrl: './register-hong-kong-unlimited-company.component.html',
  styleUrls: ['./register-hong-kong-unlimited-company.component.scss'],
})
export class RegisterHongKongUnlimitedCompanyComponent implements OnInit {
  
  lang: string;
  public plans: { [key: string]: PriceChartData } = (PLANS as any).default;
  
  constructor(
    public dialog: MatDialog,
    public translateService: TranslateService,
  ) {
    this.lang = this.translateService.currentLang;
    this.translateService.onLangChange.subscribe(
      (event) => (this.lang = event.lang)
    );
  }

  ngOnInit(): void {}

  openDialog(service: string): void {
    // console.log('open dialog');
    const requestServiceDialogRef = this.dialog.open(
      RequestServiceDialogComponent,
      {
        data: {
          name: '',
          contact_number: '',
          email: '',
          service_requested: service,
        },
      }
    );
    requestServiceDialogRef.afterClosed().subscribe((result) => {
      console.log('dialog closed');
      console.log('result', result);
    });
  }
}
