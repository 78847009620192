<!-- zh -->
<div *ngIf="lang === 'zh'" fxLayout="column" fxLayoutAlign="center stretch">
  <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
    <img
      src="assets/images/hong-kong-bank.jpg"
      alt="hongkong"
      class="banner-image"
    />
    <div class="banner-text" style="transform: translate(70%, 40%)">
      <p
        style="
          color: white;
          font-weight: bold;
          font-size: 3.5vw;
          margin: 0;
          padding: 0;
        "
      >
        香港銀行開戶<br />
        親臨開戶／視頻開戶<br />
        商業銀行戶口
      </p>
    </div>
  </div>

  <section
    fxLayout="row"
    fxLayoutAlign="center"
    style="background-color: white"
  >
    <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
      <p>適用於香港有限公司、無限公司、離岸公司。</p>
      <!-- <app-whatsapp [lang]="'zh'"></app-whatsapp> -->

      <h1>大香港銀行易</h1>
      <p>
        大香港與香港各國際性銀行建立了良好的合作關係。我們特設香港銀行開戶團隊，根據企業實際情況，安排企業在香港各國際性銀行開立銀行賬戶，並協助預約銀行及準備銀行開戶所需的相關文件，例如：會計師鑒證文件（由香港執業會計師鑒證）、公司營運證明、董事聲明、董事局會議記錄等。 
      </p>

      <h2>為什麼選擇大香港銀行易辦理開立香港銀行戶口？</h2>
      <p>
        企業客戶可以在香港的國際性商業銀行開立他們的商業銀行戶口，大香港銀行易會為閣下提供預先開戶評估，我們的團隊將了解客戶企業、及其董事和股東成員的背景，作出最專業的分析及最詳盡的說明從而協助客戶妥善準備開銀行戶口所需文件和業務證明，助客戶知悉及明白銀行對客戶業務及背景證明的數據質量
        (Data Quality) 要求。
      </p>

      <h2>能透過大香港銀行易轉介銀行作遙距開戶嗎？</h2>
      <p>
        可以。
        我們助客戶於香港重要金融中心遙距開戶，於國際性銀行開立香港當地的個人及商業銀行戶口。為環球客戶提供遙距的銀行開戶服務，毋須親臨香港亦可完成整個開戶安排。
      </p>
      <p>
        因為大部分提供遙距開戶服務的銀行，都不接受客戶的直接申請，因此，客戶需要先聯絡銀行指定的銀行開戶介紹人，經轉介至相關銀行以開始遙距開戶。
      </p>
      <p>
        大香港與香港銀行工商金融業界有長久的聯繫，亦是部分銀行的指定開戶中間人。為您省時省力的方法達致開戶成功。
      </p>

      <h2>為什麼要開立香港銀行戶口？</h2>
      <ul>
        <li>不受外匯管制，資金可自由調撥</li>
        <li>方便進行國際貿易的款項結算</li>
        <li>免徵利息稅，存款實際收益較高</li>
        <li>各種貨幣可自由兌換</li>
        <li>賬戶日常操作簡便，可開通網上銀行進行交易</li>
        <li>便於向國際性銀行進行貿易融資</li>
      </ul>

      <h2>香港銀行開戶需留意事項：</h2>
      <ul>
        <li>
          <strong>最低結餘限制</strong>
          <p>
            大多數銀行都要求最低存款來開立賬戶。因此，您需要在合理範圍內尋找最適合您的銀行。
          </p>
        </li>
        <li>
          <strong>公司戶口信用卡</strong>
          <p>
            隨著現在電子支付的普遍，很多企業客戶都透過網上支付方式支付公司各種營運費用，企業申請銀行開戶口時請記得同時申請公司戶口信用卡。
          </p>
        </li>
        <li>
          <strong> 高風險國家限制 </strong>
          <p>
            銀行開立後戶口往來有涉及高風險國家或人士，戶口容易被凍結或戶口喪失某部份功能例如與其他銀行間轉帳的能力。
            大香港誠懇的告訴你，請好好及負責任地使用你們的銀行戶口。
          </p>
        </li>
        <li>
          <strong>銀行更新客戶資料 </strong>
          <p>
            戶口開立後銀行會不定時進行戶口資料佐證及更新，客戶應配合銀行回覆及提供要求佐證資料及文件以免相關銀行戶口被凍結或部份功能被凍結。
          </p>
        </li>
      </ul>

      <h3>香港公司開戶規定資料</h3>
      <p>一般而言銀行對公司銀行開戶條件如下</p>
      <ol>
        <li>公司注冊證書</li>
        <li>商業登記證</li>
        <li>公司章程</li>
        <li>法團成立表格(表格NNC1)或近一期的周年申報表(表格NAR1)</li>
        <li>如公司資料有過更改，需交表格ND2A，表格ND4表格</li>
        <li>
          業務證明：2-3套完整訂單資料，包括相關合同、提單、報關單、增值稅發票等
        </li>
        <li>開戶預存款</li>
        <li>
          股東董事的持股比率及授權簽字人信息：股份10%或10%以上的股東的證件原件(身份證+港澳通行證或者是護照)
        </li>
        <li>股東及董事資料：各董事及股東身份證明、銀行近3個月賬戶流水等;</li>
      </ol>
      <p>
        大香港銀行易提醒你們：開戶行不同，開戶資料也會有所不同，詳情可以咨詢我們。
      </p>

      <h3>香港公司開戶流程</h3>
      <p style="line-height: 2rem">
        第一步： 提交公司開戶所需要資料<br />
        第二步： 執業會計師為公司法定文件作核證副本<br />
        第三步： 了解客戶背景，助客戶知悉及明白銀行對客戶業務證明的數據質量(Data
        Quality)要求<br />
        第四步： 代你提交開戶文件到香港銀行預審查冊<br />
        第五步： 預約開戶時間。（可根據客戶要求安排預約）<br />
        第六步： 到香港銀行開戶(海外客戶可安排視像開戶)<br />
        第七步： 等待銀行批核銀行戶口,取得賬戶密碼及網上保安編碼器。<br />
      </p>
    </div>
  </section>
  <section
    fxLayout="column"
    fxLayoutAlign="center center"
    style="background-color: white"
  >
    <app-four-steps></app-four-steps>
    <a
      mat-raised-button
      color="primary"
      class="get-form-button"
      href="https://firebasestorage.googleapis.com/v0/b/tai-hong-kong-platform.appspot.com/o/spreadsheets%2FCDDForm.xlsx?alt=media&token=0422cb9d-f05a-48b2-81b8-077a824b414e"
      download
    >
      <!-- <button
        mat-raised-button
        color="primary"
        (click)="openDialog('開香港銀行戶口')"
      > -->
      下載申請表格</a
    >
    <!-- </button> -->
  </section>

  <section fxLayout="row" fxLayoutAlign="center" style="background-color: #eee">
    <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
      <h3>香港主要本地銀行</h3>
      <div fxLayout="row wrap" fxFlex="80" fxLayoutAlign="center stretch">
        <img
          src="assets/images/hangseng-1.png"
          alt="hang seng bank"
          class="bank-logo"
        />
        <img src="assets/images/hsbc-1.png" alt="hsbc" class="bank-logo" />
        <img
          src="assets/images/standardchartered-1.png"
          alt="standard chartered bank"
          class="bank-logo"
        />
        <img
          src="assets/images/boc-1.png"
          alt="bank of china"
          class="bank-logo"
        />
        <img src="assets/images/dbs-2.png" alt="dbs" class="bank-logo" />
        <img
          src="assets/images/ccb-1.png"
          alt="china construction bank"
          class="bank-logo"
        />
        <img src="assets/images/ocbc-2.png" alt="ocbc" class="bank-logo" />
        <img
          src="assets/images/sgp-1.png"
          alt="singapore bank"
          class="bank-logo"
        />
        <img
          src="assets/images/citic-1.png"
          alt="china citic bank"
          class="bank-logo"
        />
        <img
          src="assets/images/scb-1.png"
          alt="shanghai commercial bank"
          class="bank-logo"
        />
        <img
          src="assets/images/dahsing-1.png"
          alt="dah sing bank"
          class="bank-logo"
        />
        <img src="assets/images/ncb-1.png" alt="ncb" class="bank-logo" />
      </div>
      <p></p>
    </div>
  </section>
</div>
<!-- end of zh -->

<!-- en -->
<div *ngIf="lang === 'en'" fxLayout="column" fxLayoutAlign="center stretch">
  <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
    <img
      src="assets/images/hong-kong-bank.jpg"
      alt="hongkong"
      class="banner-image"
    />
    <div class="banner-text" style="transform: translate(60%, 40%)">
      <p
        style="
          color: white;
          font-weight: bold;
          font-size: 2vw;
          margin: 0;
          padding: 0;
        "
      >
        LOCAL BANK ACCOUNT SERVICES
      </p>
      <p style="color: white; font-size: 1.8vw">
        - assist to handle Know-Your-Customers (KYC) <br />
        and Customer Due Diligence (CDD) document <br />
        - assist to handle the FATCA documentation <br />
        - remote opening option
      </p>
    </div>
    <!-- <div class="banner-text">
          <p>We offer professional pre-application assessment and assistant</p>
          <p>
            We assist to handle Know-Your-Customers (KYC) and Customer Due
            Diligence (CDD) document
          </p>
          <p>We assist to handle the FATCA documentation</p>
          <p>Remote opening option</p>
        </div> -->
  </div>
  <section
    fxLayout="row"
    fxLayoutAlign="center"
    style="background-color: white"
  >
    <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
      <p></p>

      <app-whatsapp [lang]="'en'"></app-whatsapp>

      <h1>Tai Hong Kong Banking Made Easy Initiative</h1>
      <p>
        As one of the Smart Banking initiatives announced by Hong Kong Monetary
        Authority in September 2017, the Banking Made Easy Initiative aims at
        reducing regulatory frictions hindering technological innovations. Tai
        Hong Kong Banking Made Easy Initiative is committed to assisting
        customers to comply with the regulatory requirements of various
        financial institutions, in addition to wholeheartedly for the customer
        to increase their success rate of account opening, we also assist to
        handle Know-Your-Customers (KYC), Customer Due Diligence (CDD) document
        and FATCA documentation.
      </p>
      <p>
        As Hong Kong has all along been an intermediation hub for trade and
        financial flows in the world, Hong Kong is an ideal place for making
        investment and opening bank accounts for investors. However, banks have
        different requirements on the formalities for and necessary information
        on opening accounts. A company’s director may have to spend additional
        time in making enquiries to the bank for this as he may fail to provide
        necessary information or is unfamiliar with the procedures for bank
        account opening, which may cause a waste of time for them.
      </p>
      <p>
        Tai Hong Kong has established a strong business relationship with Hong
        Kong’s international banks and courteously provide bank account opening
        service in Hong Kong. We will handle for enterprises the formalities for
        opening bank accounts with international banks in Hong Kong and assist
        them in making appointments with banks and the preparation for relevant
        documents needed, depending on the actual circumstances of such
        enterprise . The documents needed may include certified documents from
        accountants (issued by certified public accountants of Hong Kong
        (practicing)), business operating proof, director’s declarations and
        minutes of the board of directors.
      </p>

      <h3>Why Opening Bank Account in Hong Kong</h3>
      <ul>
        <li>
          No restriction on foreign exchange remittance, free transfer of funds
        </li>
        <li>Convenience in settlement of international trades</li>
        <li>No interest tax with a higher actual revenues of deposits</li>
        <li>Free conversion of currencies</li>
        <li>Convenient daily account operation and online bank transactions</li>
        <li>Convenience in getting trade financing from international banks</li>
      </ul>

      <h3>Required Documents</h3>
      <ul>
        <li>
          Company registration particulars, such as Company Registration
          Certificate, Business Registration Certificate, Articles of
          Association, Incorporation Form or Annual Return, etc.
        </li>
        <li>
          Identification of shareholders, directors and signers and proof of
          address in the last 3 months
        </li>
        <li>
          Business certification particulars, such as orders, purchase and sales
          invoices, contracts, bills of lading, auditor report and latest 3
          months management account etc.
        </li>
        <li>Registration particulars of affiliated company</li>
        <li>
          Personal and affiliated company’s bank transaction records in the last
          3 months
        </li>
      </ul>

      <h3>Methods of Opening Bank Account in Hong Kong</h3>
      <ul>
        <li>
          By visiting a bank in person: The directors visit a bank in Hong Kong
          in person. Under normal circumstances, the signature documents
          required for opening an account can be completed on the same day. The
          account opening is expected to be completed within 14 working days
          (specifically depending on the bank’s approval time).
        </li>
        <li>
          By video conference: The directors visit the Hong Kong banks branch in
          mainland China. The process is witnessed by the staff of the branch.
          It takes 30 working days to open an account (specifically depending on
          the bank’s approval time).
        </li>
      </ul>

      <h3>Procedures for Opening Bank Account in Hong Kong</h3>
      <ul>
        <li>Submit a bank account opening application</li>
        <li>Approval by the Due Diligence Department of the bank</li>
        <li>
          The final account number is issued upon approval from the due
          diligence
        </li>
        <li>Deliver a cheque Book</li>
        <li>Deliver passwords for online banking</li>
        <li>Activate online banking password</li>
        <li>First time online banking registration service</li>
        <li>Deliver or obtain ATM card and password</li>
        <li>Deposit start-up funds</li>
      </ul>
    </div>
  </section>

  <section fxLayout="row" fxLayoutAlign="center" style="background-color: #eee">
    <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
      <h3>Principal Banks in Hong Kong</h3>
      <div fxLayout="row wrap" fxFlex="80" fxLayoutAlign="center stretch">
        <img
          src="assets/images/hangseng-1.png"
          alt="hang seng bank"
          class="bank-logo"
        />
        <img src="assets/images/hsbc-1.png" alt="hsbc" class="bank-logo" />
        <img
          src="assets/images/standardchartered-1.png"
          alt="standard chartered bank"
          class="bank-logo"
        />
        <img
          src="assets/images/boc-1.png"
          alt="bank of china"
          class="bank-logo"
        />
        <img src="assets/images/dbs-2.png" alt="dbs" class="bank-logo" />
        <img
          src="assets/images/ccb-1.png"
          alt="china construction bank"
          class="bank-logo"
        />
        <img src="assets/images/ocbc-2.png" alt="ocbc" class="bank-logo" />
        <img
          src="assets/images/sgp-1.png"
          alt="singapore bank"
          class="bank-logo"
        />
        <img
          src="assets/images/citic-1.png"
          alt="china citic bank"
          class="bank-logo"
        />
        <img
          src="assets/images/scb-1.png"
          alt="shanghai commercial bank"
          class="bank-logo"
        />
        <img
          src="assets/images/dahsing-1.png"
          alt="dah sing bank"
          class="bank-logo"
        />
        <img src="assets/images/ncb-1.png" alt="ncb" class="bank-logo" />
      </div>
      <p></p>
    </div>
  </section>
</div>
<!-- end of en -->
