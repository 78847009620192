<!-- zh -->
<div *ngIf="lang === 'zh'" fxLayout="column" fxLayoutAlign="center stretch">
  <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
    <!-- <img src="" alt="singapore" class="banner-image" /> -->
    <!-- <div class="banner-text" style="transform: translate(-30%, -30%)">
      <p style="color: #3f3e38; font-weight: bold; font-size: 8vw">
        成立海外公司
      </p>
      <p style="font-weight: bold; font-size: 4vw">大香港離岸企業服務部</p>
    </div> -->
  </div>
  <section
    fxLayout="row"
    fxLayoutAlign="center"
    style="background-color: white"
  >
    <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
      <p></p>
      <p style="color: #3f3e38; font-weight: bold; font-size: 6vw">
        成立海外公司
      </p>
      <p style="font-weight: bold; font-size: 3vw">大香港離岸企業服務部</p>

      <h1>離岸公司好處是什麼？</h1>
      <p>
        所謂的“離岸”，是一個司法管轄權的問題，任何公司都是在特定的公司法的授權下存在的，香港公司就是在香港《公司法》下成立的，所以一家離岸公司只指在某一國或能夠獨立制定公司法的地區製定的“離岸公司法”下成立的。換言之離岸公司的特殊性是“離岸公司法”規定出來的。
      </p>
      <p>
        不是稅務寬鬆便是好處，也不是維護成本低便是好，離岸公司對客戶有什麼好處是根據客戶的需求來看。例如公司經營貿易業務，註冊新加坡公司開立新加坡銀行賬戶進行國際貿易。
        利潤積累在該海外離岸公司，能減少稅款的同時也没有外匯管制。
        例如公司是控股業務，可選註冊維爾京群島（BVI） 和開曼群島（Cayman
        Islands）公司保障股東權益。
        該兩國的法制健全，已推行經濟實質(ES)法，與現時國際稅務合規接軌，是不錯的選擇。
        甚至有的是為了讓自己國內的公司名字聽起來“國際化”，反正目的是五花八門吧！
        客戶應按自己業務需要，選擇適合國家或區域作為離岸註冊地。
      </p>
      <p>
        各註冊地的法律條例和稅制不盡相同，客戶應委託持牌專業秘書公司代理公司註冊和維護,
        合規經營業務。
        請垂詢我們的諮詢顧問，找到離岸公司註冊與應用的最佳的方案。
      </p>

      <h3>如何成立離岸公司？</h3>
      <p>
        註冊離岸公司本身是一件很簡單的事情，只要你年滿18歲，您就可以擁有一家離岸公司。但這並不意味著在您使用離岸公司的時候沒有其他事情要做，
        比如您的公司要去某銀行開戶，您可能需要提供十分詳盡的商業背景資料與您的身份以及地址證明。
      </p>
      <p>
        具體流程與條件因屬地不同而有所不同，詳情請與我們聯絡。
      </p>
      <app-whatsapp [lang]="'zh'"></app-whatsapp>

      <h3>註冊離岸公司要多長時間？</h3>
      <p>
        註冊一家離岸公司大約需要兩個工作週，如果購買現成的公司，時間可以較短。具體流程與條件因屬地不同而有所不同，
        詳情請致電或電郵諮詢。
      </p>

      <h3>如何選擇離岸註冊地？</h3>
      <p>選擇一個合適的離岸註冊地，應該確保你所選的國家是否符合下列條件：</p>
      <ol>
        <li>
          穩定性：離岸註冊地政治和經濟的穩定可以確保投資資金的安全。
          大香港提供的離岸國家/地域都是相對政治及經濟環境較穩定的地區。
        </li>
        <li>
          保密性：了解該離岸註冊地對投資者的資料是否公開，是否能夠保持隱秘性。
        </li>
        <li>
          可靠的基礎設施：該離岸註冊地應該具備現代化通訊，強大的基礎設施，國際性銀行網絡及足夠專業人士確保投資安全，能更便捷的管理資產。
        </li>
        <li>
          稅率優惠政策：要了解清楚離岸註冊地對境外投資者的稅率優惠或免稅政策是怎樣的。
        </li>
        <li>註冊地的聲譽：了解該離岸註冊地在國際上是否享有良好的聲譽。</li>
      </ol>
      <p>
        以上五點僅為一般選擇的標準，如需專業的建議或更詳細的諮詢，歡迎諮詢我們。
      </p>

      <h3>離岸公司資本需要驗資嗎？</h3>
      <p>離岸公司多依照英國普通法，實行授權資本製，不用驗資。</p>

      <h3>香港註冊公司與離岸註冊公司及中國註冊公司的分別？</h3>
      <p>
        香港公司必須在香港註冊，遵循《公司條例》，並辦理商業登記和申報香港稅務；離岸公司多在海外屬地，依據群島管轄區(例如BVI)的公司法例註冊，通常豁免稅務，隱密性高；中國公司根據《中華人民共和國公司法》可在內地不同城市(例如上海和深圳)註冊，並依據當地條例，可獲得不同的政策或稅務優惠。
      </p>

      <h3>離岸公司避稅可行嗎？</h3>
      <p>
        離岸公司避稅是可行的，在港成立的離岸公司，可以豁免繳交利得稅，亦毋須就離岸的業務收支進行報稅。不過如果主要經營業務的所在地是香港，即使是離岸公司，都需要按香港的稅率繳交利得稅。
      </p>
      <p>
        稅務局會按不同情況，去判斷個別公司是否屬於在香港經營業務。一般而言，符合沒有在香港設立辦事處、營業場所或業務機構，公司控制權、商業活動、合同均是在海外等等的條件，才可獲豁免繳交利得稅。
      </p>

      <h3>
        離岸銀行賬戶是什麼？ 離岸銀行賬戶好處是什麼？為什麼要有一個離岸戶口？
      </h3>
      <p>
        離岸銀行賬戶是指與您所居住的國家/地區或企業註冊國家/地區所不同的國家/地區使用的銀行系統。
        一般客戶都會選擇一個強大，穩定的司法管轄區內設立離岸戶口。
      </p>
      <p>
        一般來說離岸戶口的好處可從資金轉移、資產保護、隱私性、安全性幾方面看。
      </p>
      <p>
        1）快速轉移資金<br />
        一個離岸賬戶也可以讓賬戶持有人靈活地轉移資金。
        例如，您可能處於需要面對訴訟進行資產保護的情況。
        此外，您可能需定期進行國際商業交易，外匯結算。
        離岸戶口能幫助你們資產配置及建立您的業務。
      </p>
      <p>
        2）資產保護<br />
        與流行的看法相反，離岸銀行不單只是逃避稅收或隱藏您的資產。
        它更多的是保護你的資產，令你的儲蓄無法輕易的被没收或凍結。
      </p>
      <p>
        3）隱私性<br />
        離岸戶口通常提供一定程度的隱私，並且對於一些居住在政治不穩定的國家的人來說，離岸銀行可以為他們提供一個安全和有保障的資產存放地點。
      </p>

      <p>
        4）安全性<br />
        每個司法管轄區內都有嚴格的政府法規。
        都是硬性要求銀行必須保持大量的資本儲備，以確保存款人的安全。
        此外，監管機構不斷審計銀行。
        這有助於確保銀行保持合規並維護那些受歡迎的金融中心國家的聲譽。
      </p>

      <p>
        此外，頂級財經出版物“環球金融雜誌 Global
        Finance”為例，他們每年都會發佈一份50最安全的銀行名單。截至撰寫本文時，以下是擁有最安全銀行的國家：
      </p>

      <ul>
        <li>德國</li>
        <li>瑞士</li>
        <li>荷蘭</li>
        <li>挪威</li>
        <li>盧森堡</li>
        <li>法國</li>
        <li>加拿大</li>
        <li>新加坡</li>
        <li>瑞典</li>
      </ul>
      <p>在上述國家中，我們能安排新加坡開設海外戶口而無需前往該國。</p>

      <h3>為什麼選擇我們辦理開立離岸公司銀行戶口？</h3>

      <p>
        我們助客戶於世界各地的重要金融中心，於國際性銀行開立當地的個人及商業銀行海外戶口(離岸銀行帳戶)。為環球客戶提供遙距的銀行開戶服務，毋須親臨當地的銀行亦可完成整個開戶安排。
      </p>
      <p>
        因為大部分提供遙距開戶服務的銀行，都不接受客戶的直接申請，因此，客戶需要先聯絡銀行指定的銀行開戶介紹人，經轉介至相關銀行以開始遙距開戶。
      </p>
      <p>
        大香港與外地的銀行有長久的聯繫，亦是部分銀行的指定開戶中間人。為您省時省力的方法達致開戶成功。
      </p>

      <p>離岸戶口開戶需留意事項：</p>
      <ul>
        <li>
          最低結餘限制 <br />
          大多數銀行都要求最低存款來開立賬戶。因此，您需要在合理範圍內尋找最適合
          您的銀行。
        </li>
        <li>
          初存限制<br />
          留意銀行有没有要求戶口開立後需存放一定金額的存款定存一段時間的要求。因此，您要預備充足儲備作資金流轉。
        </li>
        <li>
          公司成立司法地限制<br />
          一般來說企業註冊國家/地區與開戶銀行國家/地區相同是比企業註冊國家/地區與開戶銀行國家/地區不同容易開啓在岸銀行戶口。
        </li>
        <li>
          高風險國家限制<br />
          如銀行開立後戶口往來有涉及高風險國家或人士，戶口容易被凍結或戶口喪失某部份功能例如與其他銀行間轉帳的能力。
        </li>
      </ul>
    </div>
  </section>
</div>
<!-- end of zh -->

<!-- en -->
<div *ngIf="lang === 'en'" fxLayout="column" fxLayoutAlign="center center">
  <div style="height: 100vh" fxLayout="column" fxLayoutAlign="center center">
    <p>Only Chinese Version Available</p>
  </div>
</div>
<!-- end of en -->
