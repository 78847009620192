<!-- zh -->
<app-fix-whatsapp-button></app-fix-whatsapp-button>
<div *ngIf="lang === 'zh'" fxLayout="column" fxLayoutAlign="center stretch">
  <div
    class="banner-container"
    fxLayout="row"
    fxFlex="100"
    fxLayoutAlign="center center"
  >
    <img
      src="assets/images/open-sgp-account.jpg"
      alt="singapore"
      class="banner-image"
    />
  </div>
  <section
    fxLayout="row"
    fxLayoutAlign="center"
    style="background-color: white"
  >
    <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
      <p></p>
      <h1>新加坡離岸戶口</h1>
      <h1>
        新加坡遙距開銀行戶口/香港公司離岸公司開新加坡銀行戶口/解決開新加坡銀行戶口困難
      </h1>
      <ul>
        <li>商業戶口：香港公司及離岸公司均可申請</li>
        <li>個人戶口：香港人／外籍人士及非新加坡居民均可申請</li>
        <li>新加坡境外辦理開戶</li>
        <li>保證成功，無效退款！</li>
      </ul>
      <app-whatsapp [lang]="'zh'"></app-whatsapp>
      <br />

      <p class="sub-heading">新加坡當地主要銀行</p>

      <div fxLayout="row wrap" fxFlex="80" fxLayoutAlign="start stretch">
        <img src="assets/images/uob-1.png" alt="uob" class="bank-logo" />
        <img src="assets/images/ocbc-1.png" alt="ocbc" class="bank-logo" />
        <img src="assets/images/rhb-1.png" alt="rhb" class="bank-logo" />
        <img src="assets/images/dbs-1.png" alt="dbs" class="bank-logo" />
      </div>
      <p></p>
      <p class="sub-heading">「無效退款」保證計劃</p>
      <p>
        我們明白客戶希望得到實在的答案，如果我們為您挑選的新加坡本地銀行最終拒絕了客戶的開戶申請，我們會退還我們的服務費給您，給您最大保障。
      </p>
      <p>
        我們清楚列明向您收取每筆費用和開支。銀行收取的所有費用（例如銀行的申請費）和第三方收取的所有費用（例如任何郵費和新加坡當地文件處理行政費用）均不予退還。
      </p>
      <br />

      <p class="sub-heading">免費的遙距預先評估</p>
      <p>
        客戶委任我們辦理新加坡離岸銀銀行開戶手續後，我們會為各客戶提供免費離岸開戶預先評估。客戶毋須親身前往新加坡，客戶只需要把新加坡的銀行開戶所需文件及相關業務證明資料發送給我們，經我們分析後，即能為客戶挑選成功機會最大的新加坡銀行。
      </p>

      <br />
    </div>
  </section>

  <section
    fxLayout="row"
    fxLayoutAlign="center"
    style="background-color: whitesmoke"
  >
    <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
      <br />
      <p>
        以下是根據我們真實客戶關於<strong>離岸戶口</strong>開立經常提問的問題綜合而成：
      </p>

      <p>
        <strong>
          陳先生（在香港從事科研業務因財務保密原因想在新加坡開立個人銀行戶口）：「我是香港人不是新加坡居民或新加坡籍，也没有新加坡工作簽証，請問香港人於新加坡能開銀行戶口嗎？能做到離岸戶口遙距開戶嗎？」
        </strong>
      </p>
      <p>
        大香港已為客戶準備了最稱心的答案︰「可以，就算您不是新加坡居民或新加坡籍，你都能遙距在新加坡當地開立個人銀行戶口。」 
      </p>

      <p>
        <strong>
          Mr Savas
          （非中國香港國籍，在香港成立公司從事國際貿易因稅務規劃原因想開立新加坡銀行戶口）：「我們公司是在香港成立的，公司能開立新加坡當地的商業銀行戶口嗎？因疫情關係能遙距開新加坡銀行戶口嗎？」
        </strong>
      </p>
      <p>
        大香港真誠的告訴你們：「可以，即使您的公司是香港成立，也可以於新加坡當地的銀行開立商業戶口。同時，您的公司不須於新加坡當地有任何營運據點，香港及其他離岸公司亦可申請開戶。新加坡為每位企業家提供營運國際業務所必備的自由。」 
      </p>

      <p>
        <strong>
          吳小姐
          （在香港營運醫檢業務因提升公司品牌原因想在新加坡成立公司及開立新加坡公司銀行戶口）：「因生意業務關係，我們想在新加坡成立公司及開立新加坡離岸銀行戶口。
          但因公司業務煩重，能不用往新加坡情況下遙距開戶嗎？」
        </strong>
      </p>
      <p>
        對於這常見的問題，大香港本著以客為先、待客以誠的服務精神告訴你們「可以，你能選擇遙距成立新加坡公司及遙距開立離岸戶口，省掉往來新加坡的時間及成本。」<br />
        (有關成立新加坡公司，可參考:<a href="/singapore">【成立新加坡公司】</a>
      </p>
    </div>
  </section>
  <section
    fxLayout="row"
    fxLayoutAlign="center"
    style="background-color: white"
  >
    <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
      <h2>新加坡離岸戶口好處</h2>
      <p>
        個人和公司選擇開立新加坡離岸戶口的原因有很多，而這些原因常常帶來很多好處。以下是開立新加坡離岸戶口的一些重要原因：
      </p>
      <ul>
        <li>更高的投資回報率和更高的利率</li>
        <li>更高的流動性和多元化</li>
        <li>更高的流動性</li>
        <li>持有和維持各種貨幣的能力</li>
        <li>加強資產保護</li>
        <li>財務保密</li>
      </ul>
      <p>
        由以上看出一般來說新加坡離岸戶口的好處可從資金轉移、資產保護、隱私性、安全性幾方面看。
      </p>
      <h3>1）快速轉移資金</h3>
      <p>
        新加坡離岸戶口可以讓賬戶持有人靈活地轉移資金。
        例如，您可能處於需要面對訴訟進行資產保護的情況。
        此外，您可能需定期進行國際商業交易，外匯結算。
        新加坡離岸戶口能幫助你們資產配置及建立您的業務。
      </p>

      <h3>2）資產保護</h3>
      <p>
        與流行的看法相反，在新加坡開立離岸戶口不單只是逃避稅收或隱藏您的資產。
        它更多的是保護你的資產，令你的儲蓄無法輕易的被没收或凍結。
      </p>

      <h3>3）隱私性</h3>
      <p>
        新加坡離岸戶口通常提供一定程度的隱私，並且對於一些居住在政治不穩定的國家的人來說，新加坡離岸戶口可以為他們提供一個安全和有保障的資產存放地點。
      </p>

      <h3>4）安全性</h3>
      <p>
        新加坡司法管轄區內有嚴格的政府法規。硬性要求銀行必須保持大量的資本儲備，以確保存款人的安全。
        新加坡也設有存款保障計劃，計劃由新加坡存款保險公司（SDIC）提供給個人及企業存戶最高SGD75,000存款保障。
        此外，監管機構不斷審計銀行。
        這有助於確保新加坡當地銀行保持合規並維護新加坡繼續成為最受歡迎的金融中心國家的聲譽。
      </p>
      <p>
        此外，頂級財經出版物“環球金融雜誌 Global
        Finance”為例，他們每年都會發佈一份50最安全的銀行名單。截至撰寫本文時，以下是擁有最安全銀行的國家：
      </p>

      <ul>
        <li>德國</li>
        <li>瑞士</li>
        <li>荷蘭</li>
        <li>挪威</li>
        <li>盧森堡</li>
        <li>法國</li>
        <li>加拿大</li>
        <li>新加坡</li>
        <li>瑞典</li>
      </ul>
      <p>在上述國家中，新加坡是名單中所包括的國家。</p>

      <h3>為什麼選擇我們辦理開立新加坡離岸銀行戶口？</h3>
      <p>
        我們助客戶於新加坡重要金融中心，於國際性銀行開立新加坡當地的個人及商業銀行戶口(離岸銀行戶口)。為環球客戶提供遙距的銀行開戶服務，毋須親臨當地的銀行亦可完成整個開戶安排。
      </p>
      <p>
        因為大部分提供遙距開戶服務的銀行，都不接受客戶的直接申請，因此，客戶需要先聯絡銀行指定的銀行開戶介紹人，經轉介至相關銀行以開始遙距開戶。
      </p>
      <p>
        大香港與外地的銀行有長久的聯繫，亦是部分銀行的指定開戶中間人。為您省時省力的方法達致開戶成功。
      </p>
      <h3>新加坡銀行戶口開戶需留意事項：</h3>

      <ul>
        <li>
          <strong>最低結餘限制</strong>
          <p>
            大多數銀行都要求最低存款來開立賬戶。
            因此，您需要在合理範圍內尋找最適合您的銀行。
          </p>
        </li>
        <li>
          <strong>初存限制</strong>
          <p>
            留意銀行有没有要求戶口開立後需存放一定金額的存款及定存一段時間的要求。
            因此，您要預備充足儲備作資金流轉。
          </p>
        </li>
        <li>
          <strong>公司成立司法地限制</strong>
          <p>
            一般來說於新加坡註冊成立的公司較非新加坡註冊成立公司較容易於新加坡當地開立新加坡企業銀行戶口。
          </p>
        </li>
        <li>
          <strong>高風險國家限制</strong>
          <p>
            銀行開立後戶口往來有涉及高風險國家或人士，戶口容易被凍結或戶口喪失某部份功能例如與其他銀行間轉帳的能力。
            大香港誠懇的告訴你，請好好及小心使用你們的離岸銀行戶口。
          </p>
        </li>
      </ul>
    </div>
  </section>
  <section
    fxLayout="column"
    fxLayoutAlign="center center"
    style="background-color: white"
  >
    <app-four-steps></app-four-steps>
    <a
      mat-raised-button
      color="primary"
      class="get-form-button"
      href="https://firebasestorage.googleapis.com/v0/b/tai-hong-kong-platform.appspot.com/o/spreadsheets%2FCDDForm.xlsx?alt=media&token=0422cb9d-f05a-48b2-81b8-077a824b414e"
      download
    >
      下載申請表格</a
    >
  </section>
</div>
<!-- end of zh -->

<!-- en -->
<div *ngIf="lang === 'en'" fxLayout="column" fxLayoutAlign="center stretch">
  <div
    class="banner-container"
    fxLayout="row"
    fxFlex="100"
    fxLayoutAlign="center center"
  >
    <img
      src="assets/images/open-sgp-account.jpg"
      alt="singapore"
      class="banner-image"
    />
    <div class="banner-text">
      <!-- <p>Business / Personal</p>
      <p>Bank Account</p>
      <p>Remote Opening</p> -->
    </div>
  </div>

  <section
    fxLayout="row"
    fxLayoutAlign="center"
    style="background-color: white"
  >
    <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
      <p></p>
      <h1>Open Singapore Bank Account</h1>
      <h1>
        Hong Kong and offshore companies / Foreigners and Non-residents of
        Singapore? No Problem!
      </h1>
      <ul>
        <li>
          Business account： Hong Kong and offshore companies both can apply
        </li>
        <li>
          Personal account： Foreigners and Non-residents of Singapore both can
          apply
        </li>
        <li>Open Bank Account without Travelling</li>
        <li>Refund Guarantee on account open failing</li>
      </ul>
      <app-whatsapp [lang]="'en'"></app-whatsapp>
      <br />

      <p class="sub-heading">Principal Bank in Singapore</p>

      <div fxLayout="row wrap" fxFlex="80" fxLayoutAlign="start stretch">
        <img src="assets/images/uob-1.png" alt="uob" class="bank-logo" />
        <img src="assets/images/ocbc-1.png" alt="ocbc" class="bank-logo" />
        <img src="assets/images/rhb-1.png" alt="rhb" class="bank-logo" />
        <img src="assets/images/dbs-1.png" alt="dbs" class="bank-logo" />
      </div>
      <p></p>
      <p class="sub-heading">Refund Guarantee on account open failing</p>
      <p>
        We know you hate uncertainty, so we have you backed. If our suggested
        Singaporean bank finally disapproves the client’s account opening
        application, we refund our service fee to the client.
      </p>
      <p>
        We make every fee and charge clear to you. All fees collected by the
        banks (e.g. bank’s application fee) and third parties (e.g. any postal
        fees and administrative costs in Singapore) are non-refundable.
      </p>
      <br />

      <p class="sub-heading">Remote Pre-approval Assessment for Free</p>
      <p>
        Without travelling to Singapore to conduct the Assessment, we analyze
        our client’s documents and client’s business proof based on the latest
        requirements of Singaporean banks. We take the result to suggest the
        most feasible Singaporean banks to the client.
      </p>
      <p>
        After the Assessment, if the client is not willing to proceed with our
        account opening service for any reason, the client can walk away freely
        at any moment. Our Assessment is free and has no hidden fee.
      </p>
      <br />
    </div>
  </section>

  <section
    fxLayout="row"
    fxLayoutAlign="center"
    style="background-color: whitesmoke"
  >
    <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
      <br />
      <p>
        <i
          ><strong>
            The most asked questions by foreigners and non-residents are “Can I
            open a personal bank account in Singapore?”.</strong
          ></i
        >
      </p>

      <p>
        We are proudly to answer: “Yes, you can open a Singaporean personal
        account for you, no matter you are a Singapore resident or not.”
      </p>
      <br />
      <p>
        <i
          ><strong>
            The most asked questions by the management of Hong Kong companies
            “Can I open a business bank account in Singapore?”.</strong
          ></i
        >
      </p>

      <p>
        We are sincerely to answer: “Yes, you can open a Singaporean bank
        account for your company, no matter you are a Singapore company or not.
        Furthermore, your company or business does not need to have a presence
        in Singapore, and your company can be incorporated outside Singapore,
        such as a Hong Kong company or an offshore company. Singapore provides
        the freedom of doing international business for every humble business
        owners.
      </p>
      <br />
      <p>
        <i
          ><strong>
            “Can I open a bank account remotely without travelling to
            Singapore?”.</strong
          ></i
        >
      </p>
      <p>
        We are thoughtfully to answer: “Yes, you can open a bank account
        remotely without travelling to Singapore.”
      </p>
      <br />
    </div>
  </section>
</div>
<!-- end of en -->
