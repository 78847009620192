<!-- zh -->
<div *ngIf="lang === 'zh'" fxLayout="column" fxLayoutAlign="center stretch">
  <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
    <img
      src="assets/images/unlimited-company-tax.jpg"
      alt="unlimited company tax"
      class="banner-image"
    />
    <div class="banner-text">
      <p>無限公司報稅，聘用專業免逾期免損失</p>
    </div>
  </div>
  <section
    fxLayout="column"
    fxLayoutAlign="center"
    style="background-color: white; padding: 0 10%"
  >
    <h1 style="color: black"><strong>無限公司報稅</strong></h1>
    <app-whatsapp [lang]="'zh'"></app-whatsapp>

    <h3>企業社會責任</h3>
    <p>
      獨資或合夥經營被定義為「非法團業務」，利得稅的現行標準稅率是15%，首200萬元應評稅利潤可以減半計算，即是7.5%。獨資或合夥經營選擇個人入息課程的話，會將所有個人收入混合計算，就像薪俸稅一樣，然後扣除薪俸稅應有的免稅額，應課稅金額將因應累進稅率計算，由2%至17%不等。
    </p>
    <h3>無限公司報稅注意事項</h3>
    <ul>
      <li>
        獨資或合夥經營計算應課稅收入時，扣除成本必須合情合理，而且與產生收入有直接關係；
      </li>
      <li>經營者本人或配偶的薪金，不能作為成本扣除；</li>
      <li>會計帳目及單據應保存至少七年，以防稅務局抽查；</li>
      <li>
        稅務局並無要求獨資或合夥經營需提供已經執業會計師作審計的審計報告。但須就其入息及開支以英文或中文備存足夠的紀錄，以便其應評稅利潤易於確定，【大香港】建議在做帳報稅時應請真正專業人士(尤其香港會計師事務所或執業會計師)處理以免公司蒙受經濟上的損失，才有權益保證。
      </li>
      <li>關連交易需留意交易性質，有没有支付證明佐證</li>
    </ul>

    <h2>無限公司利得稅稅率</h2>
    <table>
      <thead>
        <tr>
          <th>課稅年度</th>
          <th>稅率</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th>2008/09及其後</th>
          <th>15%</th>
        </tr>
      </tbody>
    </table>
    <br />
    <table>
      <thead>
        <tr>
          <th>課稅年度</th>
          <th>稅率</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th>2018/19及其後</th>
          <th>
            不超過HK$2,000,000的應評稅利潤<br />
            7.5% 應評稅利潤中超過HK$2,000,000的部分 – 15%
          </th>
        </tr>
      </tbody>
    </table>
    <p>
      注1:
      2018/19年度及其後首HK$2,000,000的7.5%優惠稅率只能由名下其中一間控股超過50%的業務享有。
    </p>
    <p>
      注2: 【大香港】提提你, 申請個人入息課稅可有效善用剩餘的免稅額,
      並且將以累進稅率及標準稅率分別計算應繳稅款,
      你只須根據較低稅款之計算方式繳交稅款。一般來說，應課稅入息實額不多於HK$2,022,000的單身人士或HK$3,144,000的已婚人士以累進稅率計算應繳稅款將可更進一步減低稅款。
    </p>

    <h3>無限公司報稅時間</h3>

    <h3>獨資公司報稅期限</h3>
    <p>
      一般而言，獨資公司報稅會在東主個人報稅表內申報，但如稅務局對該獨資公司發出利得稅報稅表，東主都需依時填妥申報，一般而言，東主需於收到報稅表發出日期1個月內遞交。
    </p>
    <p>
      如獨資公司於該課稅年度入息超過2百萬，獨資公司的利潤表和資產負債表須連同報稅表一併遞交。
    </p>

    <h3>合夥公司報稅期限</h3>
    <p>
      合夥公司報稅並不需要直接將利潤資料填上個別人士報稅表上，稅務局會對合夥公司另外發出利得稅報稅表。如合夥人希望以個人入息報稅方式納稅，各合夥人需於個別人士報稅表和利得稅報稅表簡選以個人入息報稅納稅。
    </p>
    <p>
      同樣，如合夥公司於該課稅年度入息超過2百萬，獨資公司的利潤表和資產負債表須連同報稅表一併遞交。
    </p>

    <p><strong>如何填寫無限公司-獨資業務報稅表（BIR60）？ </strong></p>
    <p>
      詳情請參看<a
        href="https://www.ird.gov.hk/chi/pdf/ind_ctrc_demo.pdf"
        target="_blank"
        >無限公司務報稅表範本 （獨資公司業務）</a
      >
    </p>

    <h2 style="text-align: center">無限公司會計服務</h2>
    <p>我們的會計服務可按客戶需要分為按月、按季度或按會計年度。</p>
    <img
      src="assets/images/unlimited-company-accounting-service-2.jpg"
      style="margin: 1rem auto; width: 60%"
    />
    <p style="text-align: center">
      <a routerLink="/accounting-service"
        ><strong style="font-size: larger">了解更多</strong></a
      >
    </p>
  </section>
</div>
<!-- end of zh -->

<!-- en -->
<div *ngIf="lang === 'en'" fxLayout="column" fxLayoutAlign="center center">
  <div style="height: 100vh" fxLayout="column" fxLayoutAlign="center center">
    <p>Only Chinese Version Available</p>
  </div>
</div>
<!-- end of en -->
