import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from '../data.service';

@Component({
  selector: 'app-annual-maintenance',
  templateUrl: './annual-maintenance.component.html',
  styleUrls: ['./annual-maintenance.component.scss'],
})
export class AnnualMaintenanceComponent implements OnInit {
  lang: string;
  services: string[];
  otherServices: string[];
  serviceData;

  constructor(
    public translateService: TranslateService,
    public data: DataService
  ) {
    this.lang = this.translateService.currentLang;
    this.translateService.onLangChange.subscribe(
      (event) => (this.lang = event.lang)
    );
    this.serviceData = data.services;
  }
  ngOnInit(): void {
    this.services = [
      'office_address',
      'sign_ctrl_reg',
      'annual_return',
      'com_sec',
      'br',
    ];

    this.otherServices = [
      'change_address',
      'change_nature',
      'change_limited_com_name',
      'change_unlimited_com_name',
      'apply_branch',
      'increase_shares',
      // 'transfer_shares',
      'change_director',
      'change_director_info',
      'dereg_unlimited_com',
      'dereg_limited_com',
      'meeting_record',
      'annual_meeting_record',
      'change_article',
    ];
  }
}
