<!-- zh -->
<div *ngIf="lang === 'zh'" fxLayout="column" fxLayoutAlign="center stretch">
  <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
    <img src="assets/images/bud.jpg" alt="bud fund" class="banner-image" />
    <div class="banner-text" >
    
        <p > 好好利用政府資源，BUD 專項基金計劃</p> 
    </div>
  </div>

  <section
    fxLayout="column"
    fxLayoutAlign="center"
    style="background-color: white; padding: 0 10%"
  >
    <p></p>
    <h2>BUD Fund 專項基金</h2>
    <h3>中小企內地計劃 | 自貿協定計劃</h3>
    <p>
      BUD Fund (Bud 基金) 是由生產力促進局 (HKPC) 於 2012 年 6
      月推出的企業支援計劃，基金總值 HKD$10
      億，為在香港有商業登記、非上市，並在本地有實際運作的中小企提供發展品牌、升級轉型及拓展營銷的資助。及後，政府在
      2020-21 年度《財政預算案》中進一步提出了 BUD
      專項基金的優化措施，注資15億，使 BUD Fund
      資助涵蓋內地及與香港簽有自貿協定計劃的地區，增至37個國家，BUD
      Fund資助上限擴至HKD$600萬，加拿大、法國、德國、意大利、荷蘭、瑞典、英國、日本、澳洲、鈕西蘭等多個和香港往來頻繁的國家。想了解更多
      BUD Fund 的申請資訊 ? 馬上為各位送上 BUD 專項基金申請全攻略 !
    </p>
  </section>

  <section
    fxLayout="column"
    fxLayoutAlign="center"
    style="background-color: whitesmoke; padding: 0 10%"
  >
    <h1 style="text-align: center">BUD Fund 三大資助範疇</h1>
    <p>
      留意 : 除了在計劃展開前遞交全盤的 BUD
      專項基金申請計劃書外，只要符合以下資助範圍，實施中的計劃之全部及部分項目亦可申請
      BUD Fund。
    </p>
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center">
      <div
        fxFlex="25"
        fxLayout="column"
        fxLayoutAlign="start center"
        fxFlex.xs="100"
        style="margin: 10px"
      >
        <img
          src="assets/images/icons/bud-label.png"
          alt="icon"
          style="width: 10rem"
        />
        <h2>BUD Fund 資助範疇一</h2>
        <h1>發展品牌</h1>
        <p>
          BUD Fund
          藉由資助發展品牌一環，協助中小企註冊品牌、制訂品牌形象設計等，使企業以更鮮明的企業品牌形象往內地、東盟、歐盟、澳紐地區進行更多品牌宣傳及推廣。
        </p>
      </div>
      <div
        fxFlex="25"
        fxLayout="column"
        fxLayoutAlign="start center"
        fxFlex.xs="100"
        style="margin: 10px"
      >
        <img
          src="assets/images/icons/bud-listen.png"
          alt="icon"
          style="width: 10rem"
        />
        <h2>BUD Fund 資助範疇二</h2>
        <h1>升級轉型</h1>
        <p>
          BUD Fund 能資助企業投放資源到新產品的創作、技術開發，以至 BPM
          管理系統的升級。例如企業正營運 OEM 業務，欲轉型為 ODM 或
          OBM，牽涉到的產品研發及管理優化都在 BUD Fund 資助範圍內。
        </p>
      </div>
      <div
        fxFlex="25"
        fxLayout="column"
        fxLayoutAlign="start center"
        fxFlex.xs="100"
        style="margin: 10px"
      >
        <img
          src="assets/images/icons/bud-globe.png"
          alt="icon"
          style="width: 10rem"
        />
        <h2>BUD Fund 資助範疇三</h2>
        <h1>拓展營銷</h1>
        <p>
          本地企業把產品內銷、分銷到東盟 (ASEAN)
          、歐盟、澳紐、英國、日本、或與香港簽訂了 FTA
          協議的地區時，如需要配合推廣活動、制定市場策略、建設銷售渠道等均可申請
          BUD Fund 資助。
        </p>
      </div>
    </div>
  </section>

  <section
    fxLayout="column"
    fxLayoutAlign="center"
    style="background-color: white; padding: 0 10%"
  >
    <h1 style="text-align: center">BUD Fund 申請資格及資助細節</h1>
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center">
      <div
        fxFlex="20"
        fxLayout="column"
        fxLayoutAlign="start center"
        fxFlex.xs="100"
        style="margin: 10px"
      >
        <img
          src="assets/images/icons/bud-search.png"
          alt="icon"
          style="width: 10rem"
        />
        <h1>BUD Fund 企業申請資格</h1>
        <ul>
          <li>擁有香港商業登記證 (HKBR)</li>
          <li>在港有實質業務營運</li>
          <li>非上市企業</li>
        </ul>
      </div>
      <div
        fxFlex="20"
        fxLayout="column"
        fxLayoutAlign="start center"
        fxFlex.xs="100"
        style="margin: 10px"
      >
        <img
          src="assets/images/icons/bud-coin.png"
          alt="icon"
          style="width: 10rem"
        />
        <h1>BUD Fund 資助金額</h1>
        <ul>
          <li>企業最多可申請 40 個項目</li>
          <li>項目最高可獲批 HKD$100 萬</li>
          <li>企業最高可累積 HKD$400 萬</li>
          <li>上限以內地計劃及自貿計劃共同計算</li>
        </ul>
      </div>
      <div
        fxFlex="20"
        fxLayout="column"
        fxLayoutAlign="start center"
        fxFlex.xs="100"
        style="margin: 10px"
      >
        <img
          src="assets/images/icons/bud-hands.png"
          alt="icon"
          style="width: 10rem"
        />
        <h1>BUD Fund 資助原則</h1>
        <ul>
          <li>項目均以對等原則計算資助</li>
          <li>政府最多資助核准開支的 50%</li>
          <li>企業須以現金形式承擔餘下開支</li>
        </ul>
      </div>
      <div
        fxFlex="20"
        fxLayout="column"
        fxLayoutAlign="start center"
        fxFlex.xs="100"
        style="margin: 10px"
      >
        <img
          src="assets/images/icons/bud-money.png"
          alt="icon"
          style="width: 10rem"
        />
        <h1>BUD Fund 資助撥款方式</h1>
        <ul>
          <li>如項目推行 18 個月或以內，完成後發放全數核准資助</li>
          <li>
            如項目推行 18 至 24
            個月內，可視乎進度及開支申請中期撥款，最高為核准資助的
            50%，完成後發放餘款
          </li>
          <li>
            如項目推行 24 個月，可申請首期撥款，即核准資助的 75%，完成後發放餘款
          </li>
        </ul>
      </div>
    </div>
  </section>

  <section
    fxLayout="column"
    fxLayoutAlign="center"
    style="background-color: whitesmoke; padding: 0 10%"
  >
    <h1 style="text-align: center">BUD Fund 應用例子</h1>
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center">
      <div
        fxFlex="30"
        fxLayout="column"
        fxLayoutAlign="start center"
        fxFlex.xs="100"
        style="margin: 10px"
      >
        <img
          src="assets/images/icons/bud-search.png"
          alt="icon"
          style="width: 10rem"
        />
        <h1>BUD Fund 宣傳及製作費用</h1>
        <p>
          產品發佈會、展覽場地及佈置，交通往宿，貨運物流，Marketing
          的海報，以至在海外 Ecommerce 電子商貿平台建立網店，例如Amazon 、Shopee
          、韓國樂天、Ebay等，都是Bud Fund 的資助範圍
        </p>
      </div>
      <div
        fxFlex="30"
        fxLayout="column"
        fxLayoutAlign="start center"
        fxFlex.xs="100"
        style="margin: 10px"
      >
        <img
          src="assets/images/icons/bud-coin.png"
          alt="icon"
          style="width: 10rem"
        />
        <h1>知識產權及產品檢測</h1>
        <p>
          產品檢測及認証，化學測試，3C中國強制性產品認証，CE歐洲合格認証，1SO
          9001 品質認証、ISO14001 管理認証、ISO27001
          資訊管理認証，及項專利和商標注冊，以及各種專利，都是Bud Fund
          的資助範圍
        </p>
      </div>
    </div>
  </section>

  <section
    fxLayout="column"
    fxLayoutAlign="center"
    style="background-color: white; padding: 0 10%"
  >
    <h1 style="text-align: center">BUD 專項基金申請所需文件</h1>
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center">
      <div
        fxFlex="30"
        fxLayout="column"
        fxLayoutAlign="start center"
        fxFlex.xs="100"
        style="margin: 10px"
      >
        <h1>BUD Fund 申請證明文件</h1>
        <ul>
          <li>BUD Fund 網上電子申請表格</li>
          <li>商業登記證副本</li>
          <li>公司註冊處周年申報表 (表格 AR1) 副本</li>
          <li>
            業務證明文件副本，如商業合約、辦公室租約、賬單、強積金供款記錄等
          </li>
          <li>上年度營業總額證明文件副本 (本年度成立的企業除外)</li>
          <li>
            (如有) 與執行項目的當地企業之投資關係證明文件副本，如雙方簽署的合約
          </li>
          <li>介紹申請企業的宣傳單張或印刷品</li>
        </ul>
      </div>
      <div
        fxFlex="30"
        fxLayout="column"
        fxLayoutAlign="start center"
        fxFlex.xs="100"
        style="margin: 10px"
      >
        <h1>BUD Fund 申請計劃書</h1>
        <ul>
          <li>申請資助項目的目的</li>
          <li>申請資助項目的推行方法及時間表</li>
          <li>申請資助項目的財政預算及預計成果</li>
          <li>申請資助項目的對企業及至香港經濟的預期效益</li>
        </ul>
      </div>
    </div>
  </section>

  <section
    fxLayout="column"
    fxLayoutAlign="center"
    style="background-color: whitesmoke; padding: 0 10%"
  >
    <h1 style="text-align: center">
      了解 BUD Fund 的申請資格後，你應該要做的事
    </h1>
    <p>
      我們作為BUD 專項基金申請顧問，從過往提供BUD
      專項基金顧問服務經驗中所遇到申請BUD
      Fund的失敗例子中，我們發現很多中小企為了得到 BUD 基金資助，在 BUD Fund
      申請計劃書中堆砌營運的資料，期望能將申請資助的金額加大，遺忘了申請 BUD
      Fund 的初衷及企業自身的營運狀況，最後是申請失敗或批出的 BUD
      基金資助比預期中少得多。到底申請 BUD Fund
      的成功條件是甚麼，申請痛點又在於甚麼，透過 BUD Fund 中小企又能實現甚麼 ?
    </p>
    <h2>BUD 基金資助能協助 SME 優化商業模式 :</h2>
    <ul>
      <li>意思即是從原料採購、到銷售、到扣除營運費用及報稅可以改進的環節。</li>
      <li>
        要拓展市場，企業必須要知道進入某個市場中 ( 例如越南 ) 特定 /
        尤其是香港沒有的開銷項目，如 VAT 或者是 Customs
        Duty，甚或是某市場政府因特定法例而衍生的開銷項目，如到 New Zealand
        超市上架產品就必須要有 barcode。
      </li>
      <li>
        若然沒有一定資源尋找相關市場顧問了解該地營商須知，SME
        又怎樣能寫出創科局眼中合理又可行的 BUD Fund 申請計劃書，成功申請 BUD
        基金資助呢 ? 市場上便有一些專業且經驗豐富的 BUD
        顧問就可以提供這些開銷項目 (i.e. expenses item) 的
        template，從而改進企業的商業模式 (Business Model)。
      </li>
    </ul>
    <h2>BUD 基金資助能協助 SME 實現盈利模式轉型 :</h2>
    <ul>
      <li>意思即是檢視收入結構、成本結構及相應的目標利潤。</li>
      <li>
        BUD 顧問能協助 SME 透過申請 BUD Fund
        資助解決因不了解某特定市場的盈利模式裡的所有變項
        (parameter)，從而無法估計在該市場的預計銷售收入的問題。
      </li>
      <li>
        例子一 : 企業想在越南透過市場推廣來增加銷售額，就要知道特定的 target
        audience 在哪個社交媒體最活躍 (e.g. Line)
      </li>
      <li>
        例子二 : BUD 顧問又可以協助 SME 了解怎樣透過多個市場 (i.e. Bigger
        Popluation & Potential Client) 同共承擔 R&D 的開銷，以增加每個訂單的
        gross profit。
      </li>
    </ul>
    <h2>
      為何申請 BUD Fund 咁重要 ?｜因為對 SME 而言，在內地 /
      東盟市場建立品牌猶如大海撈針……
    </h2>
    <h2>BUD 基金資助協助 SME 制定品牌 DNA :</h2>
    <ul>
      <li>
        意思即是從市場研調，SME 可從當地競爭對手中探索企業的品牌定位和特性。
      </li>
      <li>
        例子一 :
        以越南為例，其市場很受韓國文化影響。如果企業產品能先在韓國打響名堂，才再反攻越南市場，那麼就能提升品牌形象。
      </li>
      <li>
        例子二 :
        以印尼排華為例，當企業品牌進入印尼時，就要改一個有印尼特色的品牌名稱融入當地，減少中國色彩，避免誤中地雷。BUD
        Fund 提供的資助便能讓 SME 投放資源了解外地市場。
      </li>
    </ul>
    <h2>BUD 基金資助能協助 SME 拓展品牌市場 :</h2>
    <ul>
      <li>
        意思即是調查企業品牌於新地域市場的接受程度，分析品牌進駐該市場的可行性及優化空間。BUD
        顧問能協助 SME 透過申請 BUD Fund 資助投放資源到推銷新品牌。
      </li>
      <li>
        例子一 :
        化妝品牌一般以女性客人為主，但在泰國，男性亦會買化妝品。以市場規模來說，男的女性的目標客群就會比其他國家市場更大。
      </li>
      <li>
        例子二 : 又例如印尼最大的網站
        Tokopedia，便是透過將產品上架到銷售試點的過程測試客人反應後才開實體店。以上種種都可以由富有拓展外地市場的
        BUD 顧問向企業提出建議，確保 BUD Fund
        資助被充分利用，又能說服當局批核資助申請。
      </li>
    </ul>
    <h2>BUD Fund 能協助 SME 管理業務架構 :</h2>
    <ul>
      <li>
        意思即是組織內部資源分配，包括當地人員、科研、採購、生產、儲運、營銷等業務內容及其程序。
      </li>
      <li>BUD Fund 顧問可以協助企業了解當地法規。</li>
      <li>
        例子一 : 印尼法例規定僱員不可每週加班超過 3
        小時，否則僱主有機會犯法。有拓展東盟業務經驗的 BUD
        顧問便可提醒企業在聘請人手時就要預留預算，而 BUD Fund 資助便可用得其所。
      </li>
      <li>
        例子二 :
        如果想為企業在泰國招聘高管，除了人工外，還要有甚麼樣的福利才可以吸引高管人才留任
        ? 又或者在公司選址時，怎樣考慮附近住宅區的租金 ?
      </li>
    </ul>
    <p>對 BUD Fund 申請更有信心 ? 坐言起行啦，BUD Fund 全年都開放申請㗎 !</p>
  </section>

  <section
    fxLayout="column"
    fxLayoutAlign="center"
    style="background-color: white; padding: 0 10%"
  >
    <h1>BUD 專項基金審計</h1>
    <h2>
      BUD 專項基金審計 (Dedicated Fund on Branding, Upgrading and Domestic
      Sales)
    </h2>
    <p>
      為確保項目撥款只單一及恰當地用於項目，及支出/收入須符合核准項目的「項目開支」部份，有關帳目必須由獨立核數師進行審計，該名核數師必須是在《專業會計師條例》(第
      50 章) (核數師) 註冊的註冊會計師。
    </p>

    <h1>審計費用統一為 HK$10,000 / 每份報告 </h1>
    <h2>
      ****經審計項目支出表均由香港執業會計師或香港執業會計師事務所提供 ***
    </h2>
    <p>我們的服務計劃:</p>
    <ul>
      <li>審計師發出之項目支出表</li>
      <li>協助 BUD 相關申報及查詢</li>
      <li>統一審計費用為HK$10,000</li>
    </ul>
    <p>
      *我們已處理很多相關審計項目, 為順利安排完成相關審核及資助申報,
      我們將先收取一半作為行政費用，行政費將從審計及編製審計報告之費用中扣除。
    </p>
    <p>
      如果您想索取我們官方審計報價和誠信及不合謀報價，請電郵/上傳以下文件並填寫以下資料給我們
      , 為順利安排完成相關審核及資助申報,
      我們將先收取一半作為行政費用，行政費將從審計及編製審計報告之費用中扣除。:
    </p>
    <p>電郵 /上傳 以下文件給我們</p>
    <ul>
      <li>有效的商業登記證(BR)</li>
      <li>申請人身分證</li>
      <li>科技券項目名稱</li>
    </ul>
    <!-- <p>
      電郵到 chengzitin@taihk.com.hk並註名BUD 專項基金審計,或直接WhatsApp<img
        style="width: 1rem"
        src="assets/images/svg/whatsapp.svg"
        alt="whatsapp-logo"
      />上傳文件。
    </p> -->
    <app-whatsapp [lang]="'zh'"></app-whatsapp>

  </section>
</div>
<!-- end of zh -->

<!-- en -->
<div *ngIf="lang === 'en'" fxLayout="column" fxLayoutAlign="center center">
  <div style="height: 100vh" fxLayout="column" fxLayoutAlign="center center">
    <p>Only Chinese Version Available</p>
  </div>
</div>
<!-- end of en -->
