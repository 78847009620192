<!-- zh -->
<div *ngIf="lang === 'zh'" fxLayout="column" fxLayoutAlign="center stretch">
  <div class="form-wrapper">
    <form
      [formGroup]="registerLimitedCompanyForm"
      (ngSubmit)="onSubmit()"
      style="width: 80vw"
    >
      <h1>成立香港公司</h1>

      <!-- contact person -->
      <div [formGroup]="contactPerson">
        <h3>聯絡人</h3>

        <div>
          <mat-form-field>
            <mat-label>您的姓名〈需填寫〉</mat-label>
            <input
              matInput
              type="text"
              [formControl]="contactPersonNameControl"
              required
            />
            <mat-error *ngIf="contactPersonNameControl.hasError('required')"
              >必須填寫</mat-error
            >
          </mat-form-field>
        </div>

        <div>
          <mat-form-field>
            <mat-label> 稱呼〈需填寫〉 </mat-label>
            <mat-select
              type="text"
              [formControl]="contactPersonTitleControl"
              required
            >
              <mat-option value="mr">先生</mat-option>
              <mat-option value="ms">女士</mat-option>
            </mat-select>
            <mat-error *ngIf="contactPersonTitleControl.hasError('required')"
              >必須填寫</mat-error
            >
          </mat-form-field>
        </div>

        <div>
          <mat-form-field>
            <mat-label> 您的電子郵件信箱〈需填寫〉 </mat-label>
            <input
              matInput
              type="email"
              [formControl]="contactPersonEmailControl"
              required
            />
            <mat-error *ngIf="contactPersonEmailControl.hasError('required')"
              >必須填寫</mat-error
            >
            <mat-error *ngIf="contactPersonEmailControl.hasError('email')"
              >請填寫有效的電子郵件格式</mat-error
            >
          </mat-form-field>
        </div>

        <div>
          <mat-form-field>
            <mat-label> 聯絡電話〈需填寫〉 </mat-label>
            <input
              matInput
              type="text"
              [formControl]="contactPersonMobileControl"
              maxLength="8"
              minLength="8"
              required
            />
            <mat-error *ngIf="contactPersonMobileControl.hasError('required')"
              >必須填寫</mat-error
            >
            <mat-error *ngIf="contactPersonMobileControl.hasError('pattern')"
              >請填寫有效的聯絡電話</mat-error
            >
          </mat-form-field>
        </div>

        <div>
          <mat-form-field>
            <mat-label> 請問如何知道我們? </mat-label>
            <mat-select
              type="text"
              [formControl]="contactPersonReferSourceControl"
              multiple
            >
              <mat-option value="friends">朋友介紹</mat-option>
              <mat-option value="onlineAds">網上廣告</mat-option>
              <mat-option value="google">Google</mat-option>
              <mat-option value="yahoo">Yahoo</mat-option>
              <mat-option value="facebook">Facebook</mat-option>
              <mat-option value="other">其他</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <!-- company info -->
      <div formGroupName="companyInfo">
        <h3>擬成立有限公司資料</h3>

        <div>
          <mat-form-field>
            <mat-label>公司英文名稱第一選擇〈需填寫〉</mat-label>
            <input
              matInput
              type="text"
              [formControl]="companyInfoFirstEngNameControl"
              required
            />
            <mat-error
              *ngIf="companyInfoFirstEngNameControl.hasError('required')"
              >必須填寫</mat-error
            >
          </mat-form-field>
        </div>

        <div>
          <mat-form-field>
            <mat-label>公司英文名稱第二選擇〈需填寫〉</mat-label>
            <input
              matInput
              type="text"
              [formControl]="companyInfoSecondEngNameControl"
              required
            />
            <mat-error
              *ngIf="companyInfoSecondEngNameControl.hasError('required')"
              >必須填寫</mat-error
            >
          </mat-form-field>
        </div>

        <div>
          <mat-form-field>
            <mat-label>公司中文名稱第一選擇〈如有，需填寫〉</mat-label>
            <input
              matInput
              type="text"
              [formControl]="companyInfoFirstChiNameControl"
            />
          </mat-form-field>
        </div>
        <div>
          <mat-form-field>
            <mat-label>公司中文名稱第二選擇〈如有，需填寫〉</mat-label>
            <input
              matInput
              type="text"
              [formControl]="companyInfoSecondChiNameControl"
            />
          </mat-form-field>
        </div>

        <h3>公司註冊地址</h3>

        <div>
          <mat-form-field>
            <mat-label>
              選用本公司註冊地址 (每年 HK$1300，已包 HK$200 按金及 HK$300
              水牌費用)
            </mat-label>
            <mat-select
              [formControl]="companyInfoUseTaiHKAdressControl"
              required
            >
              <mat-option value="yes">是</mat-option>
              <mat-option value="no">否</mat-option>
            </mat-select>
            <mat-error
              *ngIf="companyInfoUseTaiHKAdressControl.hasError('required')"
              >必須填寫</mat-error
            >
          </mat-form-field>
        </div>

        <div>
          <mat-form-field>
            <mat-label>地址<span>*必須是香港地址</span></mat-label>
            <input
              matInput
              type="text"
              [formControl]="companyInfoAddressControl"
              required
            />
            <mat-error *ngIf="companyInfoAddressControl.hasError('required')"
              >必須填寫</mat-error
            >
          </mat-form-field>
        </div>

        <h3>註冊資本</h3>
        <div>
          <mat-label>
            <!-- <span> -->
            * 預設註冊資本為 港幣 $ 10,000 分作 10,000 股
            <!-- </span> -->
          </mat-label>
          <br />
          <br />
        </div>

        <div>
          <mat-form-field>
            <mat-label>發行股數</mat-label>
            <input
              matInput
              min="1"
              type="number"
              [formControl]="companyInfoIssueSharesControl"
              required
            />
            <mat-error
              *ngIf="companyInfoIssueSharesControl.hasError('required')"
              >必須填寫</mat-error
            >
          </mat-form-field>
        </div>

        <div>
          <mat-form-field>
            <!-- total face value = issue shares x share face value -->
            <mat-label>總面值（港幣）</mat-label>
            <input
              matInput
              min="1"
              type="number"
              [formControl]="companyInfoTotalFaceValueControl"
            />
            <mat-error
              *ngIf="companyInfoTotalFaceValueControl.hasError('required')"
              >必須填寫</mat-error
            >
          </mat-form-field>
        </div>
      </div>

      <!-- company secretary -->
      <div formGroupName="companySec">
        <h3>公司秘書</h3>

        <div>
          <mat-form-field>
            <mat-label> 選用本公司的公司秘書服務 (每年 HK$600) </mat-label>
            <mat-select [formControl]="companySecUseTaiHKSecControl" required>
              <mat-option value="yes">是</mat-option>
              <mat-option value="no">否</mat-option>
            </mat-select>
            <mat-error *ngIf="companySecUseTaiHKSecControl.hasError('required')"
              >必須填寫</mat-error
            >
          </mat-form-field>
        </div>

        <div>
          <mat-form-field>
            <mat-label> 秘書類型 </mat-label>
            <mat-select [formControl]="companySecTypeControl" required>
              <mat-option value="individual">個人</mat-option>
              <mat-option value="company">公司</mat-option>
            </mat-select>
            <mat-error *ngIf="companySecTypeControl.hasError('required')"
              >必須填寫</mat-error
            >
          </mat-form-field>
        </div>

        <div>
          <mat-form-field>
            <mat-label>英文名稱</mat-label>
            <input
              matInput
              type="text"
              [formControl]="companySecEngNameControl"
              required
            />
          </mat-form-field>
        </div>

        <div>
          <mat-form-field>
            <mat-label>中文名稱</mat-label>
            <input
              matInput
              type="text"
              [formControl]="companySecChiNameControl"
            />
          </mat-form-field>
        </div>

        <div>
          <mat-form-field>
            <mat-label>地址</mat-label>
            <input
              matInput
              type="text"
              [formControl]="companySecAddressControl"
            />
          </mat-form-field>
        </div>

        <div>
          <mat-form-field>
            <mat-label>身份証/護照/公司註冊號</mat-label>
            <input matInput type="text" [formControl]="companySecIdControl" />
          </mat-form-field>
        </div>

        <div>
          <mat-form-field>
            <mat-label
              >如個人，請上載身份証的掃描本（文件不得超過5MB）</mat-label
            >
            <ngx-mat-file-input
              [formControl]="companySecFileControl"
              [multiple]="false"
              [accept]="'image/*, .pdf'"
              (change)="onFileInputChange($event)"
              required
            >
              <mat-icon ngxMatFileInputIcon>folder</mat-icon>
              <!-- <mat-hint>Hint</mat-hint> -->
            </ngx-mat-file-input>
            <mat-error *ngIf="companySecTypeControl.hasError('required')"
              >必須填寫</mat-error
            >
            <!-- <mat-error *ngIf="companySecTypeControl.hasError('maxFileSize')"
        >文件超過5MB</mat-error
      > -->
          </mat-form-field>
        </div>
      </div>

      <!-- shareholder director info -->
      <ng-container formArrayName="shareholderDirectorInfo">
        <div
          *ngFor="let sd of shareholderDirectorInfoArray.controls; index as i"
        >
          <ng-container [formGroupName]="i">
            <h3>第{{ i + 1 }}位股東 / 董事資料</h3>

            <div class="display: flex; flex-direction: row; flex-wrap: wrap;">
              <div>
                <mat-form-field>
                  <mat-label>身份</mat-label>
                  <mat-select [formControl]="sd.get('roleControl')" required>
                    <mat-option value="shareholder">股東</mat-option>
                    <mat-option value="director">董事</mat-option>
                    <mat-option value="shareholderDirector"
                      >股東及董事</mat-option
                    >
                  </mat-select>
                  <mat-error *ngIf="sd.get('roleControl').hasError('required')"
                    >必須填寫</mat-error
                  >
                </mat-form-field>
              </div>

              <div>
                <mat-form-field>
                  <mat-label> 股東/董事類型 </mat-label>
                  <mat-select [formControl]="sd.get('typeControl')" required>
                    <mat-option value="Individual">個人</mat-option>
                    <mat-option value="Corporate">公司</mat-option>
                  </mat-select>
                  <mat-error *ngIf="sd.get('typeControl').hasError('required')"
                    >必須填寫</mat-error
                  >
                </mat-form-field>
              </div>

              <div
                *ngIf="
                  sd.get('roleControl').value === 'shareholder' ||
                  sd.get('roleControl').value === 'shareholderDirector'
                "
              >
                <mat-form-field>
                  <mat-label>股份數目</mat-label>
                  <input
                    matInput
                    type="number"
                    min="1"
                    [formControl]="sd.get('sharesControl')"
                    required
                  />
                  <mat-error
                    *ngIf="sd.get('sharesControl').hasError('required')"
                    >必須填寫</mat-error
                  >
                  <mat-error *ngIf="sd.get('sharesControl').hasError('min')"
                    >股份數目最小為1</mat-error
                  >
                </mat-form-field>
              </div>

              <div>
                <mat-form-field>
                  <mat-label>英文名稱</mat-label>
                  <input
                    matInput
                    type="text"
                    [formControl]="sd.get('engNameControl')"
                    required
                  />
                  <mat-error
                    *ngIf="sd.get('engNameControl').hasError('required')"
                    >必須填寫</mat-error
                  >
                </mat-form-field>
              </div>

              <div>
                <mat-form-field>
                  <mat-label>中文名稱</mat-label>
                  <input
                    matInput
                    type="text"
                    [formControl]="sd.get('chiNameControl')"
                  />
                  <!-- <mat-error *ngIf="sd.get('chiNameControl').hasError('required')"
                  >必須填寫</mat-error
                > -->
                </mat-form-field>
              </div>

              <div>
                <mat-form-field>
                  <mat-label>地址</mat-label>
                  <input
                    matInput
                    type="text"
                    [formControl]="sd.get('addressControl')"
                    required
                  />
                  <mat-error
                    *ngIf="sd.get('addressControl').hasError('required')"
                    >必須填寫</mat-error
                  >
                </mat-form-field>
              </div>

              <div>
                <mat-form-field>
                  <mat-label>身份証/護照/公司註冊號</mat-label>
                  <input
                    matInput
                    type="text"
                    [formControl]="sd.get('idControl')"
                    required
                  />
                  <mat-error *ngIf="sd.get('idControl').hasError('required')"
                    >必須填寫</mat-error
                  >
                </mat-form-field>
              </div>

              <div>
                <mat-form-field>
                  <mat-label>簽發/註冊地區</mat-label>
                  <input
                    matInput
                    type="text"
                    [formControl]="sd.get('idIssueControl')"
                    required
                  />
                  <mat-error
                    *ngIf="sd.get('idIssueControl').hasError('required')"
                    >必須填寫</mat-error
                  >
                </mat-form-field>
              </div>

              <div>
                <mat-form-field>
                  <mat-label>請上載証件的掃描本</mat-label>
                  <ngx-mat-file-input
                    [formControl]="sd.get('fileControl')"
                    [multiple]="false"
                    accept="image/*, .pdf"
                    (change)="onFileInputChange($event)"
                    required
                  >
                    <mat-icon ngxMatFileInputIcon>folder</mat-icon>
                    <mat-hint>接受 jpeg 或 png 檔案</mat-hint>
                  </ngx-mat-file-input>
                </mat-form-field>
              </div>

              <div
                *ngIf="
                  sd.get('roleControl').value === 'shareholder' ||
                  sd.get('roleControl').value === 'shareholderDirector'
                "
              >
                <mat-form-field>
                  <mat-label>擬成立公司資金來源</mat-label>
                  <input
                    matInput
                    type="text"
                    [formControl]="sd.get('fundSourceControl')"
                    placeholder="例子：公司資金來自股東個人儲蓄，他曾於「公司名稱」擔任「職位」"
                    required
                  />
                  <mat-error
                    *ngIf="sd.get('fundSourceControl').hasError('required')"
                    >必須填寫</mat-error
                  >
                </mat-form-field>
              </div>

              <div
                *ngIf="
                  sd.get('roleControl').value === 'shareholder' ||
                  sd.get('roleControl').value === 'shareholderDirector'
                "
              >
                <mat-form-field>
                  <mat-label>是否擬成立公司之實益擁有人</mat-label>
                  <mat-select [formControl]="sd.get('ownerControl')" required>
                    <mat-option value="yes">是</mat-option>
                    <mat-option value="no">否</mat-option>
                  </mat-select>
                  <mat-error *ngIf="sd.get('typeControl').hasError('required')"
                    >必須填寫</mat-error
                  >
                </mat-form-field>
              </div>

              <div *ngIf="sd.get('ownerControl').value == 'no'">
                <div>
                  <mat-form-field>
                    <mat-label>實益擁有人英文名稱</mat-label>
                    <input
                      matInput
                      type="text"
                      [formControl]="sd.get('ownerEngNameControl')"
                      required
                    />
                  </mat-form-field>
                </div>

                <div>
                  <mat-form-field>
                    <mat-label>實益擁有人中文名稱</mat-label>
                    <input
                      matInput
                      type="text"
                      [formControl]="sd.get('ownerChiNameControl')"
                    />
                  </mat-form-field>
                </div>

                <div>
                  <mat-form-field>
                    <mat-label>實益擁有人地址</mat-label>
                    <input
                      matInput
                      type="text"
                      [formControl]="sd.get('ownerAddressControl')"
                      required
                    />
                  </mat-form-field>
                </div>

                <div>
                  <mat-form-field>
                    <mat-label>實益擁有人身份証/護照/公司註冊號</mat-label>
                    <input
                      matInput
                      type="text"
                      [formControl]="sd.get('ownerIdControl')"
                      required
                    />
                  </mat-form-field>
                </div>

                <div>
                  <mat-form-field>
                    <mat-label>簽發地區</mat-label>
                    <input
                      matInput
                      type="text"
                      [formControl]="sd.get('ownerIdIssueControl')"
                      required
                    />
                  </mat-form-field>
                </div>

                <div>
                  <mat-form-field>
                    <mat-label>請上載實益擁有人証件的掃描本</mat-label>
                    <ngx-mat-file-input
                      [formControl]="sd.get('ownerFileControl')"
                      [multiple]="false"
                      accept="image/*, .pdf"
                      (change)="onFileInputChange($event)"
                      required
                    >
                      <mat-icon ngxMatFileInputIcon>folder</mat-icon>
                      <mat-hint>接受 jpeg 或 png 檔案</mat-hint>
                    </ngx-mat-file-input>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </ng-container>
        </div>

        <div fxLayout fxLayoutAlign="center center">
          <button
            mat-raised-button
            type="button"
            (click)="addShareholderDirector()"
          >
            添加股東 / 董事資料
          </button>
          <button
            mat-raised-button
            type="button"
            color="warn"
            [disabled]="shareholderDirectorInfoArray.length == 1"
            (click)="
              removeShareholderDirector(shareholderDirectorInfoArray.length - 1)
            "
          >
            刪除股東 / 董事資料
          </button>
        </div>
      </ng-container>

      <br />
      <div fxLayout fxLayoutAlign="center center">
        <button
          mat-raised-button
          type="submit"
          color="primary"
          [disabled]="registerLimitedCompanyForm.invalid"
        >
          申請成立香港公司
        </button>
      </div>

      <!-- <button mat-raised-button type="button" (click)="view()">
      View
    </button> -->
    </form>
    <br />
  </div>
</div>
<!-- end of zh -->

<!-- en -->
<div *ngIf="lang === 'en'" fxLayout="column" fxLayoutAlign="center stretch">
  <div style="height: 100vh" fxLayout="column" fxLayoutAlign="center center">
    <p>Only Chinese Version Available</p>
  </div>
</div>
<!-- end of en -->
