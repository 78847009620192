<!-- zh -->
<div *ngIf="lang === 'zh'" fxLayout="column" fxLayoutAlign="center stretch">
  <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
    <img src="assets/images/ngo.jpg" alt="" class="banner-image" />
    <div class="banner-text">
      <p>成立慈善團體NGO</p>
    </div>
  </div>
  <section
    fxLayout="row"
    fxLayoutAlign="center"
    style="background-color: white"
  >
    <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
      <h1>成立慈善團體（NGO）／香港擔保有限公司</h1>
      <h2>認可慈善機構團體（NGO）組成要求？</h2>
      <ul>
        <li>成立香港擔保有限公司</li>
        <li>香港擔保有限公司的基本結構要求是至少 2 名成員</li>
        <li>至少 2 名董事 (法人團體不得出任公眾公司或擔保有限公司的董事)</li>
        <li>至少 1 名公司秘書</li>
      </ul>

      <h2>香港擔保有限公司和股份有限公司於出資方面有何不同？</h2>
      <p>
        擔保有限公司如公司面臨解期，每名擔保人必須貢獻相同而固定的擔保金額，没有商業味道的投資性質，是貢獻精神，因此是没有股本配發。
      </p>
      <p>
        股份有限公司是每個股東必須要投資約定的資本，資本金額取決於股東願意投資於公司股本百分比，是有商業味道的投資性質，因此股份有限公司是有股本配發。
      </p>

      <h2>什麼是認可慈善機構團體 (NGO)？</h2>
      <p>
        認可慈善團體是根據《稅務條例》第 88
        條獲豁免繳稅的屬公共性質的慈善機構或慈善信託作慈善用途的款項，或指捐贈給政府作慈善用途的款項。(《稅務條例》第
        2 條) 如成功獲認可為慈善團體，公司會被列入《稅務條例》第 88
        條獲豁免繳稅的慈善機構及慈善信託的名單，普羅大眾可於稅務局網頁內 -
        <a href="https://www.ird.gov.hk/chi/tax/ach_index.htm" target="_blank"
          >獲豁免繳稅的慈善機構及慈善信託的名單</a
        >, 查看他們的捐款能否在報稅時申請扣除。
      </p>
      <!-- <p>
        <a href="https://www.ird.gov.hk/chi/tax/ach_index.htm" target="_blank"
          >獲豁免繳稅的慈善機構及慈善信託的名單名單</a
        >
      </p> -->

      <h2>認可慈善機構團體（NGO）設立目的需要符合難那些要求？</h2>
      <p>
        成立香港擔保有限公司後，慈善團體 (NGO)
        的需要為其組織章程設立目的，慈善團體成立目的需符合 1）救助貧困
        2）促進教育 3）推廣宗教
        及4）其他有益於社會而具慈善性質的宗旨，稅務局才接納有關《稅務條例》第 88
        條獲豁免繳稅的慈善機構申請。
      </p>
    </div>
  </section>

  <app-four-steps></app-four-steps>
  <section
    fxLayout="row"
    fxLayoutAlign="center"
    style="background-color: white"
  >
    <div
      fxLayout="column"
      fxFlex="60"
      fxFlex.xs="80"
      fxLayoutAlign="center stretch"
    >
      <div
        fxLayout="row"
        fxLayoutAlign="center start"
        fxLayoutAlign.xs="center center"
        fxLayout.xs="column"
      >
        <div
          fxFlex="50"
          fxFlex.xs="80"
          fxLayoutAlign="center stretch"
          style="margin: 10px"
        >
          <app-ngo-price-chart
            [priceChartData]="plans.PLAN_A_ZH"
          ></app-ngo-price-chart>
        </div>
      </div>
    </div>
  </section>
  <section fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
    <div>
      <p></p>
      <p></p>
      <h2>成立慈善團體 (NGO)</h2>
      <p>
        我們會根據稅務條列第88條協助你向稅務局申請稅務括免，慈善團體成立流程包括成立擔保有限公司、草擬一份慈善團體的宗旨和未來一年的計劃書，我們經驗豐富的團隊可為你處理向稅務局申請的所有文件。
        慈善團體資格獲稅務局批准後，你們可以選擇刪除公司名稱中的「有限公司」字眼及商業登記署會停止發出商業登記證。
      </p>
      <p>
        如你們對成立香港擔保有限公司及慈善團體(NGO)有任何問題，歡迎聯繫我們與我們成立顧問團隊聯繫。
      </p>
      <app-whatsapp [lang]="'zh'"></app-whatsapp>
    </div>
  </section>
</div>
<!-- end of zh -->

<!-- en -->
<div *ngIf="lang === 'en'" fxLayout="column" fxLayoutAlign="center center">
  <div style="height: 100vh" fxLayout="column" fxLayoutAlign="center center">
    <p>Only Chinese Version Available</p>
  </div>
</div>
<!-- end of en -->
