import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import {
  FormBuilder,
  Validators,
  FormGroup,
  FormControl,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from '../data.service';
import * as firebase from 'firebase/app';

@Component({
  selector: 'app-request-manual-quotation-dialog',
  templateUrl: './request-manual-quotation-dialog.component.html',
  styleUrls: ['./request-manual-quotation-dialog.component.scss'],
})
export class RequestManualQuotationDialogComponent implements OnInit {
  lang: string;
  contactForm = this.fb.group({
    companyControl: ['', [Validators.required]],
    contactPersonControl: ['', [Validators.required]],
    emailControl: ['', [Validators.required, Validators.email]],
    mobileControl: [
      '',
      [Validators.required, Validators.pattern('^[23456789][0-9]{7}$')],
    ],
  });
  serviceData;
  serviceId: string;
  constructor(
    private fb: FormBuilder,
    public translateService: TranslateService,
    private afs: AngularFirestore,
    public dialogRef: MatDialogRef<RequestManualQuotationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar,
    public dataService: DataService
  ) {
    this.lang = this.translateService.currentLang;
    this.serviceId = data.serviceId;
    this.serviceData = dataService.services;
  }

  ngOnInit(): void {}

  async onSubmit() {
    try {
      const requestQuotationData = {
        company_name: this.companyControl.value,
        contact_person: this.contactPersonControl.value,
        contact_number: this.mobileControl.value,
        contact_email: this.emailControl.value,
        service_en: this.serviceData[this.data.serviceId].name_en,
        service_zh: this.serviceData[this.data.serviceId].name_zh,
        created_at: firebase.firestore.FieldValue.serverTimestamp(),
      };

      await this.afs.collection('manual-quotations').add(requestQuotationData);
      const message =
        this.lang == 'zh'
          ? `多謝您的查詢，我們將會跟你聯絡，為您提供報價單`
          : `Thank you for your enquiry, we will contact you and provide quotation`;
      this.openSnackBar(message);
      this.dialogRef.close();
      
    } catch (err) {
      console.error('error saving data to firestore', err);
      const message =
        this.lang == 'zh'
          ? `查詢報價單出現問題，請您從新嘗試`
          : `Error occurred during your enquiry, please try again`;
      this.openSnackBar(message);
      this.dialogRef.close();
    }
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, 'x', {
      duration: 5000,
    });
  }

  get companyControl() {
    return this.contactForm.get('companyControl') as FormControl;
  }

  get contactPersonControl() {
    return this.contactForm.get('contactPersonControl') as FormControl;
  }

  get emailControl() {
    return this.contactForm.get('emailControl') as FormControl;
  }

  get mobileControl() {
    return this.contactForm.get('mobileControl') as FormControl;
  }
}
