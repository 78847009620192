import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from '../data.service';

@Component({
  selector: 'app-com-sec',
  templateUrl: './com-sec.component.html',
  styleUrls: ['./com-sec.component.scss'],
})
export class ComSecComponent implements OnInit {
  lang: string;
  services: string[];
  serviceData;
  constructor(
    public translateService: TranslateService,
    public data: DataService
  ) {
    this.lang = this.translateService.currentLang;
    this.translateService.onLangChange.subscribe(
      (event) => (this.lang = event.lang)
    );
    this.serviceData = data.services;
  }

  ngOnInit(): void {
    this.services = [
      'change_address',
      'change_nature',
      'change_limited_com_name',
      'change_unlimited_com_name',
      'apply_branch',
      'increase_shares',
      // 'transfer_shares',
      'change_director',
      'change_director_info',
      'dereg_unlimited_com',
      'dereg_limited_com',
      'meeting_record',
      'annual_meeting_record',
      'change_article',
    ];
  }
}
