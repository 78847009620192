// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyC5mrXxXaxsj987ZncO1hoLbes1O_Q00Gk',
    authDomain: 'tai-hong-kong-platform.firebaseapp.com',
    databaseURL: 'https://tai-hong-kong-platform.firebaseio.com',
    projectId: 'tai-hong-kong-platform',
    storageBucket: 'tai-hong-kong-platform.appspot.com',
    messagingSenderId: '654324517306',
    appId: '1:654324517306:web:40fdc5cc8df8a4a7a5f583',
    measurementId: 'G-J9BW2C9B2F',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
