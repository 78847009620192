<!-- zh -->
<div *ngIf="lang === 'zh'" fxLayout="column" fxLayoutAlign="center stretch">
  <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
    <img src="assets/images/trim-prc.jpg" alt="prc" class="banner-image" />
    <div class="banner-text" style="transform: translate(40%, -80%)">
      <p style="color: white; font-size: 5vw; margin: 0; padding: 0">
        中國 WFOE 成立服務
      </p>
      <p style="color: white; font-weight: lighter; font-size: 2.5vw">
        香港及海外企業適用
      </p>
    </div>
    <div class="banner-text" style="transform: translate(100%, 50%)">
      <p style="color: white; font-size: 3vw">大香港中國企業服務部</p>
    </div>
    <!-- 
    <p
      class="banner-text"
      fxShow.gt-xs="false"
      style="font-size: 1rem; right: 10vw"
    >
      中國 WFOE 成立服務<br />香港及海外企業適用<br />大香港中國企業服務部
    </p> -->
  </div>

  <section
    fxLayout="row"
    fxLayoutAlign="center"
    style="background-color: white"
  >
    <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
      <p></p>
      <h1>成立中國公司</h1>
      <ul>
        <li>註冊費用: <span>HK$5,599</span> (深圳)</li>
        <li>註冊費用: <span>HK$12,999</span> (廣州)</li>
        <li>註冊費用: <span>HK$15,999</span> (北京)</li>
        <li>註冊費用: <span>HK$15,999</span> (上海)</li>
        <li>國內公司報稅: <span>HK$899</span> /月</li>
        <li>國內公司年檢: <span>HK$5,399</span></li>
      </ul>
      <app-whatsapp [lang]="'zh'"></app-whatsapp>
      <br />

      <p>
        大香港提供完善的註冊中國公司服務，為客戶處理註冊中國公司的多重工序，投資者可透過成立外商獨資公司在中國內地註冊成立公司。
      </p>
      <p>大香港為客戶可在以下的中國城市辦理公司註冊服務:</p>
      <ul>
        <li>廣州</li>
        <li>深圳</li>
        <li>北京</li>
        <li>上海</li>
      </ul>

      <h3>我們的服務</h3>
      <ul>
        <li>協助辦理註冊過程及中國公證（中國公證費用須由客人承擔）</li>
        <li>提供地址作註冊用途</li>
        <li>會計及稅務申報</li>
      </ul>

      <h3>外資企業的優勢</h3>
      <ul>
        <li>可有能力正式開展業務，無須像辦事處一樣有諸多限制。</li>
        <li>可向用戶開人民幣發票，並以人民幣作為收入。</li>
        <li>用人民幣利潤轉化成美元向境外母公司匯付。</li>
        <li>直接在中國僱傭員工。</li>
      </ul>

      <h3>外資企業的營業範圍</h3>
      <ul>
        <li>
          在中國所有行業的營業範圍都是限制得十分嚴格。外資企業只能在其允許的營業範圍內進行商業活動，此範圍需於營業執照上標明。
        </li>
      </ul>

      <h3>成立外資公司要求</h3>
      <ul>
        <li>必須要有實際的註冊地址</li>
        <li>至少 1 名股東，股東無國籍限制</li>
        <li>至少 1 名董事，如董事只有一位，該人士會同時擔任法定代表人</li>
        <li>至少 1 名監事，不可同時擔任中國公司的董事或法定代表人</li>
      </ul>

      <h3>註冊資本要求</h3>
      <ul>
        <li>貿易類：RMB500,000(批發)、RMB300,000(零售)</li>
        <li>
          國際貨運代理：RMB5,000,000(海運)、RMB3,000,000(空運)、RMB2,000,000(陸運)
        </li>
        <li>軟件開發、諮詢、設計：RMB100,000</li>
      </ul>

      <h3>需提供資料</h3>
      <ul>
        <li>股東、董事、監事身份證(2份)，需經中國公證</li>
        <li>股東的銀行資金證明 （需附加中文翻譯本）</li>
        <li>辦公室租約原件</li>
        <li>法人國內實名驗証的電話號碼</li>
      </ul>
      <p>如股東為另一有限公司,需提供以下中國公證 (2份):</p>
      <ul>
        <li>公司註冊證書</li>
        <li>最近一年之商業登記</li>
        <li>最近一年之周年申報表</li>
        <li>董事會決議</li>
      </ul>

      <h3>每年申報時間</h3>
      <ul>
        <li>會計及稅務申報：每月15日前</li>
        <li>年度稅審報告：1月1日至5月31日</li>
        <li>年度審計報告：1月1日至6月30日</li>
        <li>工商年檢：1月1日至6月30日</li>
      </ul>

      <h3>外資企業稅率</h3>
      <p>企業所得稅</p>
      <p>
        小型微利企業年度利潤在100萬以內，按照利潤的2.5%繳納，超過100萬元但不超過300萬元的部分，按照利潤的10%，超過300萬不屬於小型微利企業了，所有的按照行業標準計算，如果不是特殊行業，就是利潤的25%徵收（小型微利企業是指從事國家非限制和禁止行業,且同時符合年度應納稅所得額（註：即評稅利潤）不超過300萬元、從業人數不超過300人、資產總額不超過5000萬元等三個條件的企業）。
      </p>
      <p>
        小型微利企業不分小規模還是一般納稅人，主要是根據利潤區分，按季度申報，年終彙算清繳再重新計算，多退少補。
      </p>
      <!-- <ul>
        <li>稅率為25%</li>
      </ul> -->
      <p>增值稅</p>
      <p>
        如是小規模納稅人（一般年營業額不超500W爲小規模納稅人），增值稅稅率爲3%（疫情期間有減免，現在可以最低到1%），不管是貿易類還是服務類統一稅率；如是一般納稅人（一般年營業額超500W或主動由小規模納稅人升級），增值稅稅率爲6%（普通服務類），9%（工程類、物業租賃、倉儲物流等），13%（貨物類），但一般納稅人可以通過合法的進項增值稅專用發票進行抵扣。
      </p>
      <!-- <ul>
        <li>年銷售額達到80萬以上的一般為17%</li>
        <li>年銷售額80萬以下的為3%</li>
      </ul> -->

      <h3>辦理外資企業所需時間</h3>
      <p>約7個工作日完成。（不包括郵遞時間）</p>

      <h3>中國公司開內地戶口毋須返大陸</h3>
      <p>
        成立中國公司後，我們能提供遙距的銀行開戶服務，毋須親臨中國內地的銀行亦可完成整個開戶安排。
      </p>
      <p>
        因為大部分提供遙距開戶服務的銀行，都不接受客戶的直接申請，因此，客戶需要先聯絡銀行指定的銀行開戶介紹人，經轉介至相關銀行以開始遙距開戶。
      </p>
      <p>
        大香港與內地的銀行有長久的聯繫，亦是部分銀行的指定開戶中間人。為您省時省力的方法達致開戶成功。
      </p>
      <app-whatsapp [lang]="'zh'"></app-whatsapp>
      <br />
    </div>
  </section>

  <section
    fxLayout="row"
    fxLayoutAlign="center"
    style="background-color: whitesmoke"
  >
    <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
      <h3>離岸戶口開戶小貼士：</h3>
      <ul>
        <li>最低結餘限制</li>
        <p>
          大多數銀行都要求最低存款來開立賬戶。因此，您需要在合理範圍內尋找最適合您的銀行。
        </p>
        <li>初存限制</li>
        <p>
          留意銀行有没有要求戶口開立後需存放一定金額的存款定存一段時間的要求。因此，您要預備充足儲備作資金流轉。
        </p>
        <li>公司成立司法地限制</li>
        <p>
          一般來說企業註冊國家/地區與開戶銀行國家/地區相同是比企業註冊國家/地區與開戶銀行國家/地區不同容易開啓在岸銀行戶口。
        </p>
        <li>高風險國家限制</li>
        <p>
          如銀行開立後戶口往來有涉及高風險國家或人士，戶口容易被凍結或戶口喪失某部份功能例如與其他銀行間轉帳的能力。
        </p>
        <li>公司成立司法地資金管制限制</li>
        <p>留意部份國家實施外匯管制，資金流出的限制。</p>
      </ul>
    </div>
  </section>

  <section
    fxLayout="row"
    fxLayoutAlign="center"
    style="background-color: white"
  >
    <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
      <p></p>
      <h3>BUD 專項基金 - 香港政府資助高達600萬 助你發展中國巿場</h3>
      <p>
        中國屬BUD基金自貿計劃資助的巿場，資助香港的中小企向外發展，每項資助額為50%，每間公司最高可獲資助600萬，如有興趣，<a
          href="/bud"
          >請按此了解詳情。</a
        >
      </p>
      <app-whatsapp [lang]="'zh'"></app-whatsapp>
    </div>
  </section>

  <section
  fxLayout="row"
  fxLayoutAlign="center"
  style="background-color: whitesmoke"
>
  <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
    <p><strong>離岸戶口開戶小貼士：</strong></p>
    <ul>
      <li>
        <strong>最低結餘限制</strong>
        <p>
          大多數銀行都要求最低存款來開立賬戶。
          因此，您需要在合理範圍內尋找最適合您的銀行。
        </p>
      </li>
      <li>
        <strong>初存限制 </strong>
        <p>
          留意銀行有没有要求戶口開立後需存放一定金額的存款定存一段時間的要求。
          因此，您要預備充足儲備作資金流轉。
        </p>
      </li>
      <li>
        <strong>公司成立司法地限制 </strong>
        <p>
          一般來說企業註冊國家/地區與開戶銀行國家/地區相同是比企業註冊國家/地區與開戶銀行國家/地區不同容易開啓在岸銀行戶口。
        </p>
      </li>
      <li>
        <strong>高風險國家限制 </strong>
        <p>
          如銀行開立後戶口往來有涉及高風險國家或人士，戶口容易被凍結或戶口喪失某部份功能例如與其他銀行間轉帳的能力。
        </p>
      </li>
      <li>
        <strong>公司成立司法地資金管制限制 </strong>
        <p>留意部份國家實施外匯管制，資金流出的限制。</p>
      </li>
    </ul>
  </div>
</section>
</div>
<!-- end of zh -->

<!-- en -->
<div *ngIf="lang === 'en'" fxLayout="column" fxLayoutAlign="center stretch">
  <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
    <img src="assets/images/trim-prc.jpg" alt="prc" class="banner-image" />
    <div class="banner-text" style="transform: translate(15%, -80%)">
      <p style="color: white; font-size: 4.5vw; margin: 0; padding: 0">
        China WFOE Formation Services
      </p>
      <p style="color: white; font-weight: lighter; font-size: 2.5vw">
        for Hong Kong and overseas enterprises
      </p>
    </div>
    <div class="banner-text" style="transform: translate(100%, 50%)">
      <p style="color: white; font-size: 3vw">大香港中國企業服務部</p>
    </div>

    <!-- <p
      class="banner-text"
      fxShow.gt-xs="false"
      style="font-size: 1rem; right: 10vw"
    >
      China WFOE Formation Services
      <br />for Hong Kong and overseas enterprises<br />大香港中國企業服務部
    </p> -->
  </div>
  <section
    fxLayout="row"
    fxLayoutAlign="center"
    style="background-color: white"
  >
    <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
      <p></p>
      <h1>Incorporation of Foreign-owned Enterprises in China</h1>
      <ul>
        <li>Registration fee: <span>HK$5,599</span> (Shenzhen)</li>
        <li>Registration fee: <span>HK$12,999</span> (Guangzhou)</li>
        <li>Registration fee: <span>HK$15,999</span> (Beijing)</li>
        <li>Registration fee: <span>HK$15,999</span> (Shanghai)</li>
        <li>Tax reporting fee: <span>HK$899</span> per month</li>
        <li>Annual Audit fee: <span>HK$5,399</span></li>
      </ul>

      <app-whatsapp [lang]="'en'"></app-whatsapp>
      <br />

      <p>
        Tai Hong Kong offers high quality registered services of China Company,
        we help client to save their heavy workload in company registration.
      </p>

      <h3>Overview of Foreign-owned Enterprises</h3>
      <p>
        We provide through-train service for China incorporation, company could
        be formed by Wholly Own Foreigner Enterprise.
      </p>
      <p>
        We provide the company registration service in the following cities in
        China:
      </p>
      <ul>
        <li>Guangzhou</li>
        <li>Shenzhen</li>
        <li>Beijing</li>
        <li>Shanghai</li>
      </ul>

      <h3>Our Services</h3>
      <ul>
        <li>Assist with the registration process and Chinese notary</li>
        <li>Provide Address for Registration</li>
        <li>Accounting and Tax Returns</li>
      </ul>

      <h3>Advantages of Foreign-owned Enterprises</h3>
      <ul>
        <li>
          Be able to do business formally without having to have as many
          limitations as your office.
        </li>
        <li>Users can open RMB invoices, and RMB as revenue.</li>
        <li>
          Renminbi profit is converted into USD to remit to overseas parent
          company.
        </li>
        <li>Employ employees directly in China.</li>
      </ul>

      <h3>Business Scope of Foreign-owned Enterprises</h3>
      <ul>
        <li>
          There is a strict limitation on carrying business in China.
          Foreign-owned Enterprises only allowed to carry business within its
          allowed business scope which is stated on its business certificate.
        </li>
      </ul>

      <h3>Requirements for setting up a foreign company</h3>
      <ul>
        <li>An actual registered address</li>
        <li>
          At least one shareholder. Shareholders without citizenship
          restrictions
        </li>
        <li>
          At least one director. If there is only one director, who will serve
          as legal representative
        </li>
        <li>
          At least 1 supervisor, who cannot concurrently as a director or legal
          representative of a Chinese company
        </li>
      </ul>

      <h3>Registered capital requirements</h3>
      <ul>
        <li>Trade: RMB500,000 (wholesale), RMB300,000 (retail)</li>
        <li>
          International Freight Forwarders: RMB5,000,000 (Sea), RMB3,000,000
          (Air), RMB2,000,000 (Land)
        </li>
        <li>Software Development, Consulting, Design: RMB100,000</li>
      </ul>

      <h3>Information Required</h3>
      <ul>
        <li>
          Identity card of shareholders, directors and supervisors (2 copies),
          subject to Chinese notary
        </li>
        <li>
          Shareholder’s bank credit certificate (additional Chinese translation
          required)
        </li>
        <li>Original Office Lease</li>
      </ul>
      <p>
        If the shareholder is another limited company, the following Chinese
        notarization (2 copies) is required:
      </p>
      <ul>
        <li>Certificate of Incorporation</li>
        <li>Last year’s business registration</li>
        <li>Annual Return of the Latest Year</li>
      </ul>

      <h3>Annual filing hours</h3>
      <ul>
        <li>Accounting and tax filing: 15th of each month</li>
        <li>Annual Tax Review: 1 January to 31 May</li>
        <li>Annual Auditor’s Report: 1 January to 30 June</li>
        <li>Degree annual inspection: 1 January to 30 June</li>
      </ul>

      <h3>Tax rate</h3>
      <p>Enterprise Income Tax</p>
      <ul>
        <li>Tax rate is 25%</li>
      </ul>
      <p>VAT</p>
      <ul>
        <li>Annual sales of more than 80 million: 17%</li>
        <li>Annual sales of 800,000 or less: 3%</li>
      </ul>

      <h3>Time Required for the Application</h3>
      <ul>
        <li>Around 30 working days.</li>
      </ul>
    </div>
  </section>
</div>
