<div *ngIf="lang == 'zh'">
  <p>
    查詢：<strong>(852) 3611 5771／3611 5772</strong> 或
    <a href="https://wa.me/85295126339">WhatsApp (852) 9512 6339</a>
    <img src="assets/images/svg/whatsapp.svg" alt="whatsapp-logo" />查詢!
  </p>
</div>

<div *ngIf="lang == 'en'">
  <p>
    Enquiry：<strong>(852) 3611 5771／3611 5772</strong> or
    <a href="https://wa.me/85295126339">WhatsApp (852) 9512 6339</a>
    <img src="assets/images/svg/whatsapp.svg" alt="whatsapp-logo" />enquiry!
  </p>
</div>
