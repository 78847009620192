import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-singapore',
  templateUrl: './singapore.component.html',
  styleUrls: ['./singapore.component.scss'],
})
export class SingaporeComponent implements OnInit {
  lang: string;
  constructor(
    public translateService: TranslateService,
    private meta: Meta,
    private title: Title
  ) {
    this.lang = this.translateService.currentLang;
    this.translateService.onLangChange.subscribe(
      (event) => (this.lang = event.lang)
    );
    this.meta.addTags([
      {
        name: 'description',
        content:
          '成立新加坡公司/開新加坡銀行戶口-大香港離岸企業。註冊新加坡公司後，助你遙距開戶口毋須親往新加坡。根據新加坡ACRA商業局規定，董事必須是高於18歲的自然人，公司的董事會可以由一名或多名董事組成',
      },
      { name: 'keywords', content: '新加坡, 成立公司, 銀行開戶, 註冊公司' },
    ]);
    this.title.setTitle('成立新加坡公司');
  }

  ngOnInit(): void {}
}
