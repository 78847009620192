<section style="padding-bottom: 6rem">
  <div fxLayout="column" fxLayoutAlign="space-around center">
    <h1>團隊</h1>

    <div
      fxFlex="80"
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutAlign="center stretch"
    >
      <!-- ceo -->
      <div fxFlex="25" class="team-box" fxHide.xs>
        <div class="front chairman">
          <p>行政總裁</p>
        </div>
        <div class="back">
          <h3>行政總裁</h3>
          <h3><strong>鄭婕賢小姐</strong></h3>
          <ul>
            <li>香港執業會計師</li>
            <li>香港稅務學會資深會員</li>
            <li>香港華人會計師公會會員</li>
          </ul>

          <div fxLayout="row">
            <img
              src="assets/images/square-cpa-logo.png"
              alt=""
              class="prof-logo"
            />
            <img src="assets/images/tax-logo.png" alt="" class="prof-logo" />
            <img src="assets/images/ca-logo.png" alt="" class="prof-logo" />
          </div>
        </div>
      </div>

      <div
        fxFlex="80"
        fxHide.gt-xs
        fxLayout="column"
        fxLayoutAlign="space-evenly center"
        style="padding: 0 2rem"
      >
        <h3>主席</h3>
        <h3><strong>鄭婕賢小姐</strong></h3>
        <ul>
          <li>香港執業會計師</li>
          <li>香港稅務學會資深會員</li>
          <li>香港華人會計師公會會員</li>
        </ul>

        <div fxLayout="row" fxLayoutAlign="space-evenly center">
          <img
            src="assets/images/square-cpa-logo.png"
            alt=""
            style="margin: 1vw; width: 10vw; height: 10vw"
          />
          <img
            src="assets/images/tax-logo.png"
            alt=""
            style="margin: 1vw; width: 10vw; height: 10vw"
          />
          <img
            src="assets/images/ca-logo.png"
            alt=""
            style="margin: 1vw; width: 10vw; height: 10vw"
          />
        </div>
      </div>

      <!-- consultant -->
      <div fxFlex="25" class="team-box" fxHide.xs>
        <div class="front consultant">
          <p>榮譽顧問</p>
        </div>
        <div class="back">
          <h3>榮譽顧問</h3>
          <h3><strong>司徒文輝先生</strong></h3>
          <ul>
            <li>工商管理碩士（MBA）</li>
            <li>美國公認會計師公會會員（CPA（USA））</li>
            <li>紐約州注冊會計師</li>
          </ul>

          <p>
            於業內累積超過二十年財務服務經驗。
            曾處理多項本港上市公司年審、境外架構與內地收購合併個案。
          </p>
        </div>
      </div>

      <div
        fxFlex="80"
        fxHide.gt-xs
        fxLayout="column"
        fxLayoutAlign="space-evenly center"
        style="padding: 0 2rem"
      >
        <h3>榮譽顧問</h3>
        <h3><strong>司徒文輝先生</strong></h3>
        <ul>
          <li>工商管理碩士（MBA）</li>
          <li>美國公認會計師公會會員（CPA（USA））</li>
          <li>紐約州注冊會計師</li>
        </ul>

        <p>
          於業內累積超過二十年財務服務經驗。
          曾處理多項本港上市公司年審、境外架構與內地收購合併個案。
        </p>
      </div>

      <!-- offshore director -->
      <div fxFlex="25" class="team-box" fxHide.xs>
        <div class="front offshore-director">
          <p>離岸總監</p>
        </div>
        <div class="back">
          <h3>離岸業務總監</h3>
          <h3><strong>Sezer Murat Uygar 先生</strong></h3>

          <p>
            Sezer 於土耳其 Erciyes University 獲得酒店管理學學士。
            加入本公司前曾於不同私人企業担任客戶服務及法律相關工作。
            Sezer與我們共同努力下建立大香港離岸服務部，協助客戶拓展亞洲和歐洲之間的跨境業務及為兩地的客戶擴展國際業務。同時，他亦專注為歐洲公司在香港建立市場提供諮詢。
          </p>
        </div>
      </div>

      <div
        fxFlex="80"
        fxHide.gt-xs
        fxLayout="column"
        fxLayoutAlign="space-evenly center"
        style="padding: 0 2rem"
      >
        <h3>離岸總監</h3>
        <h3><strong>Sezer Murat Uygar 先生</strong></h3>

        <p>
          Sezer 於土耳其 Erciyes University 獲得酒店管理學學士。
          加入本公司前曾於不同私人企業担任客戶服務及法律相關工作。
          Sezer與我們共同努力下建立大香港離岸服務部，協助客戶拓展亞洲和歐洲之間的跨境業務及為兩地的客戶擴展國際業務。同時，他亦專注為歐洲公司在香港建立市場提供諮詢。
        </p>
      </div>
    </div>
  </div>
</section>
