<!-- <p>invoice-template works!</p> -->
<div class="a4" fxLayout="column" fxLayoutAlign="space-between">
  <section class="header">
    <img src="assets/images/brown-l-logo.png" alt="" />
    <p>發票</p>
  </section>
  <section class="content">
    <div fxLayout fxLayoutAlign="space-between">
      <p>Date</p>
      <p>Ref No</p>
    </div>
    <div>
      <p>company name</p>
      <p>name</p>
      <p>
        contact number <br />
        contact email
      </p>
    </div>
    <div>
      <p>
        有關: [公司秘書服務] <br />
        Re: [Company Secretarial Services]
      </p>
    </div>
    <div class="work" fxLayout fxLayoutAlign="space-between">
      <div fxLayout="column">
        <span>工作範圍 Scope of Work</span>
        <span>1. xxx</span>
        <span>2. yyy</span>
        <span>3. zzz</span>
      </div>
      <div fxLayout="column" fxLayoutAlign="start center">
        <span>HK$</span>
        <span>1000</span>
        <span>2000</span>
        <span>3000</span>
        <hr />
        <span>6000</span>
      </div>
    </div>
    <div>
      <p>我們在此就我們的專業工作範圍、相關專業服務收費及付款方式設定如下：-</p>
      <p>
        We refer to our engagement and have set out below our scope of work,
        professional charges and payments details for your kindly attention.
      </p>
    </div>
  </section>
  <section class="payment-details">
    <p>
      支票枱頭請寫 “大香港企業服務平台有限公司 ” <br />
      Please make a cheque payable to “Tai Hong Kong Corporate Services Platform
      Limited”
      <br />
      匯豐銀行有限公司
      <br />
      Hong Kong Bank <br />
      戶口號碼 /A/C#484-518410-838 <br />
      Swift Code: HSBCHKHHHKH <br />
      Bank Code: 004
    </p>
  </section>
  <section class="footer">
    <p>
      大香港企業服務平台有限公司
      <br />
      Tai Hong Kong Corporate Services Platform Limited
    </p>
    <div fxLayout fxLayoutAlign="space-between">
      <img class="chop" src="assets/images/invoice/chop.png" alt="" />
      <div fxLayout="column" fxFlex="60">
        <div fxLayout fxLayoutAlign="start center">
          <img class="icon" src="assets/images/invoice/phone.png" alt="" />
          <p>+852 3611 5773</p>
        </div>
        <div fxLayout fxLayoutAlign="start center">
          <img class="icon" src="assets/images/invoice/fax.png" alt="" />
          <p>+852 3591 6114</p>
        </div>
        <div fxLayout fxLayoutAlign="start start">
          <img class="icon" src="assets/images/invoice/address.png" alt="" />
          <p>
            總辦事處: 九龍觀塘道414號亞太中心29樓 <br />
            客戶聯絡辦事處: 觀塘成業街18號新怡生工業大廈10樓
          </p>
        </div>
      </div>
    </div>
  </section>
</div>
