<!-- zh -->
<div *ngIf="lang === 'zh'">
  <p>關於我們</p>
</div>
<!-- end of zh -->

<!-- en -->
<div *ngIf="lang === 'en'">
  <p>About Us</p>
</div>
<!-- end of en -->
