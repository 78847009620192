<!-- zh -->
<div *ngIf="lang === 'zh'" fxLayout="column" fxLayoutAlign="center stretch">
  <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
    <img
      src="assets/images/hong-kong-zh.jpg"
      alt="hongkong"
      class="banner-image"
    />
  </div>

  <section
    fxLayout="row"
    fxLayoutAlign="center"
    style="background-color: white"
  >
    <div
      fxLayout="column"
      fxFlex="60"
      fxFlex.xs="80"
      fxLayoutAlign="center stretch"
    >
      <h1 fxHide.xs="true">成立香港有限公司</h1>
      <h3 fxHide.gt-xs="true">成立香港有限公司</h3>

      <div
        fxLayout="row"
        fxLayoutAlign="center start"
        fxLayoutAlign.xs="center center"
        fxLayout.xs="column"
      >
        <div
          fxFlex="50"
          fxFlex.xs="100"
          fxLayoutAlign.xs="center stretch"
          fxLayout.xs="column"
        >
          <div class="plan-head-top">
            <h2>{{ (package | async)?.name }}</h2>
          </div>

          <div class="plan-head-bottom">
            <p class="plan-period">{{ (package | async)?.period }}</p>
            <div class="plan-price">
              <span class="plan-currency">HK$</span
              ><span class="plan-amount">{{ (package | async)?.price }}</span>
            </div>
            <p class="plan-desc">{{ (package | async)?.description }}</p>
            <p>{{ (package | async)?.time }}</p>
          </div>

          <div class="plan-body">
            <div *ngFor="let content of (package | async)?.content">
              <p *ngIf="!content.name">{{ content }}</p>
              <p *ngIf="content.name">{{ content.name }}</p>
              <p *ngIf="content.description" style="font-size: small">
                {{ content.description }}
              </p>
              <hr />
            </div>
          </div>

          <!-- <div class="plan-body">
            <p>公司名稱查冊</p>
            <hr />
            <p>公司註冊費 (HK$1720)</p>
            <hr />
            <p>首年商業登記徵費 (HK$250)</p>
            <hr />
            <p>向政府有關部門遞交文件及費用</p>
            <hr />
            <p>領取公司註冊證書</p>
            <hr />
            <p>領取商業登記證</p>
            <hr />
            <p>銀行開戶所需文件</p>
            <hr />
            <p>公司組織章程細則 8 本</p>
            <hr />
            <p>鋼印 (Common Seal)</p>
            <hr />
            <p>簽名印 (Signature Chop)</p>
            <hr />
            <p>圓印 (22mm)</p>
            <hr />
            <p>公司記錄冊一本</p>
            <hr />
            <p>股票冊一本</p>
            <hr />
            <p>精美綠盒 (已燙有金色公司名稱)</p>
          </div> -->

          <div class="plan-foot">
            <a mat-button routerLink="/register-limited-company-form"
              ><h2>申請</h2></a
            >
          </div>
        </div>

        <div
          fxFlex="auto"
          fxFlexOffset="10"
          fxFlexOffset.xs="0"
          fxFlex.xs="100"
        >
          <h2>成立公司相關服務</h2>

          <div
            *ngFor="let service of (relevantServices | async)?.services"
            class="side-bar-item"
            fxLayout="row"
            fxLayoutAlign="start start"
          >
            <button mat-fab color="accent">
              <mat-icon>{{ service.icon }}</mat-icon>
            </button>
            <div>
              <p>{{ service.name }}</p>
              <button
                class="grey-button"
                mat-flat-button
                routerLink="/{{ service.link }}"
              >
                暸解更多
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section
    fxLayout="row"
    fxLayoutAlign="center"
    style="background-color: whitesmoke"
  >
    <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
      <p class="primary-color">
        <i><strong> 請問貴司之公司股東及董事是否均持有香港身份證？</strong></i>
      </p>
      <p>
        是，本公司股東及董事均持有香港身份證， 立即前往下單
        <button
          mat-fab
          color="primary"
          routerLink="/register-limited-company-form"
        >
          <mat-icon>add_shopping_cart</mat-icon>
        </button>
      </p>
      <p>
        否，按此轉往客戶經理作線下電話支援
        <app-whatsapp [lang]="'zh'"></app-whatsapp>
      </p>
    </div>
  </section>

  <section
    fxLayout="row"
    fxLayoutAlign="center"
    style="background-color: white"
  >
    <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
      <h2>成立公司所需資料及申請程序</h2>
      <div style="width: 80%; border: 1px solid #efefef; padding: 1rem">
        <mat-tab-group>
          <mat-tab label="所需資料">
            <ul>
              <li>擬定新公司名稱</li>
              <li>註冊資本 (一般設定為HK$10,000)</li>
              <li>創辦成員(股東)之名稱、地址、及認購股份數目</li>
              <li>董事之姓名、地址、身份証/海外護照/公司註冊號碼</li>
              <li>
                公司秘書之名稱、地址、香港身份証或香港公司註冊號碼
                (本公司可提供，$600/年)
              </li>
              <li>公司之香港註冊地址(本公司可提供，$1300/年)</li>
            </ul>
          </mat-tab>
          <mat-tab label="申請程序">
            <ul>
              <li>
                客戶填寫網上<a routerLink="/register-limited-company-form"
                  >申請表格
                </a>
              </li>
              <li>
                請客戶於本平台<a routerLink="/register-limited-company-form"
                  >申請表格</a
                >系統上載股東及董事身份證及地址證明
              </li>
              <li>
                我們將查核擬採用的公司名稱是否可用。我們將於1個工作天內備妥所需文件及電郵客戶確認。
              </li>
              <li>
                客戶可選擇全數費用以現金、轉帳方式存至我們的銀行戶口(匯豐銀行
                A/C#484-518410-838 或 中國銀行 A/C#012-394-20164429 或轉數快 95126339) 付款。
              </li>
              <li>
                我們向公司註冊處、稅務局遞交文件及費用，進行審批及簽發公司證書。
              </li>
              <li>
                完成審批後，我們將通知客戶領取全套公司文件或快遞到付送至客戶。
              </li>
              <li>客戶可憑我們提供之文件，於銀行開設公司戶口。</li>
            </ul>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </section>

  <section
    fxLayout="row"
    fxLayoutAlign="center"
    style="background-color: #b2988f"
  >
    <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
      <h3>經大香港成立香港有限公司的重要條件</h3>
      <p>
        根據法例，我們須核實股東及董事身份，驗證或經銀行FPS支付，身份證明文件，三個月住址證明及所有申請人須到本公司簽署文件，如未能親身到本公司簽署文件，須提供兩份不同的身份證明文件作核實身份。
      </p>
    </div>
  </section>
</div>
<!-- end of zh -->

<!-- en -->
<div *ngIf="lang === 'en'" fxLayout="column" fxLayoutAlign="center stretch">
  <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
    <img
      src="assets/images/hong-kong-en.jpg"
      alt="hongkong"
      class="banner-image"
    />
  </div>

  <!-- <section fxLayout="row"
  fxLayoutAlign="center">
    <iframe src="https://www.youtube.com/embed/cL1t3Q-K3Hs"> </iframe>
  </section> -->

  <section
    fxLayout="row"
    fxLayoutAlign="center"
    style="background-color: white"
  >
    <div
      fxLayout="column"
      fxFlex="60"
      fxFlex.xs="80"
      fxLayoutAlign="center stretch"
    >
      <h1 fxHide.xs="true">Incorporation of a Hong Kong Company</h1>
      <h3 fxHide.gt-xs="true">Incorporation of a Hong Kong Company</h3>

      <div
        fxLayout="row"
        fxLayoutAlign="center start"
        fxLayoutAlign.xs="center center"
        fxLayout.xs="column"
      >
        <div
          fxFlex="50"
          fxFlex.xs="100"
          fxLayoutAlign.xs="center stretch"
          fxLayout.xs="column"
        >
          <div class="plan-head-top">
            <h2>{{ (package_en | async)?.name }}</h2>
          </div>

          <div class="plan-head-bottom">
            <p class="plan-period">{{ (package_en | async)?.period }}</p>
            <div class="plan-price">
              <span class="plan-currency">HK$</span
                ><span class="plan-amount">{{ (package_en | async)?.price }}</span>
              </div>
              <p class="plan-desc">{{ (package_en | async)?.description }}</p>
              <p>{{ (package_en | async)?.time }}</p>
            </div>

            <div class="plan-body">
              <div *ngFor="let content of (package_en | async)?.content">
                <p *ngIf="!content.name">{{ content }}</p>
                <p *ngIf="content.name">{{ content.name }}</p>
                <p *ngIf="content.description" style="font-size: small">
                  {{ content.description }}
                </p>
                <hr />
              </div>
            </div>

          <div class="plan-foot">
            <a mat-button routerLink="/register-limited-company-form"
              ><h2>Apply</h2></a
            >
          </div>
        </div>

        <div
          fxFlex="auto"
          fxFlexOffset="10"
          fxFlexOffset.xs="0"
          fxFlex.xs="100"
        >
          <h2>Company incorporation-related services</h2>

          <div class="side-bar-item" fxLayout="row" fxLayoutAlign="start start">
            <button mat-fab color="accent"><mat-icon>inbox</mat-icon></button>
            <div>
              <p>Act as yearly named company secretary $600/YEAR</p>
              <button
                class="grey-button"
                mat-flat-button
                routerLink="/annual-maintenance"
              >
                Learn more
              </button>
            </div>
          </div>

          <div class="side-bar-item" fxLayout="row" fxLayoutAlign="start start">
            <button mat-fab color="accent"><mat-icon>home</mat-icon></button>
            <div>
              <p>Provision of Registered Address $1,300/YEAR</p>
              <button
                class="grey-button"
                mat-flat-button
                routerLink="/annual-maintenance"
              >
                Learn more
              </button>
            </div>
          </div>

          <div class="side-bar-item" fxLayout="row" fxLayoutAlign="start start">
            <button mat-fab color="accent">
              <mat-icon>account_balance</mat-icon>
            </button>
            <div>
              <p>Local Bank Account Opening Service</p>
              <button
                class="grey-button"
                mat-flat-button
                routerLink="/open-hong-kong-bank"
              >
                Learn more
              </button>
            </div>
          </div>

          <div class="side-bar-item" fxLayout="row" fxLayoutAlign="start start">
            <button mat-fab color="accent"><mat-icon>star</mat-icon></button>
            <div>
              <p>Trademark Registration $2,500</p>
              <button
                class="grey-button"
                mat-flat-button
                routerLink="/trademark"
              >
                Learn more
              </button>
            </div>
          </div>

          <div class="side-bar-item" fxLayout="row" fxLayoutAlign="start start">
            <button mat-fab color="accent">
              <mat-icon>business_center</mat-icon>
            </button>
            <div>
              <p>Accounting & Tax Service</p>
              <button
                class="grey-button"
                mat-flat-button
                routerLink="/account-tax"
              >
                Learn More
              </button>
            </div>
          </div>

          <div class="side-bar-item" fxLayout="row" fxLayoutAlign="start start">
            <button mat-fab color="accent"><mat-icon>near_me</mat-icon></button>
            <div>
              <p>Government Funding Application</p>
              <button
                class="grey-button"
                mat-flat-button
                routerLink="/gov-funds"
              >
                Learn more
              </button>
            </div>
          </div>

          <div class="side-bar-item" fxLayout="row" fxLayoutAlign="start start">
            <button mat-fab color="accent">
              <mat-icon>attach_money</mat-icon>
            </button>
            <div>
              <p>Corporate Financing Service</p>
              <button
                class="grey-button"
                mat-flat-button
                routerLink="/corporate-finance"
              >
                Learn more
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section
    fxLayout="row"
    fxLayoutAlign="center"
    style="background-color: whitesmoke"
  >
    <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
      <p class="primary-color">
        <i
          ><strong>
            Do your company’s shareholders and directors hold Hong Kong identity
            cards?</strong
          ></i
        >
      </p>
      <p>
        Yes, The shareholders and directors of the company hold Hong Kong
        identity cards. Order now
        <button
          mat-fab
          color="primary"
          routerLink="/register-limited-company-form"
        >
          <mat-icon>add_shopping_cart</mat-icon>
        </button>
      </p>
      <p>
        No, click here to transfer to platform managers for offline phone
        support <app-whatsapp [lang]="'en'"></app-whatsapp>
      </p>
    </div>
  </section>

  <section
    fxLayout="row"
    fxLayoutAlign="center"
    style="background-color: white"
  >
    <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
      <h2>
        INFORMATION AND APPLICATION PROCEDURES REQUIRED TO ESTABLISH A HONG KONG
        COMPANY
      </h2>
      <div style="width: 80%; border: 1px solid #efefef; padding: 1rem">
        <mat-tab-group>
          <mat-tab label="Required information">
            <ul>
              <li>Proposed new company name</li>
              <li>Registered share capital (Generally set to HK$10,000)</li>
              <li>
                Founder members’ name, address and no. of subscribe shares
              </li>
              <li>
                Directors’ name, address, ID/ passport/ Certificate of
                Incorporation number (if corporate director)
              </li>
              <li>
                Secretary’s name, address, ID/ passport/ Certificate of
                Incorporation number if corporate secretary ( Tai Hong Kong
                offer for $600/each year)
              </li>
              <li>
                Hong Kong Registered Address (Tai Hong Kong offer for $1300/each
                year)
              </li>
            </ul>
          </mat-tab>
          <mat-tab label="Application Procedures">
            <ul>
              <li>
                Client fills out the
                <a routerLink="/register-limited-company-form"
                  >online application form</a
                >.
              </li>
              <li>
                Clients are requested to upload shareholder and director ID
                cards and address certificates in the
                <a routerLink="/register-limited-company-form"
                  >application form</a
                >
                system of this platform.
              </li>
              <li>
                We will check whether the proposed company name is available. We
                will prepare the required documents and email customer
                confirmation within 1 working day.
              </li>
              <li>
                Clients can choose to deposit the full amount in cash or
                transfer to our bank account (HSBC A/C#484-518410-838 or BOC A/C#012-394-20164429 or FPS 95126339)) for
                payment.
              </li>
              <li>
                We will submit documents and fees to the Companies Registry for
                approval.
              </li>
              <li>
                After completing the approval, we will notify the clients to
                pick up a full set of company registered documents or send it to
                the client by express delivery (delivery charges paid by
                addressee).
              </li>
              <li>
                Client can open company bank accounts with the documents
                provided by us.
              </li>
            </ul>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </section>

  <section
    fxLayout="row"
    fxLayoutAlign="center"
    style="background-color: #b2988f"
  >
    <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
      <h3>
        Important conditions for incorporation of a Hong Kong limited company
        through Tai Hong Kong Corporate Service Platform
      </h3>
      <p>
        According to the law, we must verify the identity of shareholders and
        directors of the company, it could be verified by bank-FPS payment, the
        Identity document and latest three-month address proof. If clients who
        fail to pass the document verification, you must go to our office to
        verify your identity.
      </p>
    </div>
  </section>
</div>
<!-- end of en -->
