import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ContactFormDialogComponent } from '../contact-form-dialog/contact-form-dialog.component';
import { DataService } from '../data.service';

@Component({
  selector: 'app-add-to-cart-button',
  templateUrl: './add-to-cart-button.component.html',
  styleUrls: ['./add-to-cart-button.component.scss'],
})
export class AddToCartButtonComponent implements OnInit {
  @Input() serviceId: string;
  serviceData;
  constructor(public dialog: MatDialog, public data: DataService) {
    this.serviceData = data.services;
  }

  ngOnInit(): void {}

  openDialog() {
    const dialogRef = this.dialog.open(ContactFormDialogComponent, {
      width: '350px',
      data: {
        serviceId: this.serviceId,
        // service: this.serviceData[this.serviceId].name_zh,
        // charge: this.serviceData[this.serviceId].charge_desc_zh,
      },
    });
  }
}
