<!-- zh -->
<div *ngIf="lang === 'zh'" fxLayout="column" fxLayoutAlign="center stretch">
  <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
    <img
      src="assets/images/corporate-change.jpg"
      alt="banner"
      class="banner-image"
    />
    <div class="banner-text" style="transform: translate(-60%, -30%)"></div>
  </div>

  <section
    fxLayout="row"
    fxLayoutAlign="center"
    style="background-color: white"
  >
    <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
      <p></p>
      <p>
        公司股份轉讓時常都會發生，原因可以是生意轉讓
        (公司轉讓)，可以是更改股權比例，股東退股，亦可因為增添新的股東成員。本文分享《香港有限公司轉讓》程序和注意事宜、股份轉讓書等相關所需文件，讓有意轉股人士早作準備。
      </p>
      <h2>公司股份轉讓注意事項</h2>
      <p>
        以下是公司轉讓及有限公司股份轉讓前注意事項，如屬整間公司轉讓，新買家要特別注意。
      </p>
      <ul>
        <li>公司有沒有未清繳的政府費用 (例如: 商業登記證費、稅款等)</li>
        <li>
          公司稅務狀況如何？會計賬目有沒有按法例規定進行核數，會計憑證有否妥善保存
        </li>
        <li>公司有沒有潛在負債或責任需要履行</li>
        <li>
          如轉讓的公司有一定規模，考慮轉讓委託第三方會計師進行盡職調查，確保交易公平進行
        </li>
      </ul>

      <h2>股份轉讓所需資料</h2>
      <ul>
        <li>公司名稱</li>
        <li>現有公司股權比例分佈</li>
        <li>提供轉讓人和受讓人姓名、地址、轉讓股數、轉讓價</li>
        <li>交易日期</li>
      </ul>

      <h2>股份轉讓所需文件</h2>
      <ul>
        <li>公司章程 (articles of association)</li>
        <li>最近期周年申報稅 (NAR1)</li>
        <li>有限公司成立表格 (NNC1) (如轉股日期未到首個公司周年日)</li>
        <li>股份轉讓的董事會議記錄</li>
        <li>股份轉讓協議書 (Instrument of Transfer)</li>
        <li>股份買賣書 (Bought Note & Sold Note)</li>
        <li>
          最近期的經審計的財務報表。如果還沒有經審計的財務報表，則需提供最近期
          (股份轉讓日期3個月內) 的管理財務報表（management accounts）
        </li>
        <li>
          如果轉股公司持有子公司，也需要提供子公司最近期的經審計的財務報表。如果還沒有經審計的財務報表，則需提供最近期
          (股份轉讓日期3個月內) 的管理財務報表（management accounts）
        </li>
        <li>
          公司董事或轉讓人簽署的書面確認，確認此股份轉讓没有買賣合同，轉股公司没有持有房地產及公司是否還未開始營業。
        </li>
      </ul>

      <h2>股份轉讓流程</h2>
      <ol>
        <li>
          所有股東確認同意股份轉讓（因各股東持有優先購買權，因此需要獲得其他股東同意此次股份轉讓）
        </li>
        <li>安排股份轉讓表由轉讓人和承讓人簽署</li>
        <li>親身到印花稅署辦理加蓋印花手續，或將文件郵寄到本署加蓋印花</li>
        <li>即日領取加蓋印花的轉讓文件</li>
        <li>董事會簽署決議批准把已加蓋印花的股份轉讓交易登記入股東名冊內。</li>
        <li>安排發出新股票給各股東</li>
      </ol>

      <h2>股份轉讓印花稅</h2>
      <p>
        香港稅務局會就股份轉讓徵收印花稅。根據《印花稅條例》規定，買賣任何香港股票均須徵收印花稅。除了每份股份轉讓書港幣5元的費用外，雙方在股份轉讓時亦需按轉讓股份的公司淨資產或轉股代價（兩者以較高為選）的0.13%
        計算 (一共0.26%)。
      </p>
      <p>
        舉例：甲方把其持有的普通股1
        股售予丙方。該筆股份代表30%的權益。該公司的資產淨值是40萬元。甲丙雙方需繳付的印花稅金額如下：
      </p>
      <ul>
        <li>印花稅金額 = （40萬 x 30%）x 0.13% x 2 + 5 = 317元</li>
        <li>
          <a
            href="https://www.ird.gov.hk/chi/ese/sd_comp/csdshare.htm"
            target="_blank"
          >
            <strong style="font-size: larger"> 印花稅計算機 </strong>
            <img
              src="assets/images/svg/calculator.svg"
              style="margin-left: 1rem; height: 2rem"
            />
          </a>
        </li>
      </ul>

      <h2>加蓋印花時限</h2>
      <table>
        <thead>
          <tr>
            <th>文件類別</th>
            <th>加蓋印花時限</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>售賣或購買任何香港證券的成交單據</th>
            <th>
              如售賣或購買在香港完成，<br />
              則在售賣或購買後 2 天內；<br />
              <br />
              如售賣或購買在其他地方完成，<br />則在售賣或購買後 30 天內
            </th>
          </tr>
          <tr>
            <th>無償產權處置轉讓書（即餽贈）</th>
            <th>簽立後 7 天內；如在香港以外地方簽立，則在簽立後 30 天內</th>
          </tr>
          <tr>
            <th>任何其他種類的轉讓書</th>
            <th>簽立之前；<br />如在香港以外地方簽立，則在簽立後 30 天內</th>
          </tr>
        </tbody>
      </table>

      <h2>股份轉讓逾期罰款</h2>
      <table>
        <thead>
          <tr>
            <th>逾期</th>
            <th>罰款</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>不超過 1 個月</th>
            <th>印花稅款額的 2 倍</th>
          </tr>
          <tr>
            <th>超過 1 個月，但不超過 2 個月</th>
            <th>印花稅款額的 4 倍</th>
          </tr>
          <tr>
            <th>其他情況</th>
            <th>印花稅款額的 10 倍</th>
          </tr>
        </tbody>
      </table>

      <h2>完成轉讓後，何時要通知公司註冊處?</h2>
      <p>
        公司無須即時通知公司註冊處申報股份轉讓的詳情，但公司必須在股份轉讓後製備的首份周年申報表
        (表格NAR1)
        作出申報。但如公司轉讓牽涉董事辭任和委任，公司須遞交ND2A到公司註冊處作出相關申報
        (新董事委任15日內)。
      </p>
    </div>
  </section>
  <section
    fxLayout="column"
    fxLayoutAlign="center center"
    style="background-color: white"
  >
    <app-four-steps></app-four-steps>
    <a
      mat-raised-button
      color="primary"
      class="get-form-button"
      href="https://firebasestorage.googleapis.com/v0/b/tai-hong-kong-platform.appspot.com/o/spreadsheets%2FTransferSharesForm.xlsx?alt=media&token=888fe521-12bc-4b39-a470-4d942d47c014"
      download
    >
      下載申請表格
    </a>
  </section>
</div>
