<!-- zh -->
<div *ngIf="lang === 'zh'" fxLayout="column" fxLayoutAlign="center stretch">
  <section
    fxLayout="row"
    fxLayoutAlign="center"
    style="background-color: white"
  >
    <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
      <form [formGroup]="requestInvoiceForm" (ngSubmit)="onSubmit()">
        <div [formGroup]="requestService">
          <h3>選擇需要的服務</h3>

          <!-- <div>
            <mat-form-field>
              <mat-label>服務</mat-label>
              <mat-select type="text" [formControl]="serviceControl" multiple>
                <mat-option
                  *ngFor="let service of serviceFee | keyvalue; index as i"
                  [value]="service"
                  >{{ service.key }} - HK${{ service.value }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div> -->
          <div
            fxLayout.xs="column"
            fxLayout="row wrap"
            fxLayoutAlign.xs="start stretch"
            fxLayoutAlign="start center"
          >
            <div
              fxFlex="15"
              class="service-box"
              *ngFor="let service of serviceFee | keyvalue"
              fxLayout="column"
              fxLayoutAlign="center center"
              (click)="toggleService(service.key)"
              [ngClass]="{ selected: selectedService[service.key] }"
            >
              <p>
                {{ service.value.name }}<br /><span
                  *ngIf="service.value.name_desc"
                  >({{ service.value.name_desc }})</span
                >
              </p>
              <p>{{ service.value.charge_desc }}</p>
            </div>
          </div>
        </div>

        <h3>聯絡人</h3>
        <div class="contact-person" [formGroup]="contactPerson" fxLayout="column" fxLayoutAlign="center center">
          <div fxFlex="80">
            <mat-form-field>
              <mat-label>公司名稱〈需填寫〉</mat-label>
              <input
                matInput
                type="text"
                [formControl]="companyNameControl"
                required
              />
              <mat-error *ngIf="companyNameControl.hasError('required')"
                >必須填寫</mat-error
              >
            </mat-form-field>
          </div>

          <div fxFlex="80">
            <mat-form-field>
              <mat-label>您的姓名〈需填寫〉</mat-label>
              <input
                matInput
                type="text"
                [formControl]="contactPersonNameControl"
                required
              />
              <mat-error *ngIf="contactPersonNameControl.hasError('required')"
                >必須填寫</mat-error
              >
            </mat-form-field>
          </div>

          <div fxFlex="80">
            <mat-form-field>
              <mat-label>稱呼〈需填寫〉</mat-label>
              <mat-select
                type="text"
                [formControl]="contactPersonTitleControl"
                required
              >
                <mat-option value="mr">先生</mat-option>
                <mat-option value="ms">女士</mat-option>
              </mat-select>
              <mat-error *ngIf="contactPersonTitleControl.hasError('required')"
                >必須填寫</mat-error
              >
            </mat-form-field>
          </div>

          <div fxFlex="80">
            <mat-form-field>
              <mat-label>您的電子郵件信箱〈需填寫〉</mat-label>
              <input
                matInput
                type="email"
                [formControl]="contactPersonEmailControl"
                required
              />
              <mat-error *ngIf="contactPersonEmailControl.hasError('required')"
                >必須填寫</mat-error
              >
              <mat-error *ngIf="contactPersonEmailControl.hasError('email')"
                >請填寫有效的電子郵件格式</mat-error
              >
            </mat-form-field>
          </div>

          <div fxFlex="80">
            <mat-form-field>
              <mat-label>聯絡電話〈需填寫〉</mat-label>
              <input
                matInput
                type="text"
                [formControl]="contactPersonMobileControl"
                required
              />
              <mat-error *ngIf="contactPersonMobileControl.hasError('required')"
                >必須填寫</mat-error
              >
              <mat-error *ngIf="contactPersonMobileControl.hasError('pattern')"
                >請填寫有效的聯絡電話</mat-error
              >
            </mat-form-field>
          </div>

          <button type="submit" mat-raised-button [disabled]="contactPerson.invalid">Submit</button>
        </div>
      </form>
    </div>
  </section>
</div>
