<!-- zh -->
<div *ngIf="lang === 'zh'" fxLayout="column" fxLayoutAlign="center stretch">
  <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
    <img
      src="assets/images/virtual-office.jpg"
      alt="virtual office"
      class="banner-image"
    />
    <div class="banner-text">
      <p>虛擬辦公室</p>
    </div>
  </div>
  <section
    fxLayout="row"
    fxLayoutAlign="center"
    style="background-color: white"
  >
    <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">
      <p></p>
      <h1>大香港．虛擬辦公室服務</h1>
      <p style="text-align: justify">
        我們明白企業形象的重要性，能達到良好的企業形象必須要有一個能配合企業形象的地址，更能令客戶增加對公司的信心。
        企業除了重視虛擬辦公室地址的卓越度外，還會考慮能否同時提供不同類型的增值服務。
        因此大香港的虛擬辦公室服務除了能提昇企業形象外，更能滿足公司的實際營運需要。
      </p>
      <p style="text-align: justify">
        客戶的遇見的問題就是我們改變的動力。我們不時按客戶的需求，市場環境的改變，推出新的增值服務；相比其他商務中心，服務更多樣化而且體貼全面，更具彈性。
        此外，我們亦著重保持與客戶的雙向數碼溝通，不繼優化每個營運流程以確立優質服務的基礎，為客戶帶來嶄新的服務體驗。
      </p>
      <div fxLayout="row wrap" fxFlex="80" fxLayoutAlign="space-around center">
        <mat-card fxFlex="40" fxFlex.xs="80">
          <mat-card-content fxLayout="column" fxLayoutAlign="center center">
            <div class="icon-wrapper">
              <mat-icon>business</mat-icon>
            </div>
            <h2>中英文公司名水牌</h2>
          </mat-card-content>
        </mat-card>

        <mat-card fxFlex="40" fxFlex.xs="80">
          <mat-card-content fxLayout="column" fxLayoutAlign="center center">
            <div class="icon-wrapper">
              <mat-icon>alarm</mat-icon>
            </div>
            <h2>延長服務時間</h2>
          </mat-card-content>
        </mat-card>

        <mat-card fxFlex="40" fxFlex.xs="80">
          <mat-card-content fxLayout="column" fxLayoutAlign="center center">
            <div class="icon-wrapper">
              <mat-icon>account_balance</mat-icon>
            </div>
            <h2>中環商業區地址供選擇</h2>
          </mat-card-content>
        </mat-card>

        <mat-card fxFlex="40" fxFlex.xs="80">
          <mat-card-content fxLayout="column" fxLayoutAlign="center center">
            <div class="icon-wrapper">
              <mat-icon>control_camera</mat-icon>
            </div>
            <h2>全方位企業配套服務</h2>
          </mat-card-content>
        </mat-card>

        <mat-card fxFlex="40" fxFlex.xs="80">
          <mat-card-content fxLayout="column" fxLayoutAlign="center center">
            <div class="icon-wrapper">
              <mat-icon>email</mat-icon>
            </div>
            <h2>中港、國際互遞信件包裹</h2>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </section>

  <section
    fxLayout="column"
    fxLayoutAlign="center center"
    style="background-color: white"
  >
    <app-four-steps></app-four-steps>
  </section>

  <section
    fxLayout="column"
    fxLayoutAlign="center center"
    style="background-color: white"
  >
    <section
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutAlign="center"
      fxFlex="80"
      style="background-color: white"
    >
      <div
        fxFlex="30"
        fxFlex.xs="80"
        fxLayoutAlign="center stretch"
        style="margin: 10px"
      >
        <app-virtual-office-price-chart
          [priceChartData]="plans.PLAN_A_ZH"
        ></app-virtual-office-price-chart>
      </div>
      <div
        fxFlex="30"
        fxFlex.xs="80"
        fxLayoutAlign="center stretch"
        style="margin: 10px"
      >
        <app-virtual-office-price-chart
          [priceChartData]="plans.PLAN_B_ZH"
        ></app-virtual-office-price-chart>
      </div>
      <div
        fxFlex="30"
        fxFlex.xs="80"
        fxLayoutAlign="center stretch"
        style="margin: 10px"
      >
        <app-virtual-office-price-chart
          [priceChartData]="plans.PLAN_C_ZH"
        ></app-virtual-office-price-chart>
      </div>
    </section>
    <p><i>* (包裏體積超過30cm闊 x 30cm高 x5cm深或重於1kg) 收費另計 </i></p>
  </section>

  <section
    fxLayout="row"
    fxLayoutAlign="center"
    style="background-color: white"
  >
    <div fxLayout="column" fxFlex="80" fxLayoutAlign="center stretch">

      <h3>虛擬辦公室服務申請流程</h3>

      <p>
        <strong style="text-decoration: underline">申請</strong><br />
        下載表格填妥後電郵或Whatapps我們，我們會於1個工作天內與閣下聯絡，確認服務要求並核對相關資料。
      </p>

      <p>
        <strong style="text-decoration: underline">驗證</strong><br />
        收到申請表格後，我們會向閣下收取所需文件，進行身份驗證及盡職審查*。<br />
        <i
          >*根據2018年政府修訂及推出新公司條例，公司服務提供者必須申領「信託或公司服務牌照」，並按照《打擊洗錢及恐怖分子資金籌集條例》向客戶進行「盡職審查」。 如客戶拒絕提供文件作盡職審查之用，有關服務會被取消及收取$500 作行政費用。
        </i>
      </p>

      <p>
        <strong style="text-decoration: underline">繳費</strong><br />
        驗証成功及確定申請服務後會向閣下提供帳單，以便繳付所需收費費用。可親臨現金繳付，郵寄支票，銀行轉賬、轉數快等方式。<br />
        <i>Cash, Cheque, </i
        ><img
          src="assets/images/svg/fps_color.svg"
          style="height: 2rem; vertical-align: middle"
        />
      </p>

      <p>
        <strong style="text-decoration: underline">生效</strong><br />
        驗證成功後，虛擬辦公室服務會在1-2個工作天內生效。 虛擬辦公室生效期為一年，如不續約或未有支付費用或失聯或沒有收到指示情況下，我們會幫助貴公司註冊地址轉往公司主要股東或董事或其他關連人士之通訊地址及收取$1300更改地址服務費用(費用按適時調整)，以方便貴公司能繼續接收重要信件。
        <br />
      </p>

      <h3>虛擬辦公室服務常見問題</h3>

      <p>
        <strong>香港公司註冊地址有什麼作用及要求？</strong><br /><br />
        香港成立的有限公司註冊地址必須要在香港。
        註冊辦事地址是法例指定作為接收通知及程序的法定地址, 可不同於營業地址。
      </p>

      <p>
        <strong>什麼是虛擬辦公室?</strong><br /><br />
        虛擬辦公室英文 virtual
        office，虛擬辦公室意思是指非實體存在的辦公室。具體來說，為企業提供虛擬辦公室商業登記地址和支援服務，包括郵件處理、接聽電話、會議室等等，為無須租用實體辦公室的企業提供支援，從而提升企業形象，虛擬辦公室邊間好取決於您需要的服務。
      </p>

      <p>
        <strong>使用虛擬辦公室是合法嗎?</strong><br /><br />
        選用虛擬辦公室作為註冊辦事處地址是合法。根據《打擊洗錢條例》，持牌人必須持有「公司服務提供者牌照」及已向客戶進行「盡職審查」的情況下，才可以在香港經營及提供虛擬辦公室及註冊地址服務，否則即屬違法。因此客戶請放心我們提供的虛擬辦公室及註冊地址服務是合乎法例要求的。
      </p>

      <p>
        <strong> 是否必須要顯示公司名稱於水牌上? </strong><br /><br />
        法例規定公司須於註冊辦事處內放置能展示其公司名稱的水牌或顯示器，大香港的虛擬辦公室服務會為客戶提供水牌並於公司註冊地址內可見位置展示公司名稱給訪客。
      </p>

      <p>
        <strong> 可否使用虛擬辦公室服務代替公司接聽客戶來電? </strong
        ><br /><br />
        可以，大香港的虛擬辦公室服務提供專線電話供客戶使用，確保每一個打入的電話都會由專業的團隊以貴公司名義接聽電話，有助提升公司形象。
      </p>

      <p>
        <strong> 如果未能在辦公時間內領取信件，可以怎麼辦? </strong><br /><br />
        我們作為貴公司的公司註冊地址及收信地址同時亦提供信件轉寄服務，每逢雙月份（即2月，4月，6月，8月，10月，12月）將信件以平郵形式轉寄到指定地址，方便客戶收取信件。
      </p>

      <p>
        <strong> 虛擬辦公室可以怎樣協助公司發展業務? </strong><br /><br />
        無需投放大量資本，您便可以擁有一個交通非常便利的辦公室地址作為貴公司註冊地址，並馬上擁有專業的團隊提供前台接待、行政秘書、收發信件等服務，從而提升企業形象。
      </p>

      <p>
        <strong> 能提供代開信件服務嗎? </strong
        ><br /><br />
        有，我們的虛擬辦公室除了可以作為貴公司的註冊地址及收信地址外，我們同時也能提供代開信件及把相關信件即時掃描及通知貴公司，方便客戶能即時傳閱及存檔。
      </p>

      <h3>交通及地圖</h3>
      <agm-map
        [latitude]="lat"
        [longitude]="lng"
        [zoom]="zoom"
        style="height: 500px"
      >
        <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
      </agm-map>
      <p>
        香港中環皇后大道中168號6樓1室<br />

        港鐵中環D出口（10分鐘路程）<br />

        港鐵上環站E2出口（5分鐘路程） *建議使用<br />
        巴士： 1, 1P, 2, 3A, 4, 5B, 7, 8X, 10, 18, 26, 37A, 88R, 90B, 91, 94,
        101, 104, 109, 111, 113, 182, 301, 302, 307, 307A, 368, 373, 601P, 603,
        619, 641, 722, 900, 905, 907B, 914, 930, 934, 934A, 935, 936, 948, 960,
        961, 968, 969B, 969, 978, 978A, 979, 980A, 980X, 985, 985B<br />

        專線小巴： 55<br />

        停車場：中遠大廈
      </p>
    </div>
  </section>
</div>
<!-- end of zh -->

<!-- en -->
<div *ngIf="lang === 'en'" fxLayout="column" fxLayoutAlign="center center">
  <div style="height: 100vh" fxLayout="column" fxLayoutAlign="center center">
    <p>Only Chinese Version Available</p>
  </div>
</div>
<!-- end of en -->
